import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Img,
  useMediaQuery,
} from "@chakra-ui/react";
import moment from "moment";
import {
  BetaggedLogo,
  ChevronDown,
  HeaderMenuIcon,
  NotificationBell,
  LogoutIcon,
  ProfileIcon,
  MoneyBillIcon,
} from "@be-tagged/shared/src/assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import {
  Colors,
  getNotifications,
  readAllNotifications,
  readNotification,
  RootState,
  Strings,
  userLogout,
} from "@be-tagged/shared";
import { ROUTING_PARAMS, ROUTING_URLS } from "../../../app/constants";
import { memo, useEffect, useRef, useState } from "react";
import useAuth from "src/app/hooks/useAuth";
import { DEVICE } from "src/app/assets/styles/styleEnums";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import "./navbar.scss";
import TooltipWrapper from "src/app/components/TooltipWrapper";
const { StartFrom, ListQty } = FormikKeys;

interface NavItem {
  label: string;
  action: any;
  path?: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}
const PER_PAGE = 10;
let notificationInterval: any;

const Navbar = memo((): JSX.Element => {
  const listInnerRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [menuOpen, setMenuOpen] = useState(false);
  const [unreadNotification, setUnreadNotification] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const { name, isBrand, profilePic } = useAuth();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading } = useSelector((state: RootState) => {
    return {
      isLoading: state.appReducer.loading,
    };
  });
  const logOut = () => {
    dispatch(userLogout(goToHome));
  };

  const goToHome = () => {
    navigate(`${ROUTING_URLS.LANDING}`);
  };

  const goToCatalog = () => {
    navigate(`${ROUTING_URLS.LANDING}/${ROUTING_URLS.PRODUCTS_CATALOG}`);
  };
  const goToInsights = () => {
    navigate(`${ROUTING_URLS.LANDING}/${ROUTING_URLS.BUSINESS_INSIGHTS}`);
  };
  const goToTeam = () => {
    navigate(`${ROUTING_URLS.LANDING}/${ROUTING_URLS.TEAMS}`);
  };
  const goToProfile = () => {
    navigate(`${ROUTING_URLS.LANDING}/${ROUTING_URLS.PROFILE}`);
  };
  const goToBankDetails = () => {
    navigate(
      `${ROUTING_URLS.LANDING}/${ROUTING_URLS.PROFILE}?tab=${ROUTING_PARAMS.BANK_DETAILS}`
    );
  };

  const goToProductDetails = (productId: number) => {
    navigate(
      `${ROUTING_URLS.LANDING}/${ROUTING_URLS.PRODUCT_DETAILS}/${productId}`
    );
  };

  const goToProductListing = () => {
    navigate(`${ROUTING_URLS.LANDING}/${ROUTING_URLS.PRODUCT_LISTING}`);
  };

  const goToProductCataog = () => {
    navigate(`${ROUTING_URLS.LANDING}/${ROUTING_URLS.PRODUCTS_CATALOG}`);
  };

  const goToProductApprovalRequests = () => {
    navigate(`${ROUTING_URLS.LANDING}/${ROUTING_URLS.APPROVAL_REQUESTS}`);
  };

  const navItems: Array<NavItem> = [
    {
      label: Strings.home,
      action: goToHome,
      path: ROUTING_URLS.LANDING,
    },
    {
      label: Strings.products,
      action: goToCatalog,
      path: `${ROUTING_URLS.LANDING}/${ROUTING_URLS.PRODUCTS_CATALOG}`,
    },
    {
      label: Strings.businessInsights,
      action: goToInsights,
      path: `${ROUTING_URLS.LANDING}/${ROUTING_URLS.BUSINESS_INSIGHTS}`,
    },
  ];

  if (isBrand)
    navItems.push({
      label: Strings.team,
      action: goToTeam,
      path: `${ROUTING_URLS.LANDING}/${ROUTING_URLS.TEAMS}`,
    });

  const [isSmallScreen] = useMediaQuery(DEVICE.tablet);
  const getNotificationsAsync = async () => {
    dispatch(
      getNotifications(
        {
          [StartFrom]: 0,
          [ListQty]: PER_PAGE,
        },
        false
      )
    );
  };

  const isLoggedIn = useSelector((state: RootState) => {
    return state.userReducer.isLoggedIn || null;
  });
  const setnotificationInterval = () => {
    if (notificationInterval === undefined) {
      notificationInterval = setInterval(getNotificationsAsync, 5000);
    }
  };
  useEffect(() => {
    getNotificationsAsync();
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      setnotificationInterval();
    }
    return () => {
      clearInterval(notificationInterval);
    };
  }, [isLoggedIn]);
  useEffect(() => {
    if (isLoading) {
      clearInterval(notificationInterval);
      notificationInterval = undefined;
    } else {
      setnotificationInterval();
    }
  }, [isLoading]);
  const notificationsList = useSelector((state: RootState) => {
    return state?.commonReducer?.commonData?.notifications || null;
  });
  const navigateFromNotifications = (
    notificationId: number,
    notificationType: string,
    notificationArguments: any
  ) => {
    switch (notificationType) {
      case "ProductListed":
        goToProductDetails(notificationArguments.brandProductId);
        break;
      case "SalesDataUploadReminder":
        goToProductDetails(notificationArguments.brandProductId);
        break;
      case "XCouponExhausted":
        goToProductDetails(notificationArguments.brandProductId);
        break;
      case "ProductRequestAccepted":
        goToProductDetails(notificationArguments.brandProductId);
        break;
      case "ProductRequestRejected":
        goToProductDetails(notificationArguments.brandProductId);
        break;
      case "InfluencersSeekingApproval":
        goToProductApprovalRequests();
        break;
      case "ProductListingReminder":
        goToProductListing();
        break;
      case "ProductPickingReminder":
        goToProductCataog();
        break;
      case "AddBankAccountReminder":
        goToBankDetails();
        break;
      default:
        break;
    }
    dispatch(readNotification(notificationId));
  };
  const handleReadAllNotifications = () => {
    dispatch(readAllNotifications());
    setMenuOpen(false);
  };
  const fetchNotifications = (viaScroll: boolean = false) => {
    dispatch(
      getNotifications(
        {
          [StartFrom]: currentPage * PER_PAGE - PER_PAGE,
          [ListQty]: PER_PAGE,
        },
        viaScroll
      )
    );
  };

  useEffect(() => {
    if (notificationsList?.nextLink) {
      fetchNotifications(currentPage !== 1);
    }
  }, [currentPage]);

  useEffect(() => {
    if (notificationsList?.data?.length) {
      const hasUnreadNotification = notificationsList?.data.find(
        (i: any) => i.hasReadByUser === false
      );
      if (hasUnreadNotification) {
        setUnreadNotification(true);
      } else {
        setUnreadNotification(false);
      }
    }
  }, [notificationsList?.data]);

  const handleNotificationScroll = (e: any) => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isBottom = Math.ceil(scrollTop) + clientHeight === scrollHeight;
      if (isBottom && notificationsList?.nextLink) {
        setCurrentPage(currentPage + 1);
      }
    }
  };
  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
        className="betagged-navbar"
      >
        <Flex
          ml={{ base: -2 }}
          mr={{ base: 5 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            icon={<Img height={"24px"} src={HeaderMenuIcon} />}
            onClick={onToggle}
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          justify={{ base: "space-between", md: "start" }}
        >
          <Box onClick={goToHome} cursor={"pointer"}>
            <Image src={BetaggedLogo} maxW={"80%"} />
          </Box>
        </Flex>

        <Flex alignItems={"center"}>
          <Flex display={{ base: "none", md: "flex" }} ml={10} mr={5}>
            <DesktopNav navItems={navItems} />
          </Flex>

          <Menu
            isOpen={menuOpen}
            onClose={() => {
              setMenuOpen(false);
              setnotificationInterval();
            }}
            onOpen={() => {
              setMenuOpen(true);
              clearInterval(notificationInterval);
              notificationInterval = undefined;
            }}
          >
            <MenuButton
              as={Button}
              _focus={{ boxShadow: "none" }}
              _expanded={{ bg: Colors.borderColor }}
              variant={"link"}
              cursor={"pointer"}
              minW={0}
            >
              {unreadNotification && <div className="green-dot"></div>}
              <IconButton
                icon={<Img height={"20px"} src={NotificationBell} />}
                variant={"ghost"}
                aria-label={"Toggle Notifications"}
              />
            </MenuButton>
            <MenuList className="menu-list">
              <Flex
                justifyContent={"space-between"}
                paddingLeft={"10px"}
                paddingRight={"10px"}
                paddingTop={"10px"}
              >
                <Text
                  color={Colors.blackColor}
                  fontSize={"16px"}
                  fontWeight={600}
                  alignItems={"center"}
                >
                  {Strings.notifications}
                </Text>
                {notificationsList?.data?.length ? (
                  <Text
                    color={Colors.purple}
                    fontSize={"12px"}
                    fontWeight={400}
                    alignItems={"center"}
                    cursor={"pointer"}
                    onClick={handleReadAllNotifications}
                  >
                    {Strings.markAllAsread}
                  </Text>
                ) : (
                  <></>
                )}
              </Flex>
              <div
                className="insights_scroll_parent"
                onScroll={handleNotificationScroll}
                ref={listInnerRef as any}
              >
                {notificationsList?.data?.length ? (
                  notificationsList?.data?.map((i: any) => {
                    return (
                      <>
                        <MenuDivider />
                        <MenuItem
                          onClick={() =>
                            navigateFromNotifications(
                              i.notificationId,
                              i.notificationType,
                              i.arguments
                            )
                          }
                          backgroundColor={
                            i.hasReadByUser ? "white" : Colors.borderColor
                          }
                        >
                          <Text
                            color={Colors.grey}
                            fontSize={"13px"}
                            fontWeight={400}
                            flexBasis={"80%"}
                            alignItems={"center"}
                          >
                            {i.content}
                          </Text>
                          <Text
                            color={Colors.grey200}
                            fontSize={"10px"}
                            fontWeight={400}
                            flexBasis={"20%"}
                            textAlign={"center"}
                            alignSelf={"flex-start"}
                          >
                            {moment(i.createdAtUtc).fromNow()}
                          </Text>
                        </MenuItem>
                      </>
                    );
                  })
                ) : (
                  <Flex justifyContent={"center"}>
                    <Text
                      color={Colors.grey}
                      fontSize={"13px"}
                      fontWeight={400}
                      alignItems={"center"}
                    >
                      {Strings.noNotifications}
                    </Text>
                  </Flex>
                )}
              </div>
            </MenuList>
          </Menu>
          <Text
            opacity={0.2}
            color={Colors.blackColor}
            px={"5px"}
            paddingRight={{
              sm: "10px",
              md: 0,
            }}
          >
            |
          </Text>
          <Menu>
            <TooltipWrapper label={name}>
              <MenuButton
                as={Button}
                rounded={"full"}
                _focus={{ boxShadow: "none" }}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                <Flex alignItems={"center"}>
                  {!isSmallScreen && (
                    <Text
                      color={Colors.blackColor}
                      fontSize={"sm"}
                      fontWeight={500}
                      px={4}
                    >
                      {name && name?.length > 10 ? (
                        <>{name?.slice(0, 10) + "..."}</>
                      ) : (
                        <>{name}</>
                      )}
                    </Text>
                  )}

                  <Avatar size={"sm"} src={profilePic} />
                  <Img display={"inline"} pl={2} src={ChevronDown} />
                </Flex>
              </MenuButton>
            </TooltipWrapper>
            <MenuList>
              <MenuItem onClick={goToProfile}>
                <Image
                  alt={Strings.profile}
                  width={"1em"}
                  mr={2}
                  src={ProfileIcon}
                />
                {Strings.profile}
              </MenuItem>
              {!isBrand && (
                <MenuItem onClick={goToBankDetails}>
                  <Image
                    alt={Strings.bankDetails}
                    width={"1em"}
                    mr={2}
                    src={MoneyBillIcon}
                  />
                  {Strings.bankDetails}
                </MenuItem>
              )}
              <MenuDivider />
              <MenuItem onClick={logOut}>
                <Image
                  alt={Strings.logOut}
                  width={"1em"}
                  mr={2}
                  src={LogoutIcon}
                />
                {Strings.logOut}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav navItems={navItems} onMenuToggle={onToggle} />
      </Collapse>
    </Box>
  );
});

Navbar.displayName = "Navbar";

const DesktopNav = ({ navItems }: { navItems: any[] }) => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {navItems.map((navItem: any, idx) => (
        <Box key={idx}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <NavLink
                end
                to={navItem.path}
                className={({ isActive }) =>
                  isActive ? "menu_item selected" : "menu_item"
                }
              >
                <Text
                  as="span"
                  p={2}
                  fontSize={"sm"}
                  fontWeight={"inherit"}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                >
                  {navItem.label}
                </Text>
              </NavLink>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child: any, idx: number) => (
                    <DesktopSubNav key={idx} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = ({
  navItems,
  onMenuToggle,
}: {
  navItems: any[];
  onMenuToggle: any;
}) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {navItems.map((navItem, idx) => (
        <MobileNavItem key={idx} onMenuToggle={onMenuToggle} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({
  label,
  action,
  children,
  href,
  onMenuToggle,
}: NavItem | any) => {
  const { isOpen, onToggle } = useDisclosure();
  const onClickAction = () => {
    action();
    onMenuToggle();
  };
  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        onClick={onClickAction}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child: any, idx: number) => (
              <Link key={idx} py={2}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default Navbar;
