export enum FormikKeys {
  // Onboarding, Sign In, Sign Up
  EmailOTP = "emailOTP",
  MobileOTP = "mobileOTP",
  NumberPad = "number-pad",
  EmailAddressPad = "email-address",
  DefaultPad = "default",
  UrlPad = "url",
  OneTimeCode = "oneTimeCode",
  CountryCode = "countryCode",
  BrandMember = "BrandMember",
  Influencer = "Influencer",
  Phone = "phone",
  Email = "email",
  Password = "password",
  DeleteAccount = "deleteAccount",
  CurrentPassword = "currentPassword",
  MobileNumber = "mobileNumber",
  // Onboarding
  FullName = "fullName",
  BusinessName = "brandLegalName",
  ProductsAndServices = "categories",
  Country = "country",
  CountryISOCode = "countryIso2Code",
  City = "city",
  Location = "location",
  Latitude = "latitude",
  Longitude = "longitude",
  EntryValidation = "entryValidation",
  SocialMediaAccounts = "socialMediaAccounts",
  Instagram = "instagram",
  TikTok = "tikTok",
  Facebook = "facebook",
  Twitter = "twitter",
  HasAcceptedTermsAndCondition = "hasAcceptedTermsAndCondition",
  PurchaseOrderFileUrl = "purchaseOrderFileUrl",
  PurchaseOrderFileName = "purchaseOrderFileName",
  //Product Listing
  ProductName = "name",
  Tags = "hashtags",
  Description = "description",
  Countries = "countries",
  CurrencyCode = "currencyCode",
  CurrencySymbol = "currencySymbol",
  ProductPrice = "price",
  CommissionPercent = "commissionPercentage",
  BuyerDiscountPercent = "buyerDiscountPercentage",
  IsApprovalBased = "isApprovalBasedOffer",
  BannerURL = "bannerImageUrl",
  ShowCaseURLs = "showCaseMediaUrls",
  SocialMediaKits = "socialMediaKits",
  DiscountCodesFileURL = "discountCodesFileUrl",
  CurrentlyChecked = "currentlyChecked",
  // productlisting step 4
  ProductDetails = "productDetails",
  Size = "size",
  OrderUnits = "orderUnits",
  Color = "color",
  Dimension = "dimension",
  Weight = "weight",
  InventoryLocation = "inventoryLocation",
  ContactPersonNumber = "contactPersonNumber",
  ZipCode = "zipCode",
  //Social Media Subkeys begin//
  FBMediaURL = "facebookMediaKitUrl",
  TikTokMediaURL = "tikTokMediaKitUrl",
  TwitterMediaURL = "twitterMediaKitUrl",
  InstagramMediaURL = "instagramMediaUrl",
  //Social Media Subkeys end//
  ProductURLs = "productUrls",
  //Product URLs Subkeys begin//
  CompanyProductURL = "companyProductUrl",
  TikTokProductURL = "tikTokCommerceUrl",
  BeTaggedMallURL = "beTaggedMallUrl",
  ECommercePlatforms = "ecommercePlatformUrl",
  InCommMall = "inCommMall",
  DeliveredByInCommTeam = "DeliveredByInCommTeam",
  DeliveredByYou = "DeliveredByYou",
  DeliveryOption = "deliveryOption",
  OtherCommerceURL = "otherCommerceUrl",
  EcommercePlatformUrl = "ecommercePlatformUrl",
  //Product URLs Subkeys end//
  // INCOMM MALL Subkeys begin //
  ProductIncommDetails = "productIncommDetails",
  IsDeliveredByIncommTeam = "isDeliveredByIncommTeam",
  IsDeliveredByBrand = "isDeliveredByBrand",
  VariantDetails = "variantDetails",
  Colour = "colour",
  ContactPersonCountryCode = "contactPersonCountryCode",
  // INCOMM MALL Subkeys end //
  ProductStatus = "productStatus",
  ProductCode = "productCode",
  //Product Catalog Query Params
  StartFrom = "skip",
  ListQty = "take",
  OrderBy = "orderBy",
  Search = "criteria",
  QuerySearch = "search",
  Influencers = "influencers",
  Sales = "sales",
  onlyIncludeActiveMembers = "onlyIncludeActiveMembers",
  // CommissionPercent already present above
  Brands = "brands",
  BrandProductID = "brandProductId",
  // Categories already present as ProductsAndServices
  OfferType = "offerType",
  // Business Insights
  SocialMedia = "socialMedia",
  EmailAddresses = "emailAddresses",
  OldPassword = "oldPassword",
  NewPassword = "newPassword",
  From = "from",
  To = "to",
  SalesChannel = "salesChannel",
  CountryID = "countryId",
  MonthlySalesDistribution = "monthlySalesDistribution",
  OrderByAsc = "asc",
  OrderByDesc = "desc",
  SalesDataURL = "salesDataFileUrl",
  Data = "data",
  SocialMediaDetails = "socialMediaDetails",
  //Adding Bank Details
  AccountName = "accountName",
  AccountNumber = "accountNumber",
  BankName = "bankName",
  Branch = "branch",
  SwiftCode = "swiftCode",
  Address = "address",
  Open = "Open",
  ApprrovalBased = "ApprovalBased",
  //Add Team Members
  Name = "name",
  Role = "role",
  InvitationSent = "Invitation Sent",
}
