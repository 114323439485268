import {
  getBrandEmailSignInURL,
  getBrandPhoneSignInURL,
  getInfluencerEmailSignInURL,
  getInfluencerPhoneSignInURL,
} from "../../../utils/UrlBuilder";
import {
  SHOW_OTP_VERIFY_MODAL,
  UPDATE_LOADING_STATUS,
  UPDATE_IS_USER_LOGGED_IN,
  SET_USER_INFO,
  SET_USER_AS_BRAND,
  LOGIN_VIA_PHONE,
} from "../../constants/ReduxConstants";
import { HTTPMethod } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import { setAccessToken } from "../../../config/AccessTokenHelper";
import { RootState } from "../../reducers";

import { sanitizeUserObject } from "../../../utils/ObjectUtils";
import { IdentifyUser } from "../../../services/Logging/Helper/IdentifyUser";

interface IUserInfo {
  isEmailVerified: boolean;
  isPhoneVerified: boolean;

  isInfluencerOnBoarded?: boolean;
  isBrandOnBoarded?: boolean;
  isUserOnBoarded?: boolean;

  primaryEmailAddressId: number;
  primaryEmailAddress: string;
  primaryPhoneId: number;
  primaryPhone: string;

  userType: string;
}

export const userSignInWithEmail =
  (data: any, isBrand: boolean, navigateHelperFunction: any) =>
  async (dispatch: any, getState: () => RootState) => {
    const request = {
      method: HTTPMethod.POST,
      queryStringParams: {},
      data,
      url: isBrand ? getBrandEmailSignInURL() : getInfluencerEmailSignInURL(),
    };
    const postSuccessFn = (payload: any) =>
      updateUserInfoAfterSignin(payload, isBrand, navigateHelperFunction);
    return fetchAsync(dispatch, [request], [postSuccessFn]);
    //navigateHelperFunction is a function that takes the user to the onboarding screen
  };

export const userSignInWithPhone =
  (data: any, isBrand: boolean, callbackFn?: any) =>
  async (dispatch: any, getState: () => RootState) => {
    const request = {
      method: HTTPMethod.POST,
      queryStringParams: {},
      data,
      url: isBrand ? getBrandPhoneSignInURL() : getInfluencerPhoneSignInURL(),
    };
    return fetchAsync(dispatch, [request], [() => postPhoneEntry(callbackFn)]);
  };

const postPhoneEntry = (callbackFn: any) => {
  callbackFn();
  return {
    type: LOGIN_VIA_PHONE,
  };
};

/**
 * The function that is triggered after sign in credentials are valid
 * First step is to update the brand user info in the redux store
 * After that a check should be made to see whether the user's email or phone are verified. If no, show the OTP modal and take the OTP(s)
 * If both are verified, check if the Brand / User is onboarded. If not, take him to onboarding screen using navigateHelperFunction
 * The redirection to the dashboard (when both the above conditions are already met) is handled at the respective client layer
 * @param payload - The user data payload received from the server
 * @param navigateHelperFunction - The navigation function that takes the user to Onboarding screen
 */
export const updateUserInfoAfterSignin =
  (payload: any, isBrand: boolean, navigateHelperFunction?: any) =>
  (dispatch: any) => {
    const accessToken: string = payload?.data?.accessToken;
    setAccessToken(accessToken);
    IdentifyUser(payload?.data, isBrand);
    navigateHelperFunction(payload.data);

    dispatch({
      type: SET_USER_AS_BRAND,
      payload: isBrand,
    });

    const userObject = sanitizeUserObject(payload.data, isBrand);
    const userInfo: IUserInfo = userObject.userInfo;

    dispatch({
      type: SET_USER_INFO,
      payload: userObject,
    });

    const {
      isBrandOnBoarded,
      isEmailVerified,
      isPhoneVerified,
      isUserOnBoarded,
      isInfluencerOnBoarded,
    } = userInfo;

    if (!isEmailVerified || !isPhoneVerified) {
      dispatch({
        type: SHOW_OTP_VERIFY_MODAL,
        payload: true,
      });
    } else {
      // Navigate to onboarding if:
      // The user is a brand, and
      //    the brand or the user is not onboarded
      // The user is an influencer, and
      //    the influencer is not onboarded
      if (
        (isBrand && (!isBrandOnBoarded || !isUserOnBoarded)) ||
        (!isBrand && !isInfluencerOnBoarded)
      ) {
        navigateHelperFunction();
      }
      dispatch(updateIsUserLoggedIn(true));
      // Log & identify the user
    }

    return {
      type: UPDATE_LOADING_STATUS,
      payload: false,
    };
  };

export const updateIsUserLoggedIn =
  (payload: any) =>
  (dispatch: any): void => {
    dispatch({
      type: UPDATE_IS_USER_LOGGED_IN,
      payload: payload,
    });
  };
