import { FC, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Spinner,
  Box,
  Flex,
  Button,
} from "@chakra-ui/react";

import { useSelector } from "react-redux";

import "./FilterModal.scss";

import {
  Colors,
  logger,
  LoggerStrings,
  RootState,
  Strings,
} from "@be-tagged/shared";

import { CustomBtn, Label } from "src/app/components";

import { FilterDropdown } from "./DropdownFilters";
import { useState } from "react";
import CategoriesSelection from "src/app/components/CustomSelect/CategoriesSelection";
import BrandSelect from "src/app/components/CustomSelect/AsyncSelect";
import { FilterType } from "src/app/enums";
import { CommonObjectType } from "src/app/constants";

type setterFunction = (x: any) => void;

interface FilterModalProps {
  isOpen: boolean;
  isBrand: boolean;
  onClose: () => void;
  // Brand Filters
  influencerFilter?: any[];
  setInfluencerFilter?: setterFunction;
  salesFilter?: any[];
  setSalesFilter?: setterFunction;
  commissionFilter?: any[];
  setCommissionFilter?: setterFunction;
  closeFilter?: any;
  // Influencer Filters
  brandFilter?: any[];
  setBrandFilter?: setterFunction;
  offerFilter?: CommonObjectType | null;
  setOfferFilter?: setterFunction;
  categoriesFilter?: any[];
  setCategoriesFilter?: setterFunction;
}

/**
 * @param {Object} props - Of the form FilterModalProps
 * @returns {JSX.Element} - A React Functional Component
 */
const FilterModal: FC<FilterModalProps> = ({
  isOpen,
  isBrand,
  onClose,
  ...props
}): JSX.Element => {
  const {
    influencerFilter,
    setInfluencerFilter,
    salesFilter,
    setSalesFilter,
    commissionFilter,
    setCommissionFilter,
    brandFilter,
    setBrandFilter,
    offerFilter,
    setOfferFilter,
    categoriesFilter,
    setCategoriesFilter,
    closeFilter,
  } = props;

  const [localInfFilter, setLocalInfFilter] = useState(influencerFilter);
  const [localSalesFilter, setLocalSalesFilter] = useState(salesFilter);
  const [localComFilter, setLocalComFilter] = useState(commissionFilter);

  const [localBrandFilter, setLocalBrandFilter] = useState(brandFilter);
  const [localOfferFilter, setLocalOfferFilter] = useState(offerFilter);
  const [localCatFilter, setLocalCatFilter] = useState(categoriesFilter);

  const { loading } = useSelector(
    (state: RootState) => state?.appReducer?.loading
  );

  const [filterApplied, setFilterApplied] = useState(false);
  const [filterCleared, setFilterCleared] = useState(false);

  const applyFilters = () => {
    setFilterApplied(true);
    if (isBrand) {
      setInfluencerFilter?.(localInfFilter);
      setSalesFilter?.(localSalesFilter);
      setCommissionFilter?.(localComFilter);
    } else {
      setBrandFilter?.(localBrandFilter);
      setOfferFilter?.(localOfferFilter);
      setCategoriesFilter?.(localCatFilter);
    }
    closeFilter();
  };

  useEffect(() => {
    if (isOpen) {
      logger.info(
        `${isBrand ? Strings.brand : Strings.influencer} ${
          LoggerStrings.openedCatalogFilterModal
        }`
      );
    }
  }, [isOpen]);
  useEffect(() => {
    if (filterApplied) {
      logger.info(
        `${isBrand ? Strings.brand : Strings.influencer} ${
          LoggerStrings.appliedFilters
        }`
      );
    }
  }, [filterApplied]);
  const clearAllFilters = () => {
    setFilterCleared(true);
    if (isBrand) {
      setLocalInfFilter([]);
      setLocalSalesFilter([]);
      setLocalComFilter([]);

      setInfluencerFilter?.([]);
      setSalesFilter?.([]);
      setCommissionFilter?.([]);
    } else {
      setLocalBrandFilter([]);
      setLocalOfferFilter(null);
      setLocalCatFilter([]);

      setBrandFilter?.([]);
      setOfferFilter?.(null);
      setCategoriesFilter?.([]);
    }
    closeFilter();
  };

  useEffect(() => {
    if (filterCleared) {
      logger.info(
        `${isBrand ? Strings.brand : Strings.influencer} ${
          LoggerStrings.clearedFilters
        }`
      );
    }
  }, [filterCleared]);

  const cancelAndClose = () => {
    if (isBrand) {
      setLocalInfFilter(influencerFilter);
      setLocalSalesFilter(salesFilter);
      setLocalComFilter(commissionFilter);
    } else {
      setLocalBrandFilter(brandFilter);
      setLocalOfferFilter(offerFilter);
      setLocalCatFilter(categoriesFilter);
    }
    closeFilter();
  };

  return (
    <>
      <Modal
        size={"2xl"}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent className="filter_modal" style={{ paddingTop: 12 }}>
          <ModalBody>
            <Box px="15px" className="content_container">
              <Box className="bold" style={{ fontSize: 18, paddingBottom: 24 }}>
                {Strings.filterYourResults}
              </Box>

              {isBrand ? (
                <Flex
                  mb={8}
                  flexWrap="wrap"
                  gap={"20px"}
                  justifyContent="space-between"
                >
                  <Box w={"280px"}>
                    <FilterDropdown
                      isSearchable={false}
                      type={FilterType.Influencer}
                      placeholder={Strings.influencer}
                      optionValue="value"
                      optionLabel="label"
                      value={localInfFilter}
                      isMulti={true}
                      onChange={setLocalInfFilter}
                    />
                  </Box>
                  <Box w={"280px"}>
                    <FilterDropdown
                      type={FilterType.Sales}
                      placeholder={Strings.sales}
                      optionValue="value"
                      optionLabel="label"
                      value={localSalesFilter}
                      isMulti={true}
                      isSearchable={false}
                      onChange={setLocalSalesFilter}
                    />
                  </Box>
                  <Box w={"280px"}>
                    <FilterDropdown
                      isSearchable={false}
                      type={FilterType.Commission}
                      placeholder={Strings.icommission}
                      optionValue="value"
                      optionLabel="label"
                      value={localComFilter}
                      isMulti={true}
                      onChange={setLocalComFilter}
                    />
                  </Box>
                </Flex>
              ) : (
                <Flex
                  mb={8}
                  flexWrap="wrap"
                  gap={"20px"}
                  justifyContent="space-between"
                >
                  <Box w={"280px"}>
                    <BrandSelect
                      selectedBrand={localBrandFilter}
                      setSelectedBrand={(x: any) => {
                        setLocalBrandFilter(x);
                      }}
                    />
                  </Box>
                  <Box w={"280px"}>
                    <FilterDropdown
                      isSearchable={false}
                      type={FilterType.Offer}
                      placeholder={Strings.offerType}
                      optionValue="value"
                      optionLabel="label"
                      value={localOfferFilter}
                      onChange={setLocalOfferFilter}
                    />
                  </Box>
                  <Box w={"280px"}>
                    <Label>{Strings.categories}</Label>
                    <CategoriesSelection
                      value={localCatFilter}
                      handleChange={(cat: any) => {
                        setLocalCatFilter(cat);
                      }}
                    />
                  </Box>
                </Flex>
              )}

              <form onSubmit={(e) => e.preventDefault()}>
                <Box className="otp_inputs"></Box>

                <Box className="filter_actions_container">
                  <Box
                    className="clear_action no_focus_shadow"
                    as={Button}
                    variant="link"
                    color={Colors.purple}
                    onClick={clearAllFilters}
                  >
                    {Strings.clearAllFilters}
                  </Box>

                  <Flex className="primary_actions_container">
                    <CustomBtn
                      className="purple_btn medium inv"
                      onClick={cancelAndClose}
                    >
                      {loading && (
                        <Spinner size={"xs"} style={{ marginRight: 10 }} />
                      )}
                      {Strings.cancel}
                    </CustomBtn>
                    <CustomBtn
                      onClick={applyFilters}
                      className="purple_btn medium"
                    >
                      {loading && (
                        <Spinner size={"xs"} style={{ marginRight: 10 }} />
                      )}
                      {Strings.apply}
                    </CustomBtn>
                  </Flex>
                </Box>
              </form>
            </Box>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default FilterModal;
