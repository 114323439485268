import mixpanel from "mixpanel-browser";
import MixpanelLogTransportBase from "@be-tagged/shared/src/services/Logging/MixpanelLogTransportBase";

export class MixpanelLogTransportWeb extends MixpanelLogTransportBase {
  client = mixpanel;

  init = () => {
    mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`, { debug: true });
  };
}
