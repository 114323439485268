import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { RootState } from "@be-tagged/shared";

function Toasts() {
  const toast = useToast();
  const toastIdRef = useRef<any>();

  const { error, success } = useSelector(
    (state: RootState) => state.appReducer
  );

  useEffect(() => {
    if (error) {
      toastIdRef.current = toast({
        description: error?.serverMessage || error?.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
    //eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (success) {
      toastIdRef.current = toast({
        description: success?.message,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    //eslint-disable-next-line
  }, [success]);

  return <></>;
}

export default Toasts;
