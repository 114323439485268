import { Box } from "@chakra-ui/react";
import { Colors } from "@be-tagged/shared";
import { FC } from "react";
import { Navbar, Footer } from "../common";
import "./dashboardLayout.scss";
import { BottomColoredBar } from "../../app/components";
import { useLocation } from "react-router-dom";
import { ROUTING_URLS } from "src/app/constants";

interface IDashboardLayout {
  children: JSX.Element;
}

const DashboardLayout: FC<IDashboardLayout> = ({ children }) => {
  const { pathname } = useLocation();
  return (
    <Box backgroundColor={Colors.bgDark}>
      <Navbar />
      <Box
        className={
          pathname.includes(ROUTING_URLS.PRODUCT_DETAILS)
            ? "dashboard-container gradient-bg"
            : "dashboard-container"
        }
      >
        <Box className="dashboard-main">{children}</Box>
        <Footer />
      </Box>
      <BottomColoredBar />
    </Box>
  );
};

export default DashboardLayout;
