import {
  CITY_MIN_CHAR_FOR_SEARCH,
  getCities,
  RootState,
  Strings,
} from "@be-tagged/shared";
import { AsyncQueryParams, FormikKeys } from "@be-tagged/shared/src/enums";
import { debounce } from "@be-tagged/shared/src/utils";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { beTaggedStyles, Label, PurpleArrowDropdown } from "src/app/components";
import { FC } from "react";
import { CommonObjectType } from "src/app/constants";

interface CitySelectProps {
  selectedCity: CommonObjectType;
  setSelectedCity: (args: any) => any;
  countryIsoCode?: string;
  isDisabled?: boolean;
  labelProps?: CommonObjectType;
  onBlur?: (args: any) => void;
  isEditMode?: boolean;
  cityName?: string;
}

const { QuerySearch, CountryISOCode } = FormikKeys;

/**
 * A reusable dropdown component that dynamically takes the cities from the redux store.
 * This dropdown is searchable and asynchronously fetches the results from the API.
 * @param {props} - React props of the type CitySelectProps
 * @returns {JSX.Element} - A React Functional Component
 */
const CitySelect: FC<CitySelectProps> = ({
  selectedCity,
  setSelectedCity,
  isDisabled = false,
  countryIsoCode,
  labelProps = {},
  onBlur,
  isEditMode,
  cityName,
}): JSX.Element => {
  const dispatch = useDispatch();
  const { cities, isLoadingCities } = useSelector(
    (state: RootState) => state.commonReducer?.commonData
  );

  /**
   * handleInputChange dipaches the action that communicates with the API
   * A min character length condition must be satistied to dispatch the function
   * Otherwise the function does nothing
   * @param {string} query - the query of city being looked up by the user
   */
  const handleInputChange = (query: string) => {
    if (query.length >= CITY_MIN_CHAR_FOR_SEARCH) {
      let queryObject: CommonObjectType = {
        [QuerySearch]: query,
      };
      if (countryIsoCode) queryObject[CountryISOCode] = countryIsoCode;
      return dispatch(getCities(queryObject as AsyncQueryParams));
    }
    return undefined;
  };

  return (
    <>
      <Label {...labelProps}>
        {labelProps?.labelTitle || Strings.yourCity}
      </Label>
      <Select
        placeholder={isEditMode && cityName !== null ? cityName : "Select..."}
        isDisabled={isDisabled}
        components={{ ...PurpleArrowDropdown, NoOptionsMessage }}
        styles={beTaggedStyles}
        value={selectedCity}
        defaultValue={null}
        options={cities}
        getOptionLabel={(option) =>
          `${option.city}, ${option.region}${
            option.region && option.country && ", "
          }${option.country}`
        }
        getOptionValue={(option: any) => `${option.city}, ${option.country}`}
        onInputChange={debounce(handleInputChange)}
        onChange={setSelectedCity}
        onBlur={onBlur}
        isLoading={isLoadingCities}
      />
    </>
  );
};

/**
 * Custom Message to that comes up when:
 * - The results are being fetched
 * - The user has not inserted enough letters to trigger a search
 * @param props - Default props sent by the react-select component
 * @returns {JSX.Element} - of the `component` type of react-select
 */
const NoOptionsMessage = (props: any): JSX.Element => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>
        {props.selectProps.isLoading
          ? Strings.loading
          : props.selectProps.inputValue.length < CITY_MIN_CHAR_FOR_SEARCH
          ? Strings.enterMoreCharsForCity
          : Strings.noDataFound}
      </span>
    </components.NoOptionsMessage>
  );
};

export default CitySelect;
