import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Box, Text, Image, Collapse } from "@chakra-ui/react";

import {
  Colors,
  getCategories,
  getCountries,
  RootState,
  Strings,
  updateCitiesList,
} from "@be-tagged/shared";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import {
  IconDoubleArrowUp,
  IconDoubleArrowDown,
} from "@be-tagged/shared/src/assets/icons";
import {
  Label,
  MaterialStyleInput,
  ErrorMsg,
  TagSelect,
  CountrySelect,
  CitySelect,
} from "src/app/components";
import CategoriesSelection from "src/app/components/CustomSelect/CategoriesSelection";
import TooltipWrapper from "src/app/components/TooltipWrapper";
import useSetPageTitle from "src/app/hooks/useSetPageTitle";

const {
  ProductName,
  ProductsAndServices,
  Country,
  CurrencyCode,
  CurrencySymbol,
  City,
  Tags,
  Description,
} = FormikKeys;

/**
 * Step 1 of Product Listing requires the user to enter basic details
 * Name, Categories, Country, Tags, Description
 * @param {Object} - Formik Props like values, errors, etc
 * @returns {JSX.Element} - A React Functional Component
 */
const StepOne: FC<any> = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  edit,
  cityName,
}) => {
  const dispatch = useDispatch();
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  let { countries } =
    useSelector((state: RootState) => state.commonReducer?.commonData) || [];

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getCountries());
    //eslint-disable-next-line
  }, []);

  useSetPageTitle("Product Listing - Product Details");

  return (
    <Flex className="main_product_details_container">
      <Flex className="left_container">
        <Flex className="input_row" id="name_category">
          <Flex className="input_container">
            <MaterialStyleInput
              label={Strings.productName}
              value={values[ProductName]}
              onChange={handleChange(ProductName)}
              onBlur={handleBlur(ProductName)}
            />
            {touched[ProductName] && errors[ProductName] && (
              <ErrorMsg bottom={-15}>{errors[ProductName]}</ErrorMsg>
            )}
          </Flex>
          <Flex className="input_container">
            <Label>{Strings.productCategory}</Label>
            <CategoriesSelection
              value={values[ProductsAndServices]}
              preferredCategories={false} //// make it true, If we need to display the selected categories list that selected on OnBoarding time.
              handleChange={(items: any) =>
                setFieldValue(ProductsAndServices, items)
              }
              handleBlur={() => setFieldTouched(ProductsAndServices, true)}
            />
            {touched[ProductsAndServices] && errors[ProductsAndServices] && (
              <ErrorMsg bottom={-15}>{errors[ProductsAndServices]}</ErrorMsg>
            )}
          </Flex>
        </Flex>

        <Flex className="input_row" mt={"35px"} id="name_category">
          <Flex className="input_container">
            <Label style={{ marginBottom: 0 }}>{Strings.productCountry}</Label>
            <CountrySelect
              isMulti={false}
              countryOptions={countries}
              selectedCountries={values[Country]}
              setCountries={(country: any) => {
                setFieldValue(Country, country);
                setFieldValue(CurrencyCode, country?.currencyCode);
                setFieldValue(CurrencySymbol, country?.currencySymbol);
                setFieldValue(City, null);
                dispatch(updateCitiesList(null));
              }}
              onBlur={() => setFieldTouched(Country)}
              isDisabled={edit}
            />
            {touched[Country] && errors[Country] && (
              <ErrorMsg bottom={-15}>{errors[Country]}</ErrorMsg>
            )}
          </Flex>
          <Flex className="input_container">
            <TooltipWrapper
              label={!values[Country] ? Strings.enterCountryFirst : ""}
            >
              <CitySelect
                isDisabled={!values[Country] || edit}
                countryIsoCode={values[Country]?.isoCode}
                labelProps={{
                  labelTitle: Strings.enterCity,
                  style: { marginBottom: 5 },
                }}
                selectedCity={values[City]}
                setSelectedCity={(city: any) => setFieldValue(City, city)}
                onBlur={() => setFieldTouched(City)}
                isEditMode={edit}
                cityName={cityName}
              />
            </TooltipWrapper>
            {touched[City] && errors[City] && (
              <ErrorMsg bottom={-15}>{errors[City]}</ErrorMsg>
            )}
          </Flex>
        </Flex>
        <Flex className="input_row" mt={"35px"}>
          <Flex className="input_container">
            <Label style={{ marginBottom: 0 }}>{Strings.enterTags}</Label>
            <TagSelect
              selectedTags={values[Tags]}
              setTags={(tags: any) => setFieldValue([Tags], tags)}
            />
            {touched[Tags] && errors[Tags] && (
              <ErrorMsg bottom={-15}>{errors[Tags]}</ErrorMsg>
            )}
          </Flex>
        </Flex>
        <Box pos={"relative"} mt={"40px"} width="100%">
          <MaterialStyleInput
            isTextArea={true}
            labelLeft={5}
            style={Styles.textArea}
            label={Strings.productDesc}
            value={values[Description]}
            onChange={handleChange(Description)}
            onBlur={handleBlur(Description)}
          />
          {touched[Description] && errors[Description] && (
            <ErrorMsg bottom={-15}>{errors[Description]}</ErrorMsg>
          )}
        </Box>
      </Flex>
      <Flex flexDirection={"column"} className="right_container">
        <Flex
          onClick={() => setIsCollapseOpen(!isCollapseOpen)}
          style={{
            width: "350px",
            height: "42px",
            border: "1px solid #D3E0FC",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            padding: "10px",
            boxShadow: "0px 3px 30px rgba(153, 186, 208, 0.3)",
          }}
          className="collapse_item"
        >
          <Text alignSelf={"center"}>Instructions</Text>
          <Image
            style={{ position: "absolute", right: 10 }}
            onClick={() => setIsCollapseOpen(true)}
            cursor={"pointer"}
            src={isCollapseOpen ? IconDoubleArrowUp : IconDoubleArrowDown}
          />
        </Flex>
        <Collapse in={isCollapseOpen} animateOpacity>
          <Box
            style={{
              color: Colors.grey,
              height: "429px",
              width: "350px",
              border: "1px solid #D3E0FC",
              boxShadow: "0px 3px 30px rgba(153, 186, 208, 0.3)",
              overflow: "scroll",
              padding: "10px",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "150%",
              marginTop: "0px",
            }}
            className="collapse_item"
          >
            <p>{Strings.stepOneParaOne}</p>
            <br />
            <p>{Strings.stepOneParaTwo}</p>
            <br />
            <p>{Strings.stepOneParaThree}</p>
            <br />
            <p>{Strings.stepOneParaFour}</p>
            <br />
            <p>{Strings.stepOneParaFive}</p>
          </Box>
        </Collapse>
      </Flex>
    </Flex>
  );
};

const Styles = {
  materialStyle: {
    style: { width: "350px" },
  },
  textArea: {
    borderRadius: 5,
    padding: 10,
    minHeight: 100,
    height: 180,
  },
};

export default StepOne;
