import { Colors, Strings } from "@be-tagged/shared";
import { Box, Text, Flex, Image, Skeleton } from "@chakra-ui/react";
import Stats from "./Statistics";
import { Link } from "react-router-dom";
import useData from "src/app/hooks/useData";
import { withCommas } from "src/app/utils";
import NoDataFound from "src/app/components/NoDataFound";
import { ROUTING_PARAMS, ROUTING_URLS } from "src/app/constants";
import { SUPPORTED_FILES } from "src/dashboard-module/interfaces";
import ReactImageFallback from "react-image-fallback";

const ProductsBox = ({ isBrand }: { isBrand: boolean }) => {
  const { dashboardData } = useData();
  const { topSellingProducts = [] } = dashboardData;
  return (
    <Box>
      <Text className="bold" mb="12px">
        {Strings.products}
      </Text>
      <Flex bgColor={Colors.bgLight} className="white_box_container info_box">
        <Flex className="double_shaded_container">
          {isBrand ? <ProductStatsBrand /> : <ProductStatsInfluencer />}
          <MiniProductsCatalog products={topSellingProducts} />
        </Flex>
      </Flex>
    </Box>
  );
};

const ProductStatsBrand = () => {
  const { dashboardData } = useData();
  const {
    productListedTillDate,
    salesValueThisMonth = "",
    salesVolumeThisMonth = "",
    currencySymbol = "",
  } = dashboardData || "";
  return (
    <Box className="darker_shade" bgColor={Colors.grey400}>
      <Flex className="stats_container">
        <Stats
          label={Strings.productListedTillDate}
          value={productListedTillDate}
        />
        <Stats
          label={Strings.totalSalesVolumeThismonth}
          value={withCommas(salesVolumeThisMonth)}
        />
        <Stats
          label={Strings.totalSalesValueThismonth}
          value={`${currencySymbol} ${withCommas(salesValueThisMonth)}`}
        />
      </Flex>
      <Link
        to={`${ROUTING_URLS.LANDING}/${ROUTING_URLS.BUSINESS_INSIGHTS}`}
        className="bold action_btn"
        style={Styles.linkStyle}
      >
        {Strings.viewDetailedAnalysis}
      </Link>
    </Box>
  );
};

const ProductStatsInfluencer = () => {
  const { dashboardData } = useData();
  const {
    commissionEarned = "",
    productSold = "",
    productsActivelyEngaged = "",
    currencySymbol = "",
  } = dashboardData;

  const { LANDING, BUSINESS_INSIGHTS, PRODUCTS_CATALOG } = ROUTING_URLS;
  const { COMMISSION_PAYOUT, PROMOTED_BY_ME } = ROUTING_PARAMS;
  return (
    <Box className="darker_shade" bgColor={Colors.grey400}>
      <Flex className="stats_container">
        <Stats label={Strings.productsSoldMonth} value={productSold} />
        <Stats
          label={Strings.commissionEarnedMonth}
          value={`${currencySymbol} ${withCommas(commissionEarned)}`}
        >
          <Link
            to={`${LANDING}/${BUSINESS_INSIGHTS}?tab=${COMMISSION_PAYOUT}`}
            className="bold action_btn"
            style={Styles.linkStyle}
          >
            {Strings.viewDetailedAnalysis}
          </Link>
        </Stats>
        <Stats
          label={Strings.productsActivelyEngaged}
          value={withCommas(productsActivelyEngaged)}
        >
          <Link
            to={`${LANDING}/${PRODUCTS_CATALOG}?tab=${PROMOTED_BY_ME}`}
            className="bold action_btn"
            style={Styles.linkStyle}
          >
            {Strings.viewAll}
          </Link>
        </Stats>
      </Flex>
    </Box>
  );
};

const MiniProductsCatalog = ({ products }: { products: any[] }) => {
  const { VideoMp4, Mp4 } = SUPPORTED_FILES;
  return (
    <Box className="lighter_shade" bgColor={Colors.themeColor}>
      <Text className="bold list_title">{Strings.topFiveProducts}</Text>
      <Flex className="list">
        {products.length ? (
          products.map((product: any) => {
            return (
              <Link
                key={product?.brandProductId}
                to={`${ROUTING_URLS.LANDING}/${ROUTING_URLS.PRODUCT_DETAILS}/${product?.brandProductId}`}
              >
                <Box className="product">
                  {product?.showCaseMediaUrls?.[0]?.includes(Mp4) ? (
                    <>
                      <video className="img shadow" autoPlay muted>
                        <source
                          src={product?.showCaseMediaUrls?.[0]}
                          type={VideoMp4}
                        />
                      </video>
                    </>
                  ) : (
                    <ReactImageFallback
                      fallbackImage={<Skeleton className="img shadow" />}
                      src={product?.showCaseMediaUrls?.[0]}
                      alt={"Product"}
                      className="img shadow"
                    />
                  )}
                  <Text className="name" color={Colors.grey100}>
                    {product?.productName}
                  </Text>
                </Box>
              </Link>
            );
          })
        ) : (
          <NoDataFound />
        )}
      </Flex>
    </Box>
  );
};

const Styles = {
  linkStyle: {
    color: Colors.purple,
  },
};

export default ProductsBox;
