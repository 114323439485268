import { getCategoriesUrl, getCountriesUrl, getCurrenciesUrl } from '../../../utils/UrlBuilder';
import { SET_COUNTRIES, SET_CURRENCIES, UPDATE_CATEGORIES, UPDATE_LOADING_COUNTRIES_STATUS } from '../../constants/ReduxConstants';
import { HTTPMethod } from '../../../enums';
import { clearErrors, fetchAsync } from '../GlobalActions/App.actions';

export const getCountries = () => async (dispatch: any) => {
  dispatch(setIsCountriesLoading(true))
  const request = {
    method: HTTPMethod.GET,
    queryStringParams: {},
    url: getCountriesUrl(),
  };
  await fetchAsync(dispatch, [request], [updateCountries])
};

const setIsCountriesLoading = (status: boolean) => ({
  type: UPDATE_LOADING_COUNTRIES_STATUS,
  payload: status
})

export const updateCountries = (payload: any) => (dispatch: any) => {
  dispatch(setIsCountriesLoading(false))
  return dispatch({
    type: SET_COUNTRIES,
    payload: payload.data
  })
}

export const getCurrencies = () => async (dispatch: any) => {
  await dispatch(clearErrors())
  const request = {
    method: HTTPMethod.GET,
    queryStringParams: {},
    url: getCurrenciesUrl(),
  };
  await fetchAsync(dispatch, [request], [updateCurrencies])
};

export const updateCurrencies = (payload: any) => ({
  type: SET_CURRENCIES,
  payload: payload.data
})

export const getCategories = () => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.GET,
    queryStringParams: {},
    url: getCategoriesUrl(),
  };
  return fetchAsync(dispatch, [request], [updateCategories]);
};

const updateCategories = (payload: any) => {
  return ({
    type: UPDATE_CATEGORIES,
    payload: payload.data
  })
}