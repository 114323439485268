import {
  Colors,
  getBankAccounts,
  getCountries,
  RootState,
  setAsPrimaryBankAccount,
  Strings,
} from "@be-tagged/shared";
import { Box, Flex, Switch } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BankDetailsSkeleton } from "../SkeletonLayout";
import AddBankDetailsModal from "./AddBankDetailsModal";
import DeleteBankAccountModal from "./DeleteBankAccountModal";

const BankDetails = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [id, setId] = useState(-1);
  const [isPrimaryAccount, setIsPrimaryAccount] = useState<boolean>(false);
  const [bankAccountsList, setBankAccountsList] = useState<any>([]);
  const closeModal = () => {
    setShowModal(false);
  };
  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  const onClickDelete = (bankId: number, isPrimary: boolean) => {
    setIsPrimaryAccount(isPrimary);
    setId(bankId);
    setShowConfirmationModal(true);
  };
  const dispatch = useDispatch();
  const bankAccounts = useSelector((state: RootState) => {
    return state.userReducer?.profileData?.bankAccounts;
  });
  const countries = useSelector((state: RootState) => {
    return state.commonReducer?.commonData?.countries;
  });
  const loading = useSelector((state: RootState) => {
    return state.appReducer?.loading;
  });
  const postCallFn = () => {
    dispatch(getBankAccounts());
  };
  const onChangePrimaryBank = (id: number) => {
    dispatch(setAsPrimaryBankAccount(id, postCallFn));
  };
  useEffect(() => {
    if (bankAccounts?.length) {
      const tempBankAccountsList = bankAccounts;
      tempBankAccountsList.sort((a: any, b: any) => a.id - b.id);
      setBankAccountsList(tempBankAccountsList);
    }
  }, [bankAccounts]);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getBankAccounts());
  }, []);

  return (
    <>
      <AddBankDetailsModal
        isOpen={showModal}
        onClose={() => null}
        closeModal={closeModal}
        countries={countries}
      />
      <DeleteBankAccountModal
        isOpen={showConfirmationModal}
        id={id}
        isPrimary={isPrimaryAccount}
        closeModal={closeConfirmationModal}
        onClose={() => {}}
      />
      <Flex direction="column" mt={5} ml={5} rowGap={5}>
        <Box fontSize="12px" fontWeight="600">
          {Strings.bankDetails}
        </Box>
        <Flex direction="column" rowGap={10}>
          {bankAccountsList?.length !== 0 && loading ? (
            <BankDetailsSkeleton bankAccounts={bankAccountsList} />
          ) : (
            bankAccountsList?.map((el: any, index: number) => {
              return (
                <Flex
                  direction={{ md: "row", base: "column-reverse" }}
                  columnGap="150px"
                  rowGap={5}
                  key={index}
                >
                  <Flex
                    fontSize="14px"
                    direction="column"
                    rowGap={2}
                    width={{ md: "280px", base: "auto" }}
                  >
                    <Flex dir="column" flexWrap="wrap">
                      <Box color={Colors.grey100}>
                        {Strings.accountName} :&nbsp;
                      </Box>
                      <Box>{el.accountName}</Box>
                    </Flex>
                    <Flex dir="column" flexWrap="wrap">
                      <Box color={Colors.grey100}>
                        {Strings.accountNumber} :&nbsp;
                      </Box>
                      <Box>{el.accountNumber}</Box>
                    </Flex>
                    <Flex dir="column" flexWrap="wrap">
                      <Box color={Colors.grey100}>
                        {Strings.bank} {"&"} {Strings.branch} :&nbsp;
                      </Box>
                      <Box>
                        {el.bankName}
                        {el.branch !== null && `, ${el.branch}`}
                      </Box>
                    </Flex>
                    <Flex dir="column" flexWrap="wrap">
                      <Box color={Colors.grey100}>
                        {Strings.country} {"&"} {Strings.swiftCode} :&nbsp;
                      </Box>
                      <Box>
                        {countries[el.countryId - 1]?.name} , {el.swiftCode}
                      </Box>
                    </Flex>
                    <Flex dir="column" flexWrap="wrap">
                      <Box color={Colors.grey100}>
                        {Strings.yourAddress} :&nbsp;
                      </Box>
                      <Box>{el.address === null ? "NA" : el.address}</Box>
                    </Flex>
                    <Flex dir="column" flexWrap="wrap">
                      <button
                        onClick={() => {
                          onClickDelete(el.id, el.isPrimary);
                        }}
                      >
                        <Box
                          fontSize="11px"
                          fontWeight="600"
                          color={Colors.purple}
                        >
                          {Strings.removeAccount}
                        </Box>
                      </button>
                    </Flex>
                  </Flex>
                  {bankAccountsList?.length > 1 && (
                    <Flex fontSize="12px">
                      <Switch
                        isChecked={el.isPrimary}
                        colorScheme={"green"}
                        size={"md"}
                        onChange={() => {
                          onChangePrimaryBank(el.id);
                        }}
                      />
                      <Box ml={2}>{Strings.useDefaultAccount}</Box>
                    </Flex>
                  )}
                </Flex>
              );
            })
          )}
        </Flex>
        <Box mt={10}>
          {bankAccounts?.length === 0 ? (
            <Flex
              fontSize="14px"
              ml={{ md: "7vw", base: "0px" }}
              direction={{ md: "row", base: "column" }}
            >
              <Box color={Colors.grey100}>
                {Strings.notAddedBankDetails}&nbsp;
              </Box>
              <button
                onClick={() => setShowModal(true)}
                style={{
                  color: Colors.purple,
                }}
              >
                {Strings.addNow}
              </button>
            </Flex>
          ) : (
            <button
              onClick={() => setShowModal(true)}
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: Colors.purple,
              }}
            >
              {Strings.addAnotherAccount}
            </button>
          )}
        </Box>
      </Flex>
    </>
  );
};

export default BankDetails;
