import { brandKey, influencerKey } from "../constants"

interface IUserInfo {
  isEmailVerified: boolean;
  isPhoneVerified: boolean;

  isInfluencerOnBoarded?: boolean;
  isBrandOnBoarded?: boolean;
  isUserOnBoarded?: boolean;

  primaryEmailAddressId: number;
  primaryEmailAddress: string;
  primaryPhoneId: number;
  primaryPhone: string;

  userType: string;
}

interface IUserObject {
  accessToken: string;
  userInfo: IUserInfo;
}

/**
 * Changes the key name from brandMemberInfo / influencerInfo to userInfo
 * @param responseObject - the response from the server has accessToken key and either of the two keys depending on the type of user
 * @param isBrand - flag used to access the key and rename it
 * @returns a uniform user object for both brand user and influencer user, of the type IUserObject
 */
export const sanitizeUserObject = (responseObject: any, isBrand: boolean): IUserObject => {
  const keyName = isBrand ? brandKey : influencerKey
  delete Object.assign(responseObject, { userInfo: responseObject[keyName] })[keyName];
  return responseObject
}