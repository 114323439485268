import { Box, Skeleton } from "@chakra-ui/react";

const DashboardSkeleton = () => {
  return (
    <Box>
      <Skeleton rounded={"md"} height={"50px"} mb="30px" width={"300px"} />
      <Box mb="30px">
        <Skeleton rounded={"md"} height={"30px"} width={"100px"} mb="10px" />
        <Skeleton rounded={"md"} height={"150px"} />
      </Box>
      <Box mb="30px">
        <Skeleton rounded={"md"} height={"30px"} width={"100px"} mb="10px" />
        <Skeleton rounded={"md"} height={"85px"} />
      </Box>
      <Box mt="10px">
        <Skeleton rounded={"md"} height={"150px"} />
      </Box>
    </Box>
  );
};

export default DashboardSkeleton;
