import { Strings } from "@be-tagged/shared";
import { FC } from "react";

/**
 * Component that will return a 404 - Page not found error message
 * @returns {JSX.Element}
 */
const Support: FC<{}> = (): JSX.Element => {
  return (
    <div style={{ padding: "20px" }}>
      <p style={{ marginTop: "10px", fontWeight: "800" }}>
        {Strings.companyAddress}
      </p>
      <p style={{ marginTop: "10px" }}>{Strings.companyAddressValue}</p>
      <p style={{ marginTop: "10px", fontWeight: "800" }}>
        {Strings.contactDetails}
      </p>
      <p style={{ marginTop: "10px" }}>{Strings.contactDetailsValue1}</p>
      <p style={{ marginTop: "5px" }}>{Strings.contactDetailsValue2}</p>
      <p style={{ marginTop: "5px" }}>{Strings.contactDetailsValue3}</p>
    </div>
  );
};

export default Support;
