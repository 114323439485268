import { FC } from "react";
import { Box, Spinner } from "@chakra-ui/react";

import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { RootState, Strings } from "@be-tagged/shared";
import { FormikKeys } from "@be-tagged/shared/src/enums";

import {
  CustomBtn,
  ErrorCleaner,
  MaterialStyleInput,
} from "../../../app/components";
import { YUP_VALIDATION } from "../../../app/strings";
import { ROUTING_URLS } from "../../../app/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { getResetPassword, PasswordRegex } from "@be-tagged/shared";

const { FORGOT_PASSWORD } = ROUTING_URLS;

const { Password } = FormikKeys;
const { password } = Strings;

const getInitialValues = () => {
  return {
    [Password]: "",
  };
};

interface ResetPasswordProps {
  primaryEmail: string;
  userType: string;
}

const getValidationSchema = () => {
  return {
    [Password]: yup
      .string()
      .required(Strings.enterPassword)
      .matches(PasswordRegex, Strings.passwordMatch),
  };
};

/**
 * @returns {JSX.Element}
 * @param {Object}
 */

const ResetPassword: FC<ResetPasswordProps> = ({
  primaryEmail,
  userType,
}): JSX.Element => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.appReducer);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const savePassword = (values: any) => {
    dispatch(
      getResetPassword(
        { email: primaryEmail, password: values.password, userType: userType },
        () => {
          navigate(pathname.replace(FORGOT_PASSWORD, ROUTING_URLS.SIGN_IN));
        }
      )
    );
  };

  return (
    <>
      <ErrorCleaner />
      <Formik
        initialValues={getInitialValues()}
        onSubmit={savePassword}
        validationSchema={yup.object().shape(getValidationSchema())}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          isValid,
        }) => (
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <Box pb={{ base: 0, lg: "20px" }}>
                <Box pos={"relative"} pb={"12px"}>
                  <MaterialStyleInput
                    parentProps={{ style: { width: 300 } }}
                    value={values.password}
                    onChange={handleChange(Password)}
                    onBlur={() => setFieldTouched(Password)}
                    type={Password}
                    label={password}
                  />
                  {touched.password && errors.password && (
                    <Box style={{ position: "relative" }} className="error_msg">
                      {errors.password}
                    </Box>
                  )}
                </Box>
              </Box>

              <Box className="actions_btn_container">
                <CustomBtn
                  id="action_btn"
                  className="purple_btn"
                  disabled={!isValid || loading}
                  type="submit"
                  style={{
                    fontSize: 14,
                    marginBottom: 30,
                  }}
                >
                  {loading && (
                    <Spinner size={"xs"} style={{ marginRight: 10 }} />
                  )}
                  {"Save"}
                </CustomBtn>
              </Box>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default ResetPassword;
