import { ILogTransport } from "./LogTransportInterface"
import { LogLevels } from "../../enums/LogLevels";
import UserModel from "./UserModel";

/**
 * The LoggerService class defines the logging methods, and contains the loggers to be used.  
 */
class LoggerService {
  // List of transports in use
  private transportProviders: ILogTransport[] = [];

  /**
   * To initialise the Logger with configuration [of log transports].
   * The transports being used should be dependent on the environment the app currently runs in.
   * Refer to Technical - Log Transports page of BeTagged Logging Playbook
   * @param _transports An array containing the transports to be used.
   */
  init = (_transports: ILogTransport[]) => {
    this.transportProviders = _transports;
    this.transportProviders.forEach(_transport => _transport.init())
  }

  /**
   * Debug logs are valuable when we have to debug a hard-to-reproduce production issue.
   * Add debug logs when you have a prod bug which is not reproduced easily and you want to know values being used on user's browser/device.
   * e.g., variable values, API request / response payloads 
   * @param message A string describing what exactly is being debugged
   * @param attributes an object containing the values like variables / payloads as described above.
   */
  debug = (message: string, attributes: Record<string, any>) => {
    this.transportProviders.forEach(_transport => _transport.log(message, attributes, LogLevels.Debug))
  }

  /**
   * Info logs record all of the user actions + any major task the app undertakes.
   * @param message 
   * @param attributes 
   * @example function delete(id){
   *  logger.info("event description", {
   *    btnLabel: "delete",
   *    btnAnalyticsID: "btnDelete",
   *    taskID: id
   * })}
   */
  info = (message: string, attributes?: Record<string, any>) => {
    this.transportProviders.forEach(_transport => _transport.log(message, attributes || {}, LogLevels.Info))
  }

  /**
   * Error method is used to record handled errors like getting an error response from the back-end.
   * This should be used in every case where we show the error messages to the user, but should be called before showing the message. 
   * @param message The error message to be shown
   * @param attributes An object containing the details of the error message like error response payload
   */
  error = (message: string, attributes: Record<string, any>) => {
    this.transportProviders.forEach(_transport => _transport.log(message, attributes, LogLevels.Error))
  }

  /**
   * For when we want to identify a user using the app, so that the other logging actions can be attributed to the user.
   * Should be ideally called whenever the core identifying details of a user appear / change
   * This means when the user signs up / logs in / opens the app while already being logged in
   * @param user An object describing the user's details
   */
  identify = (user: UserModel) => {
    this.transportProviders.forEach(_transport => _transport.identify(user))
  }

  /**
   * When you want to log a page visit event. If you're a mobile user, try using the `trackScreen` method instead.
   * @param name The name of the page. For web this can be a `document.title`
   * @param attributes Additional data you want to log
   */
  trackPage = (name: string, attributes?: Record<string, any>) => {
    this.transportProviders.forEach(_transport => _transport.trackPage(name, attributes))
  }

  /**
   * When you want to log a screen visit event. Note: Only to be used by a mobile user, web client should try using the `trackPage` method instead.
   * @param name The name of the screen currently on. For mobile this can be a `Screen` enum, used by the `react-navigation` library
   * @param attributes Additional data you want to log
   */
  trackScreen = (name: string, attributes?: Record<string, any>) => {
    this.transportProviders.forEach(_transport => _transport.trackScreen(name, attributes))
  }
}

export const logger = new LoggerService();  