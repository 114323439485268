import { RootState, clearErrors } from "@be-tagged/shared";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

/**
 * Component that will trigger removal of all errors in Redux state at unmount.
 * Useful when is placed as a child of a component which shows errors, which on remounting should show no errors
 * @returns {JSX.Element}
 */
const ErrorClearer: FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const error = useSelector((state: RootState) => state.appReducer.error);

  useEffect(() => {
    return () => {
      if (error) dispatch(clearErrors());
    };
    //eslint-disable-next-line
  }, []);

  return <></>;
};

export default ErrorClearer;
