import {
  getInfluencersOfBrandURL,
  getSalesDataUploadURL,
} from "../../../utils/UrlBuilder";
import {
  FormikKeys,
  HTTPMethod,
  InfluencersOfBrandParams,
} from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import {
  SET_SUCCESS,
  UPDATE_INFLUENCERS_OF_BRAND,
} from "../../constants/ReduxConstants";
import Strings from "../../../strings/LocalisedStrings";
import LoggerStrings from "../../../strings/LoggerStrings";
import { logger } from "../../../services/Logging";

interface SalesDataRequestFormat {
  [FormikKeys.BrandProductID]: number;
  [FormikKeys.SalesDataURL]: string;
}

export const getInfluencersOfBrand =
  (params: InfluencersOfBrandParams, booleanFn?: any) =>
  async (dispatch: any) => {
    booleanFn?.(true);
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: params,
      url: getInfluencersOfBrandURL(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => postInfluencersFetch(payload, booleanFn)]
    );
  };

const postInfluencersFetch = (payload: any, booleanFn?: any) => {
  booleanFn?.(false);
  return {
    type: UPDATE_INFLUENCERS_OF_BRAND,
    payload,
  };
};

export const clearInfluencersOfBrand = () => ({
  type: UPDATE_INFLUENCERS_OF_BRAND,
  payload: null,
});

export const uploadSalesData =
  (payload: SalesDataRequestFormat) => async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      data: payload,
      url: getSalesDataUploadURL(payload[FormikKeys.BrandProductID]),
    };
    return fetchAsync(dispatch, [request], [postUploadFn]);
  };

export const postUploadFn = (payload: any) => {
  logger.info(`${Strings.brand} ${LoggerStrings.uploadedSalesData}`);
  return {
    type: SET_SUCCESS,
    payload: {
      message: payload?.data?.data || Strings.salesDataUploaded,
    },
  };
};
