import { components } from "react-select";
import { useState } from "react";
import { default as ReactSelect } from "react-select";
import { beTaggedStyles, PurpleArrowDropdown } from "..";
import { useSelector } from "react-redux";
import { Colors, RootState, Strings } from "@be-tagged/shared";
import { useEffect } from "react";
import { Box, Checkbox, Flex } from "@chakra-ui/react";
import { FC } from "react";
import useAuth from "src/app/hooks/useAuth";

interface CategoriesProps {
  value: any;
  handleChange: any;
  handleBlur?: any;
  preferredCategories?: boolean;
}

interface DropDownProps extends CategoriesProps {
  categories: any[];
}

const Option = (props: any) => {
  const onCategorySelect = (e: any) => {
    e?.stopPropagation?.();
    e?.preventDefault?.();
    props.selectOption({ ...props.data });
  };
  return (
    <div>
      <components.Option {...props}>
        <Flex onClick={onCategorySelect}>
          <Box
            mr={2}
            onClick={(e) => {
              e?.preventDefault();
            }}
          >
            <Checkbox
              style={{ borderColor: Colors.borderColor }}
              colorScheme={"pink"}
              isChecked={props.isSelected}
            />
          </Box>
          <label style={{ fontSize: 12, color: Colors.grey, fontWeight: 400 }}>
            {props.label}
          </label>
        </Flex>
      </components.Option>
    </div>
  );
};

const MultiValue = (props: any) => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.name}</span>
    </components.MultiValue>
  );
};

const MultiValueRemove = () => null;

const CategoriesSelection: FC<CategoriesProps> = (props) => {
  let categories =
    useSelector((state: RootState) => {
      if (props.preferredCategories) return useAuth()?.preferredCategories;
      return state.commonReducer?.commonData?.categories;
    }) || [];

  return <CategoriesDropdown categories={categories} {...props} />;
};

const CategoriesDropdown: FC<DropDownProps> = ({
  categories,
  value,
  handleChange,
  handleBlur,
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    let items: any = [];
    categories.forEach((el: any) => {
      const ind = items.findIndex((x: any) => el.categoryGroupName === x.label);
      if (ind === -1) {
        items.push({
          label: el.categoryGroupName,
          options: [
            {
              ...el,
              id: el.id.toString(),
            },
          ],
        });
      } else {
        items[ind] = {
          ...items[ind],
          options: [
            ...items[ind].options,
            {
              ...el,
              id: el.id.toString(),
            },
          ],
        };
      }
    });
    setList(items);
  }, [categories]);

  return (
    <ReactSelect
      options={list}
      getOptionLabel={(option: any) => option.name}
      getOptionValue={(option: any) => option.id}
      isMulti={true}
      isClearable={false}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      hideSelectedOptions={false}
      placeholder={Strings.selectProductsServices}
      components={{
        Option,
        MultiValue,
        MultiValueRemove,
        ...PurpleArrowDropdown,
      }}
      value={value}
      styles={{ ...beTaggedStyles, ...customStyles }}
      onChange={handleChange}
      onMenuClose={handleBlur}
    />
  );
};

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: `1px solid ${Colors.borderColor}`,
    color: Colors.blackColor,
    fontWeight: 400,
    backgroundColor: "auto",
    padding: 5,
    fontSize: 12,
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingLeft: 12,
    paddingRight: 12,
  }),
  groupHeading: (provided: any) => ({
    ...provided,
    color: Colors.blackColor,
    fontWeight: 400,
  }),
};

export default CategoriesSelection;
