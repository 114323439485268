import { FC, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Spinner,
  Box,
  Flex,
} from "@chakra-ui/react";

import { logger, LoggerStrings, Strings } from "@be-tagged/shared";

import { CustomBtn } from "../../../../app/components";
import useData from "src/app/hooks/useData";
import { CommonObjectType } from "src/app/constants";
import { PossibleRequestStates } from "@be-tagged/shared/src/enums";

interface ActionConfirmProps {
  actionData: CommonObjectType;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

/**
 * This Modal comes up when an influencer's request is either approved or rejected
 * @param {Object} props - Of the form ActionConfirmProps
 * @returns {JSX.Element} - A React Functional Component
 */
const ActionConfirmModal: FC<ActionConfirmProps> = ({
  actionData,
  isOpen,
  onClose,
  onConfirm,
}): JSX.Element => {
  const { loading } = useData();
  const { action, salesChannel = [] } = actionData;
  const isApproved = action === PossibleRequestStates.Approved;

  useEffect(() => {
    if (isOpen) {
      logger.info(
        `${Strings.influencerRequest} ${
          isApproved ? Strings.accept : Strings.reject
        } ${LoggerStrings.promptOpened}`
      );
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        size={"2xl"}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent className="otp_modal" style={{ paddingTop: 12 }}>
          <ModalBody>
            <Box className="content_container">
              <Box className="bold" style={{ fontSize: 18, paddingBottom: 24 }}>
                {isApproved ? Strings.accept : Strings.reject}{" "}
                {Strings.influencerRequest}
              </Box>

              <Box style={{ fontSize: 16, marginBottom: 50 }}>
                {Strings.requestActionText1}{" "}
                {isApproved ? Strings.accept : Strings.reject}{" "}
                {Strings.requestActionText2} {Strings.on}{" "}
                {salesChannel?.join(", ")}?
              </Box>

              <Flex
                justify={"center"}
                direction={{
                  sm: "column",
                  md: "row",
                }}
                gap={"20px"}
              >
                <CustomBtn onClick={onClose} className="purple_btn medium inv">
                  {Strings.noLetMeRethink}
                </CustomBtn>
                <CustomBtn onClick={onConfirm} className="purple_btn medium">
                  {loading && (
                    <Spinner size={"xs"} style={{ marginRight: 10 }} />
                  )}
                  {isApproved ? Strings.yesAccept : Strings.yesReject}
                </CustomBtn>
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ActionConfirmModal;
