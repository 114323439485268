import {
  getBrandOnboardingURL,
  getInfluencerOnboardingURL,
} from "../../../utils/UrlBuilder";
import {
  SET_ACCESS_TOKEN,
  SHOW_ONBOARDING_MODAL,
  UPDATE_ONBOARDING_VALUES,
} from "../../constants/ReduxConstants";
import { HTTPMethod } from "../../../enums";
import { clearErrors, fetchAsync } from "../GlobalActions/App.actions";
import { RootState } from "../../reducers";

export const completeUserOnboarding =
  (data: any, navigationFn: any) =>
  async (dispatch: any, getState: () => RootState) => {
    await dispatch(clearErrors());
    const { isBrand } = getState().userReducer;
    const request = {
      method: HTTPMethod.POST,
      queryStringParams: {},
      data,
      url: isBrand ? getBrandOnboardingURL() : getInfluencerOnboardingURL(),
    };
    await dispatch({
      type: SHOW_ONBOARDING_MODAL,
      payload: true,
    });
    await fetchAsync(
      dispatch,
      [request],
      [(payload: any) => updateOnboardingValues(payload, navigationFn)]
    );
  };

export const setShowOnboardingModal = (status: boolean) => {
  return {
    type: SHOW_ONBOARDING_MODAL,
    payload: status,
  };
};

const updateOnboardingValues = (payload: any, navigationFn: any) => {
  return (dispatch: any, getState: () => RootState) => {
    const { isBrand } = getState().userReducer;
    const onboardingObject: { [k: string]: any } = {};

    if (isBrand) {
      onboardingObject.isBrandOnBoarded = true;
      onboardingObject.isUserOnBoarded = true;
      if (payload?.data?.accessToken) {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: payload.data.accessToken,
        });
      }
    } else {
      onboardingObject.isInfluencerOnBoarded = true;
    }
    dispatch(setShowOnboardingModal(false));
    navigationFn();
    return dispatch({
      type: UPDATE_ONBOARDING_VALUES,
      payload: onboardingObject,
    });
  };
};
