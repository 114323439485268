import { FC, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Spinner,
  Box,
  Flex,
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";

import {
  addTeamMember,
  Colors,
  getBrandTeamMembers,
  logger,
  LoggerStrings,
  RootState,
  Strings,
} from "@be-tagged/shared";

import { CustomBtn, MaterialStyleInput } from "src/app/components";
import { Formik } from "formik";
import { FormikKeys, TeamQueryParams } from "@be-tagged/shared/src/enums";
import * as yup from "yup";
import { YUP_VALIDATION } from "src/app/strings";
import RoleSelect from "src/app/components/CustomSelect/RoleSelection";
import { CommonObjectType } from "src/app/constants";

interface AddMemberModalProp {
  isOpen: boolean;
  onClose: () => void;
  closeFilter: () => void;
}
interface SubmittedDetailsInt {
  [FormikKeys.Email]?: string;
  [FormikKeys.Role]?: string;
}

/**
 * @param {Object} props - Of the form AddMemberModalProp
 * @returns {JSX.Element} - A React Functional Component
 */
const AddMemberModal: FC<AddMemberModalProp> = ({
  isOpen,
  closeFilter,
  onClose,
}): JSX.Element => {
  //Fetching Formik Keys
  const { Email, Role } = FormikKeys;
  const [localRoleFilter, setLocalRoleFilter] = useState<CommonObjectType>({
    id: -1,
    name: "",
  });
  const getInitialValues: () => SubmittedDetailsInt = () => {
    let initialValues = {
      [Email]: "",
      [Role]: "",
    };
    return initialValues;
  };
  const initialParams: CommonObjectType = {
    skip: 0,
    take: 5,
    order: "",
    criteria: "",
  };
  const getValidationSchema = () => {
    return {
      [Email]: yup.string().email().required(YUP_VALIDATION.EMAIL_PRESENCE),
      [Role]: yup.string().required(YUP_VALIDATION.ROLE_PRESENCE),
    };
  };
  const { loading } = useSelector(
    (state: RootState) => state?.appReducer?.loading
  );
  const dispatch = useDispatch();

  const postCallFn = () => {
    const finalPayload = initialParams as TeamQueryParams;
    dispatch(getBrandTeamMembers(finalPayload));
    closeFilter();
  };

  useEffect(() => {
    if (isOpen) logger.info(`Team - ${LoggerStrings.openedAddMember}`);
    setLocalRoleFilter({
      id: -1,
      name: "",
    });
  }, [isOpen]);

  const [addedMemberStage, setAddedMemberStage] = useState(false);

  const addNewTeamMember = (values: CommonObjectType) => {
    setAddedMemberStage(true);
    dispatch(
      addTeamMember(
        {
          [Email]: values[Email],
          role: localRoleFilter?.name,
        },
        false,
        postCallFn
      )
    );
  };
  useEffect(() => {
    if (addedMemberStage) {
      logger.info(`${Strings.brand} ${LoggerStrings.clickedOnAddButton}`);
    }
  }, [addedMemberStage]);
  const setRole = (role: string, values: any) => {
    values[Role] = role;
  };
  return (
    <>
      <Modal
        size={"2xl"}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          className="filter_modal"
          style={{ paddingTop: 12 }}
          backgroundColor={Colors.bgDark}
        >
          <ModalBody padding="15px">
            <Box px={{ md: "15px", base: "0px" }} className="content_container">
              <Box className="bold" style={{ fontSize: 18, paddingBottom: 24 }}>
                {Strings.addTeamMember}
              </Box>
              <Formik
                initialValues={getInitialValues()}
                onSubmit={() => {}}
                validationSchema={yup.object().shape(getValidationSchema())}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  setFieldTouched,
                  errors,
                  isValid,
                  touched,
                }) => (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                      }}
                    >
                      <>
                        <Flex
                          pos={"relative"}
                          pb={"12px"}
                          direction={{ md: "row", base: "column" }}
                          rowGap={4}
                        >
                          <div>
                            <MaterialStyleInput
                              value={values.email}
                              onChange={handleChange(Email)}
                              onBlur={() => setFieldTouched(Email)}
                              type={Email}
                              label={Strings.emailId}
                            />
                            {touched.email && errors.email && (
                              <Box color={Colors.red} fontSize={"9px"} mt={1}>
                                {errors.email}
                              </Box>
                            )}
                          </div>
                          <div>
                            <RoleSelect
                              selectedRole={localRoleFilter}
                              setSelectedRole={(x: any) => {
                                setLocalRoleFilter(x);
                              }}
                              onBlur={() => setFieldTouched("role")}
                            />
                            {touched.role && errors.role && (
                              <Box color={Colors.red} fontSize={"9px"} mt={1}>
                                {errors.role}
                              </Box>
                            )}
                            {setRole(localRoleFilter?.name, values)}
                          </div>
                        </Flex>
                      </>
                      <Flex justifyContent="flex-end" columnGap={4} mt={8}>
                        <CustomBtn
                          type="button"
                          className="purple_btn medium inv"
                          onClick={closeFilter}
                        >
                          {loading && (
                            <Spinner size={"xs"} style={{ marginRight: 10 }} />
                          )}
                          {Strings.cancel}
                        </CustomBtn>
                        <CustomBtn
                          type="submit"
                          disabled={
                            localRoleFilter.id === -1 || !Email || loading
                          }
                          className="purple_btn medium"
                          onClick={() => {
                            addNewTeamMember(values);
                          }}
                          style={{ paddingLeft: "30px", paddingRight: "30px" }}
                        >
                          {loading && (
                            <Spinner size={"xs"} style={{ marginRight: 10 }} />
                          )}
                          {Strings.add}
                        </CustomBtn>
                      </Flex>
                    </form>
                  </>
                )}
              </Formik>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddMemberModal;
