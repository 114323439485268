import { FC } from "react";
import { Box, useMediaQuery } from "@chakra-ui/react";
import { Colors, Strings } from "@be-tagged/shared";

import {
  BrandWatchIcon,
  BrandDumbleIcon,
  BrandShoeIcon,
  BrandHeadphoneIcon,
  BrandMobileIcon,
  Influencer_1,
  Influencer_2,
  Influencer_3,
  Influencer_4,
  Influencer_5,
} from "@be-tagged/shared/src/assets/icons";

import { AppLogo, CustomBtn, SplitOption } from "../../app/components";

import { ROLE_TYPES } from "../../app/enums";
import { Link, useNavigate } from "react-router-dom";
import { ROUTING_URLS } from "../../app/constants";
import { DEVICE } from "src/app/assets/styles/styleEnums";

interface SelectionOptionProps {
  roleType: ROLE_TYPES;
  isSelected?: boolean;
}

/**
 * SelectionOption is the component that takes up 50% of the screen size for larger displays
 * This `Option`, by default when isn't selected, shows "Sign In" & "Sign Up" action buttons
 * If it is selected, it implies that the user has clicked on either of the action buttons
 * Then that means we can show him the appropriate form (Sign In / Sign Up) alongside this one
 * @param {Object} - props of type SelectionOptionProps
 * @returns {JSX.Element} -  A React Component
 */
const SelectionOption: FC<SelectionOptionProps> = ({
  roleType,
  isSelected,
}): JSX.Element => {
  const isBrand = roleType === ROLE_TYPES.Brand;
  const BRAND_IMAGES = [
    BrandHeadphoneIcon,
    BrandShoeIcon,
    BrandDumbleIcon,
    BrandWatchIcon,
    BrandMobileIcon,
  ];
  const INFLUENCER_IMAGES = [
    Influencer_1,
    Influencer_2,
    Influencer_3,
    Influencer_4,
    Influencer_5,
  ];
  let bodyData = {
    preText: isBrand ? Strings.iam : Strings.iam + "n",
    roleName: isBrand ? Strings.brand : Strings.influencer,
    extendedTextMobile: isBrand
      ? Strings.extendedTextBrandMob
      : Strings.extendedTextInfMob,
    extendedText: isBrand
      ? Strings.extendedTextBrand
      : Strings.extendedTextInfluencer,
    extendedText2: isBrand
      ? Strings.extendedTextBrand2
      : Strings.extendedTextInfluencer2,
    images: isBrand ? BRAND_IMAGES : INFLUENCER_IMAGES,
  };
  const { BRAND, INFLUENCER, SIGN_IN, SIGN_UP } = ROUTING_URLS;
  const [isSmallerScreen] = useMediaQuery(DEVICE.tablet);
  const navigate = useNavigate();

  const switchToLogin = (toLogin: boolean) => {
    navigate(`${isBrand ? BRAND : INFLUENCER}/${toLogin ? SIGN_IN : SIGN_UP}`);
  };

  const [isSmall] = useMediaQuery(DEVICE.custom);

  return (
    <SplitOption
      className={`option ${isSelected ? "selected" : ""}`}
      id={isBrand ? "brand" : "influencer"}
    >
      <Box className="inner">
        {(isSmall && isSelected) || isBrand ? (
          <Box>
            <Link to={`${ROUTING_URLS.GENERAL}`}>
              <AppLogo />
            </Link>
          </Box>
        ) : (
          <Box className="logo_gap" />
        )}

        <Box className="role_actions_container">
          <Box className="role">
            {bodyData.preText}
            <br />
            <span className="type">{bodyData.roleName}</span>
            {isSmallerScreen ? (
              <Box
                fontSize={"18px"}
                color={Colors.grey200}
                fontWeight={"400"}
                lineHeight={"27px"}
                marginTop={"10px"}
              >
                {bodyData.extendedTextMobile}
              </Box>
            ) : (
              <>
                <Box
                  fontSize={"18px"}
                  color={Colors.grey200}
                  fontWeight={"400"}
                  lineHeight={"27px"}
                  marginTop={"10px"}
                >
                  {bodyData.extendedText}
                </Box>
                <Box
                  fontSize={"18px"}
                  color={Colors.grey200}
                  fontWeight={"400"}
                  lineHeight={"27px"}
                >
                  {bodyData.extendedText2}
                </Box>
              </>
            )}
          </Box>

          {!isSelected && (
            <Box className="btn_group">
              <CustomBtn
                className="action_btn"
                onClick={() => switchToLogin(true)}
                style={{ marginRight: 20 }}
                data-test={"button-" + Strings.signIn.toLowerCase()}
              >
                {Strings.signIn}
              </CustomBtn>

              <CustomBtn
                className="action_btn inv"
                onClick={() => switchToLogin(false)}
                data-test={"button-" + Strings.signUp.toLowerCase()}
              >
                {Strings.signUp}
              </CustomBtn>
            </Box>
          )}
        </Box>

        <Box className="circular_imgs_parent">
          {bodyData.images.map((el, idx) => {
            return (
              <Box key={idx} className="img_container">
                <img className="img" src={el} alt="Logo" key={idx} />
              </Box>
            );
          })}
        </Box>
      </Box>
    </SplitOption>
  );
};

export default SelectionOption;
