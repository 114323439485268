import { Strings } from "@be-tagged/shared";
import { sortObject } from "../components/CustomSort";

const {
  onboardingHeading,
  onboardingNameInput1,
  onboardingNameInput2,
  onboardingBusinessInput1,
  onboardingBusinessInput2,
  onboardingServicesInput1,
  onboardingServicesInput2,
  onboardingCityInput,
  onboardingSocialPresence,
  onboardingInterests1,
  onboardingInterests2,
} = Strings;

export const TOKEN_NAME = "userInfo";

const SIGN_IN = "sign-in";
const SIGN_UP = "sign-up";
const FORGOT_PASSWORD = "forgot-password";
export const ROUTING_URLS = {
  GENERAL: "/",
  SELECT: "/s",
  BRAND: "brand",
  INFLUENCER: "influencer",
  SIGN_IN,
  SIGN_UP,
  FORGOT_PASSWORD,
  INFLUENCER_SIGNIN: "influencer-signin",
  INFLUENCER_SIGNUP: "influencer-signup",
  ONBOARDING: "/onboarding",
  LANDING: "/app",
  NON_EXISTENT: "*",
  PRIVACY_POLICY: "/privacy-policy",
  SUPPORT: "/support",
  // Dashboard
  PRODUCT_LISTING: "product-listing",
  PRODUCTS_CATALOG: "products-catalog",
  PRODUCT_DETAILS: "product-details",
  BUSINESS_INSIGHTS: "business-insights",
  SETTINGS: "settings",
  APPROVAL_REQUESTS: "approval-requests",
  TEAMS: "team",
  PROFILE: "profile",
};

export const ROUTING_PARAMS = {
  PLATFORM_INSIGHTS: "platform_insights",
  COMMISSION_PAYOUT: "commission-payout",
  PROMOTED_BY_ME: "promoted-by-me",
  BANK_DETAILS: "bank-details",
  INFLUENCERS: "influencers",
  ADD_TEAM_MEMBER: "add-member",
};

export const ONBOARDING_TEXT = {
  heading: onboardingHeading,

  inputForm: {
    name: {
      title: {
        preText: onboardingNameInput1,
        postText: onboardingNameInput2,
      },
      influencerTitle: {
        preText: onboardingNameInput2,
        postText: onboardingCityInput,
      },
    },
    business: {
      title: {
        preText: onboardingBusinessInput1,
        postText: onboardingBusinessInput2,
      },
      influencerTitle: {
        preText: "",
        postText: onboardingSocialPresence,
      },
    },
    services: {
      title: {
        preText: onboardingServicesInput1,
        postText: onboardingServicesInput2,
      },
      influencerTitle: {
        preText: onboardingInterests1,
        postText: onboardingInterests2,
      },
    },
  },
};

export const defaultCountryCode = {
  currencyCode: "IDR",
  currencySymbol: "IDR",
  id: 78,
  isdCode: "62",
  iso3Code: "IDN",
  isoCode: "ID",
  name: "Indonesia",
};

export const BRAND_MEMBER = "BrandMember";

export const COUNTRY_CODES = ["91", "96", "62", "63", "886", "55"];

export const CUSTOM_DATE_FORMAT = "DD MMM YYYY";

export const MEMBER_SINCE_DATE_FORMAT = "MMM, YYYY";

export const PRODUCTS_SOLD_SORT_OPTIONS: sortObject[] = [
  {
    name: "Price",
    value: "price",
  },
  {
    name: "Sales",
    value: "sales",
  },
  {
    name: "Value",
    value: "salesValue",
  },
  {
    name: "Commission",
    value: "commission",
  },
  {
    name: "Data upload date",
    value: "salesDataLastUploadedAtUtc",
  },
];

export const DIRECT_TRACKING_BLACKLISTED_ROUTES = [
  ROUTING_URLS.GENERAL,
  ROUTING_URLS.ONBOARDING,
  ROUTING_URLS.LANDING,
];
