import styled from "styled-components";
import { FC, forwardRef } from "react";
import { Colors } from "@be-tagged/shared/src/theme";

interface IMaterialInput {
  isTextArea?: boolean;
  label: string;
  parentProps?: object;
  labelLeft?: number;
  [k: string]: any;
  hideLabel?: boolean;
  hideLabelOnEnter?: boolean;
  bottomLabel?: string;
  makeResponsive?: boolean;
}

interface LabelProps {
  labelLeft?: number;
  makeResponsive?: boolean;
}
/**
 * Custom made input component that resembles a Material UI Input
 * The label acts as a placeholder when the input is empty and not focused
 * On focus / value fill, the label size decreases and transitions above the input text
 * @param {Object} - Props for the element
 * @returns {JSX.Element}
 */

const MaterialStyleInput: FC<IMaterialInput> = forwardRef<
  HTMLInputElement,
  IMaterialInput
>(
  (
    {
      isTextArea = false,
      label = "",
      parentProps = {},
      labelLeft = 0,
      makeResponsive = false,
      ...props
    },
    ref
  ): JSX.Element => {
    return (
      <InputGroup {...parentProps}>
        {isTextArea ? (
          <TextArea ref={ref} {...props} placeholder=" " />
        ) : (
          <Input
            ref={ref}
            {...props}
            makeResponsive={makeResponsive}
            placeholder=" "
          />
        )}
        <Bar />
        {!props.hideLabel && (
          <Label labelLeft={labelLeft} makeResponsive={makeResponsive}>
            {label}
          </Label>
        )}
        {props.bottomLabel && <BottomLabel>{props.bottomLabel}</BottomLabel>}
      </InputGroup>
    );
  }
);

MaterialStyleInput.displayName = "MaterialStyleInput";

// Styled components //

const customWidth = "320px";
const transTime = "300ms";

const InputGroup = styled.div`
  margin: 25px 0 3px;
  position: relative;
  width: ${(props: any) => props?.styles?.width || "inherit"};
`;

const Input = styled.input<any>`
  background: none;
  color: ${Colors.blackColor};
  text-overflow: ellipsis;
  display: block;
  width: ${(props) => props.width || "inherit"};
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${Colors.borderColor};
  &:focus {
    outline: none;
  }
  &:focus ~ label,
  &:not(:placeholder-shown) ~ label {
    display: ${(props) => (props.hideLabelOnEnter ? "none" : "block")};
    top: -20px;
    font-size: 12px;
    font-weight: 600;
  }
  &:focus ~ .bar:before {
    /* width: ${customWidth}; */
    width: ${(props) => props.width || "inherit"};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${Colors.grey100};
  }
  @media screen and (max-width: 406px) {
    width: ${(props) => {
      return props.makeResponsive ? "calc(100vw - 200px)" : "inherit";
    }};
  }
`;

const Label = styled.label<LabelProps>`
  font-size: inherit;
  color: ${Colors.blackColor};
  position: absolute;
  pointer-events: none;
  top: 2px;
  left: ${({ labelLeft }) => `${labelLeft}px`};
  transition: ${transTime} ease all;
  font-size: 14px;
  @media screen and (max-width: 406px) {
    width: ${(props) => {
      return props.makeResponsive ? "calc(100vw - 200px)" : "inherit";
    }};
    white-space: ${(props) => {
      return props.makeResponsive ? "nowrap" : "wrap";
    }};
    overflow: ${(props) => {
      return props.makeResponsive ? "hidden" : "visible";
    }};
    text-overflow: ellipsis;
  }
`;

const BottomLabel = styled.div<any>`
  color: ${Colors.blackColor};
  display: block;
  pointer-events: none;
  font-size: 10px;
  margin-top: 5px;
`;

const TextArea = styled(Input).attrs({ as: "textarea" })`
  border: 1px solid ${Colors.borderColor};
  border-radius: 5px;
`;

const Bar = styled.span`
  position: relative;
  display: block;
  width: ${customWidth};
  &:before {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: black;
    transition: ${transTime} ease all;
    left: 0%;
  }
`;

export default MaterialStyleInput;
