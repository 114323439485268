import { logger } from "../Logger";

export const IdentifyUser = (payloadData: any, isBrand: boolean) => {
  // Logging begins to identify the user //
  const { brandMemberInfo, influencerInfo } = payloadData;
  if (isBrand) {
    logger.identify({
      id: brandMemberInfo?.userKey,
      firstName: '',
      lastName: '',
      email: brandMemberInfo?.primaryEmailAddress,
      userType: '',
    });
  } else {
    logger.identify({
      id: influencerInfo?.userKey,
      firstName: '',
      lastName: '',
      email: influencerInfo?.primaryEmailAddress,
      userType: '',
    });
  }
  // Logging ends //
};
