import { getCommissionPayoutURL } from "../../../utils/UrlBuilder";
import { CommissionPayoutParams, HTTPMethod } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import {
  UPDATE_COMMISSION_PAYOUT,
} from "../../constants/ReduxConstants";

export const getCommissionPayout =
  (params: CommissionPayoutParams, booleanFn?: any) => async (dispatch: any) => {
    booleanFn?.(true)
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: params,
      url: getCommissionPayoutURL(),
    };
    return fetchAsync(dispatch, [request], [(payload: any) => postCommissionFetch(payload, booleanFn)]);
  };

const postCommissionFetch = (payload: any, booleanFn?: any) => {
  booleanFn?.(false)
  return ({
    type: UPDATE_COMMISSION_PAYOUT,
    payload: payload
  })
}

export const clearCommissionPayout = () => ({
  type: UPDATE_COMMISSION_PAYOUT,
  payload: null
})