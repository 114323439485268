import { LogLevels } from "../../enums/LogLevels";
import { ILogTransport } from "./LogTransportInterface";
import UserInterface from "./UserModel";

export default class ConsoleLogTransport implements ILogTransport {
  client: any;
  init = () => { }
  log = (message: string, attributes: Record<string, any>, level: LogLevels) => {
    const { log, error, warn } = console
    let fn: (x?: any) => void;
    switch (level) {
      case LogLevels.Info:
        fn = log;
        break;
      case LogLevels.Error:
        fn = error;
        break;
      default:
        fn = log;
        break;
    }
    console.group(`${level} Event Logging`.toUpperCase());
    fn(message)
    fn(attributes)
    console.groupEnd();
  };
  identify = (user: UserInterface) => { }
  trackPage = (name: string, attributes?: Record<string, any>) => {
    this.log(`User visited the ${name} page`, attributes || {}, LogLevels.Info)
  }
  trackScreen = (name: string, attributes?: Record<string, any>) => {
    this.log(`User visited the ${name} screen`, attributes || {}, LogLevels.Info)
  }
}
