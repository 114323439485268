export const withPadding = (num: number): string => {
  return num.toString().padStart(2, "0")
}

export const obfuscateMobileNo = (phone: string): string => {
  return `${phone.slice(0, 3)}${phone.slice(3, phone.length - 2).replace(/./g, "*")}${phone.slice(phone.length - 2)}`
}

export const obfuscateEmail = (email: string): string => {
  const [head, domain] = email.split("@")
  return `${head.slice(0, 3)}${head.slice(3).replace(/./g, "*")}@${domain}`
}

export const isValidUrl = (url: any) => {
  try {
    new URL(url);
  } catch (e) {
    console.error(e);
    return false;
  }
  return true;
};

export const capitalizeFirstLetter = (string = ' ') => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}