export enum Screen {
  Intro = 'INTRO',
  Signup = 'SIGNUP',
  Signin = 'SIGNIN',
  Onboarding = 'ONBOARDING',
  ForgotPassword = 'FORGOTPASSWORD',
  ResetPassword = 'RESETPASSWORD',
  ProductDescription = 'PRODUCT_DESCRIPTION',
  ProductDetails = 'PRODUCTDETAILS',
  Products = 'PRODUCTS',
  ProductListing = 'PRODUCTLISTING',
  Dashboard = "DASHBOARD",
  Home = 'Home',
  ProductApproval = 'PRODUCTAPPROVAL',
  BusinessInsights = 'BUSINESSINSIGHTS',
  Team = 'TEAM',
  AddTeamMember = 'ADD_TEAM_MEMBER',
  Settings = 'SETTINGS',
  App = 'APP',
  BellNotification = 'BELL_NOTIFICATION',
  SwipScreen = 'SWIP_SCREEN',
}
