import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
  Spinner,
  Stack,
  VStack,
} from "@chakra-ui/react";
import "../ProductsCatalog.styles.scss";
import {
  SearchIcon,
  RightPagination,
  LeftPagination,
  NotFound,
} from "@be-tagged/shared/src/assets/icons";
import { List } from "../HelperComponents/index";
import { useEffect, useState, SyntheticEvent } from "react";
import {
  Colors,
  getInfluencerSeekingApprovalData,
  logger,
  LoggerStrings,
  RootState,
  Strings,
} from "@be-tagged/shared";
import { useDispatch, useSelector } from "react-redux";
import {
  Pagination,
  usePagination,
  PaginationPage,
  PaginationNext,
  PaginationPrevious,
  PaginationPageGroup,
  PaginationContainer,
  PaginationSeparator,
} from "@ajna/pagination";
import InfluencersList from "./InfluencersList";
import { Fragment } from "react";
import ProductsCatalogSkeleton from "../HelperComponents/SkeletonLayout";
import useSetPageTitle from "src/app/hooks/useSetPageTitle";
import useAuth from "src/app/hooks/useAuth";

const initalParams = {
  skip: 0,
  take: 5,
  order: "",
  criteria: "",
  onlyIncludeProductsWithPromotionRequests: true,
};

/**
 * @returns {JSX.Element} - A React Functional Component
 */
const ApprovalRequests = (): JSX.Element => {
  const { loading } = useSelector((state: RootState) => state?.appReducer);
  const [searchParams, setSearchParams] = useState(initalParams);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [withQuery, setWithQuery] = useState<boolean>(false);
  const clearPrev = !searchQuery && withQuery;

  const approvalData =
    useSelector((state: RootState) => {
      return state.brandReducer?.productData?.approvalData;
    }) || [];

  useSetPageTitle(`Influencers Seeking Approval`);

  // Pagination constants
  const { pages, pagesCount, currentPage, setCurrentPage, isDisabled } =
    usePagination({
      total: approvalData?.totalCount,
      limits: {
        outer: 1,
        inner: 1,
      },
      initialState: {
        pageSize: 5,
        isDisabled: false,
        currentPage: 1,
      },
    });

  const dispatch = useDispatch();

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };

  const filterByName = (e: SyntheticEvent) => {
    e.preventDefault();
    let _temp = {
      ...searchParams,
      criteria: searchQuery,
      skip: 0,
    };
    const isAtFirstPage = currentPage === 1;
    changeParams(_temp, isAtFirstPage);
    if (!isAtFirstPage) setCurrentPage(1);
  };

  const changeParams = (params: any, triggerChange: boolean = true) => {
    setSearchParams(params);
    setWithQuery(!!params.criteria);
    triggerChange && dispatch(getInfluencerSeekingApprovalData(params));
  };

  useEffect(() => {
    changeParams({
      ...searchParams,
      skip: currentPage * 5 - 5,
    });
  }, [currentPage]);

  useEffect(() => {
    if (clearPrev) {
      let _temp = { ...searchParams };
      _temp = {
        ..._temp,
        skip: 0,
        criteria: "",
      };
      changeParams({
        ..._temp,
        criteria: searchQuery,
      });
    }
  }, [searchQuery]);
  const { isBrand } = useAuth();
  useEffect(() => {
    if (withQuery && searchQuery) {
      const user = isBrand ? Strings.brand : Strings.influencer;
      logger.info(`${user} ${LoggerStrings.searchedFor} ${searchQuery}`);
    }
  }, [withQuery, searchQuery]);

  return (
    <Stack>
      <Flex>
        <Box>
          <Flex direction="column">
            <Flex
              direction={{
                base: "column",
                tablet: "row",
              }}
              fontSize="36px"
              fontWeight="600"
              mb={5}
            >
              <Box>
                <span>{Strings.influencersSeeking}</span>
                <span style={{ color: Colors.dollarGreen }}>
                  {" "}
                  {Strings.salesApproval}
                </span>
              </Box>
            </Flex>
            <Flex
              direction={{ md: "row", base: "column" }}
              height="51px"
              alignItems={{ md: "center", base: "flex-start" }}
              justifyContent={{ md: "space-between", base: "normal" }}
              width={{ custom: "85vw", md: "98vw", base: "95vw" }}
            >
              <Flex alignItems="center">
                <form onSubmit={filterByName}>
                  <Box width={{ md: "40vw", base: "90vw" }} position="relative">
                    <input
                      type="text"
                      onChange={(e: any) => setSearchQuery(e.target.value)}
                      placeholder={Strings.typeYourSearch}
                      className="search-bar box-shadow"
                    />
                    <button
                      type="submit"
                      className="search-button box-shadow"
                      style={{ background: Colors.purple }}
                    >
                      <img src={SearchIcon} alt={"Search"} />
                    </button>
                  </Box>
                </form>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>
      {approvalData?.totalCount === 0 ? (
        <Center>
          <Flex direction="column">
            <img src={NotFound} alt="NoDataFound" />

            <Box fontSize="16px" p={10}>
              {Strings.productNotFound}
            </Box>
          </Flex>
        </Center>
      ) : (
        <Fragment>
          <VStack spacing={4} align="stretch" pt={5} minH="350px">
            {loading && <ProductsCatalogSkeleton />}
            {(approvalData?.data || []).map((el: any) => {
              return (
                <Accordion allowToggle key={el?.brandProductId}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        className="accordion"
                        _expanded={{ bg: "white" }}
                        style={{ padding: "0px", outline: "none" }}
                      >
                        <Box flex={1}>
                          <List
                            id={el.brandProductId}
                            name={el.name}
                            price={el.price}
                            currencySymbol={el.currencySymbol}
                            commission={el.commissionPercentage}
                            influencers={el.influencerCount}
                            sales={el.salesCount}
                            image={el.showCaseMediaUrls?.[0]}
                            status={el.productStatus}
                            isBrand={true}
                          />
                        </Box>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} backgroundColor="white">
                      <InfluencersList id={el.brandProductId} />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              );
            })}
          </VStack>
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            isDisabled={isDisabled}
            onPageChange={handlePageChange}
          >
            <PaginationContainer align="center" justify="flex-end">
              <PaginationPrevious bg={Colors.bgDark}>
                <div>
                  <img src={LeftPagination} alt="Previous" />
                </div>
              </PaginationPrevious>
              <PaginationPageGroup
                isInline
                align="center"
                separator={
                  <PaginationSeparator
                    bg={Colors.bgDark}
                    fontSize="sm"
                    jumpSize={11}
                  />
                }
              >
                {pages.map((page: number) => (
                  <PaginationPage
                    w={10}
                    color="black"
                    fontWeight="400"
                    key={`pagination_page_${page}`}
                    page={page}
                    fontSize="18px"
                    borderRadius="100%"
                    _hover={{
                      bg: Colors.dollarGreen,
                    }}
                    _current={{
                      bg: Colors.dollarGreen,
                      fontSize: "18px",
                      w: 10,
                      color: Colors.themeColor,
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext bg={Colors.bgDark}>
                <div>
                  <img src={RightPagination} alt="Next" />
                </div>
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Fragment>
      )}
    </Stack>
  );
};

export default ApprovalRequests;
