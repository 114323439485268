import React, { FC, useState } from "react";
import { Box } from "@chakra-ui/react";
import "../";
import { CrossBadgeIcon } from "@be-tagged/shared/src/assets/icons";
import { Strings } from "@be-tagged/shared";
import { refreshFile } from "@be-tagged/shared/src/utils/FileUpload";

interface RefreshedImage {
  imageUrl: string;
  removeImage: any;
}

const RefreshedImage: FC<RefreshedImage> = ({
  imageUrl,
  removeImage,
}): JSX.Element => {
  const [refreshedImageUrl, setRefreshedImageUrl] = useState(imageUrl);
  const refreshImage = async (img: string) => {
    const fileUrl = await refreshFile(img);
    setRefreshedImageUrl(fileUrl);
  };
  return (
    <Box className="image_width_badge">
      <Box className="cross_badge_icon">
        <img
          alt="cross"
          src={CrossBadgeIcon}
          style={{
            position: "absolute",
            height: "8px",
          }}
          onClick={removeImage}
        />
      </Box>
      <Box className="product_image_container">
        <img
          src={refreshedImageUrl}
          alt={Strings.productImage}
          style={{
            borderRadius: "10px",
            fontSize: "10px",
            width: "45px",
            height: "45px",
          }}
          onError={() => refreshImage(imageUrl)}
        />
      </Box>
    </Box>
  );
};
export default RefreshedImage;
