import {
  clearProductsSold,
  getProductsSold,
  logger,
  LoggerStrings,
  RootState,
  Strings,
} from "@be-tagged/shared";
import { NoDataFound, NotFound } from "@be-tagged/shared/src/assets/icons";
import {
  FormikKeys,
  ProductsSoldQueryParams,
} from "@be-tagged/shared/src/enums";
import { Box, Center, Flex, Stack } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "src/app/hooks/useAuth";
import useData from "src/app/hooks/useData";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomSort from "../../../../../app/components/CustomSort";
import {
  CommonObjectType,
  PRODUCTS_SOLD_SORT_OPTIONS,
  ROUTING_URLS,
} from "src/app/constants";
import LoadingSpinner from "src/app/components/LoadingSpinner";
import SearchBar from "src/app/components/SearchBar";
import BrandProductsSoldRow from "../Brand/ProductsSoldRow";
import InfluencerProductsSoldRow from "../Influencer/ProductsSoldRow";
import SalesDataSampleSection from "./SalesDataSampleSection";
import InsightsSkeleton from "../SkeletonLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const PER_PAGE = 10;

const { StartFrom, ListQty, CountryID, OrderBy, Search } = FormikKeys;
interface ITabProductsSold {
  country: CommonObjectType | null;
  dateParams: any;
  key?: number;
}

/**
 * @returns {JSX.Element} - A React Functional Component
 */
const TabProductsSold: FC<ITabProductsSold> = ({
  country,
  dateParams,
}): JSX.Element => {
  const [isLazyLoading, setIsLazyLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { BUSINESS_INSIGHTS, PRODUCT_DETAILS } = ROUTING_URLS;
  const navigateToProductDetails = (id: number) => {
    navigate(pathname.replace(BUSINESS_INSIGHTS, PRODUCT_DETAILS) + "/" + id);
  };
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [hasMounted, setHasMounted] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const { isBrand } = useAuth();
  const { loading } = useData();

  const productsSold = useSelector((state: RootState) => {
    return state.commonReducer.commonData?.insights?.productsSold || {};
  });

  useEffect(() => {
    return () => {
      dispatch(clearProductsSold());
    };
  }, []);

  useEffect(() => {
    if (isBrand && !country) return;
    initiateFetch();
  }, [dateParams, country, sortBy, searchQuery]);

  useEffect(() => {
    if (hasMounted) fetchProductsSold(true);
    else setHasMounted(true);
  }, [currentPage]);

  const fetchProductsSold = (persistPrevious = false) => {
    if (!persistPrevious) dispatch(clearProductsSold());
    let params: CommonObjectType = {
      [StartFrom]: currentPage * PER_PAGE - PER_PAGE,
      [ListQty]: PER_PAGE,
      [OrderBy]: sortBy,
      [Search]: searchQuery || null,
    };
    if (isBrand) {
      params[CountryID] = country?.id;
    }
    let finalPayload = {
      ...params,
      ...dateParams,
    } as ProductsSoldQueryParams;

    dispatch(
      getProductsSold(
        finalPayload,
        isBrand,
        persistPrevious ? setIsLazyLoading : undefined
      )
    );
  };

  const initiateFetch = () => {
    if (currentPage !== 1) setCurrentPage(1);
    else fetchProductsSold();
  };

  useEffect(() => {
    if (searchQuery) {
      const user = isBrand ? Strings.brand : Strings.influencer;
      logger.info(`${user} ${LoggerStrings.searchedFor}`, { searchQuery });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (sortBy) {
      const user = isBrand ? Strings.brand : Strings.influencer;
      logger.info(`${user} ${LoggerStrings.sortedProductsSold}`, { sortBy });
    }
  }, [sortBy]);
  return (
    <Stack>
      {(productsSold?.data?.length === 0 && !searchQuery) ||
      (isBrand && !country) ? (
        <Center>
          {isBrand ? (
            <Flex direction="column" alignItems="center">
              <img src={NotFound} alt="NoDataFound" />
              <Box fontSize="14px">{Strings.noDataFound}</Box>
            </Flex>
          ) : (
            <Flex direction="column">
              <img src={NoDataFound} alt="NoDataFound" />
              <Box fontSize="14px">{Strings.noSale}</Box>
              <Box fontSize="14px">{Strings.promoteSellEarn}</Box>
            </Flex>
          )}
        </Center>
      ) : (
        <>
          {isBrand && (
            <Flex className="search_sort_sample_parent">
              <Flex className="search_sort_container">
                <SearchBar onSearch={setSearchQuery} />
                <CustomSort
                  sortOptions={PRODUCTS_SOLD_SORT_OPTIONS}
                  onChange={setSortBy}
                />
              </Flex>
              <SalesDataSampleSection />
            </Flex>
          )}
          {productsSold?.data?.length === 0 && searchQuery ? (
            <Center>
              <Flex direction="column" alignItems="center">
                <img src={NotFound} alt="NoDataFound" />
                <Box fontSize="16px" p={10}>
                  {Strings.productNotFound}
                </Box>
              </Flex>
            </Center>
          ) : (
            <Flex direction={"column"} minHeight={{ md: "350px" }}>
              {loading && !isLazyLoading ? (
                <InsightsSkeleton />
              ) : (
                <InfiniteScroll
                  className="insights_scroll_parent"
                  dataLength={productsSold?.data?.length || 0}
                  next={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  hasMore={productsSold?.nextLink}
                  loader={<LoadingSpinner />}
                >
                  {(productsSold?.data || [])?.map((el: any, index: number) =>
                    isBrand ? (
                      <BrandProductsSoldRow item={el} key={index} />
                    ) : (
                      <Box
                        onClick={() => {
                          navigateToProductDetails(el?.brandProductId);
                        }}
                        cursor={"pointer"}
                      >
                        <InfluencerProductsSoldRow item={el} key={index} />
                      </Box>
                    )
                  )}
                </InfiniteScroll>
              )}
            </Flex>
          )}
        </>
      )}
    </Stack>
  );
};

export default TabProductsSold;
