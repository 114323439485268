import { useState, useEffect, FC } from "react";
import { Text } from "@chakra-ui/react";
import {
  withPadding,
  completeUserOnboarding,
  getCategories,
  Strings,
  MIN_NAME_LENGTH,
  SocialMediaRegex,
  getCountries,
  logger,
  LoggerStrings,
} from "@be-tagged/shared";

import { FormikKeys, OnboardingStepsName } from "@be-tagged/shared/src/enums";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  NameInput,
  BusinessNameInput,
  ProductsAndServicesInput,
  TermsAndConditionInput,
} from "./FormInputs";

import {
  NameAndCityInput,
  SocialMediaHandleInput,
} from "./InfluencerFormInputs";

import { Formik } from "formik";
import * as yup from "yup";

import { Step, StepArrows } from "../../../../app/components";
import { CommonObjectType, ROUTING_URLS } from "../../../../app/constants";
import useAuth from "src/app/hooks/useAuth";

const {
  FullName,
  BusinessName,
  ProductsAndServices,
  City,
  Location,
  Latitude,
  Longitude,
  Country,
  Instagram,
  TikTok,
  Facebook,
  Twitter,
  EntryValidation,
  SocialMediaAccounts,
  HasAcceptedTermsAndCondition,
} = FormikKeys;

const {
  enterFullName,
  enterBusinessName,
  enterProductsAndServices,
  cannotBeNumber,
  enterValidName,
  enterValidSocialHandle,
} = Strings;

interface IOnboardingDetails {
  [FullName]: string;
  [BusinessName]?: string;
  [ProductsAndServices]?: string[];

  [City]?: CommonObjectType | null;
  [Country]?: CommonObjectType | null;

  [Instagram]?: string;
  [Twitter]?: string;
  [Facebook]?: string;
  [TikTok]?: string;

  [HasAcceptedTermsAndCondition]?: boolean;
}

/**
 * Onboarding Form is the container that takes values step-by-step
 * If a brand is onboarded already, the user will only have to provide their name
 * Otherwise Name, Brand Name and Types of Services Offered inputs are required
 * @returns {JSX.Element} - A React Functional Component
 */
const OnboardingForm: FC = (): JSX.Element => {
  const { NameStep, BusinessStep, ProdServStep, TermsAndConditionsStep } =
    OnboardingStepsName;
  const [currentStep, setCurrentStep] = useState<OnboardingStepsName>(NameStep);
  const [stepArray, setStepArray] = useState<any[]>([
    NameStep,
    BusinessStep,
    ProdServStep,
    TermsAndConditionsStep,
  ]);
  const {
    isBrandFullyOnboarded: isBrandOnboarded,
    isOnlyBrandOnboarded,
    isOnlyUserOnboarded,
    fullnameForOnBoardedUser,
  } = useAuth();

  const isInfluencerOnboarded = false;

  const { isBrand } = useAuth();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isOnlyBrandOnboarded) {
      setStepArray([NameStep, TermsAndConditionsStep]);
    }
    if (isOnlyUserOnboarded) {
      setCurrentStep(BusinessStep);
      setStepArray([BusinessStep, ProdServStep, TermsAndConditionsStep]);
    }
  }, [isOnlyBrandOnboarded, isOnlyUserOnboarded]);
  const getOnboardingSchema = (currentStep: any): CommonObjectType => {
    const schema: CommonObjectType = {};
    switch (currentStep) {
      case NameStep:
        schema[FullName] = yup
          .string()
          .required(enterFullName)
          .min(MIN_NAME_LENGTH, enterValidName)
          .test(cannotBeNumber, cannotBeNumber, (val) => !/\d/.test(`${val}`))
          .matches(/^[aA-zZ\s]+$/, enterValidName);
        if (!isBrand) {
          schema[Country] = yup
            .object()
            .nullable()
            .required(Strings.selectCountry);
          schema[City] = yup.object().nullable();
        }
        return schema;
      case BusinessStep:
        if (!isBrand) {
          return {
            [Instagram]: yup
              .string()
              .matches(SocialMediaRegex, enterValidSocialHandle),
            [Twitter]: yup
              .string()
              .matches(SocialMediaRegex, enterValidSocialHandle),
            [Facebook]: yup
              .string()
              .matches(SocialMediaRegex, enterValidSocialHandle),
            [TikTok]: yup
              .string()
              .matches(SocialMediaRegex, enterValidSocialHandle),
            [EntryValidation]: yup
              .string()
              .when([Instagram, Twitter, Facebook, TikTok], {
                is: (a: string, b: string, c: string, d: string) =>
                  !a && !b && !c && !d,
                then: yup.string().required(Strings.enterSocialHandle),
                otherwise: yup.string(),
              }),
          };
        }
        return {
          [BusinessName]: yup.string().required(enterBusinessName),
        };
      case ProdServStep:
        return {
          [ProductsAndServices]: yup
            .array()
            .min(1)
            .required(enterProductsAndServices),
        };
      case TermsAndConditionsStep:
        schema[HasAcceptedTermsAndCondition] = yup
          .boolean()
          .required(Strings.termsAndConditionErrorMessage);
        return schema;
      default:
        return {};
    }
  };

  const getInitialValues = () => {
    let initalValues: IOnboardingDetails = {
      [FullName]: "",
      [ProductsAndServices]: [],
      [HasAcceptedTermsAndCondition]: false,
    };

    // case for type = influencer && !isInfluencerOnboarded
    if (!isInfluencerOnboarded) {
      initalValues[Country] = null;
      initalValues[City] = null;
      initalValues[Instagram] = "";
      initalValues[Instagram] = "";
      initalValues[Facebook] = "";
      initalValues[TikTok] = "";
    }

    // case for type = brand && !isBrandOnboarded
    if (!isBrandOnboarded) {
      initalValues[BusinessName] = "";
    }
    if (isOnlyUserOnboarded) {
      initalValues[FullName] = fullnameForOnBoardedUser;
    }
    return initalValues;
  };

  useEffect(() => {
    if (stepArray.includes(ProdServStep)) dispatch(getCategories());
    if (!isBrand) dispatch(getCountries());
    //eslint-disable-next-line
  }, []);

  const sanitizeOnboardingDetails = (onboardingDetails: IOnboardingDetails) => {
    const categories = onboardingDetails[ProductsAndServices]?.map((el: any) =>
      parseInt(el?.id)
    );
    if (isBrand) {
      const onboardingObject: CommonObjectType = {
        [FullName]: onboardingDetails[FullName],
        [BusinessName]: onboardingDetails[BusinessName],
        [ProductsAndServices]: categories,
        [HasAcceptedTermsAndCondition]:
          onboardingDetails[HasAcceptedTermsAndCondition],
      };
      return onboardingObject;
    } else {
      const city = onboardingDetails[City]
        ? `${onboardingDetails[City]?.city}, ${onboardingDetails[City]?.country}`
        : null;
      const location = city
        ? {
            [Latitude]: onboardingDetails[City]?.[Latitude],
            [Longitude]: onboardingDetails[City]?.[Longitude],
          }
        : null;
      return {
        [FullName]: onboardingDetails[FullName],
        [ProductsAndServices]: categories,
        [City]: city,
        [Location]: location,
        [Country]: onboardingDetails[Country]?.id,
        [SocialMediaAccounts]: {
          [Instagram]: onboardingDetails[Instagram] || null,
          [TikTok]: onboardingDetails[TikTok] || null,
          [Facebook]: onboardingDetails[Facebook] || null,
          [Twitter]: onboardingDetails[Twitter] || null,
        },
        [HasAcceptedTermsAndCondition]:
          onboardingDetails[HasAcceptedTermsAndCondition],
      };
    }
  };

  const completeOnboarding = (onboardingDetails: IOnboardingDetails) => {
    const payload = sanitizeOnboardingDetails(onboardingDetails);
    dispatch(completeUserOnboarding(payload, navigateToLanding));
  };

  const navigateToLanding = () => {
    navigate(ROUTING_URLS.LANDING, {
      replace: true,
    });
  };

  const goToPrevious = (setErrors: any) => {
    setErrors({});
    setCurrentStep(
      (currentStep) => stepArray[stepArray.indexOf(currentStep) - 1]
    );
  };

  const goToNext = (values: IOnboardingDetails) => {
    if (currentStep !== stepArray.slice(-1)[0]) {
      setCurrentStep(
        (currentStep) => stepArray[stepArray.indexOf(currentStep) + 1]
      );
    } else completeOnboarding(values);
  };

  const getInputForStep = (formObject: any) => {
    switch (currentStep) {
      case NameStep:
        if (isBrand) return <NameInput {...formObject} />;
        return <NameAndCityInput {...formObject} />;
      case BusinessStep:
        if (isBrand) return <BusinessNameInput {...formObject} />;
        return <SocialMediaHandleInput {...formObject} />;
      case ProdServStep:
        return <ProductsAndServicesInput {...formObject} isBrand={isBrand} />;
      case TermsAndConditionsStep:
        return <TermsAndConditionInput {...formObject} />;
      default:
        return "";
    }
  };

  const isNextButtonDisabled = (isValid: boolean, cStep: any, values: any) => {
    if (
      isValid &&
      cStep === TermsAndConditionsStep &&
      !values[HasAcceptedTermsAndCondition]
    ) {
      return true;
    }

    return !isValid;
  };

  useEffect(() => {
    logger.info(
      `${isBrand ? Strings.brand : Strings.influencer} ${
        LoggerStrings.onboardingStepReached
      } ${currentStep}`
    );
  }, [currentStep]);

  return (
    <>
      <div className="step_indicator_group">
        {stepArray.map((el, index) => {
          return (
            <Step
              key={el}
              className={`step ${
                stepArray.indexOf(currentStep) >= index ? "active" : ""
              }`}
            >
              {withPadding(index + 1)}
            </Step>
          );
        })}
      </div>
      <div className="inputs_section">
        <Formik
          initialValues={getInitialValues()}
          validationSchema={yup
            .object()
            .shape(getOnboardingSchema(currentStep))}
          onSubmit={goToNext}
        >
          {({
            errors,
            isValid,
            touched,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setErrors,
            setFieldTouched,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              {getInputForStep({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                isSubmitting,
              })}
              <StepArrows
                prevDisabled={currentStep === stepArray[0]}
                nextDisabled={isNextButtonDisabled(
                  isValid,
                  currentStep,
                  values
                )}
                prevBtnClick={() => goToPrevious(setErrors)}
              />
            </form>
          )}
        </Formik>
      </div>
      {currentStep === stepArray[1] && !isBrand && (
        <Text className="social_media_text">
          The correct declaration of your social media handles will help us
          attribute your sales accurately.
        </Text>
      )}
    </>
  );
};

export default OnboardingForm;
