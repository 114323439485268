import { BetaggedLogo } from "@be-tagged/shared/src/assets/icons";
import { FC } from "react";

/**
 * The Logo for Betagged App
 * @returns {JSX.Element}
 */
const AppLogo: FC<{}> = (): JSX.Element => {
  return (
    <div
      style={{
        backgroundImage: `url(${BetaggedLogo})`,
        marginLeft: "-10px",
      }}
      className="logo"
      role="img"
      aria-label="BeTagged Logo"
    />
  );
};

export default AppLogo;
