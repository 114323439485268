export default {
  otp: "OTP",
  logOut: "Log Out",
  alreadyHavingAccount: "Already have an account?",
  boostBusiness: "Boost your sales with the power of influence.",
  spreadTheWord: "Spread the word. Get more sales. Earn more",
  userforgotPassword: "Forgot Password? Will help you sort it out.",
  brand: "Brand",
  dontFeelLikeSignIn: "Don't feel like signing in",
  dontFeelLikeSignup: "Don't feel like signing up",
  dontFeelLikeResetPass: "Don't feel like resetting password",
  next: "NEXT",
  search: "search",
  button: "button",
  save: "SAVE",
  loading: "Loading...",
  ForgetPassword: "Forget Password? will help you sort it out.",
  GreatSetNewPass: "Great, set your new password.",
  sixDigitOtp: "A six digit OTP has been sent to your registered email id",
  EnterItBelow: "Enter it below.",
  ResendOTP: "Resend OTP",
  updateContactDetails: "Wait, I want to update my OTP contact details",
  update: "Update",
  whatIsThisPreText: "What is this - ",
  LetsGo: "LETS GO !",
  Next: "NEXT > >",
  Skipthis: "Skip this",
  //yup validations begin//
  emailId: "Email ID",
  emailOTP: "Email OTP",
  enterValidOTP: "Please enter a 6-digit OTP",
  enterMobileNumber: "Please enter mobile number",
  enterPassword: "Please enter password",
  passwordMatch:
    "Password must have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 numeric character and a special character",
  enterValidEmail: "Please enter valid email",
  enterValidMobileNumber: "Mobile Number must be valid",
  enterCountryCode: "Please select a country code",
  enterCountryFirst: "Please select a country first",
  enterValidPassword: "Password must be at least 6 characters",
  enterFullName: "You must enter your full name",
  enterValidName: "Name must be at least 2 characters",
  enterBusinessName: "You must enter the name of your business",
  enterProductsAndServices:
    "You must select at least 1 category to which your business' products belong",
  enterTags: "Enter Tags",
  enterProductDesc: "Product Description is Required",
  enterValidURL: "Please enter a valid URL",
  cannotBeNumber: "Value should not contain digits",
  enterValidSocialHandle: "Improper handle entered",
  selectProductPlatform: "You must choose a product platform",
  enterURLforPlatform: "Enter the URL for the selected product platform",
  enterValidURLforPlatform: "Please enter valid product URL",
  uploadInComMallProductImage: "Please upload InCom mall product image",
  sizeMustNotEmpty: "Size must not be empty",
  orderUnitsMustNotEmpty: "Order Units must not be empty",
  orderUnitsMustBeNumber: "Order Units must be number",
  //yup validations end//
  enterVerifyOTP1: "Enter the OTP we sent you to your",
  emailAddress: "Email Address",
  enterVerifyOTPJoiner: "and to your",
  enterVerifyOTP2: "and to your email address",
  enterVerifyEmailOTP1:
    "A six digit OTP has been sent to you registered email id",
  enterVerifyEmailOTP2: "Enter it below.",
  error: "Error:",
  forgotPassword: "Forgot Password?",
  iam: "I am a",
  iaman: "I am an",
  influencer: "Influencer",
  brandMember: "Brand Member",
  mobileNo: "Mobile No",
  mobileNumber: "Mobile Number",
  mobileOTP: "Mobile OTP",
  noAccount: "Don't have an account yet?",
  password: "Password",
  deleteAccount: "Delete Account",
  pleaseConfirmDeleteAccount:
    "Please confirm that you want to delete your InCom account.",
  delete: "DELETE",
  accountDeleted: "your account deleted successfully!",
  pageNotFound: "404 Page Not Found",
  resend: "Resend",
  sendOTP: "Send OTP",
  signIn: "Sign In",
  signUp: "Sign Up",
  timeToPropelBusiness: "Boost your sales with the power of influencers",
  verify: "Verify",
  verifyContacts: "Let's verify your contact details.",
  workEmail: "Work Email",
  uploadBannerImg: "Please upload a banner image",
  uploadShowcaseImg: "Please upload at least one Showcase Image",
  //Onboarding strings begin//
  ok: "OK",
  onboardingHeading:
    "We need few basic details before we could set up your account",
  onboardingNameInput1: "",
  onboardingNameInput: "Let's start with your full name",
  onboardingNameInput2: "Let's start with your full name, country",
  onboardingCityInput: "and city",
  onboardingNameInput3: "Let's start with your full name, country and city",
  selectProductsServices: "Select Products and services",
  onboardingBusinessInput1: "Hello ",
  onboardingBusinessInput2:
    ", could you please tell us the legal name of your business.",
  onboardingServicesInput1: "Tell us a bit about",
  onboardingServicesInput2: "'s business, products and services.",
  onboardingSocialPresence: ", Let's get to know your social presence",
  productsAndServices: "Products and Services",
  onboardingModalTitle: "Grab a cup of coffee while we load your experience.",
  onboardingInterests1: "And finally, ",
  onboardingInterests2: ", what are your interests ?",
  yourName: "Your name",
  yourBusinessName: "Your Business Name",
  onBoardingPageTitle:
    "We need few basic details before we could set up your account",
  businessName: "Business Name",
  enterBusinessNAme: "Enter your business name",
  grabACupOfCoffee: "Grab a cup of coffee while we load your experience.",
  yourCity: "Your City ",
  yourCountry: "Your Country",
  enterCity: "Please enter city",
  enterMoreCharsForCity: "Enter at least 3 characters to look for a city",
  socialPresence: "Let's get to know your social presence",
  onboardingInfluencerServiceInput1: "And finally",
  onboardingInfluencerServiceInput2: "what are your interests",
  interests: "Interests",
  termsAndConditionBrand: "Lastly, Terms and Conditions and Purchase order",
  termsAndConditionInf: "Lastly, Terms and Conditions",
  noFileSelected: "No File Selected",
  uploadPurchaseOrderFile: "Purchase Order File is mandatory.",
  downloadPurchaseOrderPreText: "You can download the sample purchase order",
  here: " here",
  linkGenerated: "Congratulations! Remember the following steps:",
  step1:
    "Copy the unique link for each social media handle you will be using to sell",

  step2:
    "Make sure to paste the unique link in every post that you put up to sell the product",

  step3:
    "Do not mix up the links. For example use the link for Instagram only for IG posts.",

  step4:
    "Your followers can click these links to buy the products directly from InCom",

  step5:
    "When a sale happens you will see the results instantly on the InCom dashboard.",

  step6:
    "If there are any coupon codes, please ask your followers to use them at checkout.",
  generatedTheLinkForSocialMedia:
    "You have generated the link for social media",
  downloadPurchaseOrderPostText: ", sign it, and upload it",
  acceptTermsAndConditionPreText: "I accept ",
  acceptTermsAndConditionPostText: "Terms & Conditions",
  termsAndConditionErrorMessage: "The terms and conditions must be accepted.",
  tncPara1:
    "By downloading or using the app, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the app. You’re not allowed to copy or modify the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the app, and you also shouldn’t try to translate the app into other languages or make derivative versions. The app itself, and all the trademarks, copyright, database rights, and other intellectual property rights related to it, still belong to .",
  tncPara2:
    "is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you’re paying for.",
  tncPara3:
    "The InCom app stores and processes personal data that you have provided to us, to provide our Service. It’s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the InCom app won’t work properly or at all.",
  tncPara4:
    "The app does use third-party services that declare their Terms and Conditions.",
  tncPara5:
    "Link to Terms and Conditions of third-party service providers used by the app",
  tncPara6:
    "You should be aware that there are certain things that will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi or provided by your mobile network provider, but cannot take responsibility for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.",
  tncPara7:
    "If you’re using the app outside of an area with Wi-Fi, you should remember that the terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third-party charges. In using the app, you’re accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you’re using the app, please be aware that we assume that you have received permission from the bill payer for using the app.",
  tncPara8:
    "Along the same lines, cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged – if it runs out of battery and you can’t turn it on to avail the Service, cannot accept responsibility.",
  tncPara9:
    "With respect to ’s responsibility for your use of the app, when you’re using the app, it’s important to bear in mind that although we endeavor to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.",
  tncPara10:
    "At some point, we may wish to update the app. The app is currently available on Android – the requirements for the system(and for any additional systems we decide to extend the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. does not promise that it will always update the app so that it is relevant to you and/or works with the Android version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.",
  tncPara11: "Changes to This Terms and Conditions",
  tncPara12:
    "We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page.",
  tncPara13: "These terms and conditions are effective as of 2022-09-02",
  tncPara14: "Contact Us",
  tncPara15:
    "If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at shamik@betagged.co",
  googlePlayService: "Google Play Service",
  segment: "Segment",
  mixpanel: "Mixpanel",
  //Onboarding strings end//

  Price: "Price",
  Commission: "Commission",
  Category: "Category",
  Available: "Available",
  Description: "Description",
  productSoldOn: "Product Sold On",
  salesData: "Sales Data",
  socialMediaKit: "Social Media Kit",
  Influencers: "Influencers",
  Product_Details: "Product Details",
  Insights: "Insights",
  betagged: "BeTagged",
  productForYou: "Products for you on",
  typeYourSearch: "Type your search",
  Sales: "Sales",
  Status: "Status",
  yourProducts: "Your Products on ",
  sortByPrice: "Price High to Low",
  influencersSeekingApproval: "Influencers seeking sales approvals",
  listProduct: "List Product",
  listProduct2: "List",
  //#region ProductListing Strings Begin
  productName: "Product Name*",
  enterProductName: "Enter product name",
  enterProductDescription: "Enter product description",
  tags: "Tags",
  openOrApprovalBased: "Open offer and Approval based offer",
  description: "Product Description*",
  productCategory: "Product Category*",
  pullProduct: "Pull Product From BT Mall",
  inviteCollab: "Invite Collaborators",
  productDesc: "Product Description*",
  invalidDiscount: "Buyer discount must be between 0 and 100",
  productCountry: "Country where Product is Available*",
  productLogo: "Brand Logo (jpeg,png)*",
  productBannerImage: "Brand Logo (jpeg,png)*",
  productShowcaseImage:
    "Product Showcase images (jpeg,png)/Video (mp4) - Max 6 images/videos*",
  productSocialMediaKits: "Social Media  Guideline Kits - Zip (Optional)",
  commissionPercentage: "Commission Percentage*",
  productPrice: "Product Price*",
  enterCommissionPercentage: "Please enter commission percentage",
  enterProductPrice: "Please enter product price",
  enterBuyerDiscount: "Please enter buyer discount.",
  uploadDiscountFile: "Discount File is mandatory.",
  greaterThanZero: "Value should be greater than 0",
  valueMustInBetweenZeroToHundred: "Value must be between 0 and 100",
  descriptionCharacterLength: "Description should be atleast 25 characters",
  enterSocialHandle: "You must enter at least one handle!",
  buyerDiscount: "Buyer Discount",
  invalidCommissionPercentage:
    "Commission percentage must be between 1 and 100",
  commissionOfferText:
    "A healthy influencer commission percentage would be between 15 to 20 %",
  offer: "Offer",
  whatIsThis: "What is This?",
  open: "Open offer",
  approvalBased: "Approval Based offer",
  sellingProductPlatformText: "Where are you selling this product",
  productURLOnCompanyWebsite: "Product URL on Company Website",
  otherCommerceUrl: "Product URL",
  enterCompanyProductUrl: "Enter company product url",
  enterEcommerceUrl: "Enter eCommerce product url",
  listYourProducts: "List your products in 3 easy steps!",
  step: "Step",
  stepOne: "Step 1 - ",
  stepOneDescription: "Let's talk about the product itself",
  stepTwo: "Step 2 - ",
  stepTwoDescription: "Let's add some visuals to your product",
  stepThree: "Step 3 - ",
  stepThreeDescription: "Let's talk some business",
  currency: "Currency",
  pickBannerImage: "Add Brand Logo",
  pickshowcaseImagesOrVideos: "Add showcase Images/videos",
  uploadBannerImage: "Please upload Banner image",
  uploadShowcaseImages: "Please upload atleast one file for showcase images",
  selectCategory: "Please select category",
  selectCountry: "Please select country",
  productListedSuccessfully: "Product Listed Successfully!",
  productEditedSuccessfully: "Product Edited Successfully!",
  uploadCouponCodes: "Please upload coupon codes file",
  platformCharges: "Platform Charges",
  notOnlyWhiteSpacesInProductName:
    "Product name cannot contain only blankspaces",
  notOnlyWhiteSpacesInProductDesc:
    "Product description cannot contain only blankspaces",
  dimensionFormat: "Format is L*W*H (L, W, H should be numbers)",
  //#endregion ProductListing Strings End
  size: "Size",
  color: "Colour",
  orderUnits: "Order Units",
  lwh: "L * W * H",
  inches: "(inches)",
  gms: "(Gms)",
  weight: "Weight",
  inventoryLocation: "Inventory Location",
  contactPersonNumber: "Contact Person Number",
  zipCode: "Zip Code",
  img: "Image",
  increaseSize: "++ Size",
  productDetails: "Product Details",
  deliveryDetails: "Delivery Details",
  discountCouponsUpdated: "Discount Coupons Updated Successfully!",
  productUnlisted: "Product Unlisted Successfully!",
  salesDataUploaded: "Sales Data Uploaded Successfully!",
  ownWebsite: "Own Website",
  eCommercePlatforms: "eCommerce Platforms",
  inCommMall: "InCom Mall",
  inCommMallCaps: "InCommMall",
  tikTokCommerce: "TikTok Commerce",
  fbMediaURL: "FaceBook",
  igMediaURL: "Instagram",
  tiktokMediaURL: "TikTok",
  twitterMediaURL: "Twitter",
  beTaggedMall: "BeTagged Mall",
  comingSoon: "(Coming Soon)",
  createHashtag: "Create a Hashtag (without the #)",
  dragAndDropHere: "Drag and drop file here or",
  browseFile: "browse file",
  browseFileCap: "BROWSE FILE",
  commaSeperatedTags: "Please enter tags seperated by comma ex: Tag1,Tag2",
  platformEntryValidation: "Platform Entry Validation",
  ecommerceLimitedTracking:
    "NOTE:- Some E commerce platforms provide limited tracking. For a complete experience list your product on BT mall",
  bannerImage: "bannerImage",
  showCaseMedia: "showCaseMedia",
  facebookMediaKit: "facebookMediaKit",
  tikTokMediaKit: "tikTokMediaKit",
  instagramMediaKit: "instagramMediaKit",
  enterDimension: "enter dimension*",
  enterWeight: "enter weight*",
  enterLocation: "enter inventory location",
  enterZipCode: "enter zipcode",
  enterContactNumber: "enter contact person number",
  enterSize: "enter size",
  enterColor: "enter color",
  enterValidColor: "enter valid color",
  enterOrderUnits: "enter order units",
  addImage: "add image",
  // ProductListing Strings End
  termsAndConditions: "Terms & Conditions",
  poweredByBeTagged: "Powered By: BeTagged",
  //Landing Screen Strings Start
  welcome: "Welcome",
  onboardText: "Glad to see you onboard",
  facts1: "637 influencers",
  facts2: " (and having)",
  facts3: "12 million followers",
  facts4:
    "in Indonesia have keen interest in products and services you listed.",
  facts5: "32 brands",
  facts6: " doing business in domain same as yours, have made a sale over",
  facts7: "$500,000 ",
  facts8: "in last ",
  facts9: "6 months",
  facts10: "using our influencer network. ",
  iLongFacts1: "There are several brands here selling products that",
  iLongFacts2: "match your follower interests.",
  iLongFacts3: "Select the products you wish to sell to your followers,",
  iLongFacts4: "using your social media handles like Tiktok or Instagram.",
  iLongFacts5: "Earn commissions for every product sold.",
  feedback:
    "BeTagged help us increase our sale by 6%. And best part, we didnt spend any money on Influencer campaign!",
  influencerName: "Mark Wood, Chief Marketing Officer, Estee Lauder",
  listYourProduct1:
    "List your product now to sell to millions of consumers via influencers",
  listYourProduct2: " total 3000 influencers",
  listYourProduct3: " having ",
  listYourProduct4: "35 million followers",
  listYourProductNew:
    "List your product now to sell to millions of consumers via influencers",
  addTeamMember: "Add Team Member",
  noTeamAdded: "You haven't added any team member yet. Go ahead and add!",
  howItWorks: "Watch How Platform Works",
  download: "Download",
  productLink: "Product Link",
  productVideo: "Product Video",
  playVideo: "Play Video",
  listMyProduct: "List my product",
  productImage: "Product Image",
  on: "on",
  productURL: "Product URL",
  sampleFile: "Sample File",
  //Landing Screen Strings End
  // Axios Constants
  brandProduct: "BrandProduct",
  user: "User",
  companyWebsite: "Company Website",
  deliveredByIncomm: "Deliverd by InCom team",
  deliveredByYou: "Delivered by you",
  tikTok: "TikTok",
  twitter: "Twitter",
  facebook: "Facebook",
  instagram: "Instagram",
  beTaggedMallUrl: "BeTagged Mall",
  companyProductUrl: "Company Product",
  otherCommerceUrl1: "Other Commerce",
  tikTokCommerceUrl: "TikTok",
  price: "Price ",
  commission: "Commission ",
  category: "Category",
  offerType: "Offer Type",
  available: "Available",
  totalSales: "Total Sales",
  totalSalesAmount: "Total Sales Amount",
  totalInfluencers: "Total Influencers",
  commissionPayable: "Commission Payable",
  productDetailsTab: "Product Details",
  influencersTab: "Influencers",
  insightsTab: "Insights",
  productsSold: "Products Sold",
  socialMedia: "Social Media",
  listedOn: "Listed on: ",
  discountCouponCodes: "Discount coupon codes",
  purchaseOrder: "Purchase Order",
  purchaseOrderDescPre: "You can download the sample purchase order",
  purchaseOrderDescPost: ", sign it, and upload it",
  couponCodes: "Coupon Codes",
  upload: "Upload",
  //Navbar Items
  home: "Home",
  products: "Products",
  businessInsights: "Business Insights",
  settings: "Settings",
  productListing: "Product Listing",
  team: "Team",
  png: "png",
  unList: "UNLIST",
  unlistProduct: "Unlist Product?",
  yesUnlist: "YES, UNLIST",
  confirmUnlistProduct:
    "Are you sure? If you unlist the information will be lost and you will have to list again. Influencers will be able to sell the product for next 5 days.",
  profile: "Profile",
  bankDetails: "Bank Details",
  //Product Catalog for Influencers
  productsForYou: "Products For You On ",
  name: "Name",
  onboarding: "onboarding",
  catalog: "Catalog",
  status: "Status",
  sales: "Sales",
  influencers: "Influencers",
  icommission: "Commission",
  iprice: "Price",
  iofferType: "Offer Type",
  icategory: "Category",
  iopen: "Open",
  iapprovalBased: "Exclusive",
  productsOfYourInterest: "Products Matching your Interests",
  viewAllProducts: "View All Products",
  thereAre: "There are",
  thatSell: "that sell",
  matching: "matching your interests, which you may associate with.",
  withSimilars: "Influencers with similar interests to yours have",
  earned: "earned",
  aMissionOf: "a commission of",
  influencerTemp: "Products Matching your Interests",
  viewAllProduct: "View All Products",
  openOff: "Open",
  exclusive: "Exclusive",
  filterTitle: "Filter your results",
  clearFilters: "Clear All Filters",
  expressedOn: "Interest expressed on",
  reject: "Reject",
  accept: "Accept",
  follower: "Followers",
  interestExpressedOn: "Interest expressed on",
  seekingApproval: "sales approval",
  followers: "Followers",
  influencerSeeking: "Influencers seeking",
  influencersSeeking: "Influencers seeking",
  salesApproval: "sales approval",
  click: "Click",
  sellTheProduct: "I want to sell this product.",
  generateLink: "Generate My Unique Links for",
  discountCode: "Discount code for your followers",
  leaderboardTab: "Leaderboard",
  iWantToSell: "I want to sell this product. Generate My Unique Links for",
  filterYourResults: "Filter your results",
  categories: "Categories",
  clearAllFilters: "Clear All Filters",
  cancel: "CANCEL",
  apply: "Apply",
  browseFileCaps: "BROWSE FILE",
  toKnowMore: "To know more, please see instructions",
  influencerRequest: "Influencer Request",
  requestApproved: "You request is approved",
  saleClick: "Sales / Click",
  yesAccept: "Yes, Accept",
  yesReject: "Yes, Reject",
  noLetMeRethink: "No, let me rethink",
  requestActionText1: "Are you sure you want to",
  requestActionText2: "the influencer request to sell the product",
  youExpressedInterestOn: "You expressed interest in this product on ",
  requestRejected: "Your request is Rejected",
  requestPending: "Awaiting brand approval",
  rejectedDueToPeriodExpiration:
    "Your request is rejected due to period expiration",
  rejectedAutomatically: "Rejected due to period expiration",
  discCode: "Discount Code",
  forYourFollowers: "for your followers.",
  sellProductOn: "Are you sure you want to",
  theInfluencer: "the influencer request to sell the product on ",
  penndingInfuencerReq: "You expressed interest in this product on",
  loadingApproval: "Awaiting brand approval",
  rejectText: "Your request is rejected",
  influencerRequestdummy: "Influencer request",
  letMeRethink: "NO, LET ME RETHINK",
  your: "Your",
  performance: "performance",
  totalSale: "Total Sales - Volume",
  totalSaleValue: "Total Sales - Value",
  comissionEarned: "Comission Earned",
  paidOn: "Paid On",
  transactionID: "Transaction ID",
  clicks: "Clicks",
  conversion: "Conversion",
  commissionEarned: "Commission Earned",
  you: "You",
  allInfluencer: "All Influencers",
  topSellingInfluencer: "Top Selling Influencer",
  //influencers business Insights strings begin
  yourPerformance: "your performance",
  thisMonth: "This month",
  lastThreeMonths: "Last 3 months",
  lastSixMonths: "Last 6 months",
  volume: "Volume",
  value: "Value",
  commissionPayout: "Commission Payout",
  platformInsights: "Platform Insights",
  transactionId: "Transaction ID : ",
  allInfluencers: "All Influencers",
  topSellingInf: "Top Selling Influencer",
  approvedOn: "Approved on",
  rejectedOn: "Rejected on",
  success: "Success",
  errorMsg: "Error",
  permissiondenied: "Permission denied",
  downloadError: "Error occured while downloading",
  left: "left",
  lastUploaded: "Last uploaded",
  couponCodeExpiring: "Coupon codes expiring. Please upload",
  couponCodesExhausted: "Coupon codes exhausted. Please upload",
  couponCodesExhaustedMessage:
    "There are no more coupon code for this product. Influencers are not allowed to promote this product. Please upload coupon codes as soon as possible.",
  productTemporaryUnavailable:
    "We are temporarily unable to offer this product due to an exhaustion  of coupon codes. Please come back in 24 hours.",
  youHaveUnlistedThisProduct:
    "You have unlisted this product. After 5 days, the influencer will no longer be able to promote the product. To list again, repeat the listing process.",
  productUnlistedAlert: "Product Unlisted",
  sellerHasWithdrawnOffer: "The seller has withdrawn this offer effective",
  sellTillDate: "You can sell this product upto",
  listed: "Listed",
  unListed: "Unlisted",
  competitiorsAvg: "Competitiors (Avg)",
  topSellingProduct: "Top Selling Product",
  productSalesOn: "Product sales on",
  socialMediaPlatform: "social media platform",
  emptyProductSoldText:
    "It appears you haven't sold anything. Promote the products , sell and earn.",
  noSale: "It appears you haven't sold anything. ",
  promoteSellEarn: "Promote the products , sell and earn.",
  productNotFound:
    "Oops! The product you were searching for is no longer available.",
  // Brand Business Insights strings begin
  yourBetagged: "Your BeTagged,",
  totalView: "360 view",
  influencerCommission: "Influencer Commission",
  salesDataUploadedOn: "Sales data uploaded on",
  influencersInvolved: "Influencers Involved",
  sortBy: "Sort by:",
  noDataText: "No data available",
  businessInsightDefault:
    "It appears you havent sold anything. Promote the products , sell and earn.",
  // Brand Business Insights strings end
  productSalesOnPlatforms: "Product sales on social media platform",
  monthlySales: "Monthly Sales",
  makeCopy: "Make Copy",
  salesDataUpdatedOn: "Sales data updated on",
  wideView: "360 View",
  yourRank: "Your Rank",
  outOf: "out of",
  topSocialMedia:
    "The product is gaining more attraction on Facebook. We recommend promoting it more there.",
  helpline:
    "Our social media experts can help you increse your sale of this product through one on one consultation.",
  bookNow: "Book Now",
  accountName: "Account Name",
  accountNumber: "Account Number",
  bank: "Bank",
  addAnotherAccount: "++Add another account",
  country: "Country",
  useLoginID: "Use this as Login ID",
  useLoginNumber: "Use this as Login Number",
  useDefaultAccount: "Use this account as default",
  addAlternateEmail: "Add alternate Email ID",
  addAlternateNumber: "Add alternate Mobile No",
  addSocialMedia: "Add Social Media ID",
  edit: "Edit",
  loginDetails: "Login Details",
  influencerSince: "Influencer Since",
  totalProductsSold: "Total Products Sold",
  totalCommissionEarned: "Total Commission Earned",
  noDataFound: "No Data Found",
  noProductsListed: "Success awaits you on the other side,",
  listYouProductNow: "list your product now",
  yourBetaggedTeam: "your BeTagged Team",
  email: "Email",
  role: "Role",
  add: "Add",
  beTeggedTeam: "Be Tagged Team",
  allProducts: "All Products",
  promotedByMe: "Promoted by me",
  beTaggedTeam: "BeTagged Team",
  thisProductIsGaining:
    "The product is gaining more traction on Facebook. We recommend promoting it more there.",
  addBankAccount: "Add Bank Account",
  optional: "optional",
  bankAndBranch: "Bank & Branch",
  countrySwiftCode: "Country & SWIFT Code",
  teamMemberSince: "Team member since",
  change: "Change",
  changePasword: "Change Password",
  changePassword: "Change Password",
  bankName: "Bank Name",
  branch: "Branch",
  swiftCode: "Swift Code",
  yourAddress: "Your Address",
  addNow: "Add Now",
  notAddedBankDetails: "You have not added bank details.",
  addBankDetails: "Add Bank Details",
  dataFrom: "Data from",
  totalInfluencersInvolved: "Total Influencers Involved (All Products)",
  commissionToBePaid: "Commission accrued by Influencers This Month",
  viewDetailedAnalysis: "View Detailed Analysis",
  viewAnalysis: "View Analysis",
  saleThisMonthValue: "Total sales value (This month)",
  saleThisMonthVol: "Total sales volume (This month)",
  productListedTillDate: "Product Listed till Date",
  topFourInfluencers: "Influencers promoting your brand",
  topFiveProducts: "Top 5 Products sold till date",
  viewAll: "View All",
  totalSalesValueThismonth: "Total sales value (This month)",
  totalSalesVolumeThismonth: "Total sales volume (This month)",
  salesChannelDistribution: "Sales channel distribution",
  socialMediaPerformance: "Social Media Performance",
  commissionEarnedMonth: "Commission earned this month",
  productsSoldMonth: "Products sold this month",
  productsActivelyEngaged: "Products actively engaged with",
  selectAll: "Select All",
  changedPrimaryEmail: "Primary Email changed successfully",
  changedPrimaryNumber: "Primary Number changed successfully",
  editSucessText: "SocialMedia changes successfully",
  youHaveBeenAdded: "You have not added bank details",
  oldPassword: "Old Password",
  newPassword: "New Password",
  bankAccountAdded: "Bank account added successfully!",
  bankAccountDeleted: "Bank account deleted successfully!",
  teamMemberAdded: "Team member added successfully!",
  teamMemberDeleted: "Team member deleted successfully!",
  removeTeamMember: "Remove team member",
  confirmDeleteTeamMember: "Are you sure you want to remove ",
  remove: "Remove",
  removeBankAccount: "Remove Bank Account",
  cantRemoveDefaultAccount: "Default Bank Account cannot be removed.",
  confirmDeleteBankDetails:
    "Are you sure you want to remove Bank Account Details?",
  removeAccount: "Remove Account",
  areYouSure: "Are you sure want to delete ",
  teamMemberAddedSuccessfull: "Team Member Added Successfull",
  teamMemberDeletedSuccessfull: "Team Member Deleted Successfull",
  primaryBankAccount: "Primary Bank account deleted successfully!",
  setPrimaryBankAccount: "Primary Bank account changed successfully!",
  profilePicUpdated: "Profile picture updated successfully!",
  enterAccountName: "Please enter account name",
  enterAccountNumber: "Please enter account number",
  enterBankName: "Please enter bank name",
  enterSwiftCode: "Please enter swift code",
  enterValidAccountNumber: "Please enter valid account number",
  enterValidSwiftCode: "Please enter valid swift code",
  enterValidBranchName: "Please enter valid branch name",
  enterCountry: "Please enter country",
  enterValidAccountName: "Please enter valid account name",
  yourConversionRatioIsLess:
    "Your conversion ratio is less than the average ratio",
  list: "LIST",
  salesDataSamplefile: "Sales Data Sample file",
  useHashtags: "Use these hashtags",
  yetToUpload: "Yet to upload",
  enterRole: "Please select a role",
  nameError: "Do not enter special character",
  isRequired: "is required",
  mediaIdLength: "Social media ID can't be more than 50 words",
  youHaveBeenLoggedOut:
    "You have been logged out due to some reason. Please try logging in again",
  hidePass: "Hide Password",
  showPass: "Show Password",
  unlistModal:
    "Are you sure? If you unlist the information will be lost and you will have to list again. Influencers will be able to sell the product for next 5 days",
  productAvailibilityMsg:
    "There are no more coupon code for this product. Influencers are not allowed to promote this product. Please upload coupon codes as soon as possible.",
  productAvailibilityMsgInfi:
    "We are temporarily unable to offer this product due to an exhaustion of coupon codes. Please come back in 24 hours.",
  influenceCommission: "Influencer commission",
  platformcharges: "Platform charges",
  termsAndConditionsPurchase: "Terms and Conditions and Purchase order",
  iAccept: "I accept",
  youCanDownload: "You can download the sample purchase order",
  signItAnd: " sign it, and upload it",
  selectTearmConditionFile: "Please select Tearm and Conditions File",
  acceptTermFile: "Please accept Tearm and Conditions",
  whatAreCouponCodes: "What are coupon codes?",
  whatIsSalesData: "What is sales data?",
  unlistText: "The seller has withdrawn this offer effective",
  unlistSecondText: "You can sell this product upto",
  unlistBrandText:
    "You have unlisted this product. After 5 days, the influencer will no longer be able to promote the product. To list again, repeat the listing process. ",
  brandIntro:
    "Harness the power of a million Influencers across SEA, access their followers and sell your products and services",
  influencerIntro:
    "Discover products and services you can sell to your followers using social media, earn handsome commissions.",
  whatIsthisTitle: "What is this - Open offer and Approval based offer",
  whatIsThisSalesDataTitle: "What is this - Sales Data Sample File",
  extendedTextBrand: "Harness the power of a million Influencers across SEA, ",
  extendedTextBrand2:
    "access their followers and sell your products and services",
  extendedTextInfluencer:
    "Discover products and services you can sell to your ",
  extendedTextInfluencer2:
    "followers using social media, earn handsome commissions.",
  extendedTextBrandMob:
    "Harness the power of a million Influencers across SEA, access their followers and sell your products and services",
  extendedTextInfMob:
    "Discover products and services you can sell to your followers using social media, earn handsome commissions.",
  accessToAlmost: "Access to over ",
  millionUnique: "1 million unique influencers",
  across: "Across",
  topSocialMediaplatform: "Top 5  Social Media platform",
  HavingOver: "Having over",
  millionFollowers: "483 million followers",
  whoSpendAlmost: "Who spend ",
  hoursDailyOnSoacialMedia: "3.5 hours daily on social media",
  millionInf: "483 million consumers",
  topFiveInf: "South East asia",
  havingOver: "Having over ",
  deleteBankAccountText:
    "Are  you sure you  want to remove bank account details ?",
  through1: "Through ",
  uniqueInf: "850,000 unique influencers",
  // Notification strings
  noNotifications: "No Notifications",
  markAllAsread: "Mark all as read",
  notifications: "Notifications",

  // terms and condition links

  mixpanelLink: "https://mixpanel.com/legal/terms-of-use/",
  googlePlayServiceLink: "https://policies.google.com/terms",
  segmentLink: "https://www.twilio.com/legal/tos",
  termsAndConditionsLink: "https://incom.betagged.co/privacy-policy",

  // privacy policy
  privacyPolicy: "Privacy Policy",
  infoCollectionUse: "Information Collection and Use",
  logData: "Log Data",
  cookies: "Cookies",
  serviceProviders: "Service Prividers",
  security: "Security",
  linksToOtherSites: "Links To Other Sites",
  childrenprivacy: `Children's Privacy`,
  changesToThisPrivacypolicy: "Chnages To This Privacy Policy",
  contactUs: "Contact Us",
  privacyPolicyPara1:
    "Be Tagged Pte Ltd built the InCom app as a Free app. This SERVICE is provided by Be Tagged Pte Ltd at no cost and is intended for use as is.",
  privacyPolicyPara2:
    "This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.",
  privacyPolicyPara3:
    "If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.",
  privacyPolicyPara4:
    "The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at InCom unless otherwise defined in this Privacy Policy.",
  privacyPolicyPara5:
    "For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to ID. The information that we request will be retained by us and used as described in this privacy policy.",
  privacyPolicyPara6:
    "The app does use third-party services that may collect information used to identify you.",
  privacyPolicyPara7:
    "Link to the privacy policy of third-party service providers used by the app",
  privacyPolicyPara8:
    "We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.",
  privacyPolicyPara9:
    "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device&#39;s internal memory.",
  privacyPolicyPara10:
    "This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.",
  privacyPolicyPara11:
    "We may employ third-party companies and individuals due to the following reasons:",
  privacyPolicyPara12:
    "We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.",
  privacyPolicyPara13:
    "We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.",
  privacyPolicyPara14:
    "This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.",
  privacyPolicyPara15:
    "These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions.",
  privacyPolicyPara16:
    "We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.",
  privacyPolicyPara17: "This policy is effective as of 2022-09-02",
  privacyPolicyPara18:
    "If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at Shamik Talukder at shamik@betagged.co",
  listText1: "To facilitate our Service.",
  listText2: "To provide the Service on our behalf.",
  listText3: "To perform Service-related services. or",
  listText4: "To assist us in analyzing how our Service is used.",
  forbetterexp:
    "For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to ID. The information that we request will be retained by us and used as described in this privacy policy.",
  accross: "across ",
  sea: "South East Asia",
  whoSpend: "Who Spend ",
  through: "Through",
  instructions1: "The first step is to input the Product name and category.",
  instructions2:
    "The second step is to choose the country where the product will be available . ",
  instructions3:
    "If the product is available in multiple countries and you would like to list all of them then you will need to create separate sub accounts per country. ",
  instructions4:
    "If your product is for Sale only in select cities then the cities can be chosen as well ",
  instructions5:
    "The Product description is most critical. The content of the product description will not only Influence the Influencer to select the product but will also empower the Influencer to present the product to his followers in his own language which is organic and has higher response from his or her followers.  ",
  cleverTapReactNativeTesting: "Clever Tap React Native Testing",
  cTReactNativeTesting: "CT React Native Testing",
  // intruction strings

  // step one instructions

  stepOneParaOne: "The first step is to input the Product name and category.",

  stepOneParaTwo:
    "The second step is to choose the country where the product will be available.",

  stepOneParaThree:
    "If the product is available in multiple countries and you would like to list all of them then you will need to create separate sub accounts per country.",
  stepOneParaFour:
    "If your product is for Sale only in select cities then the cities can be chosen as well.",
  stepOneParaFive:
    "The Product description is most critical. The content of the product description will not only Influence the Influencer to select the product but will also empower the Influencer to present the product to his followers in his own language which is organic and has higher response from his or her followers.",
  stepTwoParaOne:
    "The importance of Product visuals in online sales is tremendous. The visual not only present the product but increase consumer engagement and here Influencer engagement and ultimately drive more traction and sales.",
  stepTwoParaTwo:
    "Choose the best product image to maximize the impact. The product image chosen will be the first visual that the Influencer will see when they login to the platform and choose the Brand / Product.",
  stepTwoParaThree: "You can preview the image once you have chosen the image.",
  stepTwoParaFour:
    "Brand guideline kits are an extremely effective tool to debrief the Influencers about the brand / product / service. The Guideline can include text document and images.",
  stepTwoParaFive: "The images would vary from one media.",
  stepThreeParaOne:
    "Product price is the quantitative value of the listed item offered to the consumer on the site where the item can be purchased.",
  stepThreeParaTwo:
    "Commission percentage is the amount payable per item sold via Influencers. The split between commission payable to influencers and the platform fee is automatically calculated.",
  stepThreeParaThree:
    "Consumer discount is the discount on Product price offered to the consumer through discount coupons.",
  stepThreeParaFour:
    "To track and attribute product sales made via any third-party ecommerce site you have to issue a discount coupon.",
  stepThreeParaFive:
    "To track and attribute product sales made via your own company website you have to issue a discount coupon.",

  companyAddress: "Company Address",
  companyAddressValue: "33 UBI Ave 3, #01-55, Vertex, SINGAPORE 408868",
  contactDetails: "Contact Details",
  contactDetailsValue1: "Name - Shamik talukder",
  contactDetailsValue2: "Email - Shamik@betagged.co",
  contactDetailsValue3: "Phone no - +1 (747) 220-4332",
  instructionstep2text1:
    "The importance of Product visuals in online sales is tremendous. The visual not only present the product but increase consumer engagement and here Influencer engagement and ultimately drive more traction and sales. ",
  instructionstep2text2:
    "Choose the best product image to maximize the impact. The product image chosen will be the first visual that the Influencer will see when they login to the platform and choose the Brand / Product.",
  instructionstep2text3:
    "You can preview the image once you have chosen the image. ",
  instructionstep2text4:
    "Brand guideline kits are an extremely effective tool to debrief the Influencers about the brand / product / service. The Guideline can include text document and images. ",
  instructionstep2text5:
    "The images would vary from one media platform to another based on the dimensions of that particular platform.",
  instructionstep2text6:
    "You have the choice of  providing the brand guideline at a later stage or not providing at all.",
  instructionstep2text7:
    "Team InCom has the ability to help and advice you on the brand guideline kit in case you do not have it ready. Team InCom is just one click away. Send email to diah@betagged.co",
  instructionstep3text1:
    "Product price is the quantitative value of the listed item offered to the consumer on the site where the item can be purchased.",
  instructionstep3text2: `Commission percentage is the amount payable per item sold via Influencers. The split between commission payable to influencers and the platform fee is automatically calculated. `,
  instructionstep3text3: `Consumer discount is the discount on Product price offered to the consumer through discount coupons.`,
  instructionstep3text4: `To track and attribute product sales made via any third-party ecommerce site you have to issue a discount coupon.`,
  instructionstep3text5: `To track and attribute product sales made via your own company website you have to issue a discount coupon.`,
  instructionstep3text6: `If the product is sold via the InCom Mall then a discount coupon is NOT required, as the entire consumer journey is tracked via InCom links thus making sales attribution simple. To know more about InCom Mall click here.`,
  instructionstep3text7: `Open Offer – Any Influencer can sell your product`,
  instructionstep3text8: `Approval Based Offer – Only Influencers approved by you can sell your product. You can grant approvals to Influencers who have applied to sell your listed product on InCom.`,
  instructionstep3text9: `Discount coupons – Each Influencer will have a unique discount coupon for each social media channel. A minimum of 100 codes must be generated. You will be notified when the codes are getting exhausted so that you can replenish stock`,
  salesDataInfoText:
    "Sales data needs to uploaded for accurate attribution of sales to the influencers if you are selling on your own website or any third party website. Column A should comprise of all the coupon codes that you have uploaded, Column B should comprise of total sales made till a particular date, example: if 20 products were sold from 1st to 8th and 30 products were sold from 9th to 15th please input 50 in Column B.  Column C should have latest dates corresponding with the sales number. Please reach out to mayank@betagged.co if you have any questions.",
  couponCodesInfoText:
    "Consumer discount is the discount on Product price offered to the consumer through discount coupons. To track and attribute product sales made via any third-party ecommerce site you have to issue a discount coupon. To track and attribute product sales made via your own company website you have to issue a discount coupon. Each Influencer will have a unique discount coupon for each social media channel. A minimum of 100 codes must be generated. You will be notified when the codes are getting exhausted so that you can replenish stock",
  approvalBasedOfferInfoText:
    "Only Influencers approved by you can sell your product. You can grant approvals to Influencers who have applied to sell your listed product on InCom.",
  sellingProductPlatformInfoText1:
    "Usage of InCom mall to list & sell your product will give you the freedom to decide on the delivery mechanism. It can be delivered by you or by InCom. InCom mall since Discount coupon is not required to track and attribute sales, the same discount can be passed on and reflected in the final pricing thus making the product more competitive in the market.",
  sellingProductPlatformInfoText2:
    "Company website is your own website where the product can be bought from. Listing on company website requires a consumer discount and consumer coupon codes.",
  sellingProductPlatformInfoText3:
    "e-Commerce website is any third party website where the product can be bought from. Listing on any third party website requires a consumer discount and consumer coupon codes.",
};
