import { FC, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Spinner,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  addBankAccounts,
  AlphanumericRegex,
  Colors,
  logger,
  LoggerStrings,
  RootState,
  Strings,
} from "@be-tagged/shared";
import {
  CountrySelect,
  CustomBtn,
  ErrorMsg,
  Label,
  MaterialStyleInput,
} from "src/app/components";
import { Formik } from "formik";
import * as yup from "yup";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import { CommonObjectType } from "src/app/constants";

interface SubmittedDetailsInt extends CommonObjectType {
  [FormikKeys.AccountName]?: string;
  [FormikKeys.AccountNumber]?: any;
  [FormikKeys.BankName]?: string;
  [FormikKeys.SwiftCode]?: any;
  [FormikKeys.Country]?: string;
  [FormikKeys.Branch]?: string | null;
  [FormikKeys.Address]?: string | null;
}
interface AddBankDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  closeModal: () => void;
  countries: any;
}
/**
 * @param {Object} Propss - Of the form AddBankDetailsModalProps
 * @returns {JSX.Element} - A React Functional Component
 */
const AddBankDetailsModal: FC<AddBankDetailsModalProps> = ({
  isOpen,
  closeModal,
  onClose,
  countries,
}): JSX.Element => {
  //Fetching Formik Keys
  const {
    AccountName,
    AccountNumber,
    BankName,
    SwiftCode,
    Country,
    Branch,
    Address,
  } = FormikKeys;
  const getInitialValues: () => SubmittedDetailsInt = () => {
    let initalValues = {
      [AccountName]: "",
      [AccountNumber]: "",
      [BankName]: "",
      [SwiftCode]: "",
      [Country]: "",
      [Branch]: null,
      [Address]: null,
    };
    return initalValues;
  };
  const getValidationSchema = () => ({
    [AccountName]: yup
      .string()
      .matches(AlphanumericRegex, Strings.enterValidAccountName)
      .required(Strings.enterAccountName),
    [AccountNumber]: yup
      .string()
      .matches(AlphanumericRegex, Strings.enterValidAccountNumber)
      .required(Strings.enterAccountNumber),
    [BankName]: yup.string().required(Strings.enterBankName),
    [Branch]: yup
      .string()
      .nullable()
      .matches(AlphanumericRegex, Strings.enterValidBranchName),
    [SwiftCode]: yup
      .string()
      .matches(AlphanumericRegex, Strings.enterValidSwiftCode)
      .required(Strings.enterSwiftCode),
    [Country]: yup.object().nullable().required(Strings.selectCountry),
  });
  const mapAccountField = [
    [
      { key: FormikKeys.AccountName, label: Strings.accountName },
      { key: FormikKeys.AccountNumber, label: Strings.accountNumber },
    ],
    [
      { key: FormikKeys.BankName, label: Strings.bankName },
      { key: FormikKeys.Branch, label: Strings.branch },
    ],
    [
      { key: FormikKeys.Country, label: Strings.country },
      { key: FormikKeys.SwiftCode, label: Strings.swiftCode },
    ],
    [{ key: FormikKeys.Address, label: Strings.yourAddress }],
  ];
  const dispatch = useDispatch();
  const onSaveBankDetails = (values: CommonObjectType) => {
    dispatch(
      addBankAccounts({
        [AccountName]: values[AccountName],
        [AccountNumber]: values[AccountNumber],
        [BankName]: values[BankName],
        [Branch]: values[Branch] === "" ? null : values[Branch],
        countryId: values[Country].id,
        [SwiftCode]: values[SwiftCode],
        [Address]: values[Address] === "" ? null : values[Address],
      })
    );
    closeModal();
  };
  const { loading } = useSelector(
    (state: RootState) => state?.appReducer?.loading
  );

  useEffect(() => {
    if (isOpen) {
      logger.info(
        `${Strings.influencer} ${LoggerStrings.openedAddBankDetails}`
      );
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        size={"2xl"}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          className="filter_modal"
          style={{ paddingTop: 12 }}
          backgroundColor={Colors.bgDark}
        >
          <ModalBody padding="15px">
            <Box px={{ md: "15px", base: "0px" }} className="content_container">
              <Box className="bold" style={{ fontSize: 18, paddingBottom: 24 }}>
                {Strings.addBankDetails}
              </Box>
              <Formik
                initialValues={getInitialValues()}
                onSubmit={() => {}}
                validationSchema={yup.object().shape(getValidationSchema())}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  setFieldTouched,
                  setFieldValue,
                  isValid,
                  touched,
                  errors,
                }) => (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                      }}
                    >
                      <Flex rowGap={"20px"} direction="column">
                        {mapAccountField?.map((arr, index: number) => {
                          return (
                            <Flex
                              direction={{ md: "row", base: "column" }}
                              rowGap="20px"
                              key={index}
                              width={
                                arr?.length === 1
                                  ? "-webkit-fill-available"
                                  : "fit-content"
                              }
                            >
                              {arr?.map((field, index: number) => {
                                return (
                                  <>
                                    {field.key === "country" ? (
                                      <div
                                        style={{
                                          position: "relative",
                                          bottom: 4,
                                          width: 300,
                                          marginRight: "15px",
                                        }}
                                      >
                                        <Label>{Strings.yourCountry}</Label>
                                        <CountrySelect
                                          isMulti={false}
                                          countryOptions={countries}
                                          selectedCountries={values[Country]}
                                          setCountries={(items: any) =>
                                            setFieldValue(Country, items)
                                          }
                                          onBlur={() =>
                                            setFieldTouched(Country)
                                          }
                                        />
                                        {touched[Country] && errors[Country] && (
                                          <Box
                                            fontSize="9px"
                                            color={Colors.red}
                                          >
                                            {errors[Country]}
                                          </Box>
                                        )}
                                      </div>
                                    ) : (
                                      <Flex direction={"column"}>
                                        <MaterialStyleInput
                                          key={index}
                                          width="-webkit-fill-available"
                                          style={{ marginRight: "30px" }}
                                          value={values[field.key]}
                                          onChange={handleChange(field.key)}
                                          onBlur={() =>
                                            setFieldTouched(field.key)
                                          }
                                          type={"text"}
                                          label={field.label}
                                        />
                                        {touched[field.key] &&
                                          errors[field.key] && (
                                            <Box
                                              fontSize="9px"
                                              color={Colors.red}
                                            >
                                              {errors[field.key]}
                                            </Box>
                                          )}
                                        {field.key === "address" ||
                                        field.key === "branch" ? (
                                          <Box
                                            fontSize="9px"
                                            color={Colors.grey100}
                                          >
                                            ({Strings.optional})
                                          </Box>
                                        ) : null}
                                      </Flex>
                                    )}
                                  </>
                                );
                              })}
                            </Flex>
                          );
                        })}
                      </Flex>
                      <Flex justifyContent="flex-end" columnGap={4} mt={8}>
                        <CustomBtn
                          type="button"
                          className="purple_btn medium inv"
                          onClick={closeModal}
                        >
                          {loading && (
                            <Spinner size={"xs"} style={{ marginRight: 10 }} />
                          )}
                          {Strings.cancel}
                        </CustomBtn>
                        <CustomBtn
                          type="submit"
                          disabled={!isValid || loading}
                          className="purple_btn medium"
                          onClick={() => {
                            onSaveBankDetails(values);
                          }}
                          style={{ paddingLeft: "30px", paddingRight: "30px" }}
                        >
                          {loading && (
                            <Spinner size={"xs"} style={{ marginRight: 10 }} />
                          )}
                          {Strings.save}
                        </CustomBtn>
                      </Flex>
                    </form>
                  </>
                )}
              </Formik>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddBankDetailsModal;
