import { Strings } from "@be-tagged/shared";
import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { CommonObjectType } from "src/app/constants";

interface SMKitsProps {
  kits: CommonObjectType;
  urlMediaMapping: CommonObjectType;
}

const SocialMediaKits: FC<SMKitsProps> = ({ kits = {}, urlMediaMapping }) => {
  const arrayOfKits = Object.keys(kits);
  const valuesOfKits = Object.values(kits).filter((el) => el);
  if (!valuesOfKits.length) return null;
  return (
    <Box className="inner-container">
      <div className="tabs-title">{Strings.socialMediaKit}</div>
      <div className="product-social-items">
        {arrayOfKits?.map((mediaKey, idx: number) => {
          if (kits[mediaKey])
            return (
              <Box key={idx} className="item">
                <img
                  src={urlMediaMapping?.[mediaKey]?.icon}
                  alt={"TikTok"}
                  style={{ width: 35 }}
                />
                <div className="social-links">
                  <div className="gray-text">
                    {urlMediaMapping?.[mediaKey]?.name}
                  </div>
                  <div className="purple-text">
                    <a
                      href={kits[mediaKey]}
                      rel="noreferrer noopener"
                      target="_blank"
                      download
                    >
                      {Strings.download}
                    </a>
                  </div>
                </div>
              </Box>
            );
        })}
      </div>
    </Box>
  );
};

export default SocialMediaKits;
