import {
  getBrandProductsUrl,
  getInfluencerProductsUrl,
  getFilteredBrandProductsURL,
  getFilteredInfluencerProductsURL,
  getProductInsightsURL,
} from "../../../utils/UrlBuilder";
import {
  CatalogQueryParams,
  HTTPMethod,
  ProductInsightsParams,
} from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import {
  UPDATE_CATALOG_LIST,
  UPDATE_PRODUCT_DETAILS,
  UPDATE_LOADING_CATALOG_LIST,
  UPDATE_PRODUCT_PROMOTION_DETAIL,
  UPDATE_PRODUCT_INSIGHTS,
  IS_LOADING_PRODUCT_DETAILS,
} from "../../constants/ReduxConstants";

interface PromotionRequestType {
  salesChannel: string;
  brandProductId: number;
}

// To Get the products Catalog
export const getProductsCatalog =
  (params: CatalogQueryParams, isBrand: boolean, isMobile: boolean = false) =>
  async (dispatch: any) => {
    dispatch(setProductsLoading(true));
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: params,
      url: isBrand ? getBrandProductsUrl() : getInfluencerProductsUrl(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => updateCatalogList(payload, isBrand, isMobile)]
    );
  };

export const filterProductsCatalog =
  (data: any, isBrand: boolean, isMobile: boolean = false) =>
  async (dispatch: any) => {
    dispatch(setProductsLoading(true));
    const request = {
      method: HTTPMethod.POST,
      data,
      url: isBrand
        ? getFilteredBrandProductsURL()
        : getFilteredInfluencerProductsURL(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => updateCatalogList(payload, isBrand, isMobile)]
    );
  };

const updateCatalogList = (
  payload: any,
  isBrand: boolean,
  isMobile: boolean
) => {
  return (dispatch: any) => {
    dispatch(setProductsLoading(false));
    return dispatch({
      type: UPDATE_CATALOG_LIST,
      payload: { response: payload.data, isMobile },
    });
  };
};

export const setProductsLoading = (payload: boolean) => ({
  type: UPDATE_LOADING_CATALOG_LIST,
  payload,
});

//  clear product catalog
export const clearProductCatalog = () => {
  return {
    type: UPDATE_CATALOG_LIST,
    payload: [],
  };
};

// To get the details of a single product
export const getProductDetails =
  (id: number, isBrand: boolean) => async (dispatch: any) => {
    dispatch(setProductDetailsLoading(true));
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: {},
      url: isBrand
        ? `${getBrandProductsUrl()}/${id}`
        : `${getInfluencerProductsUrl()}/${id}`,
    };
    return fetchAsync(dispatch, [request], [updateProductDetails]);
  };

const updateProductDetails = (payload: any) => {
  return (dispatch: any) => {
    dispatch(setProductDetailsLoading(false));
    return dispatch({
      type: UPDATE_PRODUCT_DETAILS,
      payload: payload.data,
    });
  };
};

const setProductDetailsLoading = (status: boolean) => {
  return {
    type: IS_LOADING_PRODUCT_DETAILS,
    payload: status,
  };
};

export const clearProductDetails = () => {
  return {
    type: UPDATE_PRODUCT_DETAILS,
    payload: null,
  };
};
