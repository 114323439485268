import { Strings } from "@be-tagged/shared";
import { useEffect } from "react";

/**
 * useSetPageTitle hook will be used to set the page title
 */
const useSetPageTitle = (title: string, dependencies: any[] = []) => {
  useEffect((): (() => void) => {
    if (
      (dependencies.length && dependencies.every((el) => !!el)) ||
      !dependencies.length
    ) {
      document.title = title
        ? `${title} | ${Strings.betagged}`
        : Strings.betagged;
    }
    return () => (document.title = Strings.betagged);
  }, [...dependencies]);
};

export default useSetPageTitle;
