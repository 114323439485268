export default {
  mobileNavigationActivated: "Mobile Navigation activated",
  mobileNavigationVisit: "User visited the app and navigation has started",
  dashboardVisit: "User just visited the dashboard",
  otpVerificationOpened: "User reached OTP verification for",
  onboardingStepReached: "reached onboarding step",
  justOpenedThe: "just opened the",
  tab: "tab",
  userPressed: "User presses",
  userVisit: "User visited",
  staticDashboardVisit: "User just visited the #{} static dashboard",
  dynamicDashboardVisit: "User just visited the #{} dynamic dashboard",
  listingStep: "User just completed listing step #{}",
  justOpened: "just opened",
  justVisited: "just Visited",
  step: "step",
  openedCatalogFilterModal: "opened Catalog Filter Modal",
  openedLoginDetails: "opened the Login Details tab of the Profile page",
  openedBankDetails: "opened the Bank Details tab of the Profile page",
  openedAddBankDetails: "opened the Add Bank Details modal",
  openedAddMember: "opened the Add Member Modal",
  userIsTryingTo: "User is trying to",
  forgotPasswordOTPStep: "Forgot Password OTP Verification Step",
  logInVia: "log in via",
  hadTheSignInOTPSent: "had the Sign In OTP sent for login",
  addedBankAccountSuccessfully: "added Bank Account successfully",
  promptOpened: "prompt opened",
  dashboard: "Dashboard",
  empty: " - Empty",
  mainNavigation: "Main Navigation",
  userPressedAddTeam: "user presed add team member button",
  completedOnboardingStep: "just completed onboarding step",
  addTeamMemberModal: "Add team member modal open",
  addTeamMemberModalClose: "Add team member modal Closed",
  addTeamMemberSave: "Add team member save button clicked",
  teamMemeberDel: "Brand clicked team member delete button",
  brandChangedCountry: "Brand changed country  to",
  brandChangedListing: "Brand changed listing date",
  userChangedSort: "user changed sort to",
  userClickedOn: "user clicked on",
  brandClickedSearchButton: "Brand clicked search button for",
  productSoldTab: " Product sold Tab",
  userChangedCountry: "user changes country to",
  userChangedSalClick: "user changed sale/click dropdown value",
  brandClickedOn: "Brand clicked on",
  clickedOn: "clicked On",
  influencerSeekingSalesApproval: "influencer seeking sales approval",
  changed: "changed",
  changeProfilePicture: "profile picture icon",
  addSocialMediaHandle: "Add social media modal Modal opened for",
  otpModalForEmail: "OTP modal  for email ",

  openedWhatIsThisModal: "opened information modal for",
  changedCountry: "changed the country",
  changedDateFilter: "changed date filter",
  fetchedDataFor: "fetched Data for country",
  for: "for",
  searchedFor: "searched for the product",
  searchedForTeamMember: "searched for the team member",
  appliedFilters: "applied filters",
  clearedFilters: "cleared filters",
  clickedOnAddButton: "clicked on ADD button in Add team member modal",
  addedTeamMember: "added team member",
  withEmail: "with Email",
  andRole: "and Role",
  clickedOnDeleteMember: "clicked on delete member button and opened remove team member modal for",
  deletedTeamMember: "deleted team member",
  clickedOnYesDelete: "confirmed to delete the team member",
  visitedProductLink: "visited product link",
  clickedOnUnlistButton: "clicked on unlist button and opened confirmation modal",
  confirmedUnlistProduct: "confirmed to unlist the product and closed modal",
  cancelledUnlistProduct: "cancelled to unlist the product and closed modal",
  unlistedTheProduct: "unlisted the product",
  clickedOnUploadDiscountCode: "clicked on upload coupon codes button",
  uploadedCouponCodes: "uploaded coupon codes",
  clickedOnUploadSalesData: "clicked on upload sales data button",
  uploadedSalesData: "uploaded sales data",
  sortedProductsSold: "sorted the product sold list",
  clickedOnDownloadButton: "clicked on download sales data sample file button",
  sortedInfluencersList: "sorted influencers list",
  clickedOnEditButton: "clicked on edit product button",
  changedProfilePicture: "updated profile picture",
  changedPrimaryPhone: "changed primary phone number",
  changedPrimaryEmail: "changed primary email",
  updatedProfilePicture: "updated profile picture",
  addedNewMail: "added new email ID",
  addedNewPhone: "added new phone number",
  updatedSocialMediaHandles: "updated social media handle(s) in profile",
  changedPrimaryBankAccount: "changed primary bank account",
  clickedOnCheckBox: "clicked on checkbox to sell product",
  copiedLink: "copied product link to sell the product",
  cleverTapReactNativeTesting: "Clever Tap React Native Testing",
  accountDelete: "clicked on account delete button"
};
