import { Box, Divider, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

import { BrandCollage, BrandDashboardCollage } from "@be-tagged/shared/src/assets/icons";
import { Colors, Strings } from "@be-tagged/shared";

import { ROUTING_URLS } from "src/app/constants";
import useAuth from "src/app/hooks/useAuth";
import { BrandListProduct } from "../Brand/AdditionalComponents";
import { InfluencerFacts } from "../Influencer/AdditionalComponents";

import "../../Landing.styles.scss";
import WelcomeText from "./WelcomeText";
import ProductsMatchingInterest from "./ProductsMatchingInterest";

/**
 * Landing Screen is displayed under following conditions:
 * After Onboarding, if the user has not done it yet
 * Directly after Log In, if the user has logged in after completing onboarding
 * @returns {JSX.Element} - A React Functional Component
 */
const PlaceholderLanding = (): JSX.Element => {
  const { isBrand } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { LANDING, PRODUCT_LISTING } = ROUTING_URLS;
  const navigateToProductListing = () => {
    navigate(pathname.replace(LANDING, PRODUCT_LISTING));
  };

  return (
    <>
      <Flex mb={"20px"} justify={"space-between"} bgColor={Colors.bgLight} className="white_box_container first_box">
        <Box className="content">
          <Box>
            <Box className="welcome_text bold">
              <WelcomeText />
              <Box className="secondary">
                <Text>{Strings.onboardText}</Text>
              </Box>
            </Box>

            <Box maxW={{ lg: "500px" }}>
              {isBrand ? (
                <>
                  <Flex direction={{ md: "column", base: "column" }} rowGap={"10px"} className="unordered_list_container">
                    <div className="step">
                      <div className="v-stepper">
                        <div className="circle"></div>
                        <div className="line"></div>
                      </div>
                      <div className="content">
                        {Strings.accessToAlmost}
                        <strong>{Strings.millionInf}</strong>
                      </div>
                    </div>
                    <div className="step">
                      <div className="v-stepper">
                        <div className="circle"></div>
                        <div className="line"></div>
                      </div>
                      <div className="content">
                        Across <strong>{Strings.topFiveInf}</strong>
                      </div>
                    </div>
                    <div className="step">
                      <div className="v-stepper">
                        <div className="circle"></div>
                        <div className="line"></div>
                      </div>
                      <div className="content">
                        {Strings.whoSpendAlmost}
                        <strong>{Strings.hoursDailyOnSoacialMedia}</strong>
                      </div>
                    </div>
                    <div className="step">
                      <div className="v-stepper">
                        <div className="circle"></div>
                        <div className="line"></div>
                      </div>
                      <div className="content">
                        {Strings.through1}
                        <strong>{Strings.uniqueInf}</strong>
                      </div>
                    </div>
                  </Flex>
                </>
              ) : (
                <InfluencerFacts />
              )}
            </Box>
          </Box>
        </Box>

        <Box className="border_img_container" borderColor={Colors.borderColor}>
          <img src={isBrand ? BrandDashboardCollage : BrandCollage} alt="Group" />
        </Box>
      </Flex>
      {isBrand ? (
        <>
          <BrandListProduct goToListing={navigateToProductListing} />
        </>
      ) : (
        <ProductsMatchingInterest />
      )}
    </>
  );
};

export default PlaceholderLanding;
