import {
  getBrandTeamMembersURL,
  getAddTeamMemberURL,
  getDelateTeamMemberURL,
  getBrandRolesURL,
} from "../../../utils/UrlBuilder";
import { HTTPMethod, TeamQueryParams } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import {
  UPDATE_TEAM_MEMBER_LIST,
  UPDATE_LOADING_TEAM_MEMBERS,
  UPDATE_BRAND_ROLES_LOADING_STATUS,
  SET_BRAND_ROLES,
  SET_SUCCESS,
  ADD_TEAM_MEMBER,
  CLEAR_TEAM_MEMBER_LIST,
} from "../../constants/ReduxConstants";
import Strings from "../../../strings/LocalisedStrings";
import { logger } from "../../../services/Logging";
import LoggerStrings from "../../../strings/LoggerStrings";

export const getBrandRoles = (callbackFn?: any) => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.GET,
    queryStringParams: {},
    url: getBrandRolesURL(),
  };
  return fetchAsync(
    dispatch,
    [request],
    [(payload: any) => updateBrandRolesList(payload, callbackFn)]
  );
};

const setBrandRolesLoading = (payload: boolean) => {
  return {
    type: UPDATE_BRAND_ROLES_LOADING_STATUS,
    payload,
  };
};

export const updateBrandRolesList = (payload: any, callbackFn?: any) => {
  return async (dispatch: any) => {
    if (payload?.data?.length) callbackFn?.();
    dispatch(setBrandRolesLoading(false));
    return dispatch({
      type: SET_BRAND_ROLES,
      payload,
    });
  };
};

export const getBrandTeamMembers =
  (params: TeamQueryParams, isMobile: boolean = false, callbackFn?: any) =>
  async (dispatch: any) => {
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: params,
      url: getBrandTeamMembersURL(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [
        (payload: any) =>
          updateBrandTeamMembersList(payload, isMobile, callbackFn),
      ]
    );
  };

const updateBrandTeamMembersList = (
  payload: any,
  isMobile: boolean,
  callbackFn?: any
) => {
  return (dispatch: any) => {
    callbackFn?.();
    dispatch(setTeamMembersLoading(false));
    return dispatch({
      type: UPDATE_TEAM_MEMBER_LIST,
      payload: { response: payload.data, isMobile },
    });
  };
};

export const setTeamMembersLoading = (payload: boolean) => ({
  type: UPDATE_LOADING_TEAM_MEMBERS,
  payload,
});

export const addTeamMember =
  (data: any, isMobile: boolean = false, callbackFn?: any) =>
  async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      data,
      url: getAddTeamMemberURL(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => updateAddMember(payload, isMobile, callbackFn)]
    );
  };

const updateAddMember = (payload: any, isMobile: boolean, callbackFn?: any) => {
  return (dispatch: any) => {
    callbackFn?.();
    logger.info(`${Strings.brand} ${LoggerStrings.addedTeamMember}`, {
      email: payload?.data?.data?.email,
      role: payload?.data?.data?.role,
    });

    isMobile && dispatch(setTeamMembersLoading(false));
    return dispatch(
      isMobile
        ? {
            type: ADD_TEAM_MEMBER,
            payload: { response: payload.data, isMobile },
          }
        : {
            type: SET_SUCCESS,
            payload: {
              message: Strings.teamMemberAdded,
            },
          }
    );
  };
};

export const deleteTeamMember =
  (id: any, callbackFn?: any) => async (dispatch: any) => {
    const request = {
      method: HTTPMethod.DELETE,
      url: getDelateTeamMemberURL(id),
    };
    return fetchAsync(
      dispatch,
      [request],
      [() => postTeamMemberUpdate(dispatch, id, callbackFn)]
    );
  };

const postTeamMemberUpdate =
  (dispatch: any, id: number, callbackFn: any) => () => {
    callbackFn?.();
    logger.info(`${Strings.brand} ${LoggerStrings.deletedTeamMember}`, {
      memberId: id,
    });
    return dispatch({
      type: SET_SUCCESS,
      payload: {
        message: Strings.teamMemberDeleted,
      },
    });
  };

export const clearTeamMembersList = () => ({
  type: CLEAR_TEAM_MEMBER_LIST,
  payload: [],
});
