import { Platforms } from "../enums/Platforms";
import { getPlatform } from "../utils/PlatformHelper";
import appConfig from './AppConfig';

const defaultBaseURL = 'https://api-dev.betagged.co'

export const getApiBaseURL = () => {
  switch (getPlatform()) {
    case Platforms.Web:
      return process.env.REACT_APP_CORE_API_URL;
    case Platforms.Mobile:
      return appConfig?.apiBaseUrl;
    default:
      return defaultBaseURL;
  }
}

const apiConfig = {
  apiBaseUrl: getApiBaseURL(),
};

export default apiConfig;