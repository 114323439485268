import { Text } from "@chakra-ui/react";
import { Strings } from "@be-tagged/shared";

export const InfluencerFacts = () => {
  return (
    <>
      <Text as="p" mb={6}>
        {Strings.iLongFacts1} &nbsp;
        {Strings.iLongFacts2}
      </Text>
      <Text as="p">
        {Strings.iLongFacts3} {Strings.iLongFacts4} {Strings.iLongFacts5}
      </Text>
    </>
  );
};
