import { FC } from "react";

import colors from "@be-tagged/shared/src/theme/colors";
import { Flex, Box, Select } from "@chakra-ui/react";

import { CountryCodeSelect, MaterialStyleInput } from "../../app/components";
import {
  CommonObjectType,
  COUNTRY_CODES,
  defaultCountryCode,
} from "../../app/constants";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import { useDispatch, useSelector } from "react-redux";
import { getCountries, RootState } from "@be-tagged/shared";
import { useEffect } from "react";

interface MobileInputProps {
  label?: string;
  name?: string;
  makeResponsive?: boolean;
  countryCode?: CommonObjectType; // the value of the country code
  phone?: string; // the value of the mobile Number
  handleCountryCodeChange: any; // the dispatch function handle offered by Formik
  handlePhoneChange: any; // the dispatch function handle offered by Formik
  setFieldTouched?: any; // function provided by Formik that onBlur event call indicates that the input had been selecte
}

/**
 * Reusable Component for Inputting Mobile Number along With Country Code Dropdown
 * Uses Material Style Input for the mobile number input
 * @param {Object} props - the props this component will receive (described in the interface above)
 * @returns {JSX.Element}
 */
const MobileInput: FC<MobileInputProps> = ({
  countryCode,
  phone,
  handleCountryCodeChange,
  handlePhoneChange,
  setFieldTouched,
  label = "Mobile Number",
  name = FormikKeys.Phone,
  makeResponsive = false,
}): JSX.Element => {
  const countries = useSelector(
    (state: RootState) => state?.commonReducer?.commonData?.countries || []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!countries.length) dispatch(getCountries());
    if (!countryCode?.id) handleCountryCodeChange(defaultCountryCode);
  }, []);

  return (
    <Flex alignItems={"flex-end"}>
      <Box pos={"relative"} _after={Styles.dropdownContainer}>
        <Box className={"country_code_container"}>
          <CountryCodeSelect
            countryCode={countryCode}
            countryCodeOptions={countries}
            setCountryCode={handleCountryCodeChange}
          />
        </Box>
      </Box>

      <MaterialStyleInput
        parentProps={{
          style: Styles.materialInput,
        }}
        name={name}
        id="mobile_number_input"
        value={phone}
        onChange={handlePhoneChange}
        onBlur={() => {
          setFieldTouched && setFieldTouched(name);
        }}
        type={"tel"}
        label={label}
        makeResponsive={makeResponsive}
      />
    </Flex>
  );
};

const Styles = {
  dropdownContainer: {
    position: "absolute",
    content: `""`,
    height: "19px",
    width: "1px",
    right: "-5px",
    bottom: "2px",
    backgroundColor: colors.borderColor,
  },
  materialInput: {
    marginLeft: 15,
    paddingLeft: 0,
    width: 215,
  },
};

export default MobileInput;
