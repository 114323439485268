import * as React from "react";
import { PossibleRequestStates } from "@be-tagged/shared/src/enums";

interface InfluencerRequestFormat {
  showModal: boolean;
  productId: number;
  action: PossibleRequestStates | null;
  requestIds: any[];
  salesChannel?: any[];
  setData?: (x?: any) => void;
}

export const InfluencerRequestContext = React.createContext<
  InfluencerRequestFormat | undefined
>(undefined);
