import { createReducer } from "../reducerUtil";
import {
  SEND_RESET_CODE,
  VERIFY_RESET_CODE,
  RESET_PASSWORD,
  SET_COUNTRIES,
  SET_CURRENCIES,
  UPDATE_CATEGORIES,
  UPDATE_CITIES_LIST,
  UPDATE_LOADING_CITIES_STATUS,
  UPDATE_CATALOG_LIST,
  UPDATE_PRODUCT_DETAILS,
  UPDATE_PRODUCT_INSIGHTS,
  SET_PROMOTION_REQUEST,
  UPDATE_LOADING_CATALOG_LIST,
  UPDATE_PRODUCT_PROMOTION_DETAIL,
  UPDATE_INSIGHTS_SUMMARY,
  UPDATE_PRODUCTS_SOLD_LIST,
  UPDATE_PLATFORM_INSIGHTS_LIST,
  UPDATE_DASHBOARD_DATA,
  SET_DASHBOARD_DATA_LOADING_STATUS,
  IS_LOADING_PRODUCT_DETAILS,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_VIA_SCROLL,
  RESET_COMMON_REDUCER,
} from "../../constants/ReduxConstants";
import { appendToPrevious } from "../../../utils/StoreUtils";

interface CommonReducerType {
  passResetToken: string | null;
  commonData: {
    cities: any[];
    isLoadingCities: boolean;
    categories: any[];
    currencies: any[];
    isCountriesLoading: boolean;
    countries: any[];
    productData: any;
    isProductDataLoading: boolean;
    insights: { [k: string]: any };
    dashboardData: { [k: string]: any };
    isDashboardDataLoading: boolean;
    notifications: any;
  };
}

const initialState = {
  passResetToken: null,
  commonData: {
    categories: [],
    currencies: [],
    isCountriesLoading: false,
    countries: [],
    cities: [],
    isLoadingCities: false,
    productData: {},
    isProductDataLoading: false,
    insights: {},
    dashboardData: {},
    isDashboardDataLoading: false,
    notifications: {},
  },
};

export const updateVerifyResetCode = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    passResetToken: payload,
  };
};

export const updateSendResetCode = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
  };
};

export const updateResetPassword = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
  };
};

export const updateCountries = (state: CommonReducerType, payload: any) => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      countries: payload,
    },
  };
};

export const updateCurrencies = (state: CommonReducerType, payload: any) => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      currencies: payload,
    },
  };
};

export const updateCategories = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      categories: payload,
    },
  };
};

export const updateCountriesLoading = (
  state: CommonReducerType,
  payload: boolean
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      isCountriesLoading: payload,
    },
  };
};

export const setCitiesList = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      cities: payload,
    },
  };
};

export const updateLoadingCitiesStatus = (
  state: CommonReducerType,
  payload: boolean
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      isLoadingCities: payload,
    },
  };
};

export const updateCatalogList = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  if (payload.isMobile) {
    const currentData = state.commonData.productData.productCatalog || [];
    return {
      ...state,
      commonData: {
        ...state.commonData,
        productData: {
          ...state.commonData.productData,
          productCatalog: [...currentData, ...payload.response.data],
        },
      },
    };
  }
  return {
    ...state,
    commonData: {
      ...state.commonData,
      productData: {
        ...state.commonData.productData,
        productCatalog: payload.response,
      },
    },
  };
};

export const updateProductDetails = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      productData: {
        ...state.commonData.productData,
        productDetails: payload,
      },
    },
  };
};

export const updateProductInsights = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      productData: {
        ...state.commonData.productData,
        productDetails: {
          ...state.commonData.productData.productDetails,
          insights: payload,
        },
      },
    },
  };
};

export const updateProductsLoadingStatus = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      productData: {
        ...state.commonData.productData,
        isProductsLoading: payload,
      },
    },
  };
};

// Influencer specific, but resides here since commonData exists here
export const setPromotionRequest = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  const currentData = state.commonData.productData.promotionRequest || [];
  return {
    ...state,
    commonData: {
      ...state.commonData,
      productData: {
        ...state.commonData.productData,
        promotionRequest: [...currentData, payload],
      },
    },
  };
};

export const clearPromotionRequest = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      productData: {
        ...state.commonData.productData,
        promotionRequest: payload,
      },
    },
  };
};

export const updatePromotionDetail = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      productData: {
        ...state.commonData?.productData,
        productDetails: {
          ...state.commonData?.productData?.productDetails,
          promotionalInfo: {
            ...state.commonData?.productData?.productDetails?.promotionalInfo,
            [payload?.keyToUpdate]: payload.data,
          },
        },
      },
    },
  };
};

export const updateInsightsSummary = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      insights: {
        ...state.commonData.insights,
        summary: payload,
      },
    },
  };
};

export const updateProductsSoldList = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  let productsSoldData = {};
  if (payload?.data) {
    productsSoldData = appendToPrevious(
      state?.commonData?.insights?.productsSold?.data || [],
      payload
    );
  }
  return {
    ...state,
    commonData: {
      ...state.commonData,
      insights: {
        ...state.commonData.insights,
        productsSold: productsSoldData,
      },
    },
  };
};

export const updatePlatformInsightsList = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  let platformInsightsData = {};
  if (payload?.data) {
    platformInsightsData = appendToPrevious(
      state?.commonData?.insights?.platformInsights?.data || [],
      payload
    );
  }
  return {
    ...state,
    commonData: {
      ...state.commonData,
      insights: {
        ...state.commonData.insights,
        platformInsights: platformInsightsData,
      },
    },
  };
};

export const updateDashboardData = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      dashboardData: payload.data,
    },
  };
};

export const updateDashboardLoadingStatus = (
  state: CommonReducerType,
  payload: boolean
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      isDashboardDataLoading: payload,
    },
  };
};

export const resetCommonReducer = (): CommonReducerType => {
  return initialState;
};

export const setProductDetailsLoadingStatus = (
  state: CommonReducerType,
  payload: boolean
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      isProductDataLoading: payload,
    },
  };
};

export const setNotifications = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    commonData: {
      ...state.commonData,
      notifications: payload,
    },
  };
};

export const setNotificationsViaScroll = (
  state: CommonReducerType,
  payload: any
): typeof state => {
  if (payload.isMobile) {
    return {
      ...state,
      commonData: {
        ...state.commonData,
        notifications: [
          ...state.commonData.notifications,
          ...payload.data.data,
        ],
      },
    };
  }
  return {
    ...state,
    commonData: {
      ...state.commonData,
      notifications: {
        ...payload.data,
        data: [...state.commonData.notifications.data, ...payload.data.data],
      },
    },
  };
};

export const resetAppReducer = (): CommonReducerType => {
  return initialState;
};

export default createReducer(initialState, {
  [SET_COUNTRIES]: updateCountries,
  [SET_CURRENCIES]: updateCurrencies,
  [VERIFY_RESET_CODE]: updateVerifyResetCode,
  [SEND_RESET_CODE]: updateSendResetCode,
  [RESET_PASSWORD]: updateResetPassword,
  [UPDATE_CATEGORIES]: updateCategories,
  [UPDATE_CITIES_LIST]: setCitiesList,
  [UPDATE_LOADING_CITIES_STATUS]: updateLoadingCitiesStatus,
  [UPDATE_CATALOG_LIST]: updateCatalogList,
  [UPDATE_PRODUCT_DETAILS]: updateProductDetails,
  [SET_PROMOTION_REQUEST]: setPromotionRequest,
  [UPDATE_LOADING_CATALOG_LIST]: updateProductsLoadingStatus,
  [UPDATE_PRODUCT_PROMOTION_DETAIL]: updatePromotionDetail,
  [UPDATE_INSIGHTS_SUMMARY]: updateInsightsSummary,
  [UPDATE_PRODUCTS_SOLD_LIST]: updateProductsSoldList,
  [UPDATE_PLATFORM_INSIGHTS_LIST]: updatePlatformInsightsList,
  [UPDATE_PRODUCT_INSIGHTS]: updateProductInsights,
  [UPDATE_DASHBOARD_DATA]: updateDashboardData,
  [SET_DASHBOARD_DATA_LOADING_STATUS]: updateDashboardLoadingStatus,
  [IS_LOADING_PRODUCT_DETAILS]: setProductDetailsLoadingStatus,
  [SET_NOTIFICATIONS]: setNotifications,
  [SET_NOTIFICATIONS_VIA_SCROLL]: setNotificationsViaScroll,
  [RESET_COMMON_REDUCER]: resetCommonReducer,
});
