import {
  getBrandCountries,
  logger,
  LoggerStrings,
  RootState,
  Strings,
} from "@be-tagged/shared";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlaceholderLanding from "../Common/PlaceholderLanding";
import DashboardSkeleton from "../Common/DashboardSkeleton";
import BrandStandard from "./Standard";
import usePrevious from "src/app/hooks/usePreviousValue";

const BrandMain = () => {
  const [showStandardScreen, setShowStandardScreen] = useState(false);
  const [hasFetchedAndMounted, setHasFetchedAndMounted] = useState(false);

  const dispatch = useDispatch();

  const { isCountriesLoading } = useSelector(
    (state: RootState) => state.brandReducer
  );

  const previousVal = usePrevious(isCountriesLoading);

  const switchScreenShow = () => {
    setShowStandardScreen(true);
  };

  useEffect(() => {
    if (
      !isCountriesLoading &&
      previousVal != isCountriesLoading &&
      hasFetchedAndMounted
    ) {
      logger.trackPage(
        `${Strings.brand} ${LoggerStrings.dashboard}${
          showStandardScreen ? "" : LoggerStrings.empty
        }`
      );
    }
  }, [isCountriesLoading, hasFetchedAndMounted]);

  useEffect(() => {
    dispatch(getBrandCountries(switchScreenShow));
    setHasFetchedAndMounted(true);
  }, []);

  return (
    <>
      {isCountriesLoading ? (
        <DashboardSkeleton />
      ) : showStandardScreen ? (
        <BrandStandard />
      ) : (
        <PlaceholderLanding />
      )}
    </>
  );
};

export default BrandMain;
