import { getBrandsLookupURL } from "../../../utils/UrlBuilder";
import { AsyncQueryParams, FormikKeys, HTTPMethod } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import {
  UPDATE_BRANDS_LIST,
  UPDATE_LOADING_BRANDS_STATUS,
} from "../../constants/ReduxConstants";

// To get the brands
export const getBrands =
  (params: AsyncQueryParams) => async (dispatch: any) => {
    dispatch(updateBrandsLoadingStatus(true));
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: {
        [FormikKeys.ListQty]: params[FormikKeys.ListQty] || 10,
        ...params,
      },
      url: getBrandsLookupURL(),
    };
    return fetchAsync(dispatch, [request], [updateBrandsList]);
  };

const updateBrandsLoadingStatus = (payload: boolean) => ({
  type: UPDATE_LOADING_BRANDS_STATUS,
  payload,
});

const updateBrandsList = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(updateBrandsLoadingStatus(false));
    return dispatch({
      type: UPDATE_BRANDS_LIST,
      payload: payload.data?.data,
    });
  };
};
