import { RootState, Strings } from "@be-tagged/shared";
import { PlayIcon, BiCameraIcon } from "@be-tagged/shared/src/assets/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  Skeleton,
  Center,
} from "@chakra-ui/react";

import { FC, useState } from "react";
import ReactImageFallback from "react-image-fallback";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { SUPPORTED_FILES } from "src/dashboard-module/interfaces";

interface IImageCarouselComponent {
  showCaseMediaUrls: any[];
}

const { VideoMp4, Mp4 } = SUPPORTED_FILES;

/**
 * @returns {JSX.Element} - A React Functional Component
 */
const ImageCarouselComponent: FC<IImageCarouselComponent> = ({
  showCaseMediaUrls = [""],
}): JSX.Element => {
  const [image, setImage] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loading } = useSelector((state: RootState) => state.appReducer);
  const responsive = {
    desktop: {
      breakpoint: { max: 2500, min: 320 },
      items: 4,
      slidesToslide: 2,
    },
  };

  const currentMedia = showCaseMediaUrls?.[image] || "";

  return (
    <>
      <div style={{ maxWidth: "265px" }}>
        {currentMedia.includes(Mp4) ? (
          <>
            <button onClick={onOpen}>
              <div
                style={{
                  position: "absolute",
                  margin: "68px 0px 0px 68px",
                }}
              >
                <img src={PlayIcon} alt={Strings.playVideo} />
              </div>
              <video
                key={currentMedia}
                className="image-borders"
                style={{ objectFit: "cover", ...Styles.videoImgDims }}
              >
                {loading ? (
                  <Spinner size={"lg"} style={{ marginLeft: "50%" }} />
                ) : (
                  <source src={currentMedia} type={VideoMp4} />
                )}
              </video>
            </button>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{Strings.productVideo}</ModalHeader>
                <ModalCloseButton />
                <ModalBody mb={4}>
                  <Center>
                    <video
                      controls
                      key={currentMedia}
                      style={{ objectFit: "cover" }}
                    >
                      <source src={currentMedia} type={VideoMp4} />
                    </video>
                  </Center>
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        ) : (
          <ReactImageFallback
            src={currentMedia}
            fallbackImage={<Skeleton style={Styles.videoImgDims} />}
            style={Styles.videoImgDims}
            className="image-borders"
            alt={"Product"}
          />
        )}
        <Carousel
          responsive={responsive}
          swipeable={true}
          infinite={false}
          keyBoardControl={true}
          autoPlay={false}
          shouldResetAutoplay={false}
          showDots={true}
          arrows={false}
          renderDotsOutside={true}
        >
          {showCaseMediaUrls?.map((item: any, index: any) => (
            <div
              key={index}
              onClick={() => {
                setImage(index);
              }}
              className="carousel"
            >
              {item.includes(Mp4) ? (
                <div
                  style={{
                    border:
                      index === image
                        ? "1px solid #ba178c"
                        : "1px solid #d3e0fc",
                    borderRadius: "10%",
                  }}
                >
                  <div className="carousel-video">
                    <img src={BiCameraIcon} alt={Strings.productVideo} />
                  </div>
                  <video
                    style={{
                      width: "55px",
                      height: "55px",
                      objectFit: "cover",
                      borderRadius: "10%",
                    }}
                  >
                    <source src={item} type={VideoMp4} />
                  </video>
                </div>
              ) : (
                <ReactImageFallback
                  fallbackImage={
                    <Skeleton style={SmallImageStyles.videoImgDims} />
                  }
                  initialImage={
                    <Skeleton style={SmallImageStyles.videoImgDims} />
                  }
                  src={item}
                  key={index}
                  alt={"Product"}
                  style={{
                    border:
                      index === image
                        ? "1px solid #ba178c"
                        : "1px solid #d3e0fc",
                    borderRadius: "10%",
                    width: "55px",
                    height: "55px",
                    objectFit: "cover",
                  }}
                />
              )}
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

const Styles = {
  videoImgDims: {
    width: 265,
    height: 265,
  },
};
const SmallImageStyles = {
  videoImgDims: {
    width: 55,
    height: 55,
  },
};

export default ImageCarouselComponent;
