import {
  getResetPasswordUrl,
  getSendResetCodeUrl,
  getVerifyResetCodeUrl,
} from "../../../utils/UrlBuilder";
import {
  SEND_RESET_CODE,
  VERIFY_RESET_CODE,
  RESET_PASSWORD,
} from "../../constants/ReduxConstants";
import { HTTPMethod } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";

export const getSendResetCode =
  (data: any, navigateHelperFunction: any) => async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      queryStringParams: {},
      data,
      url: getSendResetCodeUrl(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => updateSendResetCode(payload, navigateHelperFunction)]
    );
  };

const updateSendResetCode = (payload: any, navigateHelperFunction: any) => {
  navigateHelperFunction();
  return {
    type: SEND_RESET_CODE,
  };
};

export const getVerifyResetCode =
  (data: any, navigateHelperFunction?: any) => async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      queryStringParams: {},
      data,
      url: getVerifyResetCodeUrl(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => updateVerifyResetCode(payload, navigateHelperFunction)]
    );
  };

const updateVerifyResetCode = (payload: any, navigateHelperFunction: any) => {
  navigateHelperFunction();
  return {
    type: VERIFY_RESET_CODE,
    payload: payload.data.passwordResetToken,
  };
};

export const getResetPassword =
  (data: any, navigateHelperFunction: any) =>
  async (dispatch: any, getState: any) => {
    const { passResetToken } = getState()?.commonReducer;
    data.passwordResetToken = passResetToken;
    const request = {
      method: HTTPMethod.POST,
      queryStringParams: {},
      data: data,
      url: getResetPasswordUrl(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => updateResetPassword(payload, navigateHelperFunction)]
    );
  };

const updateResetPassword = (payload: any, navigateHelperFunction: any) => {
  navigateHelperFunction();
  return {
    type: RESET_PASSWORD,
  };
};
