import { Strings } from "@be-tagged/shared";
import { Link } from "@chakra-ui/react";
import { FC } from "react";

/**
 * Component that will return a 404 - Page not found error message
 * @returns {JSX.Element}
 */
const PrivacyPolicy: FC<{}> = (): JSX.Element => {
  return (
    <div style={{ padding: "20px" }}>
      <p style={{ fontWeight: "800" }}>{Strings.privacyPolicy}</p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara1}</p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara2}</p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara3}</p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara4}</p>
      <p style={{ marginTop: "10px", fontWeight: "800" }}>
        {Strings.infoCollectionUse}
      </p>
      <p style={{ marginTop: "10px" }}>{Strings.forbetterexp}</p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara6}</p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara7}</p>
      <ul style={{ marginLeft: "30px", marginTop: "10px" }}>
        <li>
          <Link
            href={Strings.googlePlayServiceLink}
            boxShadow="none !important"
          >
            {Strings.googlePlayService}
          </Link>
        </li>
        <li>
          <Link href={Strings.segmentLink} boxShadow="none !important">
            {Strings.segment}
          </Link>
        </li>
        <li>
          <Link href={Strings.mixpanelLink} boxShadow="none !important">
            {" "}
            {Strings.mixpanel}
          </Link>
        </li>
      </ul>
      <p style={{ marginTop: "10px", fontWeight: "800" }}>{Strings.logData}</p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara8}</p>
      <p style={{ marginTop: "10px", fontWeight: "800" }}>{Strings.cookies}</p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara9}</p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara10}</p>
      <p style={{ marginTop: "10px", fontWeight: "800" }}>
        {Strings.serviceProviders}
      </p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara11}</p>
      <ul style={{ marginLeft: "30px", marginTop: "10px" }}>
        <li>{Strings.listText1}</li>
        <li>{Strings.listText2}</li>
        <li>{Strings.listText3}</li>
        <li>{Strings.listText4}</li>
      </ul>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara12}</p>
      <p style={{ marginTop: "10px", fontWeight: "800" }}>{Strings.security}</p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara13}</p>
      <p style={{ marginTop: "10px", fontWeight: "800" }}>
        {Strings.linksToOtherSites}
      </p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara14}</p>
      <p style={{ marginTop: "10px", fontWeight: "800" }}>
        {Strings.childrenprivacy}
      </p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara15}</p>
      <p style={{ marginTop: "10px", fontWeight: "800" }}>
        {Strings.changesToThisPrivacypolicy}
      </p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara16}</p>
      <p style={{ marginTop: "10px" }}>{Strings.privacyPolicyPara17}</p>
      <p style={{ marginTop: "10px", fontWeight: "800" }}>
        {Strings.contactUs}
      </p>
      <p style={{ marginTop: "10px", marginBottom: "20px" }}>
        {Strings.privacyPolicyPara18}
      </p>
    </div>
  );
};

export default PrivacyPolicy;
