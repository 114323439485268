import { FC } from "react";
import { ROLE_TYPES } from "../../app/enums";
import SelectionOption from "../common/SelectionOption";
import { LAYOUT_KEYS } from "../interfaces";
import ActionsLayout from "./ActionsLayout";

interface UserLayoutProps {
  roleType: ROLE_TYPES;
}

/**
 * UserLayout provides the split-screen layout for Brand / Influencer
 * For Brand - The input forms will appear on the right for larger displays
 * For Influencer - The input forms will appear on the left for larger displays
 * @returns {JSX.Element} - A React Functional Component
 */
const UserLayout: FC<UserLayoutProps> = ({ roleType }): JSX.Element => {
  const layout = [
    <ActionsLayout roleType={roleType} key={LAYOUT_KEYS.ACTIONS} />,
  ];

  roleType === ROLE_TYPES.Brand
    ? layout.unshift(
        <SelectionOption
          key={LAYOUT_KEYS.INFLUENCER}
          isSelected={true}
          roleType={ROLE_TYPES.Brand}
        />
      )
    : layout.push(
        <SelectionOption
          key={LAYOUT_KEYS.BRAND}
          isSelected={true}
          roleType={ROLE_TYPES.Influencer}
        />
      );

  return <>{layout}</>;
};

export default UserLayout;
