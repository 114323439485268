import {
  Colors,
  getProductInsights,
  logger,
  LoggerStrings,
  RootState,
  Strings,
} from "@be-tagged/shared";
import { Box, Divider, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TimeRangeFilter } from "src/app/components";
import { dateFiltersSinceListing } from "src/app/constants";
import MonthlySales from "./MonthlySales";
import ProductSales from "./ProductSales";

const Insights = () => {
  const insights = useSelector((state: RootState) => {
    return state.brandReducer?.productData?.insights || null;
  });
  const { id = "" } = useParams();
  const [selectedRange, setSelectedRange] = useState(
    dateFiltersSinceListing[0]
  );

  const onRangeChange = (value: any) => {
    setSelectedRange(value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    {
      dispatch(getProductInsights(parseInt(id), selectedRange?.value));
    }
  }, [selectedRange]);
  useEffect(() => {
    if (selectedRange) {
      const user = Strings.brand;
      logger.info(
        `${user} ${LoggerStrings.changedDateFilter} ${selectedRange.name} in Insights Tab`
      );
    }
  }, [selectedRange]);
  return (
    <Box>
      <Box className="product_sales_container">
        <Box className="dropdown_row">
          <Text as="span">{Strings.productSalesOnPlatforms}</Text>
          <TimeRangeFilter
            onChange={onRangeChange}
            value={selectedRange}
            isInclusive={true}
          />
        </Box>
        <Box className="social_media_stats_container">
          <ProductSales insights={insights} />
        </Box>
      </Box>

      <Divider borderColor={Colors.borderColor} />
      <MonthlySales monthlySalesGraph={insights?.monthlySalesDistribution} />
    </Box>
  );
};

export default Insights;
