import { createReducer } from '../reducerUtil';
import { UPDATE_LOADING_STATUS, SHOW_OTP_VERIFY_MODAL, SET_ERROR, SHOW_ONBOARDING_MODAL, SET_SUCCESS, RESET_APP_REDUCER } from '../../constants/ReduxConstants';

interface AppReducerType {
  loading: boolean;
  showOTPVerifyModal: boolean;
  showOnboardingModal: boolean;
  error: object | null;
  success: object | null;
}

const initialState = {
  loading: false,
  showOTPVerifyModal: false,
  error: null,
  success: null,
  showOnboardingModal: false,
};

export const updateLoadingStatus = (
  state: AppReducerType,
  payload: boolean,
): typeof state => {
  return {
    ...state,
    loading: payload,
  };
};

export const setError = (state: AppReducerType, payload: object) => {
  return {
    ...state,
    error: payload
  }
}

export const setSuccess = (state: AppReducerType, payload: object) => {
  return {
    ...state,
    success: payload
  }
}

export const updateOTPVerifyModalStatus = (
  state: AppReducerType,
  payload: boolean,
): typeof state => {
  return {
    ...state,
    showOTPVerifyModal: payload,
  };
};


export const updateOnboardingModalStatus = (
  state: AppReducerType,
  payload: boolean,
): typeof state => {
  return {
    ...state,
    showOnboardingModal: payload,
  };
};

export const resetAppReducer = (): AppReducerType => {
  return initialState
}

export default createReducer(initialState, {
  [UPDATE_LOADING_STATUS]: updateLoadingStatus,
  [SHOW_OTP_VERIFY_MODAL]: updateOTPVerifyModalStatus,
  [SET_ERROR]: setError,
  [SET_SUCCESS]: setSuccess,
  [SHOW_ONBOARDING_MODAL]: updateOnboardingModalStatus,
  [RESET_APP_REDUCER]: resetAppReducer
});