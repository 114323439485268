import { FC, useEffect } from "react";
import { RootState } from "@be-tagged/shared";
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  Image,
  Link,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { OnboardingForm, InfluencersArranged } from "./HelperComponents";

import {
  AppLogo,
  SelectionSplit,
  SplitOptionDarkBg,
} from "../../../app/components";
import { ONBOARDING_TEXT, ROUTING_URLS } from "../../../app/constants";
import {
  InfluencerGroup,
  FooterBackground,
} from "@be-tagged/shared/src/assets/icons";
import kaleidoScopeRight from "../../../app/assets/images/kaleidoscopeRight.png";

import OnboardingWaitModal from "./HelperComponents/OnboardingWaitModal";
import "./onboarding.scss";
import { DEVICE } from "../../../app/assets/styles/styleEnums";

/**
 * The Container Component for Onboarding Screen
 * @returns {JSX.Element} - A React Functional Component
 */
const Onboarding: FC<{}> = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { showOnboardingModal } = useSelector(
    (state: RootState) => state?.appReducer
  );

  useEffect(() => {
    showOnboardingModal ? onOpen() : onClose();
    //eslint-disable-next-line
  }, [showOnboardingModal]);

  const [isLgDisplay] = useMediaQuery(DEVICE.customMin);

  return (
    <>
      <SelectionSplit className="selection_split onboarding">
        <SplitOptionDarkBg className="option">
          <div className="inner">
            <Link to={`${ROUTING_URLS.GENERAL}`}>
              <AppLogo />
            </Link>
            <div className="role_actions_container">
              <div className="role">{ONBOARDING_TEXT.heading}</div>
              <OnboardingForm />
            </div>
          </div>

          {!isLgDisplay && (
            <Flex justify={"center"} align="center">
              <Image src={InfluencerGroup} marginBottom="20px" />
            </Flex>
          )}
        </SplitOptionDarkBg>

        <Box
          className="option kaleidoscope"
          bgImage={{
            base: `url(${FooterBackground})`,
            custom: `url(${kaleidoScopeRight})`,
          }}
          position="relative"
        >
          {isLgDisplay && <InfluencersArranged />}
        </Box>
      </SelectionSplit>

      <OnboardingWaitModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Onboarding;
