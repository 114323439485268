import { MutableRefObject, useEffect } from "react";

const useClearInputAutofill = (
  inputRefsArr: MutableRefObject<HTMLInputElement | undefined>[]
) => {
  useEffect(() => {
    inputRefsArr.forEach((inputRef) => {
      inputRef?.current?.setAttribute("readonly", "true");
    });
    setTimeout(() => {
      inputRefsArr.forEach((inputRef) => {
        inputRef?.current?.removeAttribute("readonly");
      });
    }, 600);
  }, [inputRefsArr]);
};

export default useClearInputAutofill;
