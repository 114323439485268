import {
  getBrandDashboardDetailsURL,
  getInfluencerDashboardDetailsURL,
} from "../../../utils/UrlBuilder";
import { DashboardDetailsParams, HTTPMethod } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import {
  SET_DASHBOARD_DATA_LOADING_STATUS,
  UPDATE_DASHBOARD_DATA,
} from "../../constants/ReduxConstants";

// To get the Dashboard Details (Brand / Influencer)
export const getDashboardData =
  (params: DashboardDetailsParams, isBrand: boolean, callbackFn?: any) =>
  async (dispatch: any) => {
    dispatch(setDashboardDataLoading(true));
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: params,
      url: isBrand
        ? getBrandDashboardDetailsURL()
        : getInfluencerDashboardDetailsURL(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => postDetailsFetch(payload, callbackFn)]
    );
  };

const setDashboardDataLoading = (status: boolean) => ({
  type: SET_DASHBOARD_DATA_LOADING_STATUS,
  payload: status,
});

const postDetailsFetch =
  (payload: any, callbackFn?: any) => (dispatch: any) => {
    callbackFn?.();
    dispatch(setDashboardDataLoading(false));
    return dispatch({
      type: UPDATE_DASHBOARD_DATA,
      payload,
    });
  };
