import { FC, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";

import { replace, useFormik, FormikProvider } from "formik";
import * as yup from "yup";

import {
  clearProductDetails,
  Colors,
  editProductDetails,
  getProductDetails,
  MAX_DESC_LENGTH,
  MIN_DESC_LENGTH,
  RootState,
  Strings,
  submitProductDetails,
  getCommissionDistribution,
  emptyCommissionPercentage,
  logger,
  AlphaRegex,
  DimensionRegex,
} from "@be-tagged/shared";
import {
  FormikKeys,
  ProductListingSteps,
  ProductStatus as PossibleProductStatuses,
} from "@be-tagged/shared/src/enums";

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

import {
  CommonObjectType,
  ROUTING_URLS,
  urlRegex,
} from "../../../app/constants";
import { StepArrows } from "../../../app/components";
// Styles
import "./productListing.scss";
import { scrollToTop } from "src/app/utils/Misc";

const {
  ProductDetails: DetailsStep,
  ProductVisuals: VisualsStep,
  BusinessDetails: BusinessStep,
} = ProductListingSteps;
const {
  ProductName,
  ProductsAndServices,
  Country,
  City,
  Location,
  Latitude,
  Longitude,
  Tags,
  Description,
  CurrencyCode,
  CurrencySymbol,
  ProductPrice,
  CommissionPercent,
  BuyerDiscountPercent,
  IsApprovalBased,

  BannerURL,
  ShowCaseURLs,

  SocialMediaKits,
  FBMediaURL,
  TikTokMediaURL,
  TwitterMediaURL,
  InstagramMediaURL,

  ProductURLs,
  CompanyProductURL,
  ECommercePlatforms,

  ProductStatus,
  DiscountCodesFileURL,
  PurchaseOrderFileUrl,
  CurrentlyChecked,

  DeliveryOption,
  DeliveredByInCommTeam,
  ProductIncommDetails,
  IsDeliveredByBrand,
  IsDeliveredByIncommTeam,
  VariantDetails,
  ZipCode,
  Weight,
  InCommMall,
  Dimension,
  InventoryLocation,
  ContactPersonNumber,
  ContactPersonCountryCode,
} = FormikKeys;

/**
 * Product Listing is a series of steps (3 in total)
 * The Brand will add details to their product being added
 * @returns {JSX.Element} - A React Functional Component
 */
const ProductListing: FC<{}> = (): JSX.Element => {
  const [currentStep, setCurrentStep] =
    useState<ProductListingSteps>(DetailsStep);

  const [bannerFile, setBannerFile] = useState([] as any);
  const [showcaseFiles, setShowcaseFiles] = useState([] as any);
  const [facebookFiles, setFacebookFiles] = useState([] as any);
  const [tikTokFiles, setTikTokFiles] = useState([] as any);
  const [instagramFiles, setInstagramFiles] = useState([] as any);
  const [twitterFiles, setTwitterFiles] = useState([] as any);
  const [couponFile, setCouponFile] = useState([]);
  const [buyerDiscountValue, setBuyerDiscountValue] = useState(false);
  const [currentlyCheckedValue, setCurrentlyCheckedValue] =
    useState(InCommMall);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, productDetails, commissionPercentageDistribution } =
    useSelector((state: RootState) => {
      return {
        commissionPercentageDistribution:
          state.brandReducer?.commissionPercentageDistribution,
        isLoading: state.appReducer.loading,
        productDetails:
          state.commonReducer?.commonData?.productData?.productDetails || null,
      };
    });

  const { id = "" } = useParams();

  const isEditMode = !!id;

  useEffect(() => {
    if (isEditMode) {
      dispatch(getProductDetails(parseInt(id), true));
      return () => {
        dispatch(clearProductDetails());
      };
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEditMode && productDetails?.bannerImageUrl) {
      setBannerFile([{ name: Strings.bannerImage }]);
    }
    if (isEditMode && productDetails?.showCaseMediaUrls?.length) {
      const tempMediaUrls: {
        name: string;
        initialFileUrl: string;
        size: number;
      }[] = [];
      productDetails?.showCaseMediaUrls.forEach((url: any, index: any) => {
        tempMediaUrls.push({
          name: `${Strings.showCaseMedia}${index + 1}`,
          initialFileUrl: url,
          size: url?.length,
        });
      });
      setShowcaseFiles([...tempMediaUrls]);
    }
    if (isEditMode && productDetails?.socialMediaKits?.facebookMediaKitUrl) {
      setFacebookFiles([{ name: Strings.facebookMediaKit }]);
    }
    if (isEditMode && productDetails?.socialMediaKits?.tikTokMediaKitUrl) {
      setTikTokFiles([{ name: Strings.tikTokMediaKit }]);
    }
    if (isEditMode && productDetails?.socialMediaKits?.instagramMediaKitUrl) {
      setFacebookFiles([{ name: Strings.instagramMediaKit }]);
    }
  }, [productDetails]);

  useEffect(() => {
    // Scroll to top on first render
    scrollToTop();
  }, []);

  const tags = ((productDetails?.hashtags || "") as string)
    ?.split(",")
    ?.filter((el) => el);
  const getTagValues = (selectedTags: any) => {
    const tempValues = selectedTags.map((tag: any) => {
      tag?.replace("#", "");
      const tempTag = { label: tag, value: tag, __isNew__: "true" };
      return tempTag;
    });
    return tempValues;
  };

  const getCommissionDist = (comm: any) => {
    dispatch(
      getCommissionDistribution({
        commissionPercentage: Number(comm),
      })
    );
  };

  const getInitialValues = () => {
    return {
      [ProductName]: "",
      [ProductsAndServices]: [],
      [Country]: null,
      [City]: null,
      [Tags]: [],
      [Description]: "",
      [CurrencyCode]: "",
      [CurrencySymbol]: "",
      [ProductPrice]: "",
      [CommissionPercent]: "",
      [BuyerDiscountPercent]: "",
      [IsApprovalBased]: false,
      [CompanyProductURL]: null,
      [ECommercePlatforms]: null,
      [BannerURL]: null,
      [ShowCaseURLs]: [],
      [FBMediaURL]: null,
      [TikTokMediaURL]: null,
      [TwitterMediaURL]: null,
      [InstagramMediaURL]: null,
      [DiscountCodesFileURL]: null,
      [PurchaseOrderFileUrl]: null,
      [CurrentlyChecked]: InCommMall,
      [ProductStatus]: PossibleProductStatuses.Listed, //The default state of the product when being listed for the first time
      [DeliveryOption]: DeliveredByInCommTeam,
      [Weight]: "",
      [Dimension]: "",
      [InventoryLocation]: "",
      [ZipCode]: "",
      [ContactPersonNumber]: "",
      [VariantDetails]: [
        {
          size: "",
          color: "",
          orderUnits: "",
          productImageUrls: [""],
        },
      ],
    };
  };
  const getProductInitialDetails = () => {
    const temp = {
      [ProductName]: productDetails?.name,
      [ProductsAndServices]: productDetails?.categories,
      [Country]: productDetails?.country,
      [City]: productDetails?.city,
      [Tags]: getTagValues(tags),
      [Description]: productDetails?.description,
      [CurrencyCode]: productDetails?.currencyCode,
      [CurrencySymbol]: productDetails?.currencySymbol,
      [ProductPrice]: productDetails?.price,
      [CommissionPercent]: productDetails?.commissionPercentage,
      [BuyerDiscountPercent]: productDetails?.buyerDiscountPercentage,
      [IsApprovalBased]: productDetails?.isApprovalBasedOffer,
      [CompanyProductURL]: productDetails?.productUrls?.companyProductUrl,
      [ECommercePlatforms]: productDetails?.productUrls?.otherCommerceUrl,
      [BannerURL]: productDetails?.bannerImageUrl,
      [ShowCaseURLs]: productDetails?.showCaseMediaUrls,
      [FBMediaURL]: productDetails?.socialMediaKits?.facebookMediaKitUrl,
      [TikTokMediaURL]: productDetails?.socialMediaKits?.tikTokMediaKitUrl,
      [TwitterMediaURL]: productDetails?.socialMediaKits?.twitterMediaKitUrl,
      [InstagramMediaURL]:
        productDetails?.socialMediaKits?.instagramMediaKitUrl,
      [DiscountCodesFileURL]: null,
      [PurchaseOrderFileUrl]: null,
      [CurrentlyChecked]:
        productDetails?.productUrls?.companyProductUrl !== null
          ? CompanyProductURL
          : ECommercePlatforms,
      [ProductStatus]: PossibleProductStatuses.Listed, //The default state of the product when being listed for the first time
      [PurchaseOrderFileUrl]: productDetails?.purchaseOrderFileUrl,
    };

    return temp;
  };

  const getListingSchema = (): CommonObjectType => {
    switch (currentStep) {
      case DetailsStep:
        return {
          [ProductName]: yup
            .string()
            .required(Strings.enterProductName)
            .matches(/\S/, Strings.notOnlyWhiteSpacesInProductName),
          [ProductsAndServices]: yup
            .array()
            .min(1)
            .required(Strings.enterProductsAndServices),
          [Country]: yup.object().nullable().required(Strings.selectCountry),
          [City]: yup.object().nullable(),
          [Tags]: yup.array(),
          [Description]: yup
            .string()
            .min(MIN_DESC_LENGTH)
            .max(MAX_DESC_LENGTH)
            .matches(/\S/, Strings.notOnlyWhiteSpacesInProductDesc)
            .required(Strings.enterProductDesc),
        };
      case VisualsStep:
        return {
          [BannerURL]: yup
            .string()
            .nullable()
            .required(Strings.uploadBannerImg),
          [ShowCaseURLs]: yup
            .array()
            .min(1, Strings.uploadShowcaseImg)
            .required(Strings.uploadShowcaseImg),
        };
      case BusinessStep:
        return {
          [CurrencyCode]: yup.string().required(Strings.enterCountryCode),
          [CurrencySymbol]: yup.string().required(Strings.enterCountryCode),
          [ProductPrice]: yup.number().required(Strings.enterProductPrice),
          [CommissionPercent]: yup
            .number()
            .positive(Strings.invalidCommissionPercentage)
            .min(1, Strings.invalidCommissionPercentage)
            .max(100, Strings.invalidCommissionPercentage)
            .required(Strings.enterCommissionPercentage),
          [BuyerDiscountPercent]: yup
            .number()
            .positive(Strings.invalidDiscount)
            .min(0, Strings.invalidDiscount)
            .max(100, Strings.invalidDiscount),
          [DiscountCodesFileURL]:
            isEditMode || !buyerDiscountValue
              ? yup.string().nullable()
              : yup.string().nullable().required(Strings.uploadDiscountFile),
          [PurchaseOrderFileUrl]: yup
            .string()
            .nullable()
            .required(Strings.uploadPurchaseOrderFile),
          [IsApprovalBased]: yup.boolean(),
          [CompanyProductURL]: yup
            .string()
            .nullable()
            .matches(urlRegex, Strings.enterValidURL),
          [ECommercePlatforms]: yup
            .string()
            .nullable()
            .matches(urlRegex, Strings.enterValidURL),
          [CurrentlyChecked]: yup
            .string()
            .required(Strings.selectProductPlatform)
            .test({
              name: Strings.platformEntryValidation,
              message: Strings.enterURLforPlatform,
              test: function (value) {
                if (value === FormikKeys.InCommMall) return true;
                return !!this.parent[value || ""];
              },
            }),

          ...(currentlyCheckedValue === InCommMall && {
            [VariantDetails]: yup.array().of(
              yup.object().shape({
                size: yup.string().required(Strings.enterSize),
                color: yup
                  .string()
                  .matches(AlphaRegex, Strings.enterValidColor)
                  .required(Strings.enterColor),
                orderUnits: yup.string().required(Strings.enterOrderUnits),
                productImageUrls: yup
                  .array()
                  .of(yup.string().required(Strings.addImage)),
              })
            ),
            [Weight]: yup.number().when(DeliveryOption, {
              is: DeliveredByInCommTeam,
              then: yup.number().required(Strings.enterWeight),
            }),
            [Dimension]: yup.string().when(DeliveryOption, {
              is: DeliveredByInCommTeam,
              then: yup
                .string()
                .matches(DimensionRegex, Strings.dimensionFormat)
                .required(Strings.enterDimension),
            }),
            [InventoryLocation]: yup.string().when(DeliveryOption, {
              is: DeliveredByInCommTeam,
              then: yup.string().required(Strings.enterLocation),
            }),
            [ZipCode]: yup.number().when(DeliveryOption, {
              is: DeliveredByInCommTeam,
              then: yup.number().required(Strings.enterZipCode),
            }),
            [ContactPersonNumber]: yup.string().when(DeliveryOption, {
              is: DeliveredByInCommTeam,
              then: yup.string().required(Strings.enterContactNumber),
            }),
          }),
        };
      default:
        return {};
    }
  };

  useEffect(() => {
    logger.info(
      `${Strings.productListing} ${Strings.step} ${currentStep}`,
      values
    );
  }, [currentStep]);

  const goToPrevious = (setErrors: any) => {
    setErrors({});
    setCurrentStep(currentStep - 1);
  };

  const postCreationNav = () => {
    dispatch(emptyCommissionPercentage());
    navigate(`${ROUTING_URLS.LANDING}/${ROUTING_URLS.PRODUCTS_CATALOG}`);
  };

  /**
   * We need to transform the formik values to shape it in the required payload form
   */
  const sanitizeAndSend = (values: any) => {
    const categories = values[ProductsAndServices].map((el: any) =>
      parseInt(el.id)
    );
    const country = values[Country]?.id;
    const hashtags = values[Tags].map((tag: any) =>
      tag.value.trim().replace(/\s+/g, "")
    ).join(",");
    const city = values[City]
      ? `${values[City]?.city}, ${values[City]?.country}`
      : null;
    const location = city
      ? {
          [Latitude]: values[City]?.[Latitude],
          [Longitude]: values[City]?.[Longitude],
        }
      : null;

    const transformedValues = {
      [ProductsAndServices]: categories,
      [Country]: country,
      [City]: city,
      [Location]: location,
      [Tags]: hashtags,
      [SocialMediaKits]: {
        [FBMediaURL]: values[FBMediaURL],
        [TikTokMediaURL]: values[TikTokMediaURL],
        [TwitterMediaURL]: values[TwitterMediaURL],
        [InstagramMediaURL]: values[InstagramMediaURL],
      },
      [ProductURLs]: {
        //Bring the key's values back to values[CompanyProductURL / TikTokProductURL / etc.]
        [CompanyProductURL]: null,
        [ECommercePlatforms]: null,
      },
      [ProductIncommDetails]:
        values[CurrentlyChecked] === InCommMall
          ? {
              [IsDeliveredByIncommTeam]:
                values[DeliveryOption] === DeliveredByInCommTeam,
              [IsDeliveredByBrand]:
                values[DeliveryOption] !== DeliveredByInCommTeam,
              [VariantDetails]: values[VariantDetails],
              [Dimension]: values[Dimension],
              [Weight]: values[Weight],
              [InventoryLocation]: values[InventoryLocation],
              [ZipCode]: values[ZipCode],
              [ContactPersonNumber]: values[ContactPersonNumber]
                ? values[ContactPersonCountryCode].isdCode.concat(
                    values[ContactPersonNumber]
                  )
                : null,
            }
          : null,
    };
    const transformedEditedValues = {
      [ProductsAndServices]: categories,
      [Tags]: hashtags,
      [SocialMediaKits]: {
        [FBMediaURL]: values[FBMediaURL],
        [TikTokMediaURL]: values[TikTokMediaURL],
        [TwitterMediaURL]: values[TwitterMediaURL],
        [InstagramMediaURL]: values[InstagramMediaURL],
      },
    };
    transformedValues[ProductURLs] = {
      ...transformedValues[ProductURLs],
      [values[CurrentlyChecked]]: values[values[CurrentlyChecked]],
    };
    // ^^ Remove this logic when checkboxes will be used again
    const finalObject = {
      [ProductName]: values[ProductName],
      [Description]: values[Description],
      [ProductPrice]: values[ProductPrice],
      [CommissionPercent]: values[CommissionPercent],
      [BuyerDiscountPercent]: values[BuyerDiscountPercent] || 0,
      [IsApprovalBased]: values[IsApprovalBased],
      [ProductStatus]: values[ProductStatus],
      [ShowCaseURLs]: values[ShowCaseURLs],
      [BannerURL]: values[BannerURL],
      [CurrencyCode]: values[CurrencyCode],
      [CurrencySymbol]: values[CurrencySymbol],
      [DiscountCodesFileURL]: values[DiscountCodesFileURL],
      [PurchaseOrderFileUrl]: values[PurchaseOrderFileUrl],
      ...transformedValues,
    };
    const finalEditedDetails = {
      [ProductName]: values[ProductName],
      [Description]: values[Description],
      [IsApprovalBased]: values[IsApprovalBased],
      [ShowCaseURLs]: values[ShowCaseURLs],
      [BannerURL]: values[BannerURL],
      [DiscountCodesFileURL]: values[DiscountCodesFileURL],
      [PurchaseOrderFileUrl]: values[PurchaseOrderFileUrl],
      ...transformedEditedValues,
    };
    if (isEditMode) {
      dispatch(
        editProductDetails(finalEditedDetails, parseInt(id), postCreationNav)
      );
    } else {
      dispatch(submitProductDetails(finalObject, postCreationNav));
    }
  };

  const goToNext = (values: any) => {
    if (currentStep !== BusinessStep) {
      dispatch(emptyCommissionPercentage());
      setCurrentStep((currentStep) => currentStep + 1);
    } else {
      sanitizeAndSend(values);
    }
  };

  const formik = useFormik({
    initialValues: id ? getProductInitialDetails() : getInitialValues(),
    validationSchema: yup.object().shape(getListingSchema()),
    onSubmit: goToNext,
    enableReinitialize: true,
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setTouched,
  } = formik;

  const stepProps = {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setTouched,
  };

  const stepReturner = () => {
    switch (currentStep) {
      case DetailsStep:
        return (
          <StepOne
            {...stepProps}
            edit={id ? true : false}
            cityName={productDetails?.city}
          />
        );
      case VisualsStep:
        const fileProps = {
          bannerFile,
          setBannerFile,
          showcaseFiles,
          setShowcaseFiles,
          facebookFiles,
          setFacebookFiles,
          tikTokFiles,
          setTikTokFiles,
          instagramFiles,
          setInstagramFiles,
          twitterFiles,
          setTwitterFiles,
        };
        return <StepTwo {...stepProps} {...fileProps} />;
      case BusinessStep:
        const couponProps = { couponFile, setCouponFile };
        return (
          <StepThree
            {...stepProps}
            {...couponProps}
            edit={id ? true : false}
            getCommissionDist={getCommissionDist}
            setBuyerDiscountValue={setBuyerDiscountValue}
            setCurrentlyCheckedValue={setCurrentlyCheckedValue}
            commissionPercentageDistribution={commissionPercentageDistribution}
          />
        );
      default:
        return <></>;
    }
  };

  const getTitleText = () => {
    switch (currentStep) {
      case DetailsStep:
        return Strings.stepOneDescription;
      case VisualsStep:
        return Strings.stepTwoDescription;
      case BusinessStep:
        return Strings.stepThreeDescription;
      default:
        return "";
    }
  };

  return (
    <Box
      padding={{
        base: "20px 20px",
        md: "50px 30px",
      }}
      className="outer-container"
      bgColor={Colors.bgLight}
    >
      <Box>
        <Flex
          direction={{
            base: "column",
            md: "row",
          }}
          justifyContent={"space-between"}
          alignItems={{
            md: "center",
          }}
          mb={"10px"}
        >
          <Box className="bold">{Strings.listYourProducts}</Box>
          {/* Pull Product and Invite Collab used to be here  */}
        </Flex>

        <Flex direction={"column"}>
          <Box fontSize={"22px"} fontWeight={"600"}>
            <Text as="span">
              {Strings.step} {currentStep} -{" "}
            </Text>
            <Text as="span" color={Colors.dollarGreen}>
              {getTitleText()}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Divider borderColor={Colors.borderColor} my={2} />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Box className="step_container">{stepReturner()}</Box>
          <Box mt={"50px"}>
            <StepArrows
              prevDisabled={currentStep === DetailsStep}
              nextDisabled={isLoading}
              prevBtnClick={() => goToPrevious(formik.setErrors)}
            />
          </Box>
        </form>
      </FormikProvider>
    </Box>
  );
};

export default ProductListing;
