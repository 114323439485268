import { LogLevels } from "../../enums/LogLevels";
import { ILogTransport } from "./LogTransportInterface";
import UserModel from "./UserModel";

export default abstract class SentryLogTransportBase implements ILogTransport {
  abstract client: any;
  abstract init: () => void;

  log = (message: string, attributes: Record<string, any>, level: LogLevels) => {
    if (level === LogLevels.Debug || level === LogLevels.Info) {
      this?.client?.addBreadCrumb && this.client.addBreadcrumb({
        message,
        attributes,
        level
      })
    }
    else {
      this?.client?.captureException &&
      this.client.captureException(level, {
        extra: {
          message,
          attributes,
          level
        }
      })
    }
  };

  identify = (user: UserModel) => {
    this?.client?.setUser && this.client.setUser(user)
  };

  trackPage = (name: string, attributes?: Record<string, any>) => {
    this.log(name, attributes || {}, LogLevels.Info)
  };

  trackScreen = (name: string, attributes?: Record<string, any>) => {
    this.log(name, attributes || {}, LogLevels.Info)
  };
}