import { getBrandCountriesURL } from "../../../utils/UrlBuilder";
import { HTTPMethod } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import {
  SET_BRAND_COUNTRIES,
  UPDATE_BRAND_COUNTRIES_LOADING_STATUS,
} from "../../constants/ReduxConstants";

// To get the countries of a brand, used in insights (Countries contributing to insights). This will be a subset of the exhaustive countries
export const getBrandCountries =
  (callbackFn?: any) => async (dispatch: any) => {
    dispatch(setBrandCountriesLoading(true));
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: {},
      url: getBrandCountriesURL(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => updateBrandCountriesList(payload, callbackFn)]
    );
  };

const setBrandCountriesLoading = (payload: boolean) => {
  return {
    type: UPDATE_BRAND_COUNTRIES_LOADING_STATUS,
    payload,
  };
};

export const updateBrandCountriesList = (payload: any, callbackFn?: any) => {
  return async (dispatch: any) => {
    if (payload?.data?.length) callbackFn?.();
    dispatch(setBrandCountriesLoading(false));
    return dispatch({
      type: SET_BRAND_COUNTRIES,
      payload,
    });
  };
};
