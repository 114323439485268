import { FC } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Spinner,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  Colors,
  deleteBankAccounts,
  RootState,
  Strings,
} from "@be-tagged/shared";
import { CustomBtn } from "src/app/components";

interface DeleteBankAccountModalProp {
  isOpen: boolean;
  isPrimary: boolean;
  id: number;
  onClose: () => void;
  closeModal: () => void;
}
/**
 * @param {Object} props - Of the form RemoveTeamMemberProp
 * @returns {JSX.Element} - A React Functional Component
 */
const DeleteBankAccountModal: FC<DeleteBankAccountModalProp> = ({
  isOpen,
  id,
  isPrimary,
  closeModal,
  onClose,
}): JSX.Element => {
  const { loading } = useSelector(
    (state: RootState) => state?.appReducer?.loading
  );
  const dispatch = useDispatch();
  const removeBankAccount = () => {
    dispatch(deleteBankAccounts(id));
    closeModal();
  };
  return (
    <>
      <Modal
        size={"2xl"}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          className="filter_modal"
          style={{ paddingTop: 12 }}
          backgroundColor={Colors.bgDark}
        >
          <ModalBody padding="15px">
            <Box px={{ md: "15px", base: "0px" }} className="content_container">
              <Box className="bold" style={{ fontSize: 18, paddingBottom: 24 }}>
                {Strings.removeBankAccount}
              </Box>
              {isPrimary ? (
                <Box>{Strings.cantRemoveDefaultAccount}</Box>
              ) : (
                <Box>{Strings.confirmDeleteBankDetails}</Box>
              )}
              <Flex justifyContent="flex-end" columnGap={4} mt={8}>
                <CustomBtn
                  type="button"
                  className="purple_btn medium inv"
                  onClick={closeModal}
                >
                  {loading && (
                    <Spinner size={"xs"} style={{ marginRight: 10 }} />
                  )}
                  {Strings.cancel}
                </CustomBtn>
                {isPrimary ? null : (
                  <CustomBtn
                    type="submit"
                    className="purple_btn medium"
                    onClick={() => {
                      removeBankAccount();
                    }}
                    style={{ paddingLeft: "30px", paddingRight: "30px" }}
                  >
                    {loading && (
                      <Spinner size={"xs"} style={{ marginRight: 10 }} />
                    )}
                    {Strings.remove}
                  </CustomBtn>
                )}
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteBankAccountModal;
