import { Flex, Box, Text, Collapse, Image } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";

import { Strings, Colors } from "@be-tagged/shared";
import { IconDoubleArrowUp, IconDoubleArrowDown } from "@be-tagged/shared/src/assets/icons";
import { FormikKeys, SocialMedia } from "@be-tagged/shared/src/enums";

import { DragDropComponent, SocialMediaDropzone } from "./HelperComponents";
import { ErrorMsg, Label } from "../../../app/components";
import { SUPPORTED_FILES } from "../../interfaces";
import useSetPageTitle from "src/app/hooks/useSetPageTitle";

const { BannerURL, ShowCaseURLs, FBMediaURL, InstagramMediaURL, TikTokMediaURL, TwitterMediaURL } = FormikKeys;

/**
 * Step 2 of Product Listing requires the user to upload images for the product being listed
 * @param {Object} - Formik Props like values, errors, etc & FileProps from the Index
 * @returns {JSX.Element} - A React Functional Component
 */
const StepTwo: FC<any> = ({ values, errors, touched, setFieldValue, setFieldTouched, setTouched, ...fileProps }: any) => {
  const { bannerFile, setBannerFile } = fileProps;
  const { showcaseFiles, setShowcaseFiles } = fileProps;
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  useEffect(() => {
    setTouched({});
    //eslint-disable-next-line
  }, []);

  const socialsFileData = [
    {
      name: SocialMedia.Instagram,
      file: fileProps.instagramFiles,
      setFile: fileProps.setInstagramFiles
    },
    {
      name: SocialMedia.TikTok,
      file: fileProps.tikTokFiles,
      setFile: fileProps.setTikTokFiles
    },
    {
      name: SocialMedia.Facebook,
      file: fileProps.facebookFiles,
      setFile: fileProps.setFacebookFiles
    },

    {
      name: SocialMedia.Twitter,
      file: fileProps.twitterFiles,
      setFile: fileProps.setTwitterFiles
    }
  ];

  const setBannerImgArray = (urlArray: string[]) => {
    // since we only need one image URL, and the only permissible type for BannerURL is string
    setFieldValue(BannerURL, urlArray[0] || "");
    setFieldTouched(BannerURL);
  };

  const setShowcaseArray = (urlArray: string[]) => {
    setFieldValue(ShowCaseURLs, urlArray);
    setFieldTouched(ShowCaseURLs);
  };

  const setSocialMediaKit = (urlArray: string[], name: SocialMedia) => {
    let accessorKey;
    switch (name) {
      case SocialMedia.Facebook:
        accessorKey = FBMediaURL;
        break;
      case SocialMedia.Instagram:
        accessorKey = InstagramMediaURL;
        break;
      case SocialMedia.TikTok:
        accessorKey = TikTokMediaURL;
        break;
      case SocialMedia.Twitter:
        accessorKey = TwitterMediaURL;
        break;
      default:
        accessorKey = "";
        break;
    }
    setFieldValue(accessorKey, urlArray[0] || null);
  };

  useSetPageTitle("Product Listing - Visuals");

  return (
    <Box className="visual_details_container">
      <Flex className="step_two_top_container" gap={10}>
        <Flex className="hero_showcase_container" gap={10}>
          <Flex className="upload_container">
            <Label>{Strings.productBannerImage}</Label>
            <DragDropComponent accept={SUPPORTED_FILES.Images} files={bannerFile} setFiles={setBannerFile} onChange={setBannerImgArray} />
            {touched[BannerURL] && errors[BannerURL] && <ErrorMsg>{errors[BannerURL]}</ErrorMsg>}
          </Flex>

          <Flex className="upload_container">
            <Label>{Strings.productShowcaseImage}</Label>
            <DragDropComponent
              accept={SUPPORTED_FILES.ImagesOrVideos}
              maxFiles={6}
              files={showcaseFiles}
              setFiles={setShowcaseFiles}
              onChange={setShowcaseArray}
            />
            {touched[ShowCaseURLs] && errors[ShowCaseURLs] && <ErrorMsg>{errors[ShowCaseURLs]}</ErrorMsg>}
          </Flex>
        </Flex>
        <Flex flexDirection={"column"} className="right_container">
          <Flex
            onClick={() => setIsCollapseOpen(!isCollapseOpen)}
            style={{
              width: "350px",
              height: "42px",
              border: "1px solid #D3E0FC",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              padding: "10px",
              boxShadow: "0px 3px 30px rgba(153, 186, 208, 0.3)"
            }}
            className="collapse_item"
          >
            <Text alignSelf={"center"}>Instructions</Text>
            <Image
              style={{ position: "absolute", right: 10 }}
              onClick={() => setIsCollapseOpen(true)}
              cursor={"pointer"}
              src={isCollapseOpen ? IconDoubleArrowUp : IconDoubleArrowDown}
            />
          </Flex>
          <Collapse in={isCollapseOpen} animateOpacity>
            <Box
              style={{
                color: Colors.grey,
                height: "429px",
                width: "350px",
                border: "1px solid #D3E0FC",
                boxShadow: "0px 3px 30px rgba(153, 186, 208, 0.3)",
                overflow: "scroll",
                padding: "10px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "150%",
                marginTop: "0px"
              }}
              className="collapse_item"
            >
              <p>{Strings.stepTwoParaOne}</p>
              <br />
              <p>{Strings.stepTwoParaTwo}</p>
              <br />
              <p>{Strings.stepTwoParaThree}</p>
              <br />
              <p>{Strings.stepTwoParaFour}</p>
              <br />
              <p>{Strings.stepTwoParaFive}</p>
            </Box>
          </Collapse>
        </Flex>
      </Flex>

      <Box>
        <Text mt={"30px"} className="bold">
          {Strings.productSocialMediaKits}
        </Text>
        <Flex gap={10} wrap="wrap" alignItems={"flex-end"} mt={"15px"}>
          {socialsFileData.map(({ name, ...fileProps }, idx) => (
            <SocialMediaDropzone
              key={idx}
              name={name}
              files={fileProps.file}
              setFiles={fileProps.setFile}
              onChange={(urlArray: string[]) => setSocialMediaKit(urlArray, name)}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default StepTwo;
