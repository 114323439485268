import { Box, Skeleton, Stack } from "@chakra-ui/react";
import { FC } from "react";
interface LoginDetailsSkeletonProps {
  emails?: any;
  phones?: any;
}
export const EmailSkeleton: FC<LoginDetailsSkeletonProps> = ({ emails }) => {
  const emailHeight = emails * 40;
  return (
    <Stack>
      <Skeleton className={"login_skeleton"} height={emailHeight} />
    </Stack>
  );
};

export const PhoneSkeleton: FC<LoginDetailsSkeletonProps> = ({ phones }) => {
  const phonesHeight = phones * 10;
  return (
    <Stack>
      <Skeleton className={"login_skeleton"} height={phonesHeight} />
    </Stack>
  );
};
interface ProfileSkeletonProps {
  isBrand?: boolean;
}
export const ProfileSkeleton: FC<ProfileSkeletonProps> = ({ isBrand }) => {
  return (
    <>
      {isBrand ? (
        <Stack>
          <Skeleton
            style={{
              width: "100px",
              height: "100px",
              objectFit: "cover",
              borderRadius: "100px",
            }}
            mb={5}
          />
          {[1, 2, 3].map((el: number, idx: number) => (
            <Skeleton
              height={33}
              width={300}
              borderRadius={"10px"}
              key={idx}
              mb={3}
            />
          ))}
        </Stack>
      ) : (
        <Stack>
          <Skeleton
            style={{
              width: "100px",
              height: "100px",
              objectFit: "cover",
              borderRadius: "100px",
            }}
          />
          <Box mt={4} mb={4}>
            {[1, 2, 3, 4].map((el: number, idx: number) => (
              <Skeleton
                height={33}
                width={300}
                borderRadius={"10px"}
                key={idx}
                mb={2}
              />
            ))}
          </Box>
          {[1, 2, 3, 4].map((el: number, idx: number) => (
            <Skeleton height={25} width={200} borderRadius={"10px"} key={idx} />
          ))}
        </Stack>
      )}
    </>
  );
};

interface BankDetailsSkeletonProps {
  bankAccounts?: [];
}
export const BankDetailsSkeleton: FC<BankDetailsSkeletonProps> = ({
  bankAccounts,
}) => {
  return (
    <Stack>
      {bankAccounts?.map((el: number, idx: number) => (
        <Skeleton className={"login_skeleton"} height={160} key={idx} />
      ))}
    </Stack>
  );
};
