import { extendTheme, theme as base } from "@chakra-ui/react";

import { Colors } from "@be-tagged/shared/src/theme";

import { BREAKPOINTS } from "../styleEnums";

/**
 * Providing a customised 'theme' to the Chakra UI Provider helps in customisation and responsiveness
 */
const theme = extendTheme({
  breakpoints: {
    sm: BREAKPOINTS.MOBILE,
    md: BREAKPOINTS.TABLET,
    lg: BREAKPOINTS.LARGE,
    xl: BREAKPOINTS.EXTRA_LARGE,
    "2xl": BREAKPOINTS.EXTRA_EXTRA_LARGE,
    custom: BREAKPOINTS.CUSTOM_BREAKPOINT,
  },
  colors: {
    grey100: Colors.grey100,
    darkBg: Colors.bgDark,
    whiteBg: Colors.bgLight,
    dollarGreen: Colors.dollarGreen,
    purple: Colors.purple,
  },
  fonts: {
    heading: `Poppins, ${base.fonts?.heading}`,
    body: `Poppins, ${base.fonts?.body}`,
  },
});

export default theme;
