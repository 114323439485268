import { FC } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Center,
  Link,
} from "@chakra-ui/react";
import { CustomBtn } from "src/app/components";
import { Strings } from "@be-tagged/shared";

interface TermsAndConditionModalProp {
  isOpen: boolean;
  onClose: () => void;
}

const TermsAndConditionModal: FC<TermsAndConditionModalProp> = ({
  isOpen,
  onClose,
}): JSX.Element => {
  return (
    <Modal
      size={"xl"}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent height={"inherit"} overflowY={"auto"}>
        <ModalHeader>{Strings.acceptTermsAndConditionPostText}</ModalHeader>
        <ModalBody>
          <p>{Strings.tncPara1}</p>
          <p style={{ marginTop: "10px" }}>{Strings.tncPara2}</p>
          <p style={{ marginTop: "10px" }}>{Strings.tncPara3}</p>
          <p style={{ marginTop: "10px" }}>{Strings.tncPara4}</p>
          <p style={{ marginTop: "10px" }}>{Strings.tncPara5}</p>
          <ul style={{ marginLeft: "30px", marginTop: "10px" }}>
            <li>
              <Link
                href={Strings.googlePlayServiceLink}
                boxShadow="none !important"
              >
                {Strings.googlePlayService}
              </Link>
            </li>
            <li>
              <Link href={Strings.segmentLink} boxShadow="none !important">
                {Strings.segment}
              </Link>
            </li>
            <li>
              <Link href={Strings.mixpanelLink} boxShadow="none !important">
                {" "}
                {Strings.mixpanel}
              </Link>
            </li>
          </ul>
          <p style={{ marginTop: "10px" }}>{Strings.tncPara6}</p>
          <p style={{ marginTop: "10px" }}>{Strings.tncPara7}</p>
          <p style={{ marginTop: "10px" }}>{Strings.tncPara8}</p>
          <p style={{ marginTop: "10px" }}>{Strings.tncPara9}</p>
          <p style={{ marginTop: "10px" }}>{Strings.tncPara10}</p>
          <p style={{ marginTop: "10px", fontWeight: "800", fontSize: "16px" }}>
            {Strings.tncPara11}
          </p>
          <p style={{ marginTop: "10px" }}>{Strings.tncPara12}</p>
          <p style={{ marginTop: "10px" }}>{Strings.tncPara13}</p>
          <p style={{ marginTop: "10px", fontWeight: "800", fontSize: "16px" }}>
            {Strings.tncPara14}
          </p>
          <p style={{ marginTop: "10px" }}>{Strings.tncPara15}</p>
        </ModalBody>
        <Center>
          <ModalFooter>
            <CustomBtn
              type="button"
              className="purple_btn medium inv"
              onClick={onClose}
            >
              {Strings.ok}
            </CustomBtn>
          </ModalFooter>
        </Center>
      </ModalContent>
    </Modal>
  );
};

export default TermsAndConditionModal;
