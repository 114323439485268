import { useEffect, useRef, useState } from "react";
import { FieldArray } from "formik";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Switch,
  Text,
  Image,
  RadioGroup,
  Radio,
  Link,
  Divider,
  Collapse,
} from "@chakra-ui/react";

import {
  Colors,
  DiscountCodesSampleFileURL,
  getCommissionDistribution,
  PurchaseOrderSampleFileURL,
  Strings,
} from "@be-tagged/shared";
import {
  IconDoubleArrowUp,
  IconDoubleArrowDown,
} from "@be-tagged/shared/src/assets/icons";
import { FormikKeys } from "@be-tagged/shared/src/enums";

import { ErrorMsg, MaterialStyleInput, Label } from "src/app/components";
import { SUPPORTED_FILES } from "src/dashboard-module/interfaces";
import { DragDropComponent, RefreshedImage } from "./HelperComponents";
import WhatIsThisModal from "src/dashboard-module/common/WhatIsThisModal";
import { useDispatch } from "react-redux";
import useSetPageTitle from "src/app/hooks/useSetPageTitle";
import MobileInput from "src/account-module/common/MobileInput";
import { IconDeleteGreen } from "@be-tagged/shared/src/assets/icons";
import { CustomBtn } from "src/app/components";

const {
  CurrencyCode,
  IsApprovalBased,
  ProductPrice,
  CommissionPercent,
  BuyerDiscountPercent,
  DiscountCodesFileURL,
  PurchaseOrderFileUrl,
  CompanyProductURL,
  ECommercePlatforms,
  CurrentlyChecked,
  InCommMall,
  DeliveredByYou,
  DeliveredByInCommTeam,
  DeliveryOption,
  Dimension,
  Weight,
  InventoryLocation,
  ZipCode,
  ContactPersonNumber,
  ContactPersonCountryCode,
} = FormikKeys;

/**
 * Step 3 of Product Listing requires the user to give business-related inputs
 * @param {Object} - Formik Props like values, errors, etc
 * @returns {JSX.Element} - A React Functional Component
 */
const StepThree = ({
  values,
  touched,
  errors,
  setFieldValue,
  setTouched,
  setFieldTouched,
  handleChange,
  handleBlur,
  edit,
  getCommissionDist,
  commissionPercentageDistribution,
  setBuyerDiscountValue,
  setCurrentlyCheckedValue,
  ...couponProps
}: any) => {
  const prodURLInputRef = useRef<HTMLInputElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalBodyText, setModalBodyText] = useState("");
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  const [purchaseOrderfile, setPurchaseOrderFile] = useState<any>([]);

  const { couponFile, setCouponFile } = couponProps;

  const onCouponFileChange = (urlArray: string[]) => {
    setFieldValue(DiscountCodesFileURL, urlArray[0] || "");
  };

  useEffect(() => {
    setTouched({});
    //eslint-disable-next-line
  }, []);

  const onSocialMediaInputChange = (val: any) => {
    setCurrentlyCheckedValue(val);
    setFieldValue(CurrentlyChecked, val);
  };

  const ondeliveryOptionChange = (val: any) => {
    setFieldValue(DeliveryOption, val);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    edit &&
      dispatch(
        getCommissionDistribution({
          commissionPercentage: Number(values[CommissionPercent]),
        })
      );
  }, []);

  const ignoreScrollChange = (e: any) => e?.target?.blur();

  useSetPageTitle("Product Listing - Business");

  const checkDisabled = () => {
    if (edit || values[BuyerDiscountPercent] < 1) {
      if (values[CurrentlyChecked] !== InCommMall) {
        setBuyerDiscountValue(false);
        setFieldValue(CurrentlyChecked, InCommMall);
      }
      return true;
    }
    return false;
  };
  const DeliveryOptionInput = () => {
    const isDeliveryDetails = values[DeliveryOption] === DeliveredByInCommTeam;
    return (
      <Flex className="delivey_container" mt={"20px"} mb={"10px"}>
        <Flex justify={"flex-start"}>
          <Box style={Styles.headingBadge}>{Strings.productDetails}</Box>
        </Flex>
        <FieldArray
          name="variantDetails"
          render={(arrayHelpers) => (
            <div>
              {values[FormikKeys.VariantDetails]?.map(
                (variantValue: any, index: any) => (
                  <Flex key={index} className="image_input_container">
                    <Flex className="input_container">
                      <Box id="size" w={"100px"}>
                        <MaterialStyleInput
                          parentProps={{ className: "buyer_discount" }}
                          value={values[`variantDetails[${index}].size`]}
                          onChange={handleChange(
                            `variantDetails[${index}].size`
                          )}
                          onBlur={handleBlur(`variantDetails[${index}].size`)}
                          onWheel={ignoreScrollChange}
                          label={Strings.size}
                          disabled={edit}
                        />
                        {touched[FormikKeys.VariantDetails]?.[index]?.size &&
                          errors[FormikKeys.VariantDetails]?.[index]?.size && (
                            <Box pos={"relative"}>
                              <ErrorMsg style={{ top: "0px", width: "100px" }}>
                                {errors[FormikKeys.VariantDetails][index].size}
                              </ErrorMsg>
                            </Box>
                          )}
                      </Box>
                      <Box id="color" w={"100px"}>
                        <MaterialStyleInput
                          parentProps={{ className: "buyer_discount" }}
                          value={values[`variantDetails[${index}].color`]}
                          onChange={handleChange(
                            `variantDetails[${index}].color`
                          )}
                          onBlur={handleBlur(`variantDetails[${index}].color`)}
                          onWheel={ignoreScrollChange}
                          label={Strings.color}
                          disabled={edit}
                        />
                        {touched[FormikKeys.VariantDetails]?.[index]?.color &&
                          errors[FormikKeys.VariantDetails]?.[index]?.color && (
                            <Box pos={"relative"}>
                              <ErrorMsg style={{ top: "0px" }}>
                                {errors[FormikKeys.VariantDetails][index].color}
                              </ErrorMsg>
                            </Box>
                          )}
                      </Box>
                      <Box id="order_units" w={"90px"}>
                        <MaterialStyleInput
                          parentProps={{ className: "buyer_discount" }}
                          value={values[`variantDetails[${index}].orderUnits`]}
                          onChange={handleChange(
                            `variantDetails[${index}].orderUnits`
                          )}
                          onBlur={handleBlur(
                            `variantDetails[${index}].orderUnits`
                          )}
                          type="number"
                          onWheel={ignoreScrollChange}
                          label={Strings.orderUnits}
                          disabled={edit}
                        />
                        {touched[FormikKeys.VariantDetails]?.[index]
                          ?.orderUnits &&
                          errors[FormikKeys.VariantDetails]?.[index]
                            ?.orderUnits && (
                            <Box pos={"relative"}>
                              <ErrorMsg style={{ top: "0px" }}>
                                {
                                  errors[FormikKeys.VariantDetails][index]
                                    .orderUnits
                                }
                              </ErrorMsg>
                            </Box>
                          )}
                      </Box>
                    </Flex>
                    <Flex className="image_container">
                      {values[FormikKeys.VariantDetails][
                        index
                      ]?.productImageUrls?.map(
                        (productimage: string, imgIndex: number) => {
                          if (imgIndex < 1) {
                            if (productimage) {
                              return (
                                <RefreshedImage
                                  imageUrl={productimage}
                                  removeImage={() =>
                                    setFieldValue(
                                      `variantDetails[${index}].productImageUrls[${imgIndex}]`,
                                      ""
                                    )
                                  }
                                />
                              );
                            } else {
                              return (
                                <>
                                  <DragDropComponent
                                    accept={SUPPORTED_FILES.Images}
                                    files={[]}
                                    setFiles={() => {}}
                                    onChange={(urlArray: string[]) => {
                                      setFieldValue(
                                        `variantDetails[${index}].productImageUrls[${imgIndex}]`,
                                        urlArray[0] || ""
                                      );
                                    }}
                                    onlyChildren={true}
                                  >
                                    <>
                                      <Text
                                        as="span"
                                        fontSize={"12px"}
                                        lineHeight={1}
                                        fontWeight={600}
                                        color={Colors.purple}
                                      >
                                        {Strings.img}
                                      </Text>
                                      {touched[FormikKeys.VariantDetails]?.[
                                        index
                                      ]?.productImageUrls &&
                                        errors[FormikKeys.VariantDetails]?.[
                                          index
                                        ]?.productImageUrls && (
                                          <Box pos={"relative"}>
                                            <ErrorMsg
                                              style={{
                                                top: "0px",
                                                width: "100px",
                                              }}
                                            >
                                              {
                                                errors[
                                                  FormikKeys.VariantDetails
                                                ][index].productImageUrls
                                              }
                                            </ErrorMsg>
                                          </Box>
                                        )}
                                    </>
                                  </DragDropComponent>
                                </>
                              );
                            }
                          }
                        }
                      )}
                      <Flex style={{ cursor: "pointer" }}>
                        <Image
                          src={IconDeleteGreen}
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                )
              )}
              <Flex justify={"flex-start"} mt={"30px"} mb={"20px"}>
                <Text
                  as="span"
                  cursor={"pointer"}
                  fontSize={"12px"}
                  lineHeight={1}
                  fontWeight={600}
                  color={Colors.purple}
                  onClick={() =>
                    arrayHelpers.push({
                      size: "",
                      color: "",
                      orderUnits: "",
                      productImageUrls: [""],
                    })
                  }
                >
                  {Strings.increaseSize}
                </Text>
              </Flex>
            </div>
          )}
        />
        {isDeliveryDetails && (
          <>
            <Flex justify={"flex-start"} mt={"20px"}>
              <Box style={Styles.headingBadge}>{Strings.deliveryDetails}</Box>
            </Flex>
            <Flex className="delivery_details_input">
              <Box id="dimension" w={"140px"}>
                <MaterialStyleInput
                  parentProps={{ className: "buyer_discount" }}
                  value={values[Dimension]}
                  onChange={handleChange(Dimension)}
                  onBlur={handleBlur(Dimension)}
                  onWheel={ignoreScrollChange}
                  label={Strings.lwh}
                  bottomLabel={Strings.inches}
                  disabled={edit}
                />
                {touched[Dimension] && errors[Dimension] && (
                  <Box pos={"relative"}>
                    <ErrorMsg style={{ top: "0px" }}>
                      {errors[Dimension]}
                    </ErrorMsg>
                  </Box>
                )}
              </Box>
              <Box id="weight" w={"100px"}>
                <MaterialStyleInput
                  parentProps={{ className: "buyer_discount" }}
                  value={values[Weight]}
                  onChange={handleChange(Weight)}
                  onBlur={handleBlur(Weight)}
                  type="number"
                  onWheel={ignoreScrollChange}
                  label={Strings.weight}
                  bottomLabel={Strings.gms}
                  disabled={edit}
                />
                {touched[Weight] && errors[Weight] && (
                  <Box pos={"relative"}>
                    <ErrorMsg style={{ top: "0px" }}>{errors[Weight]}</ErrorMsg>
                  </Box>
                )}
              </Box>
              <Box id="inventory_location" w={"210px"}>
                <MaterialStyleInput
                  parentProps={{ className: "buyer_discount" }}
                  value={values[InventoryLocation]}
                  onChange={handleChange(InventoryLocation)}
                  onBlur={handleBlur(InventoryLocation)}
                  onWheel={ignoreScrollChange}
                  label={Strings.inventoryLocation}
                  disabled={edit}
                />
                {touched[InventoryLocation] && errors[InventoryLocation] && (
                  <Box pos={"relative"}>
                    <ErrorMsg style={{ top: "0px" }}>
                      {errors[InventoryLocation]}
                    </ErrorMsg>
                  </Box>
                )}
              </Box>
            </Flex>
            <Flex
              className="delivery_details_input direction_column"
              mt={"5px"}
            >
              <Box id="zip_code" w={"100px"}>
                <MaterialStyleInput
                  parentProps={{ className: "buyer_discount" }}
                  value={values[ZipCode]}
                  onChange={handleChange(ZipCode)}
                  onBlur={handleBlur(ZipCode)}
                  type="number"
                  onWheel={ignoreScrollChange}
                  label={Strings.zipCode}
                  disabled={edit}
                />
                {touched[ZipCode] && errors[ZipCode] && (
                  <Box pos={"relative"}>
                    <ErrorMsg style={{ top: "0px" }}>
                      {errors[ZipCode]}
                    </ErrorMsg>
                  </Box>
                )}
              </Box>
              <Box id="contact_person_number" w={"100px"}>
                <MobileInput
                  countryCode={values[ContactPersonCountryCode]}
                  handleCountryCodeChange={(countryCode: any) =>
                    setFieldValue(ContactPersonCountryCode, countryCode)
                  }
                  phone={values[ContactPersonNumber]}
                  label={Strings.contactPersonNumber}
                  name={ContactPersonNumber}
                  handlePhoneChange={handleChange(ContactPersonNumber)}
                  setFieldTouched={setFieldTouched}
                  makeResponsive={true}
                />
                {touched[ContactPersonNumber] && errors[ContactPersonNumber] && (
                  <Box pos={"relative"}>
                    <ErrorMsg style={{ top: "0px", width: "150px" }}>
                      {errors[ContactPersonNumber]}
                    </ErrorMsg>
                  </Box>
                )}
              </Box>
            </Flex>
          </>
        )}
      </Flex>
    );
  };

  const ProductURLInput = () => {
    const currentlyChecked = values[CurrentlyChecked];
    // Since we're dealing with two values for radio, and since we're defaulting the value of currently checked with company product URL, placeholder text will be correct (and won't show a wrong when a radio is not selected, since at any given time at least Three radio will always be selected)
    if (currentlyChecked === InCommMall) {
      return (
        <Flex className="incomm_mall_container">
          <Flex
            className="radio_options"
            as={RadioGroup}
            onChange={ondeliveryOptionChange}
            value={values[DeliveryOption]}
            defaultValue={DeliveredByInCommTeam}
          >
            <Flex className="options_and_input">
              <Flex className="options">
                <Radio
                  borderColor={Colors.purple}
                  colorScheme={"pink"}
                  className="label"
                  value={DeliveredByInCommTeam}
                  isDisabled={edit}
                >
                  <Box style={Styles.checkboxStyling} as="span">
                    {Strings.deliveredByIncomm}
                  </Box>
                </Radio>

                <Radio
                  borderColor={Colors.purple}
                  colorScheme={"pink"}
                  className="label"
                  value={DeliveredByYou}
                  isDisabled={edit}
                >
                  <Box
                    style={Styles.checkboxStyling}
                    as="span"
                    fontSize={"12px"}
                  >
                    {Strings.deliveredByYou}
                  </Box>
                </Radio>
              </Flex>
              {!edit && DeliveryOptionInput()}
            </Flex>
          </Flex>
        </Flex>
      );
    } else {
      const isCompany = currentlyChecked === CompanyProductURL;
      const placeholderText =
        Strings.eCommercePlatforms +
        " on " +
        (isCompany ? Strings.companyWebsite : Strings.eCommercePlatforms);

      return (
        <Box
          w={"100%"}
          pos={"relative"}
          pl={{
            custom: "30px",
          }}
        >
          <MaterialStyleInput
            ref={prodURLInputRef}
            parentProps={{
              className: "social_media_input",
            }}
            type="url"
            label={`${placeholderText}*`}
            onChange={handleChange(currentlyChecked)}
            onBlur={handleBlur(currentlyChecked)}
            value={values[currentlyChecked] || ""}
            disabled={edit}
          />
          {touched[currentlyChecked] && errors[currentlyChecked] && (
            <ErrorMsg bottom={-15}>{errors[currentlyChecked]}</ErrorMsg>
          )}
          {!isCompany && (
            <Flex maxW={"380px"}>
              <Label style={{ lineHeight: "1.5em", marginTop: 5 }}>
                {Strings.ecommerceLimitedTracking}
              </Label>
            </Flex>
          )}
        </Box>
      );
    }
  };

  return (
    <Box className="business_details_container">
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        className="step_three_container"
      >
        <Box>
          {/* Currency, etc. Section */}
          <Flex className="currency_tip_container">
            <Flex className="currency_discount_container">
              {/* Currency and Product Price */}
              <Flex id="currency_product_price">
                {/* Currency */}
                <Box id="currency_code">
                  <MaterialStyleInput
                    label={Strings.currency}
                    disabled
                    value={values[CurrencyCode]}
                  />
                </Box>
                {/* Product Price */}
                <Box id="product_price" pl={1}>
                  <MaterialStyleInput
                    parentProps={{
                      className: "input_parent",
                    }}
                    type="number"
                    onWheel={ignoreScrollChange}
                    min={1}
                    label={Strings.productPrice}
                    value={values[ProductPrice]}
                    onChange={handleChange(ProductPrice)}
                    onBlur={handleBlur(ProductPrice)}
                    disabled={edit}
                  />
                </Box>
                <ErrorMsg style={Styles.currencyError}>
                  {touched[CurrencyCode] &&
                    errors[CurrencyCode] &&
                    errors[CurrencyCode]}
                  {touched[ProductPrice] && errors[ProductPrice] && (
                    <div>{errors[ProductPrice]}</div>
                  )}
                </ErrorMsg>
              </Flex>
              {/* Commission Percent */}
              <Box id="commission_percent" w={"200px"} pos={"relative"}>
                <MaterialStyleInput
                  type="number"
                  onWheel={ignoreScrollChange}
                  max={100}
                  min={1}
                  parentProps={{ className: "commission_percent" }}
                  label={Strings.commissionPercentage}
                  value={values[CommissionPercent]}
                  onChange={handleChange(CommissionPercent)}
                  onBlur={() => {
                    handleBlur(CommissionPercent);
                    getCommissionDist(values[CommissionPercent]);
                  }}
                  disabled={edit}
                />
                {touched[CommissionPercent] && errors[CommissionPercent] && (
                  <Box pos={"relative"}>
                    <ErrorMsg style={{ top: "0px" }}>
                      {errors[CommissionPercent]}
                    </ErrorMsg>
                  </Box>
                )}
                <Flex
                  top={
                    values[CommissionPercent] !== "" &&
                    errors[CommissionPercent]
                      ? "25px"
                      : "10px"
                  }
                  fontSize={"12px"}
                  color={Colors.grey100}
                  position={"relative"}
                >
                  <Box color={Colors.blackColor}>
                    {
                      commissionPercentageDistribution?.influencerCommissionPercentage
                    }
                    % {Strings.influencerCommission}
                  </Box>
                  <Divider
                    orientation="vertical"
                    color={Colors.blackColor}
                    height={"inherit"}
                    borderLeftWidth={"2px"}
                    borderRightWidth={"0px"}
                    marginRight={"10px"}
                  />
                  <Box color={Colors.blackColor}>
                    {
                      commissionPercentageDistribution?.platformCommissionPercentage
                    }
                    % {Strings.platformCharges}
                  </Box>
                </Flex>
              </Box>
              {/* Buyer Discount */}
              <Flex id="buyer_discount_container" wrap={"wrap"} gap={"10px"}>
                <Box id="buyer_discount" w={"200px"} pos={"relative"}>
                  <MaterialStyleInput
                    parentProps={{ className: "buyer_discount" }}
                    max={100}
                    min={0}
                    value={values[BuyerDiscountPercent]}
                    onChange={(e: any) => {
                      setBuyerDiscountValue(e.target.value > 0);
                      handleChange(BuyerDiscountPercent)(e);
                    }}
                    onBlur={handleBlur(BuyerDiscountPercent)}
                    type="number"
                    onWheel={ignoreScrollChange}
                    label={Strings.buyerDiscount}
                    disabled={edit}
                  />
                  {touched[BuyerDiscountPercent] &&
                    errors[BuyerDiscountPercent] && (
                      <Box pos={"relative"}>
                        <ErrorMsg style={{ top: "0px" }}>
                          {errors[BuyerDiscountPercent]}
                        </ErrorMsg>
                      </Box>
                    )}
                  <Box className="bottom_caption">{Strings.toKnowMore}</Box>
                </Box>
              </Flex>
            </Flex>
          </Flex>

          {/* Open Offer / Approval Based Offer */}
          <Flex className="offer_type_container">
            <FormControl className="switch_container">
              <FormLabel fontWeight={"normal"} htmlFor="offer_type" mb="0">
                {Strings.open}
              </FormLabel>

              <Switch
                onChange={() =>
                  setFieldValue(IsApprovalBased, !values[IsApprovalBased])
                }
                isChecked={values[IsApprovalBased]}
                colorScheme={"green"}
                size={"md"}
                id="offer_type"
                className="colored_switch to_green"
              />
              <FormLabel
                fontWeight={"normal"}
                htmlFor="offer_type"
                mb="0"
                ml="3"
              >
                {Strings.approvalBased}
              </FormLabel>
            </FormControl>

            <Box
              color={Colors.purple}
              className="helper_text"
              cursor={"pointer"}
              onClick={() => {
                setOpenModal(true);
                setModalBodyText(Strings.openOrApprovalBased);
              }}
            >
              {Strings.whatIsThis}
            </Box>
          </Flex>

          {/* Product URL section, a.k.a "Where are you selling this product" */}
          <Flex className="discount_urls_container">
            <Box className="discount_coupons_container">
              <Text className="bold">{Strings.discountCouponCodes} (xlsx)</Text>

              <Box pos={"relative"}>
                <DragDropComponent
                  accept={SUPPORTED_FILES.ExcelFile}
                  maxFiles={1}
                  files={couponFile}
                  setFiles={setCouponFile}
                  onChange={onCouponFileChange}
                  disabled={checkDisabled()}
                />
              </Box>
              {touched[DiscountCodesFileURL] &&
                errors[DiscountCodesFileURL] && (
                  <ErrorMsg style={{ position: "static" }}>
                    {errors[DiscountCodesFileURL]}
                  </ErrorMsg>
                )}
            </Box>
            <Link
              width={"fit-content"}
              href={DiscountCodesSampleFileURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box style={Styles.helperText}>{Strings.sampleFile}</Box>
            </Link>
            <Flex className="purchase_order_container">
              <Text className="bold">{Strings.purchaseOrder} (pdf)*</Text>
              <Box as="span" fontSize={"16px"} mt={"8px"}>
                {Strings.purchaseOrderDescPre}
                <Link
                  href={PurchaseOrderSampleFileURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  color={Colors.purple}
                  fontSize={"16px"}
                  fontWeight={"600"}
                  boxShadow="none !important"
                >
                  <Text as={"span"}>{Strings.here}</Text>
                </Link>
                {Strings.purchaseOrderDescPost}
              </Box>
              <Flex className="file_upload_main_container">
                <DragDropComponent
                  accept={SUPPORTED_FILES.PDF}
                  maxFiles={1}
                  setFiles={setPurchaseOrderFile}
                  onChange={(urlArray: string[]) => {
                    setFieldValue(PurchaseOrderFileUrl, urlArray[0] || "");
                  }}
                  onlyChildren={true}
                >
                  <Flex className="file_upload_container">
                    <Box
                      ml={"4px"}
                      as="span"
                      fontSize={"14px"}
                      style={{ color: "#666666", fontWeight: 400 }}
                    >
                      {purchaseOrderfile?.[0]?.name
                        ? purchaseOrderfile?.[0]?.name
                        : "No File Selected"}
                    </Box>
                    <CustomBtn
                      className="purple_btn small_size inv"
                      onClick={(e: any) => {
                        e.preventDefault();
                      }}
                    >
                      {Strings.browseFileCaps}
                    </CustomBtn>
                  </Flex>
                </DragDropComponent>
              </Flex>
              <Box className="bottom_caption">{Strings.toKnowMore}</Box>
            </Flex>
            <Box className="product_urls_container">
              <Text className="bold">{Strings.sellingProductPlatformText}</Text>
              {touched[FormikKeys.CurrentlyChecked] && (
                <Box pos={"relative"}>
                  <ErrorMsg>{errors[FormikKeys.CurrentlyChecked]}</ErrorMsg>
                </Box>
              )}

              <Flex
                className="radio_options"
                as={RadioGroup}
                onChange={onSocialMediaInputChange}
                value={values[CurrentlyChecked]}
                defaultValue={CompanyProductURL}
              >
                <Flex className="options_and_input">
                  <Flex className="options">
                    <Radio
                      borderColor={Colors.purple}
                      colorScheme={"pink"}
                      className="label"
                      value={InCommMall}
                      isDisabled={edit}
                    >
                      <Box
                        style={Styles.checkboxStyling}
                        as="span"
                        fontSize={"12px"}
                      >
                        {Strings.inCommMall}
                      </Box>
                    </Radio>
                    <Radio
                      borderColor={Colors.purple}
                      colorScheme={"pink"}
                      className="label"
                      value={CompanyProductURL}
                      isDisabled={checkDisabled()}
                    >
                      <Box style={Styles.checkboxStyling} as="span">
                        {Strings.companyWebsite}
                      </Box>
                    </Radio>
                    <Radio
                      borderColor={Colors.purple}
                      colorScheme={"pink"}
                      className="label"
                      value={ECommercePlatforms}
                      isDisabled={checkDisabled()}
                    >
                      <Box
                        style={Styles.checkboxStyling}
                        as="span"
                        fontSize={"12px"}
                      >
                        {Strings.eCommercePlatforms}
                      </Box>
                    </Radio>
                    <Box
                      color={Colors.purple}
                      className="helper_text"
                      cursor={"pointer"}
                      onClick={() => {
                        setOpenModal(true);
                        setModalBodyText(Strings.sellingProductPlatformText);
                      }}
                    >
                      {Strings.whatIsThis}
                    </Box>
                  </Flex>
                  {ProductURLInput()}
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Box>
        <Flex flexDirection={"column"} className="right_container">
          <Flex
            onClick={() => setIsCollapseOpen(!isCollapseOpen)}
            style={{
              width: "350px",
              height: "42px",
              border: "1px solid #D3E0FC",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              padding: "10px",
              boxShadow: "0px 3px 30px rgba(153, 186, 208, 0.3)",
            }}
            className="collapse_item"
          >
            <Text alignSelf={"center"}>Instructions</Text>
            <Image
              style={{ position: "absolute", right: 10 }}
              onClick={() => setIsCollapseOpen(true)}
              cursor={"pointer"}
              src={isCollapseOpen ? IconDoubleArrowUp : IconDoubleArrowDown}
            />
          </Flex>
          <Collapse in={isCollapseOpen} animateOpacity>
            <Box
              style={{
                color: Colors.grey,
                height: "429px",
                width: "350px",
                border: "1px solid #D3E0FC",
                boxShadow: "0px 3px 30px rgba(153, 186, 208, 0.3)",
                overflow: "scroll",
                padding: "10px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "150%",
                marginTop: "0px",
              }}
              className="collapse_item"
            >
              <p>{Strings.stepThreeParaOne}</p>
              <br />
              <p>{Strings.stepThreeParaTwo}</p>
              <br />
              <p>{Strings.stepThreeParaThree}</p>
              <br />
              <p>{Strings.stepThreeParaFour}</p>
              <br />
              <p>{Strings.stepThreeParaFive}</p>
            </Box>
          </Collapse>
        </Flex>
      </Flex>

      <WhatIsThisModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        modalHeader={modalBodyText}
        bodyText={
          modalBodyText === Strings.openOrApprovalBased
            ? Strings.approvalBasedOfferInfoText
            : ""
        }
      />
    </Box>
  );
};

const Styles = {
  checkboxStyling: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "1",
  },
  helperText: {
    color: Colors.purple,
    fontWeight: 500,
    fontSize: 12,
  },
  currencyError: {
    height: 28,
    width: "100%",
    bottom: -25,
  },
  headingBadge: {
    backgroundColor: "#d3e0fc",
    fontWeight: 400,
    fontSize: "10px",
    padding: "3px 10px",
    borderRadius: "6px",
    color: "#666666",
  },
};

export default StepThree;
