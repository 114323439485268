import { FC } from "react";
import useSetPageTitle from "src/app/hooks/useSetPageTitle";
import EmailInput from "./EmailInput";
/**
 * @param {Object}
 * @returns {JSX.Element}
 */
const ForgotPassword: FC<{
  txtBody: any;
  isBrand: boolean;
}> = ({ isBrand }): JSX.Element => {
  useSetPageTitle("Forgot Password");
  return <EmailInput isBrand={isBrand} />;
};

export default ForgotPassword;
