import { ArrowBtn } from "../";
import { FC } from "react";

interface StepArrowProps {
  prevDisabled: boolean;
  nextDisabled?: boolean;
  prevBtnClick: any;
  isSubmitType?: boolean;
}

const StepArrows: FC<StepArrowProps> = ({
  prevDisabled,
  nextDisabled,
  isSubmitType = true,
  prevBtnClick,
}) => {
  return (
    <div className="step_arrows">
      <ArrowBtn
        type="button"
        disabled={prevDisabled}
        className="arrow left"
        onClick={prevBtnClick}
      />
      <ArrowBtn
        type={isSubmitType ? "submit" : "button"}
        className="arrow right"
        disabled={nextDisabled}
      />
    </div>
  );
};

StepArrows.defaultProps = {
  isSubmitType: true,
};

export default StepArrows;
