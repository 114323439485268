import {
  getCommissionPayout,
  Colors,
  RootState,
  Strings,
  clearCommissionPayout,
} from "@be-tagged/shared";
import {
  Box,
  Center,
  Flex,
  SimpleGrid,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { getFormattedLocalFromUTC } from "src/app/utils/DateUtils";
import useData from "src/app/hooks/useData";
import { useDispatch, useSelector } from "react-redux";
import { CommonObjectType } from "src/app/constants";
import {
  CommissionPayoutParams,
  FormikKeys,
} from "@be-tagged/shared/src/enums";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "src/app/components/LoadingSpinner";
import { NoDataFound, SadFace } from "@be-tagged/shared/src/assets/icons";
import InsightsSkeleton from "../SkeletonLayout";
import { withCommas } from "src/app/utils";

const { StartFrom, ListQty } = FormikKeys;

interface ITabCommissionPayout {
  dateParams: any;
}

const PER_PAGE = 10;

/**
 * @param {Object} props - Of the form ITabCommissionPayout
 * @returns {JSX.Element} - A React Functional Component
 */
const TabCommissionPayout: FC<ITabCommissionPayout> = ({
  dateParams,
}): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMounted, setHasMounted] = useState(false);
  const [isScrollLoading, setIsScrollLoading] = useState(false);

  const { loading } = useData();

  const dispatch = useDispatch();

  const commissionPayout = useSelector((state: RootState) => {
    return state.influencerReducer?.insights?.commissionPayout || {};
  });

  const getCommissionPayoutData = (par: any, viaScroll: boolean = false) => {
    let params: CommonObjectType = {
      ...par,
      ...dateParams,
    };

    let finalPayload = {
      ...params,
      ...dateParams,
    } as CommissionPayoutParams;

    dispatch(
      getCommissionPayout(
        finalPayload,
        viaScroll ? setIsScrollLoading : undefined
      )
    );
  };

  const fetchCommissionPayout = (viaScroll: boolean = false) => {
    getCommissionPayoutData(
      {
        [StartFrom]: currentPage * PER_PAGE - PER_PAGE,
        [ListQty]: PER_PAGE,
      },
      viaScroll
    );
  };

  useEffect(() => {
    fetchCommissionPayout(currentPage !== 1);
  }, [currentPage]);

  useEffect(() => {
    dispatch(clearCommissionPayout());
    if (hasMounted) fetchCommissionPayout();
    else setHasMounted(true);
  }, [dateParams]);

  return (
    <Stack>
      <Flex direction={"column"} minHeight={{ md: "500" }}>
        {commissionPayout?.data?.length === 0 ? (
          <Center>
            <Flex direction="column" alignItems="center">
              <img src={NoDataFound} alt="NoDataFound" />
              <Box fontSize="14px">{Strings.noDataFound}</Box>
            </Flex>
          </Center>
        ) : loading && !isScrollLoading ? (
          <InsightsSkeleton commissionPayout={true} />
        ) : (
          <InfiniteScroll
            className="insights_scroll_parent"
            dataLength={commissionPayout?.data?.length || 0}
            next={() => {
              setCurrentPage(currentPage + 1);
            }}
            hasMore={commissionPayout?.nextLink}
            loader={<LoadingSpinner isLoading={isScrollLoading} />}
          >
            {commissionPayout?.data?.map((el: any, index: number) => {
              return (
                <div key={index}>
                  <SimpleGrid
                    mb={5}
                    bgColor="white"
                    shadow="md"
                    p={4}
                    borderRadius={10}
                    templateColumns={{
                      md: "1fr 1fr 1fr 1fr 0.5fr",
                      base: "repeat(1, 1fr)",
                    }}
                    rowGap={2}
                    flexWrap={"wrap"}
                  >
                    <Box fontSize="14px">
                      {getFormattedLocalFromUTC(el.transactionForMonth)}
                    </Box>
                    <Flex fontSize="14px">
                      <Box>{Strings.commission} :&nbsp;</Box>
                      <Box color={Colors.grey} fontWeight="600">
                        {el.currencySymbol}&nbsp;
                        {withCommas(el.commission)}
                      </Box>
                    </Flex>
                    <Flex fontSize="14px">
                      <Box>{Strings.paidOn} :&nbsp;</Box>
                      <Box color={Colors.grey} fontWeight="600">
                        {el.paidOnUtc === null
                          ? "NA"
                          : getFormattedLocalFromUTC(el.paidOnUtc)}
                      </Box>
                    </Flex>
                    <Flex fontSize="14px">
                      <Box>{Strings.transactionId}&nbsp;</Box>
                      <Box color={Colors.grey} fontWeight="600">
                        {el.transactionId === null ? "NA" : el.transactionId}
                      </Box>
                    </Flex>
                    {el.commission === 0 ? (
                      <Flex fontSize="14px">
                        <Box>
                          <img src={SadFace} />
                        </Box>
                        <Box color={Colors.grey} fontWeight="600">
                          No sale
                        </Box>
                      </Flex>
                    ) : (
                      <></>
                    )}
                  </SimpleGrid>
                </div>
              );
            })}
          </InfiniteScroll>
        )}
      </Flex>
    </Stack>
  );
};

export default TabCommissionPayout;
