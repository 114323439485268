import { createStore, applyMiddleware, compose } from "redux";
import { rootReducer } from "@be-tagged/shared";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { logger } from "redux-logger";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { CommonObjectType } from "./app/constants";

const blackListTransform = createTransform(
  null,
  (state: CommonObjectType, key) => {
    const newState = { ...state };
    newState.productData = {};
    return newState;
  },
  { whitelist: ["brandReducer"] }
);

export const config = {
  key: "root",
  storage: storage,
  transforms: [blackListTransform],
  whitelist: ["brandReducer", "userReducer"],
};
const persisted = persistReducer(config, rootReducer);

const middlewares = [thunk as ThunkMiddleware];
const isDevMode = process.env.NODE_ENV === `development`;
if (isDevMode) {
  middlewares.push(logger);
}
// Create a store out of this configuration
const store = createStore(
  persisted,
  compose(
    applyMiddleware(...middlewares),
    isDevMode && (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      : (f: any) => f
  )
);

const persistor = persistStore(store);
export { persistor, store };
