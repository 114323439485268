import { getDashboardData, RootState } from "@be-tagged/shared";
import { Box, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonObjectType } from "src/app/constants";
import ProductsBox from "../../Common/ProductsBox";
import ProductsMatchingInterest from "../../Common/ProductsMatchingInterest";
import SocialMediaPerformance from "../../Common/SocialMediaPerformance";
import WelcomeText from "../../Common/WelcomeText";

interface InfluencerStandardProps {
  defaultDateFilter: CommonObjectType;
}

const InfluencerStandard: FC<InfluencerStandardProps> = ({
  defaultDateFilter,
}) => {
  const dispatch = useDispatch();

  const [timeRange, setTimeRange] = useState<CommonObjectType | null>(
    defaultDateFilter
  );
  const [hasMounted, setHasMounted] = useState(false);

  const { dashboardData } = useSelector((state: RootState) => {
    return {
      countries: state.brandReducer?.countries || [],
      dashboardData: state.commonReducer.commonData?.dashboardData || {},
    };
  });

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (hasMounted) {
      const param = { ...(timeRange?.value || {}) };
      dispatch(getDashboardData(param, false));
    }
  }, [timeRange]);

  return (
    <Box className="landing_main">
      {/* Welcome Section */}
      <Box className="welcome_text bold with_data">
        <Flex alignItems={"center"} gap={"20px"}>
          <WelcomeText />
        </Flex>
      </Box>
      {/* Products Section */}
      <ProductsBox isBrand={false} />
      {/* Social Media Performance */}
      <SocialMediaPerformance
        smData={dashboardData?.socialMediaPerformance}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />
      {/* Products Matching Interest */}
      <Box mt="50px">
        <ProductsMatchingInterest />
      </Box>
    </Box>
  );
};

export default InfluencerStandard;
