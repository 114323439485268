import { TimeRangeFilter } from "src/app/components";
import {
  Box,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  Image,
  Text,
} from "@chakra-ui/react";
import { Colors, logger, LoggerStrings, Strings } from "@be-tagged/shared";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  TikTokIcon,
} from "@be-tagged/shared/src/assets/icons";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import { CommonObjectType } from "src/app/constants";
import { FC, useEffect } from "react";
import useData from "src/app/hooks/useData";
import { withCommas } from "src/app/utils";
import useAuth from "src/app/hooks/useAuth";

interface SMPerformanceProps {
  smData: CommonObjectType | null;
  timeRange: CommonObjectType | null;
  setTimeRange: (x: any | null) => any;
}

const { Facebook, TikTok, Instagram, Twitter } = FormikKeys;
const imgMapping: CommonObjectType = {
  [Facebook]: FacebookIcon,
  [Instagram]: InstagramIcon,
  [Twitter]: TwitterIcon,
  [TikTok]: TikTokIcon,
};

const SocialMediaPerformance: FC<SMPerformanceProps> = ({
  timeRange,
  setTimeRange,
}) => {
  const { dashboardData } = useData();
  const { socialMediaPerformance = {} } = dashboardData;
  const socialMediaKeys = Object.keys(socialMediaPerformance);
  const { isBrand } = useAuth();
  useEffect(() => {
    if (timeRange) {
      const user = isBrand ? Strings.brand : Strings.influencer;
      logger.info(
        `${user} ${LoggerStrings.changedDateFilter} ${timeRange.name}`
      );
    }
  }, [timeRange]);
  return (
    <Box id="social_media_performance">
      <Flex className="title_dropdown">
        <Text className="bold">{Strings.salesChannelDistribution}</Text>
        <Box className="dropdown">
          <TimeRangeFilter value={timeRange} onChange={setTimeRange} />
        </Box>
      </Flex>
      <Flex bgColor={Colors.themeColor} className="white_box_container">
        <Flex className="inner_container">
          {socialMediaKeys.map((smName: string, idx: number) => {
            return (
              <Flex key={idx} className="sale_clicks_container">
                <Image alt={smName} src={imgMapping[smName]} />
                <SalesClicks
                  label={Strings.saleClick}
                  value={`${withCommas(
                    socialMediaPerformance?.[smName]?.sales
                  )} / ${withCommas(socialMediaPerformance?.[smName]?.clicks)}`}
                />
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Box>
  );
};

const SalesClicks = ({
  label,
  value,
}: {
  label: string;
  value: number | string;
}) => {
  return (
    <Stat className="stat">
      <StatLabel color={Colors.grey100} className="label">
        {label}
      </StatLabel>
      <StatNumber fontSize={"18px"} className="value">
        {value}
      </StatNumber>
    </Stat>
  );
};

export default SocialMediaPerformance;
