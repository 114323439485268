import { Tooltip } from "@chakra-ui/react";
import { FC, forwardRef } from "react";

interface WrapperProps {
  label: string;
  children: JSX.Element;
  rest?: any;
}

const TooltipWrapper: FC<WrapperProps> = forwardRef(
  ({ children, label, ...rest }:WrapperProps, ref) => (
    <Tooltip label={label} {...rest}>
      <div>{children}</div>
    </Tooltip>
  )
);

TooltipWrapper.displayName = "TooltipWrapper";

export default TooltipWrapper;
