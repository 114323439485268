import {
  getBrandSignUpURL,
  getInfluencerSignUpURL,
  getUpdateContactDetailsURL,
} from "../../../utils/UrlBuilder";
import {
  SET_USER_INFO,
  UPDATE_LOADING_STATUS,
  SHOW_OTP_VERIFY_MODAL,
  SET_USER_AS_BRAND,
  UPDATE_USER_INFO,
} from "../../constants/ReduxConstants";
import { HTTPMethod } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import { setAccessToken } from "../../../config/AccessTokenHelper";
import { sanitizeUserObject } from "../../../utils/ObjectUtils";

export const userSignUp =
  (data: any, isBrand: boolean, callBackFn?: any) => async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      queryStringParams: {},
      data,
      url: isBrand ? getBrandSignUpURL() : getInfluencerSignUpURL(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [
        (payload: any) =>
          updateUserInfoAfterSignUp(payload, isBrand, callBackFn),
      ]
    );
  };

export const updateUserContactDetails =
  (data: any, isBrand: boolean) => async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      queryStringParams: {},
      data,
      url: getUpdateContactDetailsURL(),
    };
    return fetchAsync(dispatch, [request], [updateUserInfoAfterDetailsUpdate]);
  };

export const updateUserInfoAfterDetailsUpdate =
  (payload: any) => (dispatch: any) => {
    dispatch({
      type: UPDATE_USER_INFO,
      payload: payload.data,
    });
    if (payload.data) {
      dispatch({
        type: SHOW_OTP_VERIFY_MODAL,
        payload: true,
      });
    }
    return {
      type: UPDATE_LOADING_STATUS,
      payload: false,
    };
  };

export const updateUserInfoAfterSignUp =
  (payload: any, isBrand: boolean, callBackFn?: any) =>
  (dispatch: any, getState: any) => {
    const accessToken = payload?.data?.accessToken || "";
    setAccessToken(accessToken);
    callBackFn(payload.data);
    dispatch({
      type: SET_USER_INFO,
      payload: sanitizeUserObject(payload.data, isBrand),
    });
    dispatch({
      type: SET_USER_AS_BRAND,
      payload: isBrand,
    });
    if (payload.data) {
      dispatch({
        type: SHOW_OTP_VERIFY_MODAL,
        payload: true,
      });
    }
    return {
      type: UPDATE_LOADING_STATUS,
      payload: false,
    };
  };
