import { Strings } from "@be-tagged/shared";
import { Box, Flex, Spacer, Image } from "@chakra-ui/react";
import { InfoIcon } from "@be-tagged/shared/src/assets/icons";
import { FC, Fragment, useState } from "react";
import useData from "src/app/hooks/useData";
import SocialMediaCheckbox from "./SocialMediaCheckbox";
import WhatIsThisModal from "src/dashboard-module/common/WhatIsThisModal";

/**
 * A Component rendered for Influencers only
 * They will be able to make (or check the status of) requests for promoting a product on a given social media
 * If for a product a request has not been against a social media (e.g., Twitter), then an unchecked checkbox will be shown next to Twitter
 * The influencer can then make a request by checking it, post which the request will be forwarded to the brand to which the product belongs if it is an offer-based product
 * Else if the product is open for promotion, the user will instantly get a shortened URL for promotion.
 * For an approval-based product, if a request has been made, its possible statuses are: Pending, Approved and Rejected
 * Pending - No action has been taken by the Brand yet
 * Approved - The Brand has approved the influencer's attempt at promoting this product. A unique discount code will be generated for the influencer if the product is approval based.
 * Rejected - The Brand has rejected the influencer's attempt at promoting this product.
 * @returns {JSX.Element} - A React Functional Component
 */
const InfluencerSocialMedia: FC<{}> = (): JSX.Element => {
  const { productDetails } = useData();
  const [openModal, setOpenModal] = useState(false);
  const [doneShowingModal, setDoneShowingModal] = useState(false);
  const {
    promotionalInfo = {},
    isApprovalBasedOffer,
    brandProductId,
  } = productDetails || {};

  return (
    <Flex className="checkboxes-flex" direction={"column"}>
      <Flex flexDirection={"row"} alignItems={"center"}>
        <Box>{Strings.iWantToSell}</Box>
        <Box style={{ marginLeft: "20px" }}>
          <Image
            onClick={() => setOpenModal(true)}
            cursor={"pointer"}
            src={InfoIcon}
            width={"15px"}
          />
        </Box>
      </Flex>
      <Box>
        <Flex
          direction={{ base: "column", md: "row" }}
          wrap="wrap"
          pt={5}
          pb={10}
        >
          {Object.keys(promotionalInfo).map((item, index) => {
            return (
              <Fragment key={index}>
                <Box mb={{ base: "20px" }}>
                  <SocialMediaCheckbox
                    doneShowingModal={doneShowingModal}
                    setDoneShowingModal={setDoneShowingModal}
                    setOpenModal={setOpenModal}
                    brandProductId={brandProductId}
                    isApprovalBasedOffer={isApprovalBasedOffer}
                    name={item}
                    details={promotionalInfo[item]}
                    hasAllCodesExhausted={productDetails?.hasAllCodesExhausted}
                    productStatus={productDetails?.productStatus}
                    isRejectedDueToPeriodExpiration={
                      productDetails?.promotionalInfo[item]?.request
                        ?.isRejectedDueToPeriodExpiration
                    }
                  />
                </Box>
                <Spacer />
              </Fragment>
            );
          })}
        </Flex>
      </Box>
      <WhatIsThisModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        bodyText={""}
        modalHeader={Strings.linkGenerated}
        noImage
      />
    </Flex>
  );
};

export default InfluencerSocialMedia;
