import {
  getDashboardData,
  getProductsCatalog,
  logger,
  LoggerStrings,
  RootState,
  Strings,
} from "@be-tagged/shared";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardSkeleton from "../Common/DashboardSkeleton";
import InfluencerStandard from "./Standard";
import PlaceholderLanding from "../Common/PlaceholderLanding";
import { CommonObjectType, dateFiltersSinceListing } from "src/app/constants";
import { useState } from "react";

const { StartFrom, ListQty, OrderBy, Search } = FormikKeys;
const initalParams = {
  [StartFrom]: 0,
  [ListQty]: 3,
  [OrderBy]: "",
  [Search]: "",
};

const defaultDateParam: CommonObjectType = dateFiltersSinceListing[0];

const InfluencerMain = () => {
  const dispatch = useDispatch();

  const { isDashboardDataLoading, isProductsLoading, productsActivelyEngaged } =
    useSelector((state: RootState) => {
      return {
        isProductsLoading:
          state.commonReducer?.commonData?.productData?.isProductsLoading,
        isDashboardDataLoading:
          state.commonReducer?.commonData?.isDashboardDataLoading,
        productsActivelyEngaged:
          state.commonReducer?.commonData?.dashboardData
            ?.productsActivelyEngaged,
      };
    });

  useEffect(() => {
    dispatch(getProductsCatalog(initalParams, false));
    dispatch(getDashboardData({ ...defaultDateParam.value }, false));
  }, []);

  const hasEngagedWithProduct = !!productsActivelyEngaged;

  useEffect(() => {
    if (productsActivelyEngaged !== undefined && !isDashboardDataLoading) {
      logger.trackPage(
        `${Strings.influencer} ${LoggerStrings.dashboard}${
          hasEngagedWithProduct ? "" : LoggerStrings.empty
        }`
      );
    }
  }, [productsActivelyEngaged, hasEngagedWithProduct]);

  return (
    <>
      {isProductsLoading ? (
        <DashboardSkeleton />
      ) : hasEngagedWithProduct ? (
        <InfluencerStandard defaultDateFilter={defaultDateParam} />
      ) : (
        <PlaceholderLanding />
      )}
    </>
  );
};

export default InfluencerMain;
