import {
  Colors,
  getProfileData,
  logger,
  LoggerStrings,
  RootState,
  Strings,
  updateProfilePicture,
} from "@be-tagged/shared";
import {
  CalendarIcon,
  CameraBtn,
  FacebookIcon,
  InstagramIcon,
  MoneyBillIcon,
  ShoppingBagIcon,
  TikTokIcon,
  TwitterIcon,
  UserRoleIcon,
} from "@be-tagged/shared/src/assets/icons";
import {
  Avatar,
  Box,
  Center,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useState } from "react";
import BankDetails from "./HelperComponents/Influencer/BankDetails";
import LoginDetails from "./HelperComponents/Common/LoginDetails";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import useAuth from "src/app/hooks/useAuth";
import AddSocialMediaHandleModal from "./HelperComponents/Influencer/AddSocialMediaHandleModal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormattedLocalFromUTC,
  getMemberSinceFormattedLocalFromUTC,
} from "src/app/utils/DateUtils";
import ReactImageFallback from "react-image-fallback";
import { SUPPORTED_FILES } from "src/dashboard-module/interfaces";
import { DragDropComponent } from "../ProductListing/HelperComponents";
import { getURLParams } from "src/app/utils/Misc";
import { useLocation } from "react-router-dom";
import { ROUTING_PARAMS } from "src/app/constants";
import "./Settings.styles.scss";
import { ProfileSkeleton } from "./HelperComponents/SkeletonLayout";
import { withCommas } from "src/app/utils";
import useSetPageTitle from "src/app/hooks/useSetPageTitle";

const Settings = () => {
  const socialMediaMapping: { [k: string]: any } = {
    [FormikKeys.TikTok]: {
      icon: TikTokIcon,
    },
    [FormikKeys.Facebook]: {
      icon: FacebookIcon,
    },
    [FormikKeys.Instagram]: {
      icon: InstagramIcon,
    },
    [FormikKeys.Twitter]: {
      icon: TwitterIcon,
    },
  };
  const { isBrand, name } = useAuth();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const onTabChange = (index: number) => {
    setTabIndex(index);
  };
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDP, setShowDP] = useState<boolean>(false);
  const [field, setField] = useState("");
  const [fieldValue, setFieldValue] = useState({});
  const [profilePic, setProfilePic] = useState([]);
  const onClickField = (title: string, value: any) => {
    setField(title);
    setFieldValue(value);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const closeDPModal = () => {
    setShowDP(false);
  };
  const profileData = useSelector((state: RootState) => {
    return state.userReducer?.profileData || null;
  });
  const loading = useSelector((state: RootState) => {
    return state.appReducer?.loading;
  });
  const postCallFn = () => {
    dispatch(getProfileData(isBrand));
  };
  const onProfilePicChange = (fileURIs: string[]) => {
    dispatch(
      updateProfilePicture({
        url: fileURIs[0],
        postCallFn,
      })
    );
  };
  useEffect(() => {
    dispatch(getProfileData(isBrand));
  }, []);

  useSetPageTitle(Strings.profile);

  const { search } = useLocation();

  useEffect(() => {
    if (!isBrand) {
      switch (getURLParams("tab")) {
        case ROUTING_PARAMS.BANK_DETAILS:
          setTabIndex(1);
          break;
        default:
          setTabIndex(0);
          break;
      }
    }
  }, [search]);

  useEffect(() => {
    let eventLogText = "";
    switch (tabIndex) {
      case 0:
        eventLogText =
          (isBrand ? Strings.brand : Strings.influencer) +
          LoggerStrings.openedLoginDetails;
        break;
      case 1:
        eventLogText = LoggerStrings.openedBankDetails;
        break;
      default:
        break;
    }
    logger.info(eventLogText);
  }, [tabIndex]);

  return (
    <>
      <AddSocialMediaHandleModal
        isOpen={showModal}
        onClose={() => null}
        closeModal={closeModal}
        field={field}
        fieldValue={fieldValue}
      />
      <Modal size={"xl"} isOpen={showDP} onClose={closeDPModal}>
        <ModalOverlay />
        <ModalContent
          backgroundColor={Colors.bgDark}
          width={"300px"}
          height={"350px"}
        >
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody padding="15px">
            <Box>
              <Box>
                {loading ? (
                  <Spinner />
                ) : (
                  <Center>
                    <Avatar
                      src={profileData?.profilePicUrl}
                      alt="Profile"
                      style={{
                        width: "250px",
                        height: "250px",
                        objectFit: "cover",
                      }}
                    />
                  </Center>
                )}
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
      <Box
        bgColor={Colors.bgLight}
        py={{ md: "50px", base: "30px" }}
        pl={{ md: "30px", base: "15px" }}
        borderRadius="10px"
      >
        <Flex direction={{ md: "row", base: "column" }} rowGap={10}>
          {loading ? (
            <Box mr={5}>
              <ProfileSkeleton isBrand={isBrand} />
            </Box>
          ) : (
            <Flex direction="column" mr={{ md: "30px", base: "0px" }}>
              <Flex alignItems="end">
                <Avatar
                  src={profileData?.profilePicUrl}
                  alt="Profile"
                  style={{
                    borderRadius: "100px",
                    objectFit: "cover",
                    height: "100px",
                    width: "100px",
                  }}
                  onClick={() => {
                    setShowDP(true);
                  }}
                />
                <div style={{ marginLeft: "-25px" }}>
                  <DragDropComponent
                    disabled={false}
                    accept={SUPPORTED_FILES.Images}
                    files={profilePic}
                    setFiles={setProfilePic}
                    onChange={onProfilePicChange}
                    onlyChildren={true}
                    category={Strings.user}
                  >
                    <img
                      src={CameraBtn}
                      style={{
                        position: "sticky",
                        background: Colors.bgLight,
                        padding: "7px",
                        borderRadius: "100px",
                        boxShadow: "0px 3px 30px rgba(153, 186, 208, 0.3)",
                      }}
                    />
                  </DragDropComponent>
                </div>
              </Flex>
              <Box fontSize="22px" fontWeight="600" mt={4}>
                {name}
              </Box>
              <Flex direction="row" mt={4}>
                <img src={CalendarIcon} alt="Calendar" />
                <Box ml={2}>
                  {isBrand ? Strings.teamMemberSince : Strings.influencerSince}{" "}
                  :{" "}
                  {isBrand
                    ? getMemberSinceFormattedLocalFromUTC(
                        profileData?.memberSince
                      )
                    : getMemberSinceFormattedLocalFromUTC(
                        profileData?.influencerSinceUtc
                      )}
                </Box>
              </Flex>
              {isBrand && (
                <Flex direction="row" mt={4}>
                  <img src={UserRoleIcon} alt="Shopping Bag" />
                  <Box ml={2}>
                    {Strings.role} : {profileData?.role}
                  </Box>
                </Flex>
              )}
              {!isBrand && (
                <>
                  <Flex direction="row" mt={4}>
                    <img src={ShoppingBagIcon} alt="Shopping Bag" />
                    <Box ml={2}>
                      {Strings.totalProductsSold} :{" "}
                      {withCommas(profileData?.sales)}
                    </Box>
                  </Flex>
                  <Flex direction="row" mt={4}>
                    <img src={MoneyBillIcon} alt="Money" />
                    <Box ml={2}>
                      {Strings.totalCommissionEarned} :{" "}
                      {profileData?.currencySymbol}{" "}
                      {withCommas(profileData?.commission)}
                    </Box>
                  </Flex>
                  <Flex mt={5} direction="column">
                    {Object.keys(profileData?.socialMedia || {})?.map(function (
                      key: string
                    ) {
                      return (
                        <Flex direction="row" mt={4} key={key}>
                          <img
                            src={socialMediaMapping?.[key]?.icon}
                            alt="Social Media"
                            width="24px"
                            height="24px"
                          />
                          <Box ml={2} fontSize="14px" width="150px">
                            {key === FormikKeys.Facebook ? "/" : "@"}
                            {profileData?.socialMedia[key]}
                          </Box>
                          <Box
                            fontSize="12px"
                            fontWeight="600"
                            color={Colors.purple}
                          >
                            <button
                              onClick={() => {
                                onClickField(key, profileData?.socialMedia);
                              }}
                              style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                color: Colors.purple,
                              }}
                            >
                              {profileData?.socialMedia[key] === null ||
                              profileData?.socialMedia[key] === ""
                                ? Strings.add
                                : Strings.edit}
                            </button>
                          </Box>
                        </Flex>
                      );
                    })}
                  </Flex>
                </>
              )}
            </Flex>
          )}
          <Divider
            orientation="vertical"
            borderRightWidth="2px"
            borderLeftWidth="0px"
            height="400px"
            display={{ base: "none", md: "block" }}
          />
          {isBrand ? (
            <Box ml={{ md: "50px", base: "0px" }}>
              <LoginDetails profileData={profileData} isBrand={isBrand} />
            </Box>
          ) : (
            <Flex>
              <Tabs
                index={tabIndex}
                colorScheme={"purple"}
                onChange={(index: any) => onTabChange(index)}
              >
                <TabList
                  className="tab-list"
                  pb="10px"
                  pl={{ md: "30px", base: "0px" }}
                  borderBottomWidth="0px"
                >
                  <div className={tabIndex === 0 ? "selected_tab" : ""}>
                    <Tab
                      fontSize="14px"
                      pb="0px !important"
                      _selected={{
                        borderBottomWidth: "0px",
                        boxShadow: "none",
                        fontWeight: "600",
                      }}
                    >
                      {Strings.loginDetails}
                    </Tab>
                  </div>
                  <div className={tabIndex === 1 ? "selected_tab" : ""}>
                    <Tab
                      fontSize="14px"
                      pb="0px !important"
                      _selected={{
                        borderBottomWidth: "0px",
                        boxShadow: "none",
                        fontWeight: "600",
                      }}
                    >
                      {Strings.bankDetails}
                    </Tab>
                  </div>
                </TabList>
                <Divider orientation="horizontal" borderBottomWidth="2px" />
                <TabPanels>
                  <TabPanel pl={{ md: "20px", base: "0px" }} pr={0}>
                    <LoginDetails profileData={profileData} isBrand={isBrand} />
                  </TabPanel>
                  <TabPanel pl={{ md: "20px", base: "0px" }} pr={0}>
                    <BankDetails />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default Settings;
