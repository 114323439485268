import { FC, useState } from "react";
import { ErrorMsg, MaterialStyleInput } from "../../../../app/components";
import { ONBOARDING_TEXT } from "../../../../app/constants";
import { Colors, Strings } from "@be-tagged/shared";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import { Box, Flex, Checkbox } from "@chakra-ui/react";
import CategoriesSelection from "../../../../app/components/CustomSelect/CategoriesSelection";
import TermsAndConditionModal from "src/account-module/common/TermsAndConditionModal";

const {
  FullName,
  BusinessName,
  ProductsAndServices,
  HasAcceptedTermsAndCondition,
} = FormikKeys;

interface IArgs {
  errors: { [field: string]: string };
  values: { [field: string]: any };
  touched: { [field: string]: boolean };
  isBrand?: boolean;
  handleChange: (e: any) => void;
  handleBlur: (e: any) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

/**
 * Component that's comes as the first step of onboarding, and it takes in the name of the user
 * @param {Object} - of the type NameInputProps
 * @returns {JSX.Element} - A React Functional Component
 */
export const NameInput: FC<IArgs> = ({
  errors,
  values,
  touched,
  handleChange,
  handleBlur,
}): JSX.Element => {
  const { preText, postText } = ONBOARDING_TEXT.inputForm.name.title;
  return (
    <>
      <div className="input_title">
        {preText}
        {postText}
      </div>
      <div style={{ position: "relative" }}>
        <MaterialStyleInput
          style={Styles.materialInput}
          label={Strings.yourName}
          value={values[FullName]}
          onChange={handleChange(FullName)}
          onBlur={handleBlur(FullName)}
          hideLabelOnEnter={true}
        />
        {touched[FullName] && errors[FullName] && (
          <ErrorMsg>{errors[FullName]}</ErrorMsg>
        )}
      </div>
    </>
  );
};

/**
 * Component that comes as the second step of onboarding if the brand hasn't been already onboarded
 * It takes in the name of the user to greet with a personalised "Hello"
 * @param {Object} of the type BusinessNameInputProps
 * @returns {JSX.Element} - A React Functional Component
 */
export const BusinessNameInput: FC<IArgs> = ({
  errors,
  values,
  touched,
  handleChange,
  handleBlur,
}): JSX.Element => {
  const { preText, postText } = ONBOARDING_TEXT.inputForm.business.title;
  return (
    <>
      <div className="input_title">
        {preText} {values[FullName]}
        {postText}
      </div>
      <div style={{ position: "relative" }}>
        <MaterialStyleInput
          style={Styles.materialInput}
          label={Strings.yourBusinessName}
          value={values[BusinessName]}
          onChange={handleChange(BusinessName)}
          onBlur={handleBlur(BusinessName)}
          hideLabelOnEnter={true}
        />
        {touched[BusinessName] && errors[BusinessName] && (
          <ErrorMsg>{errors[BusinessName]}</ErrorMsg>
        )}
      </div>
    </>
  );
};

/**
 * Dropdown where the user gets to select whatever categories the brand belongs to
 * @param {Object} of the type ProductsAndServicesInputProps
 * @returns {JSX.Element} - A React Functional Component
 */
export const ProductsAndServicesInput: FC<IArgs> = ({
  errors,
  values,
  touched,
  isBrand,
  setFieldTouched,
  setFieldValue,
}): JSX.Element => {
  const { preText, postText } =
    ONBOARDING_TEXT.inputForm.services[isBrand ? "title" : "influencerTitle"];

  const midText = isBrand ? values[BusinessName] : values[FullName];

  return (
    <>
      <div className="input_title">
        {preText} {midText}
        {postText}
      </div>
      <Box
        w={{
          base: "min(350px, 100%)",
        }}
        className="input_group"
        pos={"relative"}
        my={5}
      >
        <CategoriesSelection
          value={values[ProductsAndServices]}
          handleChange={(items: any) =>
            setFieldValue(ProductsAndServices, items)
          }
          handleBlur={() => setFieldTouched(ProductsAndServices)}
        />
        {touched[ProductsAndServices] && errors[ProductsAndServices] && (
          <ErrorMsg>{errors[ProductsAndServices]}</ErrorMsg>
        )}
      </Box>
    </>
  );
};

export const TermsAndConditionInput: FC<IArgs> = ({
  errors,
  values,
  touched,
  setFieldTouched,
  setFieldValue,
}): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const handleTermsAndConsitionClick = (e: any) => {
    e.preventDefault();
    setOpenModal(true);
  };
  return (
    <>
      <div className="input_title">{Strings.termsAndConditionInf}</div>
      <Box
        w={{
          base: "min(350px, 100%)",
        }}
        className="input_group"
        pos={"relative"}
        my={0}
      >
        <Flex alignItems={"flex-end"} mt={-4} mb={2}>
          <Checkbox
            className="label"
            colorScheme="pink"
            mr={2}
            isChecked={values[HasAcceptedTermsAndCondition]}
            onChange={(event: any) => {
              setFieldValue(HasAcceptedTermsAndCondition, event.target.checked);
              setFieldTouched(HasAcceptedTermsAndCondition);
            }}
          >
            <span
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: Colors.blackColor,
              }}
            >
              {Strings.acceptTermsAndConditionPreText}
              <span
                style={{ color: Colors.purple }}
                onClick={handleTermsAndConsitionClick}
              >
                {Strings.acceptTermsAndConditionPostText}
              </span>
            </span>
          </Checkbox>
          {touched[HasAcceptedTermsAndCondition] &&
            errors[HasAcceptedTermsAndCondition] && (
              <ErrorMsg>{errors[HasAcceptedTermsAndCondition]}</ErrorMsg>
            )}
        </Flex>
      </Box>
      <TermsAndConditionModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

const Styles = {
  materialInput: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  dropdownParent: {
    marginTop: 15,
  },
};
