import { HTTPMethod } from "../enums";
import { executeRequestAsync } from "../services/NetworkHelper";
import { getSignedUploadUrl, getRefreshedFileUrl } from "./UrlBuilder";
import axios from "axios";
import LocalisedStrings from "../strings/LocalisedStrings";
import {
  SET_ERROR,
  UPDATE_LOADING_STATUS,
} from "../store/constants/ReduxConstants";
const { brandProduct } = LocalisedStrings;

//fileUpload returns an array of URL strings
export const fileUpload = async (
  dispatch: any,
  files: any[],
  isMobile?: boolean,
  category = brandProduct
) => {
  try {
    dispatch({
      type: UPDATE_LOADING_STATUS,
      payload: true,
    });
    const filesToUpload = files.map((file) => {
      return signAndUpload(file, category, isMobile);
    });
    const urlList = await axios.all(filesToUpload);
    return urlList;
  } catch (error: any) {
    dispatch({
      type: SET_ERROR,
      payload: error.message,
    });
  } finally {
    dispatch({
      type: UPDATE_LOADING_STATUS,
      payload: false,
    });
  }
};

const memoisedObject: { [k: string]: any } = {};

interface IKeyName {
  name: string;
  size: number;
}

const getKeyName = ({ name, size }: IKeyName): string => {
  return name.replaceAll(" ", "").slice(0, 6).trim() + size;
};

const signAndUpload = async (
  file: any,
  category: string,
  isMobile?: boolean
) => {
  const { name, size, type, uri } = file;

  const signingPayload = {
    fileName: name,
    contentLength: size,
    contentType: type,
    fileCategory: category,
  };

  const requestFormat = {
    method: HTTPMethod.POST,
    data: signingPayload,
    url: getSignedUploadUrl(),
  };

  const fileKey = getKeyName(file);

  if (file.initialFileUrl) {
    memoisedObject[fileKey] = file.initialFileUrl;
    return file.initialFileUrl;
  }

  //if the file has already been fetched, no need to fetch its url again
  if (memoisedObject[fileKey]) {
    return memoisedObject[fileKey];
  }

  const {
    data: { fileUrl },
  }: any = await executeRequestAsync(requestFormat);

  if (isMobile) {
    const getBlob = async (uri: string) => {
      const resp = await fetch(uri);
      const fileBody = await resp.blob();
      return fileBody;
    };
    const fileBody = await getBlob(uri);

    const response = await fetch(fileUrl, {
      method: HTTPMethod.PUT,
      body: fileBody,
    });
    memoisedObject[fileKey] = fileUrl;
    return fileUrl;
  }

  const response = await axios.put(fileUrl, file, {
    headers: {
      "Content-Type": type,
    },
  });
  memoisedObject[fileKey] = fileUrl;
  return fileUrl;
};

export const refreshFile = async (file: string) => {
  const requestFormat = {
    method: HTTPMethod.POST,
    data: { file: file },
    url: getRefreshedFileUrl(),
  };
  const {
    data: { fileUrl },
  }: any = await executeRequestAsync(requestFormat);
  return fileUrl;
};
