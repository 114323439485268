import {
  Colors,
  Strings,
  getInsightsSummary,
  RootState,
  getBrandCountries,
  logger,
  LoggerStrings,
} from "@be-tagged/shared";
import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import useAuth from "src/app/hooks/useAuth";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabProductsSold from "./HelperComponents/Common/TabProductsSold";
import TabCommissionPayout from "./HelperComponents/Influencer/TabCommissionPayout";
import TabPlatformInsights from "./HelperComponents/Common/TabPlatformInsights";
import {
  CommonObjectType,
  ROUTING_PARAMS,
  dateFiltersSinceListing,
} from "src/app/constants";
import { CountrySelect, TimeRangeFilter } from "src/app/components";
import TabInfluencers from "./HelperComponents/Brand/TabInfluencers";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import "./Insights.styles.scss";
import { getURLParams } from "src/app/utils/Misc";
import { withCommas } from "src/app/utils";
import useSetPageTitle from "src/app/hooks/useSetPageTitle";

const { CountryID } = FormikKeys;

/**
 * @returns {JSX.Element} - A React Functional Component
 */

const Insights = (): JSX.Element => {
  const { isBrand, name } = useAuth();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const onTabChange = (index: number) => {
    setTabIndex(index);
  };
  const { summary, countries } = useSelector((state: RootState) => {
    return {
      countries: isBrand ? state.brandReducer?.countries || [] : [],
      summary: state.commonReducer.commonData?.insights?.summary || {},
    };
  });

  useSetPageTitle(Strings.insightsTab);
  const [rangeFilter, setRangeFilter] = useState(dateFiltersSinceListing[0]);
  const [selectedCountry, setSelectedCountry] =
    useState<CommonObjectType | null>(null);

  const {
    commissionEarned,
    totalSalesValue,
    totalSalesVolume,
    currencySymbol,
    influencerCommission,
  } = summary;

  const tabNavigate = () => {
    switch (getURLParams("tab")) {
      case ROUTING_PARAMS.COMMISSION_PAYOUT:
        setTabIndex(1);
        break;
      case ROUTING_PARAMS.INFLUENCERS:
        setTabIndex(1);
        break;
      case ROUTING_PARAMS.PLATFORM_INSIGHTS:
        setTabIndex(2);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    isBrand && dispatch(getBrandCountries());
    tabNavigate();
  }, []);

  useEffect(() => {
    if (countries.length && !selectedCountry) {
      setSelectedCountry(countries[0]);
    }
  }, [countries]);

  const getBusinessInsightsSummary = (par: any) => {
    let finalPayload = {
      ...par,
    };
    if (isBrand) {
      finalPayload[CountryID] = selectedCountry?.id;
    }
    dispatch(getInsightsSummary(finalPayload, isBrand));
  };
  useEffect(() => {
    if (isBrand && !selectedCountry) return;
    getBusinessInsightsSummary(rangeFilter?.value);
  }, [rangeFilter, selectedCountry?.id]);

  useEffect(() => {
    let tabName = "";

    switch (tabIndex) {
      case 0:
        tabName = Strings.productsSold;
        break;
      case 1:
        tabName = Strings.commissionPayout;
        if (isBrand) tabName = Strings.influencers;
        break;
      case 2:
        tabName = Strings.platformInsights;
        break;
      default:
        break;
    }

    logger.info(
      Strings.businessInsights +
        " - " +
        (isBrand ? Strings.brand : Strings.influencer) +
        ` ${LoggerStrings.justOpenedThe} ${tabName} ${LoggerStrings.tab}`
    );
  }, [tabIndex]);

  useEffect(() => {
    if (selectedCountry) {
      const user = isBrand ? Strings.brand : Strings.influencer;
      logger.info(`${user} ${LoggerStrings.changedCountry}`, {
        country: selectedCountry?.name,
      });
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (rangeFilter) {
      const user = isBrand ? Strings.brand : Strings.influencer;
      logger.info(`${user} ${LoggerStrings.changedDateFilter}`, {
        dateRange: rangeFilter.name,
      });
    }
  }, [rangeFilter]);
  return (
    <>
      <Flex
        direction={{
          base: "column",
          md: "row",
        }}
        align={{
          base: "flex-start",
          md: "center",
        }}
        fontWeight="600"
        fontSize="36px"
      >
        {isBrand ? (
          <>
            <Box>{Strings.yourBetagged}&nbsp;</Box>{" "}
            <Box color={Colors.dollarGreen} mr={5}>
              {Strings.wideView}
            </Box>
          </>
        ) : (
          <>
            <Box>{name},&nbsp;</Box>
            <Box color={Colors.dollarGreen} mr={5}>
              {Strings.yourPerformance}
            </Box>
          </>
        )}

        <Flex gap={"10px"} fontSize={"12px"} fontWeight={"normal"}>
          {isBrand && (
            <Box w={"150px"}>
              <CountrySelect
                countryOptions={countries}
                isMulti={false}
                selectedCountries={selectedCountry}
                setCountries={setSelectedCountry}
                alternativeStyle={true}
                placeholder={"Select..."}
                labelPrefix={"from "}
              />
            </Box>
          )}
          <Box fontSize={"12px"} fontWeight={"normal"}>
            <TimeRangeFilter onChange={setRangeFilter} value={rangeFilter} />
          </Box>
        </Flex>
      </Flex>
      <Flex
        mt={10}
        rowGap={7}
        columnGap={{ md: "100px", base: "20px" }}
        bgColor="white"
        py={5}
        px={{
          md: "80px",
          base: "20px",
        }}
        shadow="md"
        width={{ md: "fit-content", base: "90vw" }}
        borderRadius={10}
        mx={"auto"}
        flexWrap="wrap"
      >
        <Box>
          <Flex
            mb="1.2%"
            color={Colors.grey100}
            fontSize="12px"
            fontWeight="600"
            width={{ base: "35vw", md: "fit-content" }}
          >
            {Strings.totalSales} - {Strings.volume}
          </Flex>
          <Box fontSize="30px" fontWeight="700">
            {isBrand
              ? !countries?.length
                ? 0
                : withCommas(totalSalesVolume)
              : withCommas(totalSalesVolume)}
          </Box>
        </Box>

        <Box>
          <Box
            mb="1.2%"
            color={Colors.grey100}
            fontSize="12px"
            fontWeight="600"
            width={{ base: "35vw", md: "fit-content" }}
          >
            {Strings.totalSales} - {Strings.value}
          </Box>
          <Box
            fontSize="30px"
            fontWeight="700"
            width={{ base: "35vw", md: "fit-content" }}
          >
            {isBrand ? (
              !countries?.length ? (
                0
              ) : (
                <>
                  {currencySymbol}&nbsp;
                  {withCommas(totalSalesValue)}
                </>
              )
            ) : (
              <>
                {currencySymbol}&nbsp;
                {withCommas(totalSalesValue)}
              </>
            )}
          </Box>
        </Box>

        <Box>
          <Box
            mb="1.2%"
            color={Colors.grey100}
            fontSize="12px"
            fontWeight="600"
          >
            {isBrand ? "Commission" : <>{Strings.commissionEarned}</>}
          </Box>
          <Box fontSize="30px" fontWeight="700">
            {isBrand ? (
              <>
                {!countries?.length ? (
                  0
                ) : (
                  <>
                    {currencySymbol}&nbsp;{withCommas(influencerCommission)}
                  </>
                )}
              </>
            ) : (
              <>
                {currencySymbol}&nbsp;{withCommas(commissionEarned)}
              </>
            )}
          </Box>
        </Box>
      </Flex>
      <Box mt={8}>
        <Tabs
          index={tabIndex}
          colorScheme={"purple"}
          isLazy={true}
          onChange={(index: number) => onTabChange(index)}
        >
          <TabList pb="10px">
            <div className={tabIndex === 0 ? "selected_tab" : ""}>
              <Tab
                fontSize="14px"
                pb="0px !important"
                _selected={{
                  borderBottomWidth: "0px",
                  boxShadow: "none",
                  fontWeight: "600",
                }}
              >
                {Strings.productsSold}
              </Tab>
            </div>
            <div className={tabIndex === 1 ? "selected_tab" : ""}>
              <Tab
                fontSize="14px"
                pb="0px !important"
                _selected={{
                  borderBottomWidth: "0px",
                  boxShadow: "none",
                  fontWeight: "600",
                }}
              >
                {isBrand ? Strings.influencers : Strings.commissionPayout}
              </Tab>
            </div>
            <div className={tabIndex === 2 ? "selected_tab" : ""}>
              <Tab
                fontSize="14px"
                pb="0px !important"
                _selected={{
                  borderBottomWidth: "0px",
                  boxShadow: "none",
                  fontWeight: "600",
                }}
              >
                {Strings.platformInsights}
              </Tab>
            </div>
          </TabList>

          <TabPanels>
            <TabPanel px={0}>
              <TabProductsSold
                country={selectedCountry}
                dateParams={rangeFilter.value}
                key={tabIndex}
              />
            </TabPanel>
            <TabPanel px={0}>
              {isBrand ? (
                <TabInfluencers
                  country={selectedCountry}
                  dateParams={rangeFilter.value}
                />
              ) : (
                <TabCommissionPayout dateParams={rangeFilter.value} />
              )}
            </TabPanel>
            <TabPanel px={0}>
              <TabPlatformInsights
                country={selectedCountry}
                dateParams={rangeFilter.value}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default Insights;
