import { FC, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Spinner,
  Box,
  Flex,
} from "@chakra-ui/react";
import {
  addNewEmail,
  addNewPhone,
  changePassword,
  Colors,
  deleteUserAccount,
  logger,
  LoggerStrings,
  PasswordRegex,
  Strings,
  userLogout,
} from "@be-tagged/shared";
import { CustomBtn, MaterialStyleInput } from "src/app/components";
import { Formik } from "formik";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import * as yup from "yup";
import { YUP_VALIDATION } from "src/app/strings";
import {
  CommonObjectType,
  defaultCountryCode,
  ROUTING_URLS,
} from "src/app/constants";
import useData from "src/app/hooks/useData";
import { useDispatch } from "react-redux";
import MobileInput from "src/account-module/common/MobileInput";
import { CountryCodeObject } from "src/account-module/interfaces";
import { useNavigate } from "react-router-dom";
import useAuth from "src/app/hooks/useAuth";

interface AddLoginDetailsModalProps {
  field: string;
  isOpen: boolean;
  onClose: () => void;
  closeModal: () => void;
  dataCallbackFn: any;
}
interface SubmittedDetailsInt extends CommonObjectType {
  [FormikKeys.Email]?: string;
  [FormikKeys.Phone]?: string;
  [FormikKeys.Password]?: string;
  [FormikKeys.OldPassword]?: string;
  [FormikKeys.CountryCode]?: CountryCodeObject;
}

/**
 * @param {Object} Propss - Of the form AddLoginDetailsModalProps
 * @returns {JSX.Element} - A React Functional Components
 */
const AddLoginDetailsModal: FC<AddLoginDetailsModalProps> = ({
  field,
  isOpen,
  closeModal,
  onClose,
  dataCallbackFn,
}): JSX.Element => {
  //Fetching Formik Keys
  const { Email, Phone, Password, OldPassword, CountryCode, DeleteAccount } =
    FormikKeys;
  const [showPass, setShowPass] = useState<boolean>(false);
  const validationSchemaSuperset: CommonObjectType = {
    [Email]: yup.string().email().required(),
    [Password]: yup
      .string()
      .required(Strings.enterPassword)
      .matches(PasswordRegex, Strings.passwordMatch),
    [Phone]: yup
      .string()
      .min(7, YUP_VALIDATION.MOBILE_NUMBER_LENGTH)
      .max(15, YUP_VALIDATION.MOBILE_NUMBER_LENGTH)
      .required(YUP_VALIDATION.MOBILE_NUMBER_PRESENCE),
  };
  const getInitialValues: () => SubmittedDetailsInt = () => {
    let initalValues = {
      [Email]: "",
      [Phone]: "",
      [Password]: "",
      [OldPassword]: "",
      [CountryCode]: defaultCountryCode,
    };
    return initalValues;
  };
  const getValidationSchema = () => {
    return {
      [field]: validationSchemaSuperset[field],
    };
  };
  const fieldName: CommonObjectType = {
    [Email]: Strings.email,
    [Phone]: Strings.mobileNo,
    [Password]: Strings.newPassword,
  };
  const label: CommonObjectType = {
    [Email]: Strings.addAlternateEmail,
    [Phone]: Strings.addAlternateNumber,
    [Password]: Strings.changePassword,
    [DeleteAccount]: Strings.deleteAccount,
  };

  const { loading } = useData();
  const { isBrand } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const postCallFn = (data: any) => {
    dataCallbackFn?.(data);
    closeModal();
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteUserAccount(isBrand));
      dispatch(userLogout(goToHome));
    } catch (e: any) {}
  };

  const goToHome = () => {
    navigate(`${ROUTING_URLS.LANDING}`);
  };

  const addDetailToProfile = (values: CommonObjectType) => {
    switch (field) {
      case Email:
        dispatch(
          addNewEmail(
            {
              [Email]: values[Email],
            },
            false,
            postCallFn
          )
        );
        break;
      case Phone:
        dispatch(
          addNewPhone(
            {
              [CountryCode]: values[CountryCode].isdCode,
              [Phone]: values[Phone],
            },
            false,
            postCallFn
          )
        );
        break;
      case Password:
        dispatch(
          changePassword(
            {
              [OldPassword]: values[OldPassword],
              newPassword: values[Password],
            },
            postCallFn
          )
        );
        break;
      default:
        break;
    }
  };
  const getHTMLType = (field: string) => {
    switch (field) {
      case Email:
        return "email";
      case Phone:
        return "number";
      case Password:
        return "password";
      default:
        return "text";
    }
  };

  useEffect(() => {
    if (isOpen) {
      let changeAction = "";
      switch (field) {
        case FormikKeys.Phone:
          changeAction = Strings.addAlternateNumber;
          break;
        case FormikKeys.Email:
          changeAction = Strings.addAlternateEmail;
          break;
        case FormikKeys.Password:
          changeAction = Strings.changePassword;
          break;
        case FormikKeys.DeleteAccount:
          changeAction = Strings.deleteAccount;
          break;
        default:
          break;
      }
      logger.info(`${LoggerStrings.userIsTryingTo} ${changeAction}`);
    }
    setShowPass(false);
  }, [isOpen]);

  return (
    <>
      <Modal
        size={"2xl"}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          className="filter_modal"
          style={{ paddingTop: 12 }}
          backgroundColor={Colors.bgDark}
        >
          <ModalBody padding="15px">
            <Box px={{ md: "15px", base: "0px" }} className="content_container">
              <Box className="bold" style={{ fontSize: 18, paddingBottom: 24 }}>
                {label?.[field]}
              </Box>
              <Formik
                initialValues={getInitialValues()}
                onSubmit={() => {}}
                validationSchema={yup.object().shape(getValidationSchema())}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  setFieldTouched,
                  errors,
                  touched,
                  isValid,
                  setFieldValue,
                }) => (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                      }}
                    >
                      <Flex rowGap={"20px"} direction="column">
                        {field === FormikKeys.Phone && (
                          <>
                            <MobileInput
                              countryCode={values[CountryCode]}
                              handleCountryCodeChange={(countryCode: any) =>
                                setFieldValue(CountryCode, countryCode)
                              }
                              phone={values[Phone]}
                              handlePhoneChange={handleChange(Phone)}
                              setFieldTouched={setFieldTouched}
                            />
                            {touched[field] && errors[field] && (
                              <Box fontSize="9px" color={Colors.red} mt={-5}>
                                {errors[field]}
                              </Box>
                            )}
                          </>
                        )}
                        {field === FormikKeys.Email && (
                          <>
                            <MaterialStyleInput
                              parentProps={{ style: { width: 300 } }}
                              value={values?.[field]}
                              onChange={handleChange(field)}
                              onBlur={() => setFieldTouched(field)}
                              type={getHTMLType(field)}
                              label={fieldName?.[field]}
                            />
                            {touched[field] && errors[field] && (
                              <Box fontSize="9px" color={Colors.red} mt={-5}>
                                {errors[field]}
                              </Box>
                            )}
                          </>
                        )}
                        {field === FormikKeys.Password && (
                          <>
                            <MaterialStyleInput
                              parentProps={{ style: { width: 300 } }}
                              value={values[OldPassword]}
                              onChange={handleChange(OldPassword)}
                              onBlur={() => setFieldTouched(OldPassword)}
                              type={Password}
                              label={Strings.oldPassword}
                            />
                            {touched[OldPassword] && errors[OldPassword] && (
                              <Box fontSize="9px" color={Colors.red} mt={-5}>
                                {errors[OldPassword]}
                              </Box>
                            )}
                            <MaterialStyleInput
                              parentProps={{ style: { width: 300 } }}
                              value={values[Password]}
                              onChange={handleChange(Password)}
                              onBlur={() => setFieldTouched(Password)}
                              type={showPass ? "text" : "password"}
                              label={fieldName?.[field]}
                            />
                            {touched[Password] && errors[Password] && (
                              <Box fontSize="9px" color={Colors.red} mt={-5}>
                                {errors[Password]}
                              </Box>
                            )}
                            {values[Password] !== "" && (
                              <button
                                type={"button"}
                                onClick={() => {
                                  setShowPass(!showPass);
                                }}
                                style={{
                                  color: Colors.purple,
                                  fontSize: "11px",
                                  fontWeight: "600",
                                  alignSelf: "baseline",
                                }}
                              >
                                {showPass ? Strings.hidePass : Strings.showPass}
                              </button>
                            )}
                          </>
                        )}
                        {field === FormikKeys.DeleteAccount && (
                          <>{Strings.pleaseConfirmDeleteAccount}</>
                        )}
                      </Flex>
                      <Flex justifyContent="flex-end" columnGap={4} mt={8}>
                        <CustomBtn
                          disabled={loading}
                          type="button"
                          className="purple_btn medium inv"
                          onClick={closeModal}
                        >
                          {Strings.cancel}
                        </CustomBtn>
                        <CustomBtn
                          disabled={!isValid || loading}
                          type="submit"
                          className="purple_btn medium"
                          onClick={
                            field === FormikKeys.DeleteAccount
                              ? handleDelete
                              : () => addDetailToProfile(values)
                          }
                          style={{ paddingLeft: "30px", paddingRight: "30px" }}
                        >
                          {loading && (
                            <Spinner size={"xs"} style={{ marginRight: 10 }} />
                          )}
                          {field === FormikKeys.DeleteAccount
                            ? Strings.delete
                            : FormikKeys.Password
                            ? Strings.save
                            : Strings.add}
                        </CustomBtn>
                      </Flex>
                    </form>
                  </>
                )}
              </Formik>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddLoginDetailsModal;
