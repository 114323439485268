import {
  Colors,
  logger,
  LoggerStrings,
  setPromotionRequest,
  Strings,
} from "@be-tagged/shared";
import {
  PossibleRequestStates,
  ProductStatus,
} from "@be-tagged/shared/src/enums";
import { Box, Checkbox, Flex, Spacer, useClipboard } from "@chakra-ui/react";
import {
  Hourglass,
  SadFace,
  SmileFace,
  CopyIcon,
} from "@be-tagged/shared/src/assets/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getFormattedLocalFromUTC } from "src/app/utils/DateUtils";
import React from "react";
import TooltipWrapper from "src/app/components/TooltipWrapper";

const { Pending, Approved, Rejected } = PossibleRequestStates;

const ObjectifiedStrings = Strings as { [k: string]: any };
interface SocialMediaProps {
  brandProductId: number;
  isApprovalBasedOffer: boolean;
  name: string;
  details: any;
  hasAllCodesExhausted?: boolean;
  productStatus?: string;
  isRejectedDueToPeriodExpiration?: boolean;
  setOpenModal?: any;
  doneShowingModal?: boolean;
  setDoneShowingModal?: any;
}

const SocialMediaCheckbox = ({
  brandProductId,
  isApprovalBasedOffer,
  name,
  details,
  hasAllCodesExhausted,
  productStatus,
  isRejectedDueToPeriodExpiration,
  setOpenModal,
  doneShowingModal,
  setDoneShowingModal,
}: SocialMediaProps) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(details?.productLink);
  const { hasCopied, onCopy } = useClipboard(value);

  const socialMediaName = ObjectifiedStrings?.[name] || name;
  // The above mapping is also in line with SalesChannel enums
  const { title, request, productLink, discountCode } = details || {};

  const isAppliedFor = !!(!isApprovalBasedOffer ||
  (isApprovalBasedOffer && request === null && productLink !== null)
    ? productLink || request?.approvalStatus
    : request);
  const { approvalStatus, requestedOnUtc, actedOnUtc } = request || {};
  const [clickedCheckbox, setClickedChechbox] = useState(false);

  const onRequestClick = () => {
    setClickedChechbox(true);
    if (isAppliedFor) return;
    dispatch(
      setPromotionRequest(name, {
        salesChannel: socialMediaName,
        brandProductId,
        setOpenModal: !doneShowingModal ? setOpenModal : null,
      })
    );
    !doneShowingModal && setDoneShowingModal(true);
  };
  useEffect(() => {
    if (clickedCheckbox && !isAppliedFor) {
      const user = Strings.influencer;
      logger.info(`${user} ${LoggerStrings.clickedOnCheckBox}`, {
        prodID: brandProductId,
        socialMedia: socialMediaName,
      });
    }
  }, [clickedCheckbox, isAppliedFor]);

  useEffect(() => {
    if (hasCopied) {
      const user = Strings.influencer;
      logger.info(`${user} ${LoggerStrings.copiedLink}`, {
        prodID: brandProductId,
        productLink: value,
      });
    }
  }, [hasCopied]);

  useEffect(() => {
    if (!value) {
      setValue(details?.productLink);
    }
  });

  const approvalBasedContentReturner = (
    approvalStatus: PossibleRequestStates
  ) => {
    let imgSrc: string = "";
    let innerContent: any = null;
    let outerContent: any = null;
    switch (approvalStatus) {
      case Pending:
        imgSrc = Hourglass;
        innerContent = Strings.requestPending;
        outerContent = (
          <Box
            color={Colors.grey100}
            fontSize="12px"
            fontWeight="normal"
            width="180px"
            mb={3}
          >
            {Strings.youExpressedInterestOn}
            {getFormattedLocalFromUTC(requestedOnUtc)}
          </Box>
        );
        break;
      case Approved:
        imgSrc = SmileFace;
        innerContent = (
          <Flex direction={"column"}>
            <Box>{Strings.requestApproved}</Box>
            <Flex>
              <Box
                color={Colors.purple}
                fontSize="14px"
                fontWeight="normal"
                mb={3}
              >
                {productLink}
              </Box>
              {hasCopied ? (
                <TooltipWrapper label={"copied"}>
                  <Box ml={2} cursor={"pointer"}>
                    <img src={CopyIcon} alt={"Copy"} onClick={onCopy} />
                  </Box>
                </TooltipWrapper>
              ) : (
                <TooltipWrapper label={"copy"}>
                  <Box ml={2} cursor={"pointer"}>
                    <img src={CopyIcon} alt={"Copy"} onClick={onCopy} />
                  </Box>
                </TooltipWrapper>
              )}
            </Flex>
            {discountCode && (
              <Flex alignItems="end">
                <Box fontSize="12px" fontWeight="normal">
                  {Strings.discCode} <br />
                  {Strings.forYourFollowers}
                </Box>
                <Spacer />
                <Box pl={10} fontSize="14px" fontWeight="600">
                  {discountCode}
                </Box>
              </Flex>
            )}
          </Flex>
        );
        break;
      case Rejected:
        imgSrc = SadFace;
        innerContent = isRejectedDueToPeriodExpiration
          ? Strings.rejectedDueToPeriodExpiration
          : Strings.requestRejected;
        break;
      default:
        break;
    }
    return (
      <>
        {outerContent}
        <Flex direction={"row"} alignItems="start">
          <img src={imgSrc} alt={approvalStatus} />
          <Box color={Colors.grey} fontSize="12px" fontWeight="normal">
            {innerContent}
          </Box>
        </Flex>
      </>
    );
  };
  return (
    <div>
      <Flex direction={"column"}>
        <Checkbox
          isChecked={isAppliedFor}
          colorScheme="pink"
          mr={2}
          title={title}
          onChange={onRequestClick}
          isDisabled={
            hasAllCodesExhausted || productStatus === ProductStatus.Unlisted
          }
        >
          <span className="social-media-title">{socialMediaName} </span>
        </Checkbox>

        {isAppliedFor && (
          <Box pl={6} pt={2}>
            <Flex direction={"column"}>
              {(!isApprovalBasedOffer &&
                approvalStatus === PossibleRequestStates?.Rejected) ||
              (isApprovalBasedOffer && details?.request !== null) ? (
                approvalBasedContentReturner(approvalStatus)
              ) : (
                <>
                  <Flex>
                    <Box
                      color={Colors.purple}
                      fontSize="14px"
                      fontWeight="normal"
                      mb={3}
                    >
                      {productLink}
                    </Box>
                    {hasCopied ? (
                      <TooltipWrapper label={"copied"}>
                        <Box ml={2} cursor={"pointer"}>
                          <img src={CopyIcon} alt={"Copy"} onClick={onCopy} />
                        </Box>
                      </TooltipWrapper>
                    ) : (
                      <TooltipWrapper label={"copy"}>
                        <Box ml={2} cursor={"pointer"}>
                          <img src={CopyIcon} alt={"Copy"} onClick={onCopy} />
                        </Box>
                      </TooltipWrapper>
                    )}
                  </Flex>
                  {discountCode && (
                    <Flex alignItems="end">
                      <Box fontSize="12px" fontWeight="normal">
                        {Strings.discCode} <br />
                        {Strings.forYourFollowers}
                      </Box>
                      <Spacer />
                      <Box pl={10}>{discountCode}</Box>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          </Box>
        )}
      </Flex>
    </div>
  );
};

export default SocialMediaCheckbox;
