import {
  clearInfluencersOfBrand,
  Colors,
  getInfluencersOfBrand,
  logger,
  LoggerStrings,
  RootState,
  Strings,
} from "@be-tagged/shared";
import {
  Avatar,
  Box,
  Center,
  Divider,
  Flex,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomSort from "../../../../../app/components/CustomSort";
import { CommonObjectType, ROUTING_URLS } from "src/app/constants";
import {
  FormikKeys,
  InfluencersOfBrandParams,
} from "@be-tagged/shared/src/enums";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "src/app/hooks/useAuth";
import useData from "src/app/hooks/useData";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "src/app/components/LoadingSpinner";
import { NotFound, ProfileIcon } from "@be-tagged/shared/src/assets/icons";
import InsightsSkeleton from "../SkeletonLayout";
import { withCommas } from "src/app/utils";
const { StartFrom, ListQty, CountryID, OrderBy } = FormikKeys;
const PER_PAGE = 10;
interface ITabInfluencers {
  country: CommonObjectType | null;
  dateParams: any;
}
interface sortObject {
  name: string;
  value: string;
}
const sortOptions: sortObject[] = [
  {
    name: "Sales",
    value: "sales",
  },
  {
    name: "Commission",
    value: "commission",
  },
];
/**
 * @param {Object} props - Of the form ITabInfluencers
 * @returns {JSX.Element} - A React Functional Component
 */
const TabInfluencers: FC<ITabInfluencers> = ({
  country,
  dateParams,
}): JSX.Element => {
  const { BUSINESS_INSIGHTS, PRODUCT_DETAILS } = ROUTING_URLS;
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [hasMounted, setHasMounted] = useState(false);
  const [isLazyLoading, setIsLazyLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isBrand } = useAuth();
  const { loading } = useData();

  useEffect(() => {
    return () => {
      dispatch(clearInfluencersOfBrand());
    };
  }, []);

  useEffect(() => {
    if (isBrand && !country) return;
    initiateFetch();
  }, [dateParams, country, sortBy]);

  const influencers = useSelector((state: RootState) => {
    return state.brandReducer?.insights?.influencers || {};
  });

  useEffect(() => {
    if (hasMounted) fetchProductsSold(true);
    else setHasMounted(true);
  }, [currentPage]);

  const fetchProductsSold = (persistPrevious = false) => {
    if (!persistPrevious) dispatch(clearInfluencersOfBrand());
    let params: CommonObjectType = {
      [StartFrom]: currentPage * PER_PAGE - PER_PAGE,
      [ListQty]: PER_PAGE,
      [OrderBy]: sortBy,
      [CountryID]: country?.id,
    };

    let finalPayload = {
      ...params,
      ...dateParams,
    } as InfluencersOfBrandParams;

    dispatch(
      getInfluencersOfBrand(
        finalPayload,
        persistPrevious ? setIsLazyLoading : undefined
      )
    );
  };

  const initiateFetch = () => {
    if (currentPage !== 1) setCurrentPage(1);
    else fetchProductsSold();
  };
  useEffect(() => {
    if (sortBy) {
      const user = Strings.brand;
      logger.info(`${user} ${LoggerStrings.sortedInfluencersList}`, {
        sortBy,
      });
    }
  }, [sortBy]);

  const navigateToProductDetails = (id: number) => {
    navigate(pathname.replace(BUSINESS_INSIGHTS, PRODUCT_DETAILS) + "/" + id);
  };
  return (
    <Stack>
      <Flex direction={"column"} minHeight={{ md: "500" }}>
        {influencers?.data?.length === 0 || !country ? (
          <Center>
            <Flex direction="column" alignItems="center">
              <img src={NotFound} alt="NoDataFound" />
              <Box fontSize="14px">{Strings.noDataFound}</Box>
            </Flex>
          </Center>
        ) : (
          <>
            <Box mb={4}>
              <CustomSort onChange={setSortBy} sortOptions={sortOptions} />
            </Box>
            {loading && !isLazyLoading ? (
              <InsightsSkeleton />
            ) : (
              <InfiniteScroll
                className="insights_scroll_parent"
                dataLength={influencers?.data?.length || 0}
                next={() => {
                  setCurrentPage(currentPage + 1);
                }}
                hasMore={influencers?.nextLink}
                loader={<LoadingSpinner />}
              >
                {influencers?.data?.map((el: any, index: number) => {
                  return (
                    <div key={index}>
                      <Flex
                        mb={5}
                        direction={{
                          base: "column",
                          md: "row",
                        }}
                        bgColor="white"
                        shadow="md"
                        p={{ md: "15px", base: "25px" }}
                        borderRadius={10}
                      >
                        <Flex alignItems="center" width="260px">
                          <Box width="50px" mr={5}>
                            <Avatar
                              size={"sm"}
                              src={el.influencerProfilePicUrl}
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "100px",
                              }}
                            />
                          </Box>
                          <Box
                            fontSize="14px"
                            fontWeight="600"
                            color={Colors.purple}
                          >
                            <Box width="180px">{el.influencerName}</Box>
                          </Box>
                        </Flex>
                        <SimpleGrid
                          templateColumns="repeat(2,1fr)"
                          spacingY={5}
                          mt={{ md: "0px", base: "15px" }}
                          width="min-content"
                        >
                          <Box width="100px">
                            <Box className="title" color={Colors.grey100}>
                              {Strings.sales}
                            </Box>
                            <Box className="value" color={Colors.grey}>
                              {withCommas(el.sales)}
                            </Box>
                          </Box>

                          <Box width="100px">
                            <Box className="title" color={Colors.grey100}>
                              {Strings.commission}
                            </Box>
                            <Box className="value" color={Colors.grey}>
                              {el.currencySymbol}&nbsp;
                              {withCommas(el.commission)}
                            </Box>
                          </Box>
                        </SimpleGrid>
                        <Flex
                          ml={{ md: "20px", base: "0px" }}
                          mt={{ md: "0px", base: "10px" }}
                          width={{ md: "700px", base: "fit-content" }}
                        >
                          <Box>
                            <Box className="title" color={Colors.grey100}>
                              {Strings.productsSold}
                            </Box>
                            <Flex flexWrap="wrap">
                              {el.productsSold?.map((ele: any, index: any) => {
                                return (
                                  <>
                                    <Box
                                      className="value"
                                      color={Colors.purple}
                                      mr="10px"
                                      key={index}
                                      onClick={() => {
                                        navigateToProductDetails(
                                          ele.brandProductId
                                        );
                                      }}
                                    >
                                      {ele.brandProductName}
                                    </Box>
                                    {index !== el.productsSold?.length - 1 && (
                                      <Divider
                                        orientation="vertical"
                                        borderRightWidth="3px"
                                        borderLeftWidth="0px"
                                        height="15px"
                                        opacity={0.8}
                                        mr="10px"
                                        my="auto"
                                        borderRightColor={Colors.grey100}
                                      />
                                    )}
                                  </>
                                );
                              })}
                            </Flex>
                          </Box>
                        </Flex>
                      </Flex>
                    </div>
                  );
                })}
              </InfiniteScroll>
            )}
          </>
        )}
      </Flex>
    </Stack>
  );
};

export default TabInfluencers;
