import {
  Colors,
  getBrandTeamMembers,
  logger,
  LoggerStrings,
  RootState,
  Strings,
} from "@be-tagged/shared";
import {
  LeftPagination,
  RightPagination,
  SearchIcon,
  Trash,
  NoProductsListed,
} from "@be-tagged/shared/src/assets/icons";
import { Box, Center, Flex, SimpleGrid } from "@chakra-ui/react";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import AddMemberModal from "./Helper Components/AddMemberModal";
import "./Teams.styles.scss";
import {
  Pagination,
  usePagination,
  PaginationPage,
  PaginationNext,
  PaginationPrevious,
  PaginationPageGroup,
  PaginationContainer,
  PaginationSeparator,
} from "@ajna/pagination";
import useAuth from "src/app/hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { FormikKeys, TeamQueryParams } from "@be-tagged/shared/src/enums";
import { CommonObjectType, ROUTING_PARAMS } from "src/app/constants";
import RemoveMemberModal from "./Helper Components/RemoveMemberModal";
import TeamSkeleton from "./Helper Components/SkeletonLayout";
import { getURLParams } from "src/app/utils/Misc";

/**
 * @returns {JSX.Element}
 */
const TeamManagement: FC<{}> = (): JSX.Element => {
  const { loading } = useSelector((state: RootState) => state?.appReducer);
  const { StartFrom, ListQty, Search } = FormikKeys;
  const initialParams: CommonObjectType = {
    [StartFrom]: 0,
    [ListQty]: 5,
    [Search]: "",
  };
  const { name } = useAuth();
  const [showAddMemberModal, setShowAddMemberModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState(initialParams);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [withQuery, setWithQuery] = useState<boolean>(false);
  const clearPrev = !searchQuery && withQuery;
  const [id, setId] = useState(-1);
  const [email, setEmail] = useState("");
  const closeFilter = () => {
    setShowAddMemberModal(false);
  };
  const openRemoveMemberModal = (id: number, email: string) => {
    setId(id);
    setEmail(email);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const teamMembers =
    useSelector((state: RootState) => {
      return state.brandReducer?.teamMembers;
    }) || [];
  const { pages, pagesCount, currentPage, setCurrentPage, isDisabled } =
    usePagination({
      total: teamMembers?.totalCount,
      limits: {
        outer: 1,
        inner: 1,
      },
      initialState: {
        pageSize: 5,
        isDisabled: false,
        currentPage: 1,
      },
    });

  const dispatch = useDispatch();
  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };
  const filterByName = (e: SyntheticEvent) => {
    e.preventDefault();
    let _temp = {
      ...searchParams,
      [Search]: searchQuery,
      [StartFrom]: 0,
    };
    const isAtFirstPage = currentPage === 1;
    changeParams(_temp, isAtFirstPage);
    if (!isAtFirstPage) setCurrentPage(1);
  };

  const changeParams = (params: any, triggerChange: boolean = true) => {
    setSearchParams(params);
    setWithQuery(!!params.criteria);
    triggerChange && dispatch(getBrandTeamMembers(params, false));
  };

  useEffect(() => {
    changeParams({
      ...searchParams,
      [StartFrom]: currentPage * 5 - 5,
    });
  }, [currentPage]);

  useEffect(() => {
    if (clearPrev) {
      let _temp = { ...searchParams };
      _temp = {
        ..._temp,
        [StartFrom]: 0,
        [Search]: "",
      };
      changeParams({
        ..._temp,
        [Search]: searchQuery,
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    switch (getURLParams("action")) {
      case ROUTING_PARAMS.ADD_TEAM_MEMBER:
        setShowAddMemberModal(true);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (withQuery && searchQuery) {
      const user = Strings.brand;
      logger.info(
        `${user} ${LoggerStrings.searchedForTeamMember} ${searchQuery}`
      );
    }
  }, [withQuery, searchQuery]);

  return (
    <Box>
      <AddMemberModal
        isOpen={showAddMemberModal}
        onClose={() => null}
        closeFilter={closeFilter}
      />
      <RemoveMemberModal
        isOpen={showModal}
        onClose={() => null}
        closeModal={closeModal}
        id={id}
        email={email}
      />
      <Flex direction="column" mb={{ md: "20px", base: "30px" }}>
        {/* Flex for heading */}
        <Flex
          direction={{
            base: "column",
            tablet: "row",
          }}
          fontSize="36px"
          fontWeight="600"
          mb={5}
        >
          <Box>
            {name}
            {", "}
            <span style={{ color: Colors.dollarGreen }}>
              {Strings.your} {Strings.beTaggedTeam}
            </span>
          </Box>
        </Flex>
        {/* Following Flex includes Navigation and Search & Filter operation */}
        <Flex
          direction={{ md: "row", base: "column" }}
          columnGap={5}
          height="51px"
          alignItems={{ md: "center", base: "flex-start" }}
          mb={10}
        >
          {/* Search Operation */}
          <form onSubmit={filterByName}>
            <Box
              width={{ md: "40vw", base: "90vw" }}
              position="relative"
              mb={{ md: "0px", base: "3" }}
            >
              <input
                type="text"
                onChange={(e: any) => {
                  setSearchQuery(e.target.value);
                }}
                placeholder={Strings.typeYourSearch}
                className="search-bar box-shadow"
              />
              <button
                type="submit"
                className="search-button box-shadow"
                style={{ background: Colors.purple }}
              >
                <img src={SearchIcon} alt={"Search"} />
              </button>
            </Box>
          </form>
          <button
            className="approval-based"
            style={{ color: Colors.purple }}
            onClick={() => {
              setShowAddMemberModal(true);
            }}
          >
            {Strings.addTeamMember}
          </button>
        </Flex>
        {loading ? (
          <TeamSkeleton />
        ) : !teamMembers?.totalCount ? (
          <Center>
            <Flex direction="column" alignItems="center">
              <img
                src={NoProductsListed}
                alt="NoDataFound"
                width={"300px"}
                height={"300px"}
              />
              <Box fontSize="16px" p={10}>
                {searchQuery && withQuery
                  ? Strings.noDataFound
                  : Strings.noTeamAdded}
              </Box>
            </Flex>
          </Center>
        ) : (
          <>
            <Flex my={2} overflowX="scroll" direction={"column"}>
              <SimpleGrid
                templateColumns="1fr 0.5fr 0.5fr 0.5fr"
                fontSize={"12px"}
                fontWeight={"600"}
                color={Colors.grey100}
                columnGap={10}
                px={5}
              >
                <Box minW={200}>{Strings.email}</Box>
                <Box minW={200}>{Strings.role}</Box>
                <Box minW={200}>{Strings.status}</Box>
              </SimpleGrid>
              <Flex
                direction="column"
                height={250}
                border="1px solid #D3E0FC"
                borderRadius={10}
                minW={{ md: "-webkit-fill-available", base: "800px" }}
                width={{ md: "-webkit-fill-available", base: "fit-content" }}
              >
                {teamMembers?.data?.map((el: any, index: number) => {
                  return (
                    <SimpleGrid
                      className="selected_row"
                      templateColumns="1fr 0.5fr 0.5fr 0.5fr"
                      fontSize={"14px"}
                      color={
                        el.status === "Invitation Sent"
                          ? Colors.grey100
                          : Colors.grey
                      }
                      columnGap={10}
                      px={5}
                      py={3}
                      key={index}
                    >
                      <Box minW={200}>{el.email}</Box>
                      <Box minW={200}>{el.role}</Box>
                      <Box minW={200}>{el.status}</Box>
                      <button
                        className="delete_btn"
                        onClick={() => {
                          openRemoveMemberModal(el.id, el.email);
                        }}
                      >
                        <img
                          src={Trash}
                          alt="delete"
                          style={{ maxWidth: "fit-content" }}
                        />
                      </button>
                    </SimpleGrid>
                  );
                })}
              </Flex>
            </Flex>
            <Pagination
              pagesCount={pagesCount}
              currentPage={currentPage}
              isDisabled={isDisabled}
              onPageChange={handlePageChange}
            >
              <PaginationContainer align="center" justify="flex-end">
                <PaginationPrevious bg={Colors.bgDark}>
                  <div>
                    <img src={LeftPagination} alt="Previous" />
                  </div>
                </PaginationPrevious>
                <PaginationPageGroup
                  isInline
                  align="center"
                  separator={
                    <PaginationSeparator
                      bg={Colors.bgDark}
                      fontSize="sm"
                      jumpSize={11}
                    />
                  }
                >
                  {pages.map((page: number) => (
                    <PaginationPage
                      w={10}
                      color="black"
                      fontWeight="400"
                      key={`pagination_page_${page}`}
                      page={page}
                      fontSize="18px"
                      borderRadius="100%"
                      _hover={{
                        bg: Colors.dollarGreen,
                      }}
                      _current={{
                        bg: Colors.dollarGreen,
                        fontSize: "18px",
                        w: 10,
                        color: Colors.themeColor,
                      }}
                    />
                  ))}
                </PaginationPageGroup>
                <PaginationNext bg={Colors.bgDark}>
                  <div>
                    <img src={RightPagination} alt="Next" />
                  </div>
                </PaginationNext>
              </PaginationContainer>
            </Pagination>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default TeamManagement;
