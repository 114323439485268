import { beTaggedStyles, Label, PurpleArrowDropdown } from "src/app/components";
import Select from "react-select";
import {
  commissionRange,
  influencersRange,
  offerType,
  salesRange,
  Strings,
} from "@be-tagged/shared";
import { FC } from "react";
import { FilterType } from "src/app/enums";

const dropdownValues = {
  influencer: influencersRange,
  commission: commissionRange,
  offer: offerType,
  sales: salesRange,
};

interface FilterDropdownProps {
  isMulti?: boolean;
  isSearchable?: boolean;
  placeholder: string;
  optionValue: string;
  optionLabel: string;
  value: any;
  type: FilterType;
  onChange: (x: any) => void;
}

export const FilterDropdown: FC<FilterDropdownProps> = ({
  placeholder,
  optionLabel,
  optionValue,
  isMulti,
  isSearchable = true,
  value,
  type,
  onChange,
}) => {
  return (
    <>
      <Label>{placeholder}</Label>
      <Select
        components={PurpleArrowDropdown}
        styles={beTaggedStyles}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isClearable={true}
        defaultValue={null}
        options={dropdownValues[type]}
        getOptionLabel={(option) => `${option?.[optionLabel]}`}
        getOptionValue={(option: any) => option?.[optionValue]}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

FilterDropdown.defaultProps = {
  placeholder: "",
  isMulti: false,
};
