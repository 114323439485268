import {
  getBrandProductsUrl,
  getFilteredBrandProductsURL,
  getUpdateDiscountCodesURL,
  getProductInsightsURL,
  getInfluencerRequestsForApprovalURL,
  getInfluencersOfProductURL,
  getUnlistProductURL,
  getCommissionDistributionUrl,
} from "../../../utils/UrlBuilder";
import {
  CatalogQueryParams,
  FormikKeys,
  GenericSearchParams,
  HTTPMethod,
  ProductInsightsParams,
  UpdateDiscountCodesParams,
} from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import {
  EMPTY_APPROVAL_DATA,
  SET_SUCCESS,
  UPDATE_INFLUENCER_SEEKING_APPROVAL_DATA,
  UPDATE_INFLUENCER_REQUESTS_FOR_APPROVAL,
  UPDATE_INFLUENCERS_OF_PRODUCT,
  UPDATE_PRODUCT_INSIGHTS,
  CLEAR_ALL_INFLUENCER_REQUESTS,
  UPDATE_INFLUENCER_REQUESTS_STATUS,
  COMMISSION_PERCENTAGE_DISTRIBUTION,
  EMPTY_COMMISSION_PERCENTAGE_DISTRIBUTION,
} from "../../constants/ReduxConstants";
import Strings from "../../../strings/LocalisedStrings";
import { getProductDetails } from "../CommonActions";
import LoggerStrings from "../../../strings/LoggerStrings";
import { logger } from "../../../services/Logging";

// To Submit the Product List Details
export const submitProductDetails =
  (productDetails: any, navigationFn: any) => async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      queryStringParams: {},
      data: productDetails,
      url: getBrandProductsUrl(),
    };
    return fetchAsync(dispatch, [request], [() => postListing(navigationFn)]);
  };
const postListing = (navigationFn: any) => {
  navigationFn();
  return {
    type: SET_SUCCESS,
    payload: {
      message: Strings.productListedSuccessfully,
    },
  };
};

// To get calculate-commission-distribution data

export const getCommissionDistribution =
  (commission: any) => async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      queryStringParams: {},
      data: commission,
      url: getCommissionDistributionUrl(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => updateCommissionDistribution(payload)]
    );
  };

const updateCommissionDistribution = (payload: any) => {
  return {
    type: COMMISSION_PERCENTAGE_DISTRIBUTION,
    payload: payload,
  };
};

export const emptyCommissionPercentage = () => {
  return {
    type: EMPTY_COMMISSION_PERCENTAGE_DISTRIBUTION,
    payload: {},
  };
};

// get Approval data
export const getInfluencerSeekingApprovalData =
  (data: any, isMobile: boolean = false) =>
  async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      data,
      url: getFilteredBrandProductsURL(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => updateInfluencerSeekingApprovalData(payload, isMobile)]
    );
  };

const updateInfluencerSeekingApprovalData = (
  payload: any,
  isMobile: boolean
) => {
  return {
    type: UPDATE_INFLUENCER_SEEKING_APPROVAL_DATA,
    payload: { response: payload.data, isMobile },
  };
};

// Get Influencer requests for approval for specific product
export const getInfluencerRequestsForApproval =
  (brandProductId: number, params: CatalogQueryParams, booleanFn?: any) =>
  async (dispatch: any) => {
    booleanFn?.(true);
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: params,
      url: getInfluencerRequestsForApprovalURL(brandProductId),
    };
    return fetchAsync(
      dispatch,
      [request],
      [
        (payload: any) =>
          updateInfluencerRequestsForApproval(
            payload,
            brandProductId,
            booleanFn
          ),
      ]
    );
  };

export const clearInfluencerRequests = () => ({
  type: CLEAR_ALL_INFLUENCER_REQUESTS,
});

const updateInfluencerRequestsForApproval = (
  payload: any,
  brandProductId: any,
  booleanFn?: any
) => {
  booleanFn?.(false);
  return {
    type: UPDATE_INFLUENCER_REQUESTS_FOR_APPROVAL,
    payload: { data: payload.data, id: brandProductId },
  };
};
export const emptyApprovalData = () => {
  return {
    type: EMPTY_APPROVAL_DATA,
    payload: [],
  };
};
// Get Influencer requests for approval for specific product
export const validateInfluencerRequest =
  (requestData: any, booleanFn?: any) => async (dispatch: any) => {
    booleanFn?.(true);
    const { brandProductId } = requestData;
    const request = {
      method: HTTPMethod.PUT,
      data: requestData,
      url: getInfluencerRequestsForApprovalURL(brandProductId),
    };
    return fetchAsync(
      dispatch,
      [request],
      [
        (payload: any) =>
          updateInfluencerRequestsValidationStatus(
            payload,
            requestData,
            booleanFn
          ),
      ]
    );
  };

const updateInfluencerRequestsValidationStatus = (
  payload: any,
  requestData: any,
  booleanFn?: any
) => {
  return (dispatch: any) => {
    booleanFn?.(false);
    const { approvalStatus } = requestData;
    dispatch({
      type: SET_SUCCESS,
      payload: {
        message: Strings.influencerRequest + " " + approvalStatus,
      },
    });
    return dispatch({
      type: UPDATE_INFLUENCER_REQUESTS_STATUS,
      payload: {
        requestData,
        responseData: payload.data,
      },
    });
  };
};

// To update the discount codes
export const updateDiscountCodes =
  (data: UpdateDiscountCodesParams) => async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      data,
      url: getUpdateDiscountCodesURL(data?.[FormikKeys.BrandProductID]),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => postDiscountCodesUpdate({ pay: payload, data })]
    );
  };

const postDiscountCodesUpdate = (payload: any) => {
  logger.info(`${Strings.brand} ${LoggerStrings.uploadedCouponCodes}`, {
    prodId: payload.data?.brandProductId,
  });
  return {
    type: SET_SUCCESS,
    payload: {
      message: payload?.pay?.data?.data || Strings.discountCouponsUpdated,
    },
  };
};

// Get influencers associated with a product
export const getProductRelatedInfluencers =
  (brandProductId: number, params: GenericSearchParams, booleanFn?: any) =>
  async (dispatch: any) => {
    booleanFn?.(true);
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: {
        [FormikKeys.ListQty]: params[FormikKeys.ListQty] || 10,
        ...params,
      },
      url: getInfluencersOfProductURL(brandProductId),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => postInfluencersFetch(payload, booleanFn)]
    );
  };

const postInfluencersFetch = (payload: any, booleanFn?: any) => {
  booleanFn?.(false);
  return {
    type: UPDATE_INFLUENCERS_OF_PRODUCT,
    payload,
  };
};

export const clearProductRelatedInfluencers = (isMobile: boolean = false) => {
  return {
    type: UPDATE_INFLUENCERS_OF_PRODUCT,
    payload: null,
  };
};

// Get insights of a product
export const getProductInsights =
  (brandProductId: number, params: ProductInsightsParams) =>
  async (dispatch: any) => {
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: {
        id: brandProductId,
        ...params,
      },
      url: getProductInsightsURL(brandProductId),
    };
    return fetchAsync(dispatch, [request], [postInsightsFetch]);
  };

const postInsightsFetch = (payload: any) => {
  return {
    type: UPDATE_PRODUCT_INSIGHTS,
    payload: payload,
  };
};

// Unlist a product
export const unlistProduct =
  (brandProductId: number, callBackFn?: any, brandProductName?: string) =>
  async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      data: {},
      url: getUnlistProductURL(brandProductId),
    };
    return fetchAsync(
      dispatch,
      [request],
      [
        (payload: any) =>
          postUnlist(payload, brandProductId, brandProductName, callBackFn),
      ]
    );
  };

const postUnlist = (
  payload: any,
  brandProductId: number,
  brandProductName: any,
  callBackFn: any
) => {
  callBackFn();
  logger.info(`${Strings.brand} ${LoggerStrings.unlistedTheProduct}`, {
    prodId: brandProductId,
    prodName: brandProductName,
  });
  return (dispatch: any) => {
    dispatch(getProductDetails(brandProductId, true)); // true because the second argument is a boolean for whether the user is a brand or not
    // and the action of unlisting is always done by a brand only
    return dispatch({
      type: SET_SUCCESS,
      payload: {
        message: Strings.productUnlisted,
      },
    });
  };
};

// To Submit the Product List Details
export const editProductDetails =
  (productDetails: any, id: number, navigationFn: any) =>
  async (dispatch: any) => {
    const request = {
      method: HTTPMethod.PUT,
      queryStringParams: {},
      data: productDetails,
      url: `${getBrandProductsUrl()}/${id}`,
    };
    return fetchAsync(dispatch, [request], [() => postEditing(navigationFn)]);
  };
const postEditing = (navigationFn: any) => {
  navigationFn();
  return {
    type: SET_SUCCESS,
    payload: {
      message: Strings.productEditedSuccessfully,
    },
  };
};
