import {
  getProductLeaderboardURL,
  getProductLeaderboardInsightsURL,
  setPromotionRequestURL,
  getProductsPromotedByURL,
} from "../../../utils/UrlBuilder";
import { HTTPMethod, LeaderboardParams } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import {
  SET_PROMOTION_REQUEST,
  UPDATE_LEADERBOARD_DATA,
  UPDATE_LEADERBOARD_INSIGHTS,
  UPDATE_PRODUCT_PROMOTION_DETAIL,
  UPDATE_PRODUCTS_PROMOTED,
  CLEAR_PRODUCTS_PROMOTED,
} from "../../constants/ReduxConstants";
interface PromotionRequestType {
  salesChannel: string;
  brandProductId: number;
  setOpenModal: any;
}

//  To POST promotion request from influencer
export const setPromotionRequest =
  (keyToUpdate: string, data: PromotionRequestType) =>
  async (dispatch: any) => {
    const id = data.brandProductId;
    const URL = setPromotionRequestURL().replace("{id}", `${id}`);
    const request = {
      method: HTTPMethod.POST,
      url: URL,
      data,
    };
    return fetchAsync(
      dispatch,
      [request],
      [
        (payload: any) =>
          updatePromotionRequest(payload, keyToUpdate, data?.setOpenModal),
      ]
    );
  };

const updatePromotionRequest = (
  payload: any,
  keyToUpdate: string,
  callbackFn?: any
) => {
  if (payload?.data?.productLink) {
    callbackFn?.(true);
  }
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_PRODUCT_PROMOTION_DETAIL,
      payload: {
        data: payload.data,
        keyToUpdate,
      },
    });
    return {
      type: SET_PROMOTION_REQUEST,
      payload: payload.data,
    };
  };
};

export const clearPromotionRequest = () => {
  return {
    type: SET_PROMOTION_REQUEST,
    payload: null,
  };
};

// The leaderboard associated with a product is shown to an influencer
export const getProductLeaderboard =
  (params: LeaderboardParams, booleanFn?: any) => async (dispatch: any) => {
    booleanFn?.(true);
    const id = params.brandProductId;
    const request = {
      method: HTTPMethod.GET,
      url: getProductLeaderboardURL(id),
      queryStringParams: params,
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => setLeaderboard(payload, booleanFn)]
    );
  };

const setLeaderboard = (payload: any, booleanFn?: any) => {
  booleanFn?.(false);
  return async (dispatch: any) => {
    return dispatch({
      type: UPDATE_LEADERBOARD_DATA,
      payload,
    });
  };
};

export const clearProductLeaderboard = () => {
  return setLeaderboard(null);
};

export const getLeaderboardInsights = (id: number) => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.GET,
    url: getProductLeaderboardInsightsURL(id),
    queryStringParams: {},
  };
  return fetchAsync(dispatch, [request], [setInsights]);
};

const setInsights = (payload: any) => ({
  type: UPDATE_LEADERBOARD_INSIGHTS,
  payload,
});

export const getProductsPromotedBy =
  (data: any, isMobile: boolean = false, callbackFn?: any) =>
  async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      data,
      url: getProductsPromotedByURL(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => updateProductsPromoted(payload, isMobile, callbackFn)]
    );
  };

const updateProductsPromoted = (
  payload: any,
  isMobile: boolean,
  callbackFn: string
) => {
  return {
    type: UPDATE_PRODUCTS_PROMOTED,
    payload: { data: payload.data, isMobile: isMobile },
  };
};

export const clearProductsPromotedBy = () => {
  return {
    type: CLEAR_PRODUCTS_PROMOTED,
    payload: [],
  };
};
