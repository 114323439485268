import { ROUTING_URLS } from "../../app/constants";
import Landing from "./Landing";
import ProductListing from "./ProductListing";
import ProductsCatalog from "./ProductsCatalog";
import ProductDetails from "./ProductDetails";
import Insights from "./Insights";
import Settings from "./Settings";

import { Routes, Route, Navigate } from "react-router-dom";
import ApprovalRequests from "./ProductsCatalog/ApprovalRequests";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserInfo } from "@be-tagged/shared";
import DashboardLayout from "../layout/DashboardLayout";
import TeamManagement from "./Teams";
import useAuth from "src/app/hooks/useAuth";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { isBrand } = useAuth();

  useEffect(() => {
    // If the user visits the landing route or any of its subroutes directly, get the user info
    dispatch(getUserInfo());
  }, []);

  return (
    <DashboardLayout>
      <Routes>
        <Route index element={<Landing />} />
        <Route
          path={`${ROUTING_URLS.PRODUCT_LISTING}`}
          element={
            isBrand ? (
              <ProductListing />
            ) : (
              <Navigate replace to={`${ROUTING_URLS.LANDING}`} />
            )
          }
        />
        <Route
          path={`${ROUTING_URLS.PRODUCT_LISTING}/:id`}
          element={
            isBrand ? (
              <ProductListing />
            ) : (
              <Navigate replace to={`${ROUTING_URLS.LANDING}`} />
            )
          }
        />
        <Route
          path={`${ROUTING_URLS.PRODUCTS_CATALOG}`}
          element={<ProductsCatalog />}
        />
        <Route
          path={`${ROUTING_URLS.PRODUCT_DETAILS}/:id`}
          element={<ProductDetails />}
        />
        <Route
          path={`${ROUTING_URLS.BUSINESS_INSIGHTS}`}
          element={<Insights />}
        />
        <Route path={`${ROUTING_URLS.PROFILE}`} element={<Settings />} />
        <Route
          path={`${ROUTING_URLS.APPROVAL_REQUESTS}`}
          element={
            isBrand ? (
              <ApprovalRequests />
            ) : (
              <Navigate replace to={`${ROUTING_URLS.LANDING}`} />
            )
          }
        />
        <Route
          path={`${ROUTING_URLS.TEAMS}`}
          element={
            isBrand ? (
              <TeamManagement />
            ) : (
              <Navigate replace to={`${ROUTING_URLS.LANDING}`} />
            )
          }
        />
      </Routes>
    </DashboardLayout>
  );
};

export default Dashboard;
export { Landing, ProductListing, ProductsCatalog, ProductDetails };
