import { Colors } from '@be-tagged/shared'
import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  .colored_switch span.chakra-switch__track {
    background-color: ${Colors.purple};
  }
  .colored_switch.to_green span.chakra-switch__track[data-checked] {
    background-color: ${Colors.dollarGreen};
  }
  .colored_switch + span:not([data-focus]) {
    background-color: ${Colors.purple};
  }
`