import { Box, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { SortDropdown } from "src/app/components";
import { SortAscIcon, SortDescIcon } from "@be-tagged/shared/src/assets/icons";
import "./CustomSort.scss";
import { useEffect } from "react";
import TooltipWrapper from "src/app/components/TooltipWrapper";

export interface sortObject {
  name: string;
  value: string;
}

const CustomSort = ({
  onChange,
  sortOptions,
}: {
  onChange: any;
  sortOptions: any[];
}) => {
  const [sortBy, setSortBy] = useState<sortObject | null>(null);
  const [byAsc, setByAsc] = useState(true);

  useEffect(() => {
    onChange(
      sortBy?.value ? `${sortBy.value} ${byAsc ? "asc" : "desc"}` : null
    );
  }, [sortBy, byAsc]);

  return (
    <Box className="sort_container">
      <Box>Sort By:</Box>
      <Box className="dropdown_container">
        <SortDropdown
          options={sortOptions}
          value={sortBy}
          onChange={setSortBy}
        />
      </Box>
      {sortBy && (
        <TooltipWrapper
          label={`Click to sort by ${byAsc ? "Descending" : "Ascending"} order`}
        >
          <img
            style={{ cursor: "pointer" }}
            onClick={() => setByAsc(!byAsc)}
            src={byAsc ? SortAscIcon : SortDescIcon}
          />
        </TooltipWrapper>
      )}
    </Box>
  );
};

export default CustomSort;
