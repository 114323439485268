import { Box, Center, Flex, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react";
import "./ProductsCatalog.styles.scss";
import {
  SearchIcon,
  RightPagination,
  LeftPagination,
  FilterIcon,
  NotFound,
  IconSuccessAwaits,
  IconNoProductFound
} from "@be-tagged/shared/src/assets/icons";
import { CustomBtn } from "src/app/components";
import { List } from "./HelperComponents/index";
import { useEffect, useState, SyntheticEvent, Fragment, useMemo, FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonObjectType, ROUTING_PARAMS, ROUTING_URLS } from "src/app/constants";
import {
  Colors,
  filterProductsCatalog,
  getCategories,
  getProductsCatalog,
  getProductsPromotedBy,
  logger,
  LoggerStrings,
  RootState,
  Strings
} from "@be-tagged/shared";
import { useDispatch, useSelector } from "react-redux";
import {
  Pagination,
  usePagination,
  PaginationPage,
  PaginationNext,
  PaginationPrevious,
  PaginationPageGroup,
  PaginationContainer,
  PaginationSeparator
} from "@ajna/pagination";
import useAuth from "src/app/hooks/useAuth";
import { FilterModal } from "./HelperComponents";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import ProductsCatalogSkeleton from "./HelperComponents/SkeletonLayout";
import { getURLParams } from "src/app/utils/Misc";
import useSetPageTitle from "src/app/hooks/useSetPageTitle";

interface ProductsListProps {
  productData?: any;
  isBrand?: boolean;
  withQuery?: boolean;
  navigateToProductListing?: () => void;
  pages?: any;
  pagesCount?: any;
  currentPage?: any;
  handlePageChange?: any;
  isDisabled?: any;
}

/**
 * @returns {JSX.Element} - A React Functional Component
 */
const ProductsCatalog = (): JSX.Element => {
  const {
    StartFrom,
    ListQty,
    OrderBy,
    Search,
    Influencers,
    Sales,
    CommissionPercent,
    Brands,
    ProductsAndServices: Categories,
    OfferType
  } = FormikKeys;

  const initalParams = {
    [StartFrom]: 0,
    [ListQty]: 5,
    [OrderBy]: "",
    [Search]: ""
  };
  const [tabIndex, setTabIndex] = useState(0);
  const onTabChange = (index: number) => {
    setTabIndex(index);
  };
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [withQuery, setWithQuery] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  // Filters
  const [influencerFilter, setInfluencerFilter] = useState<any[]>([]);
  const [salesFilter, setSalesFilter] = useState<any[]>([]);
  const [commissionFilter, setCommissionFilter] = useState<any[]>([]);
  const [brandFilter, setBrandFilter] = useState<any[]>([]);
  const [offerFilter, setOfferFilter] = useState<CommonObjectType | null>(null);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>([]);

  const closeFilter = () => {
    setShowFilter(false);
  };

  const filterProps = {
    influencerFilter,
    setInfluencerFilter,
    salesFilter,
    setSalesFilter,
    commissionFilter,
    setCommissionFilter,
    brandFilter,
    setBrandFilter,
    offerFilter,
    setOfferFilter,
    categoriesFilter,
    setCategoriesFilter,
    closeFilter
  };

  const { isBrand } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { PRODUCTS_CATALOG, PRODUCT_LISTING, APPROVAL_REQUESTS } = ROUTING_URLS;
  const navigateToProductListing = () => {
    navigate(pathname.replace(PRODUCTS_CATALOG, PRODUCT_LISTING));
  };
  const navigateToApprovalRequests = () => {
    navigate(pathname.replace(PRODUCTS_CATALOG, APPROVAL_REQUESTS));
  };
  useSetPageTitle("Products Catalog");
  const { productCatalog: productData = [], loading } = useSelector((state: RootState) => {
    return {
      ...state.commonReducer?.commonData?.productData,
      loading: state.appReducer?.loading
    };
  });
  const productsPromoted =
    useSelector((state: RootState) => {
      return state.influencerReducer?.productData?.productsPromoted;
    }) || [];
  const dispatch = useDispatch();
  const getData = (par: any) => {
    if (Object.keys(par).length === 0) {
      dispatch(getProductsCatalog(initalParams, isBrand));
      return;
    }
    const currentInitialParams = { ...initalParams, ...par };
    {
      dispatch(getProductsCatalog(currentInitialParams, isBrand));
      !isBrand && dispatch(getProductsPromotedBy(currentInitialParams));
    }
  };
  const { pages, pagesCount, currentPage, setCurrentPage, isDisabled } = usePagination({
    total: isBrand || tabIndex === 0 ? productData?.totalCount : productsPromoted?.totalCount,
    limits: {
      outer: 1,
      inner: 1
    },
    initialState: {
      pageSize: 5,
      isDisabled: false,
      currentPage: 1
    }
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };

  const filterByName = (e: SyntheticEvent) => {
    e.preventDefault();
    currentPage !== 1 ? setCurrentPage(1) : fetchProducts();
  };

  const fetchProducts = () => {
    setWithQuery(!!searchQuery);
    getData({
      [StartFrom]: currentPage * 5 - 5,
      [Search]: searchQuery
    });
  };

  const getFilteredData = () => {
    setWithQuery(!!searchQuery);
    let filterParam = {
      [StartFrom]: currentPage * 5 - 5,
      [Search]: searchQuery,
      [ListQty]: 5,
      [OrderBy]: null
    };
    let additionalFilters: CommonObjectType = {};
    if (isBrand) {
      const getFilterValues = (arr: any[]) => arr.map((el: any) => el.value);
      additionalFilters = {
        [CommissionPercent]: getFilterValues(commissionFilter),
        [Sales]: getFilterValues(salesFilter),
        [Influencers]: getFilterValues(influencerFilter)
      };
    } else {
      const getIds = (arr: any[]) => arr.map((el: any) => parseInt(el.id));
      additionalFilters = {
        [Brands]: getIds(brandFilter),
        [Categories]: getIds(categoriesFilter),
        [OfferType]: offerFilter?.value || ""
      };
    }
    let finalPayload = { ...filterParam, ...additionalFilters };
    dispatch(filterProductsCatalog(finalPayload, isBrand));
    {
      !isBrand && dispatch(getProductsPromotedBy(finalPayload));
    }
  };

  const initateFilter = () => {
    if (currentPage !== 1) setCurrentPage(1);
    else getFilteredData();
  };

  useEffect(() => {
    initateFilter();
  }, [commissionFilter, salesFilter, influencerFilter, categoriesFilter, offerFilter, brandFilter]);

  useEffect(() => {
    if (!searchQuery && withQuery) {
      initateFilter();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (hasMounted) getFilteredData();
    else setHasMounted(true);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [tabIndex]);

  useEffect(() => {
    if (!isBrand && getURLParams("tab") === ROUTING_PARAMS.PROMOTED_BY_ME) {
      setTabIndex(1);
    }
    if (!isBrand) {
      dispatch(getCategories());
    }
  }, []);

  const isFilterActive = useMemo(() => {
    if (isBrand) {
      return influencerFilter.length || salesFilter.length || commissionFilter.length;
    } else {
      return brandFilter.length || offerFilter?.label || categoriesFilter.length;
    }
  }, [influencerFilter, salesFilter, commissionFilter, brandFilter, offerFilter, categoriesFilter]);

  useEffect(() => {
    let tabName = "";
    switch (tabIndex) {
      case 0:
        tabName = Strings.allProducts;
        break;
      case 1:
        tabName = Strings.promotedByMe;
        break;
      default:
        break;
    }
    logger.info(`${isBrand ? Strings.brand : Strings.influencer} ${LoggerStrings.justOpenedThe} "${tabName}" ${LoggerStrings.tab}`);
  }, [tabIndex]);
  useEffect(() => {
    if (withQuery && searchQuery) {
      const user = isBrand ? Strings.brand : Strings.influencer;
      logger.info(`${user} ${LoggerStrings.searchedFor} ${searchQuery}`);
    }
  }, [withQuery, searchQuery]);
  return (
    <>
      <FilterModal {...filterProps} isOpen={showFilter} isBrand={isBrand} onClose={() => null} />
      <Stack>
        {/* Flex for complete top section of Products Catalog */}
        <Flex direction="column" mb={{ md: "20px", base: "30px" }}>
          {/* Flex for heading */}
          <Flex
            direction={{
              base: "column",
              tablet: "row"
            }}
            fontSize="36px"
            fontWeight="600"
            mb={5}
          >
            <Box>
              {isBrand ? Strings.yourProducts : Strings.productsForYou}
              <span style={{ color: Colors.dollarGreen }}>{Strings.betagged}</span>
            </Box>
          </Flex>
          {/* Following Flex includes Navigation and Search & Filter operation */}
          <Flex
            direction={{ md: "row", base: "column" }}
            height="51px"
            alignItems={{ md: "center", base: "flex-start" }}
            justifyContent={{ md: "space-between" }}
            className="header_bar"
          >
            {/* Search Operation */}
            <Flex alignItems="center">
              <form onSubmit={filterByName}>
                <Box width={{ md: "40vw", base: "70vw" }} position="relative">
                  <input
                    type="text"
                    onChange={(e: any) => {
                      setSearchQuery(e.target.value);
                    }}
                    placeholder={Strings.typeYourSearch}
                    className="search-bar box-shadow"
                  />
                  <button type="submit" className="search-button box-shadow" style={{ background: Colors.purple }}>
                    <img src={SearchIcon} alt={"Search"} />
                  </button>
                </Box>
              </form>
              <button onClick={() => setShowFilter(true)} className="filter-icon box-shadow" style={{ backgroundColor: Colors.grey400 }}>
                {/* For when the filter is active */}
                {!!isFilterActive && <div className="filter_dot" style={{ backgroundColor: Colors.dollarGreen }} />}
                <img src={FilterIcon} alt="Filter" width="25px" />
              </button>
            </Flex>
            {/* navigation to Influencers Seeking Approval and Product Listing */}
            {isBrand && (
              <Flex mt={{ md: "0px", base: "10px" }}>
                <button onClick={navigateToApprovalRequests} className="approval-based" style={{ color: Colors.purple }}>
                  <span>{Strings.influencersSeekingApproval}</span>
                </button>
                <Box>
                  <CustomBtn onClick={navigateToProductListing} className="list-product purple_btn medium">
                    {Strings.listProduct}
                  </CustomBtn>
                </Box>
              </Flex>
            )}
          </Flex>
        </Flex>
        {!isBrand ? (
          <Tabs index={tabIndex} colorScheme={"purple"} onChange={(index: any) => onTabChange(index)}>
            <TabList className="tab-list" pb="10px">
              <div className={tabIndex === 0 ? "selected_tab" : ""}>
                <Tab
                  fontSize="14px"
                  pb="0px !important"
                  _selected={{
                    borderBottomWidth: "0px",
                    boxShadow: "none",
                    fontWeight: "600"
                  }}
                >
                  {Strings.allProducts}
                </Tab>
              </div>
              <div className={tabIndex === 1 ? "selected_tab" : ""}>
                <Tab
                  fontSize="14px"
                  pb="0px !important"
                  _selected={{
                    borderBottomWidth: "0px",
                    boxShadow: "none",
                    fontWeight: "600"
                  }}
                >
                  {Strings.promotedByMe}
                </Tab>
              </div>
            </TabList>
            <TabPanels>
              <TabPanel pl={0} pr={0}>
                <ProductsList
                  productData={productData}
                  isBrand={isBrand}
                  withQuery={withQuery}
                  navigateToProductListing={navigateToProductListing}
                  pages={pages}
                  pagesCount={pagesCount}
                  isDisabled={isDisabled}
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </TabPanel>
              <TabPanel pl={0} pr={0}>
                <ProductsList
                  productData={productsPromoted}
                  isBrand={isBrand}
                  withQuery={withQuery}
                  navigateToProductListing={navigateToProductListing}
                  pages={pages}
                  pagesCount={pagesCount}
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <ProductsList
            productData={productData}
            isBrand={isBrand}
            withQuery={withQuery}
            navigateToProductListing={navigateToProductListing}
            pages={pages}
            pagesCount={pagesCount}
            isDisabled={isDisabled}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
          />
        )}
      </Stack>
    </>
  );
};

export const ProductsList: FC<ProductsListProps> = ({
  productData,
  isBrand,
  withQuery,
  navigateToProductListing,
  pages,
  pagesCount,
  currentPage,
  handlePageChange,
  isDisabled
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { PRODUCTS_CATALOG, PRODUCT_DETAILS } = ROUTING_URLS;
  const navigateToProductDetails = (id: number) => {
    navigate(pathname.replace(PRODUCTS_CATALOG, PRODUCT_DETAILS) + "/" + id);
  };
  const loading = useSelector((state: RootState) => state.commonReducer?.commonData?.productData?.isProductsLoading);
  return (
    <Fragment>
      <VStack spacing={4} align="stretch" minH="350px" mb={5}>
        {isBrand && (
          <CustomBtn
            onClick={navigateToProductListing}
            className="purple_btn list_btn_small box-shadow"
            style={{ background: Colors.purple }}
          >
            {Strings.listProduct2}
          </CustomBtn>
        )}
        {loading ? (
          <ProductsCatalogSkeleton />
        ) : !productData?.totalCount ? (
          <Center>
            <Flex direction="column" alignItems="center">
              {withQuery || !isBrand ? (
                <img src={IconNoProductFound} alt="NoDataFound" width={"400px"} height={"300px"} />
              ) : (
                <img src={IconSuccessAwaits} alt="NoDataFound" width={"300px"} height={"300px"} />
              )}
              {withQuery || !isBrand ? (
                <Box fontSize="16px" p={10}>
                  {Strings.productNotFound}
                </Box>
              ) : (
                <Box fontSize="16px" p={10} textAlign="center">
                  {Strings.noProductsListed} <br />
                  <span style={{ fontWeight: 500 }}>{Strings.listYouProductNow}</span>
                </Box>
              )}
            </Flex>
          </Center>
        ) : (
          (productData?.data || [])?.map((el: any, index: number) => {
            return (
              <div
                key={index}
                onClick={() => {
                  navigateToProductDetails(el.brandProductId);
                }}
              >
                <List
                  id={el.brandProductId}
                  isBrand={isBrand}
                  name={el.name}
                  price={el.price}
                  currencySymbol={el.currencySymbol}
                  commission={el.commissionPercentage}
                  image={el.showCaseMediaUrls?.[0]}
                  // brand-specific props
                  influencers={el?.influencerCount}
                  sales={el?.salesCount}
                  status={el?.productStatus === "PendingApproval" ? "Pending" + "\n" + "Approval" : el?.productStatus}
                  // influencer-specific props
                  brand={el?.brandName}
                  category={el?.brandProductCategories}
                  offerType={el?.isApprovalBasedOffer}
                />
              </div>
            );
          })
        )}
      </VStack>
      {productData?.totalCount !== 0 && (
        <Pagination pagesCount={pagesCount} currentPage={currentPage} isDisabled={isDisabled} onPageChange={handlePageChange}>
          <PaginationContainer align="center" justify="flex-end">
            <PaginationPrevious bg={Colors.bgDark}>
              <div>
                <img src={LeftPagination} alt="Previous" />
              </div>
            </PaginationPrevious>
            <PaginationPageGroup isInline align="center" separator={<PaginationSeparator bg={Colors.bgDark} fontSize="sm" jumpSize={11} />}>
              {pages.map((page: number) => (
                <PaginationPage
                  w={10}
                  color="black"
                  fontWeight="400"
                  key={`pagination_page_${page}`}
                  page={page}
                  fontSize="18px"
                  borderRadius="100%"
                  _hover={{
                    bg: Colors.dollarGreen
                  }}
                  _current={{
                    bg: Colors.dollarGreen,
                    fontSize: "18px",
                    w: 10,
                    color: Colors.themeColor
                  }}
                />
              ))}
            </PaginationPageGroup>
            <PaginationNext bg={Colors.bgDark}>
              <div>
                <img src={RightPagination} alt="Next" />
              </div>
            </PaginationNext>
          </PaginationContainer>
        </Pagination>
      )}
    </Fragment>
  );
};

export default ProductsCatalog;
