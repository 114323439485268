import {
  Colors,
  logger,
  LoggerStrings,
  SalesDataSampleFileURL,
  Strings,
} from "@be-tagged/shared";
import {
  TikTokIcon,
  OwnWebsite,
  FacebookIcon,
  InstagramIcon,
  BeTaggedLogoMini,
  TwitterIcon,
} from "@be-tagged/shared/src/assets/icons";
import { FormikKeys, ProductStatus } from "@be-tagged/shared/src/enums";
import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import ImageCarouselComponent from "./HelperComponents/ImageCarousel";
import InfluencerSocialMedia from "./HelperComponents/InfluencerSocialMedia";
import { CommonObjectType } from "src/app/constants";
import {
  DiscountCodeUpload,
  SalesDataUpload,
} from "./HelperComponents/UploadComponents";
import SocialMediaKits from "./HelperComponents/SocialMediaKits";
import WhatIsThisModal from "src/dashboard-module/common/WhatIsThisModal";

interface ITabPanelComponent {
  isBrand: boolean;
  productDetails: CommonObjectType;
}

/**
 * @returns {JSX.Element} - A React Functional Component
 */
const TabProductDetails: FC<ITabPanelComponent> = ({
  isBrand,
  productDetails,
}): JSX.Element => {
  const details: CommonObjectType = {
    [FormikKeys.CompanyProductURL]: {
      name: Strings.ownWebsite,
      icon: OwnWebsite,
    },
    [FormikKeys.TikTokProductURL]: {
      name: Strings.tikTokCommerce,
      icon: TikTokIcon,
    },
    [FormikKeys.BeTaggedMallURL]: {
      name: Strings.beTaggedMall,
      icon: BeTaggedLogoMini,
    },
    [FormikKeys.FBMediaURL]: {
      name: Strings.facebook,
      icon: FacebookIcon,
    },
    [FormikKeys.InstagramMediaURL]: {
      name: Strings.igMediaURL,
      icon: InstagramIcon,
    },
    [FormikKeys.TikTokMediaURL]: {
      name: Strings.tiktokMediaURL,
      icon: TikTokIcon,
    },
    [FormikKeys.TwitterMediaURL]: {
      name: Strings.twitterMediaURL,
      icon: TwitterIcon,
    },
    [FormikKeys.ECommercePlatforms]: {
      name: Strings.eCommercePlatforms,
      icon: OwnWebsite,
    },
  };
  const {
    description,
    socialMediaKits,
    productUrls,
    showCaseMediaUrls,
    isApprovalBasedOffer,
    listedOnUtc,
    salesDataLastUploadedAtUtc,
    promotionalInfo: promotionInfo,
    brandProductId,
    discountCodesCount,
    exhaustedDiscountCodesCount,
  } = productDetails || {};

  const [openModal, setOpenModal] = useState(false);
  const [modalBodyText, setModalBodyText] = useState("");
  const [isVisited, setIsVisited] = useState(false);
  const [platformAndLink, setPlatformAndLink] =
    useState<CommonObjectType | null>({});
  const visitedProductLink = (link: string, platform: string) => {
    setIsVisited(true);
    setPlatformAndLink({ productLink: link, socialMedia: platform });
  };
  useEffect(() => {
    if (isVisited) {
      const user = isBrand ? Strings.brand : Strings.influencer;
      logger.info(`${user} ${LoggerStrings.visitedProductLink}`, {
        productLink: platformAndLink?.productLink,
        socialMedia: platformAndLink?.socialMedia,
      });
    }
  }, [isVisited, platformAndLink]);
  const [clickedOnSampleFile, setClickedOnSampleFile] = useState(false);
  useEffect(() => {
    if (clickedOnSampleFile) {
      const user = Strings.brand;
      logger.info(`${user} ${LoggerStrings.clickedOnDownloadButton}`);
    }
  }, [clickedOnSampleFile]);
  return (
    <>
      <WhatIsThisModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        modalHeader={modalBodyText}
        bodyText={
          modalBodyText === Strings.discountCouponCodes
            ? Strings.couponCodesInfoText
            : modalBodyText === Strings.salesData
            ? Strings.salesDataInfoText
            : ""
        }
      />
      <Box className="product-details-tab">
        <Box w={"100%"}>
          <div className="product-details-container">
            <p className="tabs-title">{Strings.Description}</p>
            <br />
            {description}
          </div>
          <Box className="product-sold-social-parent">
            <Box className="inner-container">
              <div className="tabs-title">{Strings.productSoldOn}</div>
              <div className="product-social-items">
                {Object.keys(productUrls || {})?.map((urlKey, idx: number) => {
                  if (productUrls[urlKey]) {
                    return (
                      <Box className="item" key={idx}>
                        <img
                          src={details?.[urlKey]?.icon}
                          alt={details?.[urlKey]?.name}
                          style={{ width: 36 }}
                        />
                        <div className="social-links">
                          <div className="gray-text">
                            {productDetails?.productIncommDetails
                              ? Strings.inCommMall
                              : details?.[urlKey]?.name}
                          </div>
                          <div className="purple-text">
                            <a
                              href={productUrls[urlKey]}
                              rel="noreferrer noopener"
                              target="_blank"
                              onClick={() => {
                                visitedProductLink(
                                  productUrls[urlKey],
                                  details?.[urlKey]?.name
                                );
                              }}
                            >
                              {Strings.productLink}
                            </a>
                          </div>
                        </div>
                      </Box>
                    );
                  }
                })}
              </div>
            </Box>
            <SocialMediaKits urlMediaMapping={details} kits={socialMediaKits} />
          </Box>
          {isBrand && (
            <Flex
              className="upload-section"
              direction={{ md: "row", base: "column" }}
              rowGap={"10"}
            >
              <Flex className="inner-container" direction={"column"}>
                <DiscountCodeUpload
                  totalCodes={discountCodesCount}
                  usedCodes={exhaustedDiscountCodesCount}
                  productUnlisted={
                    productDetails?.productStatus === ProductStatus.Unlisted
                  }
                  isBuyerDiscountZero={
                    productDetails?.buyerDiscountPercentage === 0
                  }
                />
                <Box
                  mt={4}
                  fontSize={"12px"}
                  color={Colors.purple}
                  cursor={"pointer"}
                  onClick={() => {
                    setOpenModal(true);
                    setModalBodyText(Strings.discountCouponCodes);
                  }}
                  maxW={"fit-content"}
                >
                  {Strings.whatAreCouponCodes}
                </Box>
              </Flex>
              <Box className="inner-container">
                <Text pb={"5px"}>{Strings.salesData}</Text>
                <Flex fontWeight={"normal"} color={Colors.grey100}>
                  <SalesDataUpload
                    prefix={Strings.lastUploaded + ": "}
                    date={salesDataLastUploadedAtUtc}
                    brandProductId={brandProductId}
                    productApprovalPending={
                      productDetails?.productStatus ===
                      ProductStatus.ApprovalPending
                    }
                    productUnlisted={
                      productDetails?.productStatus === ProductStatus.Unlisted
                    }
                    isProductIncommDetails={
                      productDetails?.productIncommDetails !== null
                    }
                  />
                  <Text color={Colors.borderColor} as={"span"} mx={3}>
                    |
                  </Text>
                  <Link
                    href={SalesDataSampleFileURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    color={Colors.purple}
                    fontSize={"12px"}
                    fontWeight={"600"}
                    boxShadow="none !important"
                  >
                    <Flex className="file_link">
                      <Text
                        as={"span"}
                        onClick={() => {
                          setClickedOnSampleFile(true);
                        }}
                      >
                        {Strings.sampleFile}
                      </Text>
                    </Flex>
                  </Link>
                </Flex>
                <Box
                  mt={4}
                  fontSize={"12px"}
                  color={Colors.purple}
                  cursor={"pointer"}
                  onClick={() => {
                    setOpenModal(true);
                    setModalBodyText(Strings.salesData);
                  }}
                  maxW={"fit-content"}
                >
                  {Strings.whatIsSalesData}
                </Box>
              </Box>
            </Flex>
          )}
        </Box>
        <ImageCarouselComponent showCaseMediaUrls={showCaseMediaUrls} />
      </Box>
      {!isBrand && <InfluencerSocialMedia />}
    </>
  );
};

export default TabProductDetails;
