import { FC, useCallback } from "react";
import { Colors, Strings } from "@be-tagged/shared";
import { Box, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import { Folder, Trash } from "@be-tagged/shared/src/assets/icons";
import { SUPPORTED_FILES } from "../../../interfaces";

import { fileUpload } from "@be-tagged/shared/src/utils/FileUpload";

import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { useState } from "react";

interface DragDropProps {
  disabled?: boolean;
  accept?: SUPPORTED_FILES;
  maxFiles?: number;
  onlyChildren?: boolean;
  files?: any[];
  setFiles?: any;
  onChange?: any;
  children?: JSX.Element;
  category?: string;
}

const DragDropSection: FC<DragDropProps> = ({
  disabled = false,
  maxFiles = 1,
  accept = "*",
  files = [],
  onlyChildren = false,
  setFiles,
  onChange,
  children,
  category = Strings.brandProduct,
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  const makeTheReq = async (files: any[]) => {
    setLoading(true);
    const urlsArray = await fileUpload(dispatch, files, false, category);
    onChange(urlsArray);
    setLoading(false);
  };

  const dispatch = useDispatch();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (disabled) return;
      if (maxFiles === 1) {
        await makeTheReq(acceptedFiles);
        setFiles(acceptedFiles);
      } else {
        //will keep the last 'maxFiles' no. of files
        let uploadedFiles: any[] = [];
        setFiles((prev: any[]) => {
          const consideredFiles = [...prev, ...acceptedFiles].slice(
            -1 * maxFiles
          );
          uploadedFiles = consideredFiles;
          return consideredFiles;
        });
        makeTheReq(uploadedFiles);
      }
    },
    //eslint-disable-next-line
    [disabled]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    noClick: disabled,
  });

  const yetToUpload = () => (
    <Box as="span" position={"relative"} textAlign={"center"}>
      {isDragActive ? (
        "Drop File Here"
      ) : (
        <>
          <Image mb={"15px"} mx={"auto"} src={Folder} />
          {Strings.dragAndDropHere}{" "}
          <Text as="span" color={Colors.purple}>
            {Strings.browseFile}
          </Text>
        </>
      )}
    </Box>
  );

  const removeFile = async (e: any, index: number) => {
    e.stopPropagation();
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    const urlsArray = await fileUpload(dispatch, newFiles, false, category);
    onChange(urlsArray);
  };

  const renderFileNames = () => {
    return (
      <Flex
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        px={5}
        w={"100%"}
      >
        {files.map((file: any, idx) => (
          <Flex
            key={idx}
            alignItems={"center"}
            fontWeight={600}
            fontSize={10}
            color={Colors.grey100}
            width={"50%"}
          >
            <Flex
              gap={5}
              width={"max(30%, 100px)"}
              justifyContent={"space-between"}
              mb={5}
              as="span"
            >
              <Text
                whiteSpace={"nowrap"}
                overflow="hidden"
                textOverflow={"ellipsis"}
              >
                {file.name}
              </Text>
              <Image
                onClick={(e: any) => removeFile(e, idx)}
                zIndex={10}
                src={Trash}
              />
            </Flex>
          </Flex>
        ))}
      </Flex>
    );
  };

  if (onlyChildren)
    return (
      <Box
        {...getRootProps()}
        cursor={disabled ? "not-allowed" : "pointer"}
        onClick={disabled ? undefined : open}
        opacity={disabled ? 0.6 : 1}
      >
        {loading ? (
          <Spinner ml={"20px"} size={"sm"} />
        ) : (
          <>
            <input {...getInputProps()} />
            {children}
          </>
        )}
      </Box>
    );

  return (
    <Flex
      {...getRootProps()}
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius={"5px"}
      border={"2px dashed #D3E0FC"}
      height={150}
      opacity={disabled ? 0.5 : 1}
      cursor={disabled ? "not-allowed" : "pointer"}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <input {...getInputProps()} />
          {files.length ? renderFileNames() : yetToUpload()}
        </>
      )}
    </Flex>
  );
};

export default DragDropSection;
