import {
  getVerifyEmailUrl,
  getVerifyPhoneUrl,
  getEmailOTPUrl,
  getPhoneOTPUrl,
  getInfluencerOTPVerifySignInURL,
  getBrandOTPVerifySignInURL,
  getVerifyPhoneEmailUrl,
} from "../../../utils/UrlBuilder";
import {
  UPDATE_LOADING_STATUS,
  SHOW_OTP_VERIFY_MODAL,
  UPDATE_VERIFY_PHONE_STATUS,
  UPDATE_VERIFY_EMAIL_STATUS,
  SET_SUCCESS,
} from "../../constants/ReduxConstants";
import { FormikKeys, HTTPMethod } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import {
  updateUserInfoAfterSignin,
  updateIsUserLoggedIn,
} from "./SignIn.actions";
import { RootState } from "../../reducers";

const { MobileOTP, EmailOTP } = FormikKeys;

export const verifyUserWithPhoneOTP =
  (data: any, isBrand: boolean, navigateFunc: any) =>
  async (dispatch: any, getState: () => RootState) => {
    const request = {
      method: HTTPMethod.POST,
      queryStringParams: {},
      data,
      url: isBrand
        ? getBrandOTPVerifySignInURL()
        : getInfluencerOTPVerifySignInURL(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [
        (payload: any) =>
          updateUserInfoAfterSignin(payload, isBrand, navigateFunc),
      ]
    );
  };

const verifyPhoneEmailOTP = (
  dispatch: any,
  emailAddressId: string,
  emailOtp: string,
  phoneId: string,
  phoneOtp: string,
  callbackFn?: any
) => {
  const verifyPhoneEmailRequest = {
    method: HTTPMethod.POST,
    queryStringParams: {},
    data: {
      emailAddressId,
      emailOtp,
      phoneId,
      phoneOtp,
    },
    url: getVerifyPhoneEmailUrl(),
  };
  return fetchAsync(
    dispatch,
    [verifyPhoneEmailRequest],
    [(payload: any) => updateEmailAndPhoneStatus(payload, callbackFn)]
  );
};

export const verifyPhoneAndEmail =
  (data: any, viaProfile: boolean = false, callbackFn?: any) =>
  async (dispatch: any, getState: any) => {
    const { primaryEmailAddressId, primaryPhoneId } =
      getState()?.userReducer?.userData?.userInfo;
    let { phoneId = primaryPhoneId, emailAddressId = primaryEmailAddressId } =
      data;

    const verifyPhone = () =>
      verifyPhoneOTP(dispatch, phoneId, data[MobileOTP], callbackFn);
    const verifyEmail = () =>
      verifyEmailOTP(dispatch, emailAddressId, data[EmailOTP], callbackFn);

    if (viaProfile) {
      // User is tring to verify OTP via profile
      if (data.phoneId) verifyPhone();
      else if (data.emailAddressId) verifyEmail();
    } else {
      // User is trying to verify OTP via sign in / sign up
      verifyPhoneEmailOTP(
        dispatch,
        emailAddressId,
        data[EmailOTP],
        phoneId,
        data[MobileOTP],
        callbackFn
      );
    }
  };

const verifyPhoneOTP = (
  dispatch: any,
  phoneId: string,
  otp: string,
  callbackFn?: any
) => {
  const verifyPhoneRequest = {
    method: HTTPMethod.POST,
    queryStringParams: {},
    data: {
      phoneId,
      otp,
    },
    url: getVerifyPhoneUrl(),
  };

  return fetchAsync(
    dispatch,
    [verifyPhoneRequest],
    [(payload: any) => updateVerifyPhoneStatus(payload, callbackFn)]
  );
};

const verifyEmailOTP = (
  dispatch: any,
  emailAddressId: string,
  otp: string,
  callbackFn?: any
) => {
  const verifyEmailRequest = {
    method: HTTPMethod.POST,
    queryStringParams: {},
    data: {
      emailAddressId,
      otp,
    },
    url: getVerifyEmailUrl(),
  };
  return fetchAsync(
    dispatch,
    [verifyEmailRequest],
    [(payload: any) => updateEmailOTPStatus(payload, callbackFn)]
  );
};

export const sendEmailOTP =
  (data?: any) => async (dispatch: any, getState: () => RootState) => {
    const { primaryEmailAddressId } =
      getState()?.userReducer?.userData?.userInfo;
    const emailAddressId = data?.emailAddressId || primaryEmailAddressId;
    const sendEmailOTPRequest = {
      method: HTTPMethod.POST,
      queryStringParams: {},
      data: {
        emailAddressId,
      },
      url: getEmailOTPUrl(),
    };
    return fetchAsync(dispatch, [sendEmailOTPRequest], [postResendOtp], false);
  };

export const sendPhoneOTP =
  (data?: any) => async (dispatch: any, getState: () => RootState) => {
    const { primaryPhoneId } = getState()?.userReducer?.userData?.userInfo;
    const phoneId = data?.phoneId || primaryPhoneId;
    const sendPhoneOTPRequest = {
      method: HTTPMethod.POST,
      queryStringParams: {},
      data: {
        phoneId,
      },
      url: getPhoneOTPUrl(),
    };
    return fetchAsync(dispatch, [sendPhoneOTPRequest], [postResendOtp], false);
  };

const postResendOtp =
  (payload: any, callbackFn: any) => async (dispatch: any) => {
    callbackFn?.();
    return dispatch({
      type: SET_SUCCESS,
      payload: {
        message: "OTP Sent",
      },
    });
  };
export const updateVerifyPhoneStatus =
  (payload: any, callbackFn?: any) =>
  (dispatch: any, getState: () => RootState): void => {
    dispatch({
      type: UPDATE_VERIFY_PHONE_STATUS,
      payload: true,
    });
    callbackFn?.();
    if (getState().userReducer?.userData?.userInfo?.isEmailVerified) {
      dispatch(updateIsUserLoggedIn(true));
    }
  };

export const updateEmailOTPStatus = (payload: any, callbackFn?: any) => {
  return (dispatch: any, getState: any): void => {
    dispatch({
      type: UPDATE_VERIFY_EMAIL_STATUS,
      payload: true,
    });
    callbackFn?.();
    if (getState().userReducer?.userData?.userInfo?.isPhoneVerified) {
      dispatch(updateIsUserLoggedIn(true));
    }
  };
};

export const updateEmailAndPhoneStatus = (payload: any, callbackFn?: any) => {
  return (dispatch: any, getState: any): void => {
    dispatch({
      type: UPDATE_VERIFY_EMAIL_STATUS,
      payload: true,
    });
    dispatch({
      type: UPDATE_VERIFY_PHONE_STATUS,
      payload: true,
    });
    callbackFn?.();
    if (
      getState().userReducer?.userData?.userInfo?.isPhoneVerified &&
      getState().userReducer?.userData?.userInfo?.isEmailVerified
    ) {
      dispatch(updateIsUserLoggedIn(true));
    }
  };
};

export const setShowOTPModal = (status: boolean) => {
  return {
    type: SHOW_OTP_VERIFY_MODAL,
    payload: status,
  };
};

export const updateVerifyEmailStatus =
  (payload: any, navigateFunc?: any) =>
  (dispatch: any): void => {
    dispatch({
      type: UPDATE_LOADING_STATUS,
      payload: false,
    });
    dispatch({
      type: SHOW_OTP_VERIFY_MODAL,
      payload: false,
    });
    dispatch({
      type: UPDATE_VERIFY_EMAIL_STATUS,
      payload: payload,
    });
    navigateFunc();
  };
