import {
  clearProductRelatedInfluencers,
  Colors,
  getProductRelatedInfluencers,
  RootState,
  Strings,
} from "@be-tagged/shared";
import {
  FacebookIcon,
  InstagramIcon,
  NotFound,
  TikTokIcon,
  TwitterIcon,
} from "@be-tagged/shared/src/assets/icons";
import { FormikKeys, SocialMedia } from "@be-tagged/shared/src/enums";
import { Avatar, Box, Center, Flex, SimpleGrid } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "src/app/components/LoadingSpinner";
import { withCommas } from "src/app/utils";

interface ITabInfluencers {
  brandProductId: any;
}
const imgMapping: { [k: string]: any } = {
  [SocialMedia.TikTok]: TikTokIcon,
  [SocialMedia.Facebook]: FacebookIcon,
  [SocialMedia.Instagram]: InstagramIcon,
  [SocialMedia.Twitter]: TwitterIcon,
};
/**
 * @returns {JSX.Element} - A React Functional Component
 */
const TabInfluencers: FC<ITabInfluencers> = ({
  brandProductId,
}): JSX.Element => {
  const { StartFrom, ListQty } = FormikKeys;
  const [pagination, setPagination] = useState({
    [StartFrom]: 0,
    [ListQty]: 10,
  });

  const dispatch = useDispatch();
  const { influencersOfProduct } = useSelector((state: RootState) => {
    return state?.brandReducer?.productData || {};
  });

  useEffect(() => {
    dispatch(getProductRelatedInfluencers(brandProductId, pagination));
    //eslint-disable-next-line
  }, [pagination]);

  useEffect(() => {
    return () => {
      dispatch(clearProductRelatedInfluencers());
    };
  }, []);

  return (
    <Flex
      direction={"column"}
      maxHeight={{ md: "500" }}
      overflow={{ md: "scroll" }}
    >
      {influencersOfProduct?.data?.length === 0 ? (
        <Center>
          <Flex direction="column" alignItems="center">
            <img src={NotFound} alt="NoDataFound" />
            <Box fontSize="16px" mt={4}>
              {Strings.noDataFound}
            </Box>
          </Flex>
        </Center>
      ) : (
        <InfiniteScroll
          dataLength={influencersOfProduct?.data?.length || 0}
          next={() => {
            setPagination({
              ...pagination,
              [StartFrom]: pagination[StartFrom] + pagination[ListQty],
            });
          }}
          hasMore={influencersOfProduct?.nextLink}
          loader={<LoadingSpinner />}
        >
          {influencersOfProduct?.data?.map((el: any, index: number) => {
            return (
              <div key={index}>
                <Flex
                  mb={10}
                  direction={{
                    base: "column",
                    md: "row",
                  }}
                  flexWrap="wrap"
                >
                  <Box width={{ md: "280px", base: "fit-content" }}>
                    <Flex>
                      <Box w="80px" h="80px">
                        <Avatar
                          size={"sm"}
                          src={el.influencerProfilePicUrl}
                          style={{
                            width: "80px",
                            height: "80px",
                            objectFit: "cover",
                            borderRadius: "100px",
                          }}
                        />
                      </Box>

                      <Flex
                        direction={"column"}
                        pl={5}
                        pr={5}
                        justifyContent="space-evenly"
                        width={{ md: "200px", base: "fit-content" }}
                      >
                        <p className="purple-text" style={{ fontSize: "14px" }}>
                          {el.name}
                        </p>
                        <p className="title">
                          <span style={{ color: Colors.grey100 }}>
                            {Strings.sales}
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {" "}
                            - {withCommas(el.sales)}
                          </span>
                        </p>
                        <p className="title">
                          <span style={{ color: Colors.grey100 }}>
                            {Strings.commission}
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {" "}
                            : {el.currencySymbol}&nbsp;
                            {withCommas(el.commission)}
                          </span>
                        </p>
                      </Flex>
                    </Flex>
                  </Box>
                  <SimpleGrid
                    templateColumns={{
                      md: "repeat(4, 1fr)",
                      base: "repeat(2, 1fr)",
                    }}
                    pt={{ md: "0", base: "5" }}
                    spacingX={10}
                  >
                    {el?.salesChannelData?.map((ele: any, index: number) => {
                      return (
                        <Flex
                          key={index}
                          direction={"column"}
                          justifyContent="space-evenly"
                          width={{ md: "180px", base: "max-content" }}
                          pb={{ md: "0", base: "5" }}
                        >
                          <Flex alignItems="flex-end" mb="5px">
                            <div>
                              <img
                                src={imgMapping?.[ele?.salesChannel]}
                                alt="Sale Channel"
                                width="24px"
                                height="24px"
                              />
                            </div>
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                marginLeft: "10px",
                              }}
                            >
                              {ele.salesChannel}
                            </p>
                          </Flex>

                          <p className="purple-text social-media-followers">
                            {ele.link}
                          </p>
                          <p className="title">
                            <span style={{ color: Colors.grey100 }}>
                              {Strings.sales} / {Strings.click}&nbsp;
                            </span>
                            <span style={{ fontSize: "14px" }}>
                              - {withCommas(ele.sale)} /{" "}
                              {withCommas(ele.clickCount)}
                            </span>
                          </p>
                        </Flex>
                      );
                    })}
                  </SimpleGrid>
                </Flex>
              </div>
            );
          })}
        </InfiniteScroll>
      )}
    </Flex>
  );
};

export default TabInfluencers;
