import { FC, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Spinner,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  Colors,
  logger,
  LoggerStrings,
  RootState,
  Strings,
  unlistProduct,
} from "@be-tagged/shared";
import { CustomBtn } from "src/app/components";

interface UnlistConfirmationModalProp {
  isOpen: boolean;
  id: number;
  onClose: () => void;
  closeModal: () => void;
  productName: string;
}
/**
 * @param {Object} props - Of the form RemoveTeamMemberProp
 * @returns {JSX.Element} - A React Functional Component
 */
const UnlistConfirmationModal: FC<UnlistConfirmationModalProp> = ({
  isOpen,
  id,
  closeModal,
  onClose,
  productName,
}): JSX.Element => {
  const { loading } = useSelector(
    (state: RootState) => state?.appReducer?.loading
  );
  const [unlist, setUnlist] = useState(false);
  const [cancelUnlist, setCancelUnlist] = useState(false);
  const dispatch = useDispatch();
  const postCallFn = () => {
    closeModal();
  };
  const unlistThisProduct = () => {
    setUnlist(true);
    dispatch(unlistProduct(id, postCallFn, productName));
  };
  const cancelAndClose = () => {
    setCancelUnlist(true);
    closeModal();
  };
  const user = Strings.brand;
  useEffect(() => {
    if (isOpen) {
      logger.info(`${user} ${LoggerStrings.clickedOnUnlistButton}`, {
        productName: productName,
        prodId: id,
      });
    }
  }, [isOpen]);
  useEffect(() => {
    if (unlist) {
      logger.info(`${user} ${LoggerStrings.confirmedUnlistProduct}`, {
        productName: productName,
        prodId: id,
      });
    }
  }, [unlist]);
  useEffect(() => {
    if (cancelUnlist && !unlist) {
      logger.info(`${user} ${LoggerStrings.cancelledUnlistProduct}`, {
        productName: productName,
        prodId: id,
      });
    }
  }, [cancelUnlist, unlist]);

  return (
    <>
      <Modal
        size={"2xl"}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          className="filter_modal"
          style={{ paddingTop: 12 }}
          backgroundColor={Colors.bgDark}
        >
          <ModalBody py={"25px"} px={"50px"}>
            <Flex
              direction={"column"}
              alignItems={"center"}
              px={{ md: "15px", base: "0px" }}
              className="content_container"
            >
              <Box className="bold" style={{ fontSize: 18, paddingBottom: 24 }}>
                {Strings.unlistProduct}
              </Box>

              <Flex justifyContent={"center"}>
                {Strings.confirmUnlistProduct}
              </Flex>

              <Flex
                justifyContent="center"
                columnGap={4}
                mt={8}
                rowGap={4}
                direction={{ md: "row", base: "column" }}
              >
                <CustomBtn
                  type="button"
                  className="purple_btn medium inv"
                  onClick={cancelAndClose}
                >
                  {loading && (
                    <Spinner size={"xs"} style={{ marginRight: 10 }} />
                  )}
                  {Strings.noLetMeRethink}
                </CustomBtn>
                <CustomBtn
                  type="submit"
                  className="purple_btn medium"
                  onClick={() => {
                    unlistThisProduct();
                  }}
                  style={{ paddingLeft: "30px", paddingRight: "30px" }}
                >
                  {loading && (
                    <Spinner size={"xs"} style={{ marginRight: 10 }} />
                  )}
                  {Strings.yesUnlist}
                </CustomBtn>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default UnlistConfirmationModal;
