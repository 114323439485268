import {
  Colors,
  getProfileData,
  logger,
  RootState,
  setAsPrimaryEmail,
  setAsPrimaryPhone,
  Strings,
  updateOTPVerifyModalStatus,
} from "@be-tagged/shared";
import {
  DeleteAccountIcon,
  EmailIcon,
  MobilePhoneIcon,
  PasswordLockIcon,
} from "@be-tagged/shared/src/assets/icons";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import { Box, Flex, Switch, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OTPModal from "src/account-module/common/OTPModal";
import { CommonObjectType } from "src/app/constants";
import { EmailSkeleton, PhoneSkeleton } from "../SkeletonLayout";
import AddLoginDetailsModal from "./AddLoginDetailsModal";

interface LoginDetailsProp {
  profileData?: any;
  isBrand?: any;
}
const LoginDetails: FC<LoginDetailsProp> = ({ profileData, isBrand }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const [dataForOTPModal, setDataForOTPModal] = useState<CommonObjectType>({});
  const [field, setField] = useState("");
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => {
    return state.appReducer?.loading;
  });
  const postCallFn = () => {
    dispatch(getProfileData(isBrand));
  };

  const onChangePrimaryEmail = (id: number) => {
    dispatch(setAsPrimaryEmail(id, postCallFn));
  };

  const onChangePrimaryPhone = (id: number) => {
    dispatch(setAsPrimaryPhone(id, postCallFn));
  };

  const {
    isOpen: isOTPModalOpen,
    onOpen: showOTPModal,
    onClose: closeOTPModal,
  } = useDisclosure();

  const onClickField = (value: string) => {
    setField(value);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const postDetailChangeFn = (data: any) => {
    setDataForOTPModal(data);
  };

  const { showOTPVerifyModal } = useSelector(
    (state: RootState) => state.appReducer
  );

  useEffect(() => {
    if (showOTPVerifyModal) showOTPModal();
    else closeOTPModal();
    //eslint-disable-next-line
  }, [showOTPVerifyModal]);

  useEffect(() => {
    return () => {
      dispatch(updateOTPVerifyModalStatus(false));
    };
  }, []);

  return (
    <>
      <AddLoginDetailsModal
        isOpen={showModal}
        onClose={() => null}
        closeModal={closeModal}
        field={field}
        dataCallbackFn={postDetailChangeFn}
      />
      <OTPModal
        isOpen={isOTPModalOpen}
        profileData={dataForOTPModal}
        onClose={() => null}
        callbackFn={() => {
          postCallFn();
        }}
      />
      <Flex direction="column" rowGap={10}>
        <Flex mb="10px" alignItems="flex-start">
          <img src={EmailIcon} alt="Email" />
          <Flex alignItems="flex-start" flexWrap="wrap" direction="column">
            <Flex ml={2} fontSize="16px" color={Colors.grey100}>
              {Strings.emailId}
            </Flex>
            <Flex direction="column" ml={2} mt={2}>
              {loading ? (
                <EmailSkeleton emails={profileData?.emailAddresses?.length} />
              ) : (
                profileData?.emailAddresses?.map((el: any, index: number) => {
                  return (
                    <Flex direction={{ md: "row", base: "column" }} key={index}>
                      <Box mb={2} width={{ md: "300px", base: "fit-content" }}>
                        {el.emailAddress}
                      </Box>
                      {profileData?.emailAddresses?.length > 1 && (
                        <Flex
                          alignItems="center"
                          mb={{ md: "0px", base: "20px" }}
                        >
                          <Switch
                            isChecked={el.isPrimary}
                            colorScheme={"green"}
                            size={"md"}
                            onChange={() => {
                              onChangePrimaryEmail(el.id);
                            }}
                          />
                          <Box fontSize="12px" ml={2}>
                            {Strings.useLoginID}
                          </Box>
                        </Flex>
                      )}
                    </Flex>
                  );
                })
              )}
              <Box fontSize="12px" fontWeight="600" color={Colors.purple}>
                <button
                  onClick={() => {
                    onClickField(FormikKeys.Email);
                  }}
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: Colors.purple,
                  }}
                >
                  {Strings.addAlternateEmail}
                </button>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Flex mb="10px" alignItems="flex-start">
          <img src={MobilePhoneIcon} alt="Email" />
          <Flex alignItems="flex-start" flexWrap="wrap" direction="column">
            <Flex ml={2} fontSize="16px" color={Colors.grey100}>
              {Strings.mobileNo}
            </Flex>
            <Flex direction="column" ml={2} mt={2}>
              {loading ? (
                <PhoneSkeleton phones={profileData?.phones?.length} />
              ) : (
                profileData?.phones?.map((el: any, index: number) => {
                  return (
                    <Flex direction={{ md: "row", base: "column" }} key={index}>
                      <Box mb={2} width={{ md: "300px", base: "fit-content" }}>
                        {el.phone}
                      </Box>
                      {profileData?.phones?.length > 1 && (
                        <Flex
                          alignItems="center"
                          mb={{ md: "0px", base: "20px" }}
                        >
                          <Switch
                            isChecked={el.isPrimary}
                            colorScheme={"green"}
                            onChange={() => {
                              onChangePrimaryPhone(el.id);
                            }}
                            size={"md"}
                          />
                          <Box fontSize="12px" ml={2}>
                            {Strings.useLoginNumber}
                          </Box>
                        </Flex>
                      )}
                    </Flex>
                  );
                })
              )}
              <Box fontSize="12px" fontWeight="600" color={Colors.purple}>
                <button
                  onClick={() => {
                    onClickField(FormikKeys.Phone);
                  }}
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: Colors.purple,
                  }}
                >
                  {Strings.addAlternateNumber}
                </button>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Flex mb="10px" alignItems="flex-start">
          <img src={PasswordLockIcon} alt="Email" />
          <Flex alignItems="flex-start" flexWrap="wrap" direction="column">
            <Flex ml={2} fontSize="16px" color={Colors.grey100}>
              {Strings.password}
            </Flex>
            <Flex direction="column" ml={2} mt={2}>
              <Box fontSize="12px" fontWeight="600" color={Colors.purple}>
                <button
                  onClick={() => {
                    onClickField(FormikKeys.Password);
                  }}
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: Colors.purple,
                  }}
                >
                  {Strings.changePassword}
                </button>
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <Flex mb="10px" alignItems="flex-start">
          <img src={DeleteAccountIcon} alt="Email" />
          <Flex alignItems="flex-start" flexWrap="wrap" direction="column">
            <Flex direction="column" ml={2} mt={2}>
              <Box fontSize="12px" fontWeight="600" color={Colors.purple}>
                <button
                  onClick={() => {
                    onClickField(FormikKeys.DeleteAccount);
                  }}
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: Colors.purple,
                  }}
                >
                  {Strings.deleteAccount}
                </button>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default LoginDetails;
