import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTING_URLS } from "../constants";
import useAuth from "../hooks/useAuth";

/**
 * ProtectedRoutes is a Routing Middleware Component
 * If a user tries to visit a "protected" route without having logged in, he will be taken to the Selection Screen
 * This navigation is driven by Navigate
 * Else if a user is logged in, then he will be able to view the component
 * This view is driven by Outlet
 * @returns {JSX.Element} - A React Component
 */
export function ProtectedRoutes(): JSX.Element {
  const { isLoggedIn } = useAuth();
  let location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to={ROUTING_URLS.SELECT} state={{ from: location }} />;
  }

  return <Outlet />;
}

/**
 * UnlockedRoutes are the routes that are locked until the user is completely onboarded
 * @returns {JSX.Element} - A React Component
 */
export const UnlockedRoutes = (): JSX.Element => {
  const { isOnboarded } = useAuth();
  if (!isOnboarded) {
    return <Navigate to={ROUTING_URLS.ONBOARDING} />;
  }

  return <Outlet />;
};
