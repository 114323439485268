import { FC, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Spinner,
  Box,
  Flex,
} from "@chakra-ui/react";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Formik } from "formik";
import * as yup from "yup";

import {
  Colors,
  RootState,
  Strings,
  verifyPhoneAndEmail,
  sendEmailOTP,
  sendPhoneOTP,
  setShowOTPModal,
  OTP_LENGTH,
  logger,
  LoggerStrings,
} from "@be-tagged/shared";

import { CustomBtn, MaterialStyleInput, ErrorMsg } from "../../app/components";
import { CommonObjectType, ROUTING_URLS } from "../../app/constants";
import { FormikKeys } from "@be-tagged/shared/src/enums";

const { enterValidOTP, emailOTP, mobileOTP } = Strings;
const { EmailOTP, MobileOTP } = FormikKeys;

interface OTPModalProps {
  isOpen: boolean;
  profileData?: CommonObjectType | null;
  onClose: () => void;
  callbackFn: () => void;
}

interface OTPInputProps {
  placeholder: string;
  value: any;
  onChange: (type: any) => void;
  onBlur: () => void;
  resendOTP: () => void;
}

interface IOTPDetails {
  [MobileOTP]: string;
  [EmailOTP]: string;
}

const getValidationSchema = (
  isEmailVerified: boolean,
  isMobileVerified: boolean
) => {
  let schemaObject: CommonObjectType = {};

  if (!isEmailVerified) {
    schemaObject[EmailOTP] = yup
      .string()
      .length(OTP_LENGTH, enterValidOTP)
      .required(enterValidOTP);
  }
  if (!isMobileVerified) {
    schemaObject[MobileOTP] = yup
      .string()
      .length(OTP_LENGTH, enterValidOTP)
      .required(enterValidOTP);
  }

  return schemaObject;
};

/**
 * OTPModal comes up to provide Email or Phone No. OTPs
 * @param {Object} props - Of the form OTPModalInterface
 * @returns {JSX.Element} - A React Functional Component
 */
const OTPModal: FC<OTPModalProps> = ({
  isOpen,
  profileData = null,
  onClose,
  callbackFn,
}): JSX.Element => {
  const viaProfile = !!profileData;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { loading, userInfo } = useSelector((state: RootState) => {
    return {
      loading: state?.appReducer?.loading,
      userInfo: state?.userReducer?.userData?.userInfo,
    };
  });

  const {
    primaryEmailAddress,
    primaryPhone,
    isEmailVerified,
    isPhoneVerified,
  }: {
    primaryEmailAddress: string;
    primaryPhone: string;
    isEmailVerified: boolean;
    isPhoneVerified: boolean;
  } = userInfo || "";

  // Constants that are used to render. The values are assigned depending on whether some data has been passed into the component or not
  const phoneNo = (viaProfile ? profileData?.phone : primaryPhone) || null;
  const emailAddr =
    (viaProfile ? profileData?.emailAddress : primaryEmailAddress) || null;

  // Booleans to indicate whether to show the input fields for mobile / email OTP
  // If data is provided to the component, then it uses that data to make a judgement. Else it'll try to see whether the primary details (phone / email) is verified or not
  const showForPhone: boolean = viaProfile ? !!profileData?.phone : true;
  const showForEmail: boolean = viaProfile ? !!profileData?.emailAddress : true;

  // Obtaining the ID's using a similar way
  const emailAddressId = viaProfile
    ? showForEmail
      ? profileData?.id
      : null
    : userInfo?.primaryEmailAddressId;
  const phoneId = viaProfile
    ? showForPhone
      ? profileData?.id
      : null
    : userInfo?.primaryPhoneId;

  const verifyOTP = async (OTPDetails: IOTPDetails) => {
    const { emailOTP, mobileOTP } = OTPDetails;

    const inputObject = {
      emailAddressId,
      emailOTP,
      phoneId,
      mobileOTP,
    };

    // We want to trigger a callback function if the user is trying to verify his details via profile
    const postSingleVerification = viaProfile ? hideOTPModal : null;

    dispatch(
      verifyPhoneAndEmail(inputObject, viaProfile, postSingleVerification)
    );
  };

  const hideOTPModal = () => {
    dispatch(setShowOTPModal(false));
    onClose();
  };

  useEffect(() => {
    if (!viaProfile) {
      if (isEmailVerified && isPhoneVerified) {
        navigate(ROUTING_URLS.ONBOARDING);
        hideOTPModal();
      }
    }
    //eslint-disable-next-line
  }, [isEmailVerified, isPhoneVerified]);

  useEffect(() => {
    return () => {
      if (isOpen) hideOTPModal();
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      let loggingText = "";
      if (showForEmail) loggingText = Strings.email;
      if (showForPhone) loggingText = Strings.mobileNumber;
      if (showForEmail && showForPhone)
        loggingText = `${Strings.email} & ${Strings.mobileNumber}`;

      logger.info(LoggerStrings.otpVerificationOpened + loggingText, {
        title: document.title,
        location: location.pathname,
      });
    }
  }, [isOpen]);

  const resendMobileOTP = () => {
    dispatch(sendPhoneOTP({ phoneId }));
  };

  const resendEmailOTP = () => {
    dispatch(sendEmailOTP({ emailAddressId }));
  };

  const handleUpdateClick = () => {
    callbackFn();
    hideOTPModal();
  };

  const getPhoneNumberString = (phoneNumber: string) => {
    if (phoneNumber) {
      const splittedPhoneNumber = phoneNumber.split("-");
      return `(${splittedPhoneNumber[0]})${splittedPhoneNumber[1]}`;
    }
    return phoneNumber;
  };

  const enterOTPString: string = `${Strings.enterVerifyOTP1} ${
    showForPhone
      ? Strings.mobileNumber.toLowerCase() + " " + getPhoneNumberString(phoneNo)
      : ""
  } ${showForPhone && showForEmail ? Strings.enterVerifyOTPJoiner : ""} ${
    showForEmail
      ? Strings.emailAddress.toLowerCase() + " " + emailAddr?.toLowerCase()
      : ""
  }`;

  return (
    <>
      <Modal
        size={"2xl"}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent className="otp_modal" style={{ paddingTop: 12 }}>
          <ModalBody>
            <Box className="content_container">
              <Box className="bold" style={{ fontSize: 18, paddingBottom: 24 }}>
                {Strings.verifyContacts}
              </Box>
              <Box style={{ fontSize: 16, marginBottom: 50 }}>
                {enterOTPString}
              </Box>

              <Formik
                initialValues={{
                  [MobileOTP]: "",
                  [EmailOTP]: "",
                }}
                validateOnMount
                onSubmit={verifyOTP}
                validationSchema={yup
                  .object()
                  .shape(getValidationSchema(isEmailVerified, isPhoneVerified))}
              >
                {({
                  values,
                  handleChange,
                  errors,
                  setFieldTouched,
                  touched,
                  isValid,
                }) => (
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Box className="otp_inputs">
                      {showForPhone && (
                        <Box pos={"relative"}>
                          <OTPInputReturner
                            placeholder={mobileOTP}
                            value={values[MobileOTP]}
                            onChange={handleChange(MobileOTP)}
                            onBlur={() => setFieldTouched(MobileOTP)}
                            resendOTP={resendMobileOTP}
                          />
                          {touched[MobileOTP] && errors[MobileOTP] && (
                            <ErrorMsg bottom={20}>{errors[MobileOTP]}</ErrorMsg>
                          )}
                        </Box>
                      )}
                      {showForEmail && (
                        <Box pos={"relative"}>
                          <OTPInputReturner
                            placeholder={emailOTP}
                            value={values[EmailOTP]}
                            onChange={handleChange(EmailOTP)}
                            onBlur={() => setFieldTouched(EmailOTP)}
                            resendOTP={resendEmailOTP}
                          />
                          {touched[EmailOTP] && errors[EmailOTP] && (
                            <ErrorMsg bottom={20}>{errors[EmailOTP]}</ErrorMsg>
                          )}
                        </Box>
                      )}
                    </Box>
                    {viaProfile && (
                      <CustomBtn
                        type="button"
                        className="purple_btn medium inv"
                        onClick={handleUpdateClick}
                        style={{ marginRight: "10px" }}
                      >
                        {loading && (
                          <Spinner size={"xs"} style={{ marginRight: 10 }} />
                        )}
                        {Strings.cancel}
                      </CustomBtn>
                    )}
                    <CustomBtn
                      onClick={() => {
                        verifyOTP(values);
                      }}
                      disabled={!isValid || loading}
                      style={{
                        margin: "auto",
                      }}
                      className="purple_btn medium"
                    >
                      {loading && (
                        <Spinner size={"xs"} style={{ marginRight: 10 }} />
                      )}
                      {Strings.verify}
                    </CustomBtn>
                    {!viaProfile && (
                      <Flex
                        color={Colors.purple}
                        mt={"25px"}
                        justify={"center"}
                        align={"center"}
                      >
                        <button
                          onClick={handleUpdateClick}
                          type="button"
                          className="update_contact_btn"
                        >
                          {Strings.updateContactDetails}
                        </button>
                      </Flex>
                    )}
                  </form>
                )}
              </Formik>
            </Box>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

/**
 * OTPInputReturner is the component that takes in OTP Inputs
 * @param {object} props - of the form OTPInputProps
 * @returns {JSX.Element} - A React Functional Component
 */
const OTPInputReturner: FC<OTPInputProps> = ({
  placeholder,
  value,
  onChange,
  onBlur,
  resendOTP,
}): JSX.Element => {
  return (
    <Box marginBottom={"20px"} maxW={"200px"} pos={"relative"}>
      <MaterialStyleInput
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={"tel"}
        label={placeholder}
      />
      <Flex justifyContent={"flex-end"}>
        <button
          type="button"
          onClick={resendOTP}
          className="bold resend"
          tabIndex={-1}
          style={Styles.resendBtn}
        >
          {Strings.resend}
        </button>
      </Flex>
    </Box>
  );
};

export default OTPModal;

const Styles = {
  resendBtn: {
    fontSize: 12,
    marginTop: 5,
    color: Colors.purple,
    display: "inline-block",
  },
};
