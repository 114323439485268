import {
  Colors,
  getDashboardData,
  logger,
  LoggerStrings,
  RootState,
  Strings,
} from "@be-tagged/shared";
import { FormikKeys } from "@be-tagged/shared/src/enums";

import { Box, Flex, Text, Image, Avatar } from "@chakra-ui/react";
import {
  IconTopInfBox,
  BrandTopInfluencer1,
  BrandTopInfluencer2,
  BrandTopInfluencer3,
  BrandTopInfluencer4,
  BrandTopInfluencer5,
} from "@be-tagged/shared/src/assets/icons";

import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useData from "src/app/hooks/useData";
import { withCommas } from "src/app/utils";
import { CountrySelect, NoDataFound } from "src/app/components";
import { CommonObjectType, dateFiltersSinceListing } from "src/app/constants";

import SocialMediaPerformance from "../../Common/SocialMediaPerformance";
import Statistics from "../../Common/Statistics";
import WelcomeText from "../../Common/WelcomeText";
import ProductsBox from "../../Common/ProductsBox";
import { Link } from "react-router-dom";
import { ROUTING_PARAMS, ROUTING_URLS } from "src/app/constants";

const TopInfluencersArray = [
  BrandTopInfluencer1,
  BrandTopInfluencer2,
  BrandTopInfluencer3,
  BrandTopInfluencer4,
  BrandTopInfluencer5,
];
const BrandStandard = () => {
  const [selectedCountry, setSelectedCountry] =
    useState<CommonObjectType | null>(null);

  const dispatch = useDispatch();

  const [timeRange, setTimeRange] = useState<CommonObjectType | null>(
    dateFiltersSinceListing[0]
  );

  const { countries, dashboardData } = useSelector((state: RootState) => {
    return {
      countries: state.brandReducer?.countries || [],
      dashboardData: state.commonReducer.commonData?.dashboardData || {},
    };
  });

  useEffect(() => {
    if (countries.length && !selectedCountry) {
      setSelectedCountry(countries[0]);
    }
  }, [countries]);

  useEffect(() => {
    const param = { ...(timeRange?.value || {}) };
    if (!selectedCountry?.id) return;
    param[FormikKeys.CountryID] = selectedCountry?.id;
    dispatch(getDashboardData(param, true));
  }, [selectedCountry, timeRange]);

  const { socialMediaPerformance, influencers = [] } = dashboardData;

  useEffect(() => {
    if (selectedCountry) {
      const user = Strings.brand;
      logger.info(
        `${user} ${LoggerStrings.changedCountry} ${selectedCountry?.name}`
      );
    }
  }, [selectedCountry]);
  return (
    <Box className="landing_main">
      {/* Welcome Section */}
      <Box className="welcome_text bold with_data">
        <Flex className="text_dropdown_container">
          <WelcomeText />
          <Box w={"180px"} fontSize={"12px"} fontWeight="normal">
            <CountrySelect
              countryOptions={countries}
              isMulti={false}
              selectedCountries={selectedCountry}
              setCountries={setSelectedCountry}
              alternativeStyle={true}
              placeholder={""}
              labelPrefix={Strings.dataFrom + " "}
            />
          </Box>
        </Flex>
      </Box>
      {/* Products Section */}
      <ProductsBox isBrand={true} />
      {/* Social Media Performance */}
      <SocialMediaPerformance
        smData={socialMediaPerformance}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />
      {/* Influencers Section */}
      <Box mt="50px">
        <InfluencersSection influencers={influencers} />
      </Box>
    </Box>
  );
};

interface InfluencersSectionProps {
  influencers: any[];
}

const InfluencersSection: FC<InfluencersSectionProps> = ({
  influencers = [],
}) => {
  return (
    <Box id="influencer_info_container">
      <Flex bgColor={Colors.bgLight} className="white_box_container info_box">
        <Flex className="double_shaded_container">
          <Image id="ig_seated" src={IconTopInfBox} />
          <InfluencerCommissionStats />
          <TopInfluencers infArray={influencers} />
        </Flex>
      </Flex>
    </Box>
  );
};

const InfluencerCommissionStats: FC<{}> = () => {
  const { dashboardData } = useData();

  const { currencySymbol = "", commissionToBePaidThisMonth = "" } =
    dashboardData;

  return (
    <Box className="darker_shade" bgColor={Colors.grey400}>
      <Flex className="stats_container influencer_related">
        {/* Change the class name here */}
        <Statistics
          label={Strings.totalInfluencersInvolved}
          value={withCommas(dashboardData?.totalInfluencersInvolved)}
        />
        <Statistics
          label={Strings.commissionToBePaid}
          value={`${currencySymbol} ${withCommas(commissionToBePaidThisMonth)}`}
        />
      </Flex>
      <Link
        to={`${ROUTING_URLS.LANDING}/${ROUTING_URLS.BUSINESS_INSIGHTS}?tab=${ROUTING_PARAMS.INFLUENCERS}`}
        className="bold action_btn"
        style={Styles.linkStyle}
      >
        {Strings.viewDetailedAnalysis}
      </Link>
    </Box>
  );
};

const TopInfluencers = ({ infArray }: { infArray: any[] }) => {
  return (
    <Box className="lighter_shade" bgColor={Colors.themeColor}>
      <Text className="bold">{Strings.topFourInfluencers}</Text>
      <Flex className="list">
        {infArray.length ? (
          infArray.map(({ name, profilePicUrl }, idx) => {
            return (
              <Box className="product" key={idx}>
                <Avatar
                  borderRadius={"inherit"}
                  size={"sm"}
                  className="img shadow"
                  src={profilePicUrl ?? TopInfluencersArray[idx]}
                />
                <Text className="name" color={Colors.grey100}>
                  {name}
                </Text>
              </Box>
            );
          })
        ) : (
          <NoDataFound />
        )}
      </Flex>
    </Box>
  );
};

export default BrandStandard;

const Styles = {
  linkStyle: {
    color: Colors.purple,
  },
};
