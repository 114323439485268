import { Colors, Strings } from "@be-tagged/shared";
import {
  Box,
  Divider,
  Flex,
  SimpleGrid,
  Skeleton,
  useMediaQuery,
} from "@chakra-ui/react";
import { FC } from "react";
import { DEVICE } from "src/app/assets/styles/styleEnums";
import ReactImageFallback from "react-image-fallback";
import { withCommas } from "src/app/utils";
import { SUPPORTED_FILES } from "src/dashboard-module/interfaces";

interface IInsightsTableRow {
  productName: string;
  currentInfluencerData: any;
  allInfluencersAverageData: any;
  topSellingInfluencerData: any;
  productImage: string;
  currencySymbol: any;
}

/**
 * @returns {JSX.Element} - A React Functional Component
 */
const InsightsTableRow: FC<IInsightsTableRow> = ({
  productName,
  currentInfluencerData,
  allInfluencersAverageData,
  topSellingInfluencerData,
  productImage,
  currencySymbol,
}): JSX.Element => {
  const [isSmallerScreen] = useMediaQuery(DEVICE.tablet);
  const { VideoMp4, Mp4 } = SUPPORTED_FILES;
  return (
    <Box>
      <Flex
        bgColor="white"
        p={3}
        borderRadius={10}
        shadow="md"
        direction="column"
        mb={{ base: "15px", md: "5px" }}
      >
        {/* If all influencers average data is there, 
        we can use below code to show message if current's inf conversion ratio is less than average */}
        {/* {currentInfluencerData?.conversion <
        allInfluencersAverageData?.conversion ? (
          <Box
            fontSize={"9px"}
            color={Colors.red}
            margin={{ md: "0px", base: "auto" }}
            mb={{ md: "0px", base: "10px" }}
          >
            {Strings.yourConversionRatioIsLess}
          </Box>
        ) : (
          <></>
        )} */}
        <Flex direction={{ md: "row", base: "column" }}>
          <Flex
            direction="column"
            width={{ md: "20%", base: "100%" }}
            alignItems="center"
            justifyContent="center"
            pt={{ md: "2%" }}
            pb={{ md: "1%" }}
          >
            <Box pb={2} width="70px" height="70px" objectFit="cover">
              {productImage?.includes(Mp4) ? (
                <>
                  <video className="insights_product_image" autoPlay muted>
                    <source src={productImage} type={VideoMp4} />
                  </video>
                </>
              ) : (
                <ReactImageFallback
                  fallbackImage={
                    <Skeleton className="insights_product_image" />
                  }
                  initialImage={<Skeleton className="insights_product_image" />}
                  src={productImage}
                  alt={"Product"}
                  className="insights_product_image"
                />
              )}
            </Box>
            <Box fontSize="18px" fontWeight="600" pb={{ base: "10px" }}>
              {productName}
            </Box>
          </Flex>
          {!isSmallerScreen && (
            <Divider
              color={Colors.borderColor}
              orientation="vertical"
              borderLeftWidth="2px"
              opacity="0.8"
              height="120px"
              alignSelf="center"
              pr={5}
            />
          )}
          <Flex
            direction="column"
            overflowY="scroll"
            width="100%"
            className="insights_table"
            mb={-2}
          >
            {isSmallerScreen && (
              <Divider
                orientation="horizontal"
                width="900px"
                borderBottomWidth="2px"
                opacity="0.8"
                color={Colors.borderColor}
              />
            )}
            <SimpleGrid
              templateColumns={"repeat(5, 1fr)"}
              width="900px"
              pb={2}
              fontSize="14px"
              color={Colors.grey100}
            >
              <Box></Box>
              <Box>{Strings.sales}</Box>
              <Box>{Strings.click}</Box>
              <Box>{Strings.conversion}</Box>
              <Box>{Strings.commissionEarned}</Box>
            </SimpleGrid>
            {isSmallerScreen && (
              <Divider
                color={Colors.borderColor}
                orientation="horizontal"
                width="900px"
                borderBottomWidth="2px"
                opacity="0.8"
                mb={2}
              />
            )}
            <SimpleGrid
              templateColumns={"repeat(5, 1fr)"}
              width="900px"
              pb={4}
              fontSize="14px"
            >
              <Box fontWeight="600">{Strings.you}</Box>
              <Box>{withCommas(currentInfluencerData.sales)}</Box>
              <Box>{withCommas(currentInfluencerData.clickCount)}</Box>
              <Box>{currentInfluencerData.conversion}</Box>
              <Box>
                {currencySymbol}{" "}
                {withCommas(currentInfluencerData.commissionEarned)}
              </Box>
            </SimpleGrid>
            <SimpleGrid
              templateColumns={"repeat(5, 1fr)"}
              width="900px"
              pb={4}
              fontSize="14px"
            >
              <Box fontWeight="600">{Strings.allInfluencers}</Box>
              <Box>{withCommas(allInfluencersAverageData.sales)}</Box>
              <Box>{withCommas(allInfluencersAverageData.clickCount)}</Box>
              <Box>{allInfluencersAverageData.conversion}</Box>
              <Box>
                {currencySymbol}{" "}
                {withCommas(allInfluencersAverageData.commissionEarned)}
              </Box>
            </SimpleGrid>
            <SimpleGrid
              templateColumns={"repeat(5, 1fr)"}
              width="900px"
              fontSize="14px"
              pb={2}
            >
              <Box fontWeight="600">{Strings.topSellingInf}</Box>
              <Box>{withCommas(topSellingInfluencerData.sales)}</Box>
              <Box>{withCommas(topSellingInfluencerData.clickCount)}</Box>
              <Box>{topSellingInfluencerData.conversion}</Box>
              <Box>
                {currencySymbol}{" "}
                {withCommas(topSellingInfluencerData.commissionEarned)}
              </Box>
            </SimpleGrid>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default InsightsTableRow;
