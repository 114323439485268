export interface IBodyText {
  formHeader: string;
  otherAction: {
    notifier: string;
    btnTxtAction: string;
  };
  btnTxtAction: string;
  txtRevertAction: string;
}

export interface FormProps {
  txtBody: IBodyText; //the text that goes in the the body of the form.
  isUpdateMode?: boolean;
}

export enum LAYOUT_KEYS {
  ACTIONS = "actions-layout",
  BRAND = "brand-layout",
  INFLUENCER = "influencer-layout"
}

export interface CountryCodeObject {
  isoCode: string,
  isdCode: string,
  id: number,
  name: string
}
