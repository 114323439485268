import { setDefaultFileUrls } from '../constants'

const appConfig = {
  //NOTE: keep all applevel constants here and update them, like ex: mixpanelTokens, sentry Token etc
  apiBaseUrl: '',
};

export const setAppConfig = (config: any) => {
  appConfig.apiBaseUrl = config.apiBaseUrl;
  setDefaultFileUrls();
};

export default appConfig;

