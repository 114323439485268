import {
  Influencer_1,
  Influencer_2,
  Influencer_3,
  Influencer_4,
  Influencer_5,
} from "@be-tagged/shared/src/assets/icons";
import { Box } from "@chakra-ui/react";
import { FC } from "react";
import "./influencersArranged_lg.scss";

/**
 * This component arranges the influencers inside the "Right Kaleidoscope" design
 * @returns {JSX.Element} - A React functional Component
 */
const InfluencersArranged: FC<{}> = (): JSX.Element => {
  return (
    <>
      <Box className="inf_container" position="absolute">
        <Box backgroundImage={`url(${Influencer_1})`} id="one" />
        <Box backgroundImage={`url(${Influencer_2})`} id="two" />
        <Box backgroundImage={`url(${Influencer_4})`} id="three" />
        <Box backgroundImage={`url(${Influencer_3})`} id="four" />
        <Box backgroundImage={`url(${Influencer_5})`} id="five" />
      </Box>
    </>
  );
};

export default InfluencersArranged;
