export const mockData = [
  {
    name: "Joey Tribbiani",
    sales: "04",
    commission: "60",
    date: "08 Sept 2021",
    socialMedia: {
      tikTok: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
      instagram: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
      facebook: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
      twitter: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
    },
  },
  {
    name: "Chandler Bing",
    sales: "04",
    commission: "60",
    date: "08 Sept 2021",
    socialMedia: {
      tikTok: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
      instagram: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
      twitter: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
    },
  },
  {
    name: "Phoebe Buffay",
    sales: "04",
    commission: "60",
    date: "08 Sept 2021",
    socialMedia: {
      tikTok: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
      instagram: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
      facebook: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
      twitter: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
    },
  },
  {
    name: "Monica Geller",
    sales: "04",
    commission: "60",
    date: "08 Sept 2021",
    socialMedia: {
      tikTok: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
      twitter: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
    },
  },
  {
    name: "Ross Geller",
    sales: "04",
    commission: "60",
    date: "08 Sept 2021",
    socialMedia: {
      tikTok: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
      twitter: {
        link: "www.google.com",
        sale: "05",
        click: "50",
      },
    },
  },
  {
    name: "Rachel Green",
    sales: "04",
    commission: "60",
    date: "08 Sept 2021",
    socialMedia: {
      tikTok: {
        link: "www.google.com",
        sale: "05",
        click: "50",
        conversion: "5",
      },
      twitter: {
        link: "www.google.com",
        sale: "05",
        click: "50",
        conversion: "5",
      },
    },
  },
];
export const rank = {
  socialMedia: {
    tikTok: {
      link: "www.google.com",
      sale: "05",
      click: "50",
      conversion: "5",
    },
    instagram: {
      link: "www.google.com",
      sale: "05",
      click: "50",
      conversion: "5",
    },
    facebook: {
      link: "www.google.com",
      sale: "05",
      click: "50",
      conversion: "5",
    },
    twitter: {
      link: "www.google.com",
      sale: "05",
      click: "50",
      conversion: "5",
    },
  },
};
