import { LogLevels } from "../../enums/LogLevels";
import { ILogTransport } from "./LogTransportInterface";
import UserModel from "./UserModel";

export default abstract class MixpanelLogTransportBase
  implements ILogTransport
{
  abstract client: any;
  abstract init: () => void;

  log = (
    message: string,
    attributes: Record<string, any>,
    level: LogLevels
  ) => {
    this.client.track(message, {
      ...attributes,
      level,
    });
  };

  identify = (user: UserModel) => {
    this.client.identify(user.id);
  };

  trackPage = (name: string, attributes?: Record<string, any>) => {
    this.log(name, attributes || {}, LogLevels.Info);
  };

  trackScreen = (name: string, attributes?: Record<string, any>) => {
    //@ts-ignore
    this.log(name, attributes || {}, LogLevels.Info);
  };
}
