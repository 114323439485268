import { Colors } from "@be-tagged/shared";
import { Stat, StatLabel, StatNumber } from "@chakra-ui/react";

const Statistics = ({
  label,
  value = "",
  children,
}: {
  label: string;
  value: number | string;
  children?: JSX.Element;
}) => {
  return (
    <Stat className="stat">
      <StatLabel color={Colors.grey100} className="label">
        {label}
      </StatLabel>
      <StatNumber className="value">{value}
      {children}
      </StatNumber>
    </Stat>
  );
};

export default Statistics;
