import {
  getInfluencerInsightsURL,
  getBrandProductsSoldURL,
  getInfluencerProductsSoldURL,
  getBrandPlatformInsightsURL,
  getInfluencerPlatformInsightsURL,
  getBrandInsightsURL
} from "../../../utils/UrlBuilder";
import {
  ProductsSoldQueryParams,
  PlatformInsightsQueryParams,
  FormikKeys,
  HTTPMethod,
} from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import {
  UPDATE_INSIGHTS_SUMMARY,
  UPDATE_PRODUCTS_SOLD_LIST,
  UPDATE_PLATFORM_INSIGHTS_LIST,
} from "../../constants/ReduxConstants";

interface BusinessInsightsInt {
  [FormikKeys.From]: string;
  [FormikKeys.To]: string;
  [FormikKeys.CountryID]?: number;
}

// To get the business Insights
export const getInsightsSummary =
  (insightParams: BusinessInsightsInt, isBrand: boolean) =>
    async (dispatch: any) => {
      const request = {
        method: HTTPMethod.GET,
        queryStringParams: insightParams,
        url: isBrand ? getBrandInsightsURL() : getInfluencerInsightsURL(),
      };
      return fetchAsync(dispatch, [request], [postSummaryFetchSuccess]);
    };

const postSummaryFetchSuccess = (payload: any) => ({
  type: UPDATE_INSIGHTS_SUMMARY,
  payload: payload.data,
});

// To get the products sold. booleanFn is an optional parameter, a function used to set local loading flag as true or false
export const getProductsSold =
  (params: ProductsSoldQueryParams, isBrand: boolean, booleanFn?: any) =>
    async (dispatch: any) => {
      booleanFn?.(true);
      const request = {
        method: HTTPMethod.GET,
        queryStringParams: {
          [FormikKeys.ListQty]: params[FormikKeys.ListQty] || 10,
          ...params,
        },
        url: isBrand ? getBrandProductsSoldURL() : getInfluencerProductsSoldURL(),
      };

      return fetchAsync(
        dispatch,
        [request],
        [(payload: any) => updateProductsSoldList(payload, booleanFn)]
      );
    };

const updateProductsSoldList = (payload: any, booleanFn?: any) => {
  booleanFn?.(false)
  return async (dispatch: any) => {
    return dispatch({
      type: UPDATE_PRODUCTS_SOLD_LIST,
      payload
    });
  };
};

export const clearProductsSold = () => {
  return updateProductsSoldList(null)
}

// To get platform Insights
export const getPlatformInsights =
  (params: PlatformInsightsQueryParams, isBrand: boolean, booleanFn?: any) =>
    async (dispatch: any) => {
      booleanFn?.(true);
      const request = {
        method: HTTPMethod.GET,
        queryStringParams: params,
        url: isBrand
          ? getBrandPlatformInsightsURL()
          : getInfluencerPlatformInsightsURL(),
      };
      return fetchAsync(
        dispatch,
        [request],
        [(payload: any) => updatePlatformInsightsList(payload, booleanFn)]
      );
    };

const updatePlatformInsightsList = (
  payload: any,
  booleanFn?: any
) => {
  return async (dispatch: any) => {
    booleanFn?.(false)
    return dispatch({
      type: UPDATE_PLATFORM_INSIGHTS_LIST,
      payload,
    });
  };
};

// To Clear PlatformInsights
export const clearPlatformInsights = () => {
  return async (dispatch: any) => {
    return dispatch({
      type: UPDATE_PLATFORM_INSIGHTS_LIST,
      payload: null,
    });
  };
};