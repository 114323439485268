import { FC, useEffect, useState } from "react";
import {
  Spinner,
  Box,
  Flex,
  Text,
  HStack,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";

import { Formik } from "formik";
import * as yup from "yup";

import {
  Colors,
  obfuscateEmail,
  RootState,
  Strings,
  OTP_LENGTH,
  logger,
  LoggerStrings,
} from "@be-tagged/shared";

import { CustomBtn } from "../../../app/components";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import ResetPassword from "./ResetPassword";
import {
  getSendResetCode,
  getVerifyResetCode,
} from "@be-tagged/shared/src/store/actions/CommonActions/ForgotPassword.actions";
import { CommonObjectType } from "src/app/constants";

const { enterValidOTP } = Strings;

//Fetching Formik keys
const { EmailOTP } = FormikKeys;

interface OTPInputProps {
  primaryEmail: string;
  userType: string;
}

const getValidationSchema = (isEmailVerified: boolean) => {
  let schemaObject: CommonObjectType = {};

  if (!isEmailVerified) {
    schemaObject[EmailOTP] = yup
      .string()
      .length(OTP_LENGTH, enterValidOTP)
      .required(enterValidOTP);
  }

  return schemaObject;
};

/**
 * OTPInput comes up to provide Email or Phone No. OTPs
 * @param {Object} props - Of the form OTPInputInterface
 * @returns {JSX.Element} - A React Functional Component
 */
const OTPInput: FC<OTPInputProps> = ({
  primaryEmail,
  userType,
}): JSX.Element => {
  const { loading, userInfo } = useSelector((state: RootState) => {
    return {
      loading: state?.appReducer?.loading,
      userInfo: state?.userReducer?.userData?.userInfo,
    };
  });

  const {
    isEmailVerified,
  }: {
    primaryEmailAddress: string;
    isEmailVerified: boolean;
  } = userInfo || "";
  const [otpflag, setotpflag] = useState(true);
  const dispatch = useDispatch();
  const verifyOTP = (values: any) => {
    dispatch(
      getVerifyResetCode(
        { email: primaryEmail, code: values.emailOTP, userType: userType },
        () => setotpflag(false)
      )
    );
  };
  const resendOTP = () => {
    dispatch(getSendResetCode({ email: primaryEmail, userType }, () => {}));
  };

  useEffect(() => {
    logger.trackPage(LoggerStrings.forgotPasswordOTPStep);
  }, []);

  return (
    <>
      {otpflag ? (
        <Formik
          initialValues={{
            [EmailOTP]: "",
          }}
          validateOnMount
          onSubmit={verifyOTP}
          validationSchema={yup
            .object()
            .shape(getValidationSchema(isEmailVerified))}
        >
          {({
            values,
            handleChange,
            errors,
            touched,
            setFieldTouched,
            handleSubmit,
            isValid,
          }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <>
                <Box className="content_container">
                  <Box style={{ fontSize: 16, marginBottom: 50 }}>
                    {Strings.enterVerifyEmailOTP1}{" "}
                    {primaryEmail && obfuscateEmail(primaryEmail)}
                    {". "}
                    {Strings.enterVerifyEmailOTP2}
                  </Box>
                  <Box className="otp_inputs">
                    {!isEmailVerified && (
                      <Box pos={"relative"}>
                        <Box
                          marginBottom={"20px"}
                          maxW={"250px"}
                          pos={"relative"}
                          marginTop={"-10px"}
                        >
                          <Box marginTop={"15px"}>
                            <Text
                              fontSize={"12px"}
                              fontWeight={"600"}
                              color={Colors.grey100}
                              marginBottom={"3px"}
                            >
                              {Strings.otp}
                            </Text>
                            <Box>
                              <HStack pos={"relative"}>
                                <PinInput
                                  value={values.emailOTP}
                                  onChange={handleChange(EmailOTP)}
                                >
                                  {new Array(6).fill("").map((el, idx) => (
                                    <PinInputField
                                      onBlur={() => {
                                        setFieldTouched(EmailOTP);
                                      }}
                                      placeholder={""}
                                      borderRadius={0}
                                      key={idx}
                                    />
                                  ))}
                                </PinInput>
                              </HStack>
                              {touched.emailOTP && errors.emailOTP && (
                                <Box className="error_msg" bottom={"-20px"}>
                                  {errors.emailOTP}
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box className="actions_btn_container">
                    <CustomBtn
                      id="action_btn"
                      className="purple_btn"
                      disabled={!isValid || loading}
                      type="submit"
                      style={{
                        fontSize: 14,
                        marginBottom: 20,
                        marginTop: 25,
                      }}
                    >
                      {loading && (
                        <Spinner size={"xs"} style={{ marginRight: 10 }} />
                      )}
                      {"Next"}
                    </CustomBtn>
                  </Box>
                  <Flex justifyContent={"flex-start"}>
                    <Box
                      role={"button"}
                      onClick={resendOTP}
                      fontSize={"12px"}
                      ml={"5px"}
                      mt={"5px"}
                      color={Colors.purple}
                      className="bold resend"
                      display={"inline-block"}
                    >
                      {Strings.ResendOTP}
                    </Box>
                  </Flex>
                </Box>
              </>
            </form>
          )}
        </Formik>
      ) : (
        <ResetPassword primaryEmail={primaryEmail} userType={userType} />
      )}
    </>
  );
};

export default OTPInput;
