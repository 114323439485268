import { RootState } from "@be-tagged/shared";
import { useSelector } from "react-redux";
import { CommonObjectType } from "../constants";

interface DataProperties {
  loading: boolean;
  productData: CommonObjectType;
  productCatalog: CommonObjectType;
  productDetails: CommonObjectType;
  dashboardData: CommonObjectType;
}

/**
 * useData hook will be used to get the commonly-accessed data properties (non-auth in nature) from the redux store
 * @returns {Object} - of the type DataProperties
 */
const useData = (): DataProperties => {
  const dataObject: DataProperties = useSelector((state: RootState) => {
    const { productData, dashboardData } =
      state?.commonReducer?.commonData || {};
    const { productCatalog = {}, productDetails = {} } = productData || {};
    return {
      loading: state.appReducer?.loading,
      productData,
      productCatalog,
      productDetails,
      dashboardData,
    };
  });

  return dataObject;
};

export default useData;
