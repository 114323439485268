import { Skeleton, Stack } from "@chakra-ui/react";

const ProductsCatalogSkeleton = () => {
  return (
    <Stack>
      {[1, 2, 3, 4, 5].map((el: number, idx: number) => (
        <Skeleton key={idx} className="div_container catalog_list_skeleton" />
      ))}
    </Stack>
  );
};

export default ProductsCatalogSkeleton;
