import { Strings } from "@be-tagged/shared";
import { Box, Flex, Skeleton, useMediaQuery } from "@chakra-ui/react";
import { FC } from "react";
import ReactImageFallback from "react-image-fallback";
import { DEVICE } from "src/app/assets/styles/styleEnums";
import TooltipWrapper from "src/app/components/TooltipWrapper";
import { withCommas } from "src/app/utils";
import { SUPPORTED_FILES } from "src/dashboard-module/interfaces";

interface ProductProps {
  id?: string;
  name?: string;
  sales?: number;
  price?: string;
  currencySymbol?: string;
  commission?: string;
  status?: string;
  image?: string;
  influencers?: string;
  brand?: string;
  category?: string;
  offerType?: boolean;
  isBrand?: boolean;
}

const List: FC<ProductProps> = ({
  name,
  sales,
  price,
  currencySymbol,
  commission,
  status,
  image,
  influencers,
  brand,
  category,
  offerType,
  isBrand,
}): JSX.Element => {
  const [isSmallerScreen] = useMediaQuery(DEVICE.tablet);
  const { VideoMp4, Mp4 } = SUPPORTED_FILES;
  const count = 15;
  if (category === undefined) {
    category = "";
  }
  const productCategories =
    category?.slice(0, count) + (category?.length > count ? "..." : "");

  if (name === undefined) {
    name = "";
  }
  const trimmedName = name?.slice(0, 25) + (name?.length > 25 ? "..." : "");
  return (
    <Box h="auto" className="div_container" textAlign={"left"}>
      <Box style={{ width: "80px", height: "70px" }}>
        {image?.includes(Mp4) ? (
          <>
            <video key={image} className="product-image" autoPlay muted>
              <source src={image} type={VideoMp4} />
            </video>
          </>
        ) : (
          <ReactImageFallback
            fallbackImage={<Skeleton className="product-image" />}
            initialImage={<Skeleton width={"70px"} height={"70px"} />}
            src={image}
            alt={"Product"}
            className="product-image"
          />
        )}
      </Box>
      <div>
        <Flex direction={"column"}>
          <Box className="heading">{Strings.name}</Box>
          <TooltipWrapper label={name}>
            <Box className="heading-next">{trimmedName}</Box>
          </TooltipWrapper>
        </Flex>
      </div>
      <div>
        <Flex direction={"column"}>
          <Box className="heading">{Strings.iprice}</Box>
          <Box className="heading-next">
            {currencySymbol}&nbsp;{withCommas(price)}
          </Box>
        </Flex>
      </div>
      <div>
        <Flex direction={"column"}>
          <Box className="heading">{Strings.icommission}</Box>
          <Box className="heading-next">{commission}%</Box>
        </Flex>
      </div>
      {isBrand ? (
        <>
          {!isSmallerScreen && (
            <div>
              <Flex direction={"column"}>
                <Box className="heading">{Strings.influencers}</Box>
                <Box className="heading-next">{influencers}</Box>
              </Flex>
            </div>
          )}
          <div>
            <Flex direction={"column"}>
              <Box className="heading">{Strings.sales}</Box>
              <Box className="heading-next">{withCommas(sales)}</Box>
            </Flex>
          </div>
          <div>
            <Flex direction={"column"}>
              <Box className="heading">{Strings.status}</Box>
              <Box className="heading-next">{status}</Box>
            </Flex>
          </div>
        </>
      ) : (
        <>
          <div>
            <Flex direction={"column"}>
              <Box className="heading">{Strings.brand}</Box>
              <Box className="heading-next">{brand}</Box>
            </Flex>
          </div>

          {!isSmallerScreen && (
            <div>
              <Flex direction={"column"}>
                <Box className="heading">{Strings.icategory}</Box>
                <TooltipWrapper label={category}>
                  <Box className="heading-next">{productCategories}</Box>
                </TooltipWrapper>
              </Flex>
            </div>
          )}
          <div>
            <Flex direction={"column"}>
              <Box className="heading">{Strings.iofferType}</Box>
              {offerType ? (
                <Box className="heading-next">{Strings.iapprovalBased}</Box>
              ) : (
                <Box className="heading-next">{Strings.iopen}</Box>
              )}
            </Flex>
          </div>
        </>
      )}
    </Box>
  );
};

export default List;
