import { FormikKeys } from "@be-tagged/shared/src/enums";
import dayjs from "dayjs";
const { From, To } = FormikKeys;

export const dateFilters: { name: string; value: any }[] = [
  {
    name: "This month",
    value: {
      [From]: dayjs().startOf("month").format(),
      [To]: dayjs().format(),
    },
  },
  {
    name: "Last 3 months",
    value: {
      [From]: dayjs().subtract(2, "months").startOf("month").format(),
      [To]: dayjs().format(),
    },
  },
  {
    name: "Last 6 months",
    value: {
      [From]: dayjs().subtract(5, "months").startOf("month").format(),
      [To]: dayjs().format(),
    },
  },
];

export const dateFiltersSinceListing = [
  {
    name: "Since listing",
    value: {
      [From]: null,
      [To]: null
    }
  },
  ...dateFilters
]