import { FC } from "react";
import { Box, Spinner } from "@chakra-ui/react";
import { useFormik } from "formik";

import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import {
  RootState,
  Strings,
  updateUserContactDetails,
} from "@be-tagged/shared";
import { FormikKeys } from "@be-tagged/shared/src/enums";

import {
  ErrorCleaner,
  MaterialStyleInput,
  CustomBtn,
  ErrorMsg,
} from "../../../app/components";

import { CountryCodeObject, FormProps } from "../../interfaces";
import MobileInput from "../../common/MobileInput";
import { YUP_VALIDATION } from "../../../app/strings";
import { ROUTING_URLS } from "../../../app/constants";
import { useLocation } from "react-router-dom";

//Fetching Formik Keys
const { Email, Phone, CountryCode } = FormikKeys;

interface SubmittedDetailsInt {
  [Phone]?: string;
  [CountryCode]?: CountryCodeObject;
  [Email]: string;
}

// Strings
const { workEmail, emailId, update } = Strings;

// Functions for Formik
const getValidationSchema = () => ({
  [Email]: yup.string().email().required(),
  [CountryCode]: yup.object().required(YUP_VALIDATION.COUNTRY_CODE_PRESENCE),
  [Phone]: yup
    .string()
    .min(7, YUP_VALIDATION.MOBILE_NUMBER_LENGTH)
    .max(15, YUP_VALIDATION.MOBILE_NUMBER_LENGTH)
    .required(YUP_VALIDATION.MOBILE_NUMBER_PRESENCE),
});

/**
 * UpdateInfoForm is the form that comes up when the user clicks on 'Update my details' in the Verify OTP Modal
 * With this, users can edit their phone number / email (basically the details used to verify them)
 * @returns {JSX.Element} - React Component
 */
const UpdateInfoForm: FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.appReducer);

  const userLoginDetails = useSelector((state: RootState) => {
    const { primaryEmailAddress, primaryPhone = "" } =
      state.userReducer?.userData?.userInfo;
    let [countryCode, onlyThePhone] = primaryPhone.split("-");

    const countriesArray = state?.commonReducer?.commonData?.countries || [];
    const countryCodeEl = countriesArray.find(
      (el: CountryCodeObject) => countryCode === `+` + el.isdCode
    );

    return {
      primaryEmailAddress,
      primaryPhone: onlyThePhone,
      countryCode: countryCodeEl,
    };
  });

  const { pathname } = useLocation();

  const isBrand = pathname.includes(ROUTING_URLS.BRAND);

  const onSubmit = async (details: SubmittedDetailsInt) => {
    const phone = details?.[Phone] || "";
    const countryCode = details?.[CountryCode]?.isdCode || "";

    dispatch(
      updateUserContactDetails(
        {
          email: details?.[Email],
          countryCode,
          phone,
        },
        isBrand
      )
    );
  };

  const formik = useFormik({
    initialValues: {
      [Email]: userLoginDetails.primaryEmailAddress,
      [Phone]: userLoginDetails.primaryPhone,
      [CountryCode]: userLoginDetails.countryCode,
    },
    onSubmit,
    validationSchema: yup.object().shape(getValidationSchema()),
  });

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldTouched,
    setFieldValue,
    touched,
    isValid,
  } = formik;

  return (
    <>
      <ErrorCleaner />
      <form onSubmit={handleSubmit}>
        <Box pb={{ base: 0, lg: "20px" }}>
          <Box pos={"relative"} pb={"12px"}>
            <MaterialStyleInput
              parentProps={{ style: { width: 300 } }}
              value={values.email}
              onChange={handleChange(Email)}
              onBlur={() => setFieldTouched(Email)}
              type={Email}
              label={isBrand ? workEmail : emailId}
            />
            {touched.email && errors.email && (
              <ErrorMsg bottom={-2}>{errors.email}</ErrorMsg>
            )}
          </Box>

          <Box pos={"relative"} pb={"12px"}>
            <MobileInput
              countryCode={values.countryCode}
              handleCountryCodeChange={(countryCode: any) =>
                setFieldValue(CountryCode, countryCode)
              }
              phone={values[Phone]}
              handlePhoneChange={handleChange(Phone)}
              setFieldTouched={setFieldTouched}
            />
            {touched[Phone] && errors[Phone] && (
              <ErrorMsg bottom={-2}>{errors[Phone]}</ErrorMsg>
            )}
          </Box>
        </Box>
        <Box className="actions_btn_container">
          <CustomBtn
            id="action_btn"
            className="purple_btn"
            disabled={!isValid || loading}
            type="submit"
            style={{
              fontSize: 14,
              marginBottom: 30,
            }}
          >
            {loading && <Spinner size={"xs"} style={{ marginRight: 10 }} />}
            {update}
          </CustomBtn>
        </Box>
      </form>
    </>
  );
};

export default UpdateInfoForm;
