import { FC } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Spinner,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  Colors,
  getProfileData,
  RootState,
  Strings,
  updateSocialMediaHandles,
} from "@be-tagged/shared";
import { CustomBtn, MaterialStyleInput } from "src/app/components";
import { Formik } from "formik";
import { FormikKeys, SocialMedia } from "@be-tagged/shared/src/enums";
import { CommonObjectType } from "src/app/constants";

interface SubmittedDetailsInt extends CommonObjectType {
  [FormikKeys.Instagram]?: string;
  [FormikKeys.TikTok]?: string;
  [FormikKeys.Facebook]?: string;
  [FormikKeys.Twitter]?: string;
}
interface AddSocialMediaHandleModalProps {
  field: string;
  fieldValue: any;
  isOpen: boolean;
  onClose: () => void;
  closeModal: () => void;
}
/**
 * @param {Object} Propss - Of the form AddSocialMediaHandleModalProps
 * @returns {JSX.Element} - A React Functional Component
 */
const AddSocialMediaHandleModal: FC<AddSocialMediaHandleModalProps> = ({
  field,
  fieldValue,
  isOpen,
  closeModal,
  onClose,
}): JSX.Element => {
  //Fetching Formik Keys
  const { Instagram, TikTok, Facebook, Twitter } = FormikKeys;
  const { loading } = useSelector(
    (state: RootState) => state?.appReducer?.loading
  );
  const getInitialValues: () => SubmittedDetailsInt = () => {
    let initalValues = {
      [Instagram]: fieldValue.instagram || "",
      [TikTok]: fieldValue.tikTok || "",
      [Facebook]: fieldValue.facebook || "",
      [Twitter]: fieldValue.twitter || "",
    };
    return initalValues;
  };
  const fieldName: CommonObjectType = {
    [Facebook]: SocialMedia.Facebook,
    [Instagram]: SocialMedia.Instagram,
    [TikTok]: SocialMedia.TikTok,
    [Twitter]: SocialMedia.Twitter,
  };

  const dispatch = useDispatch();
  const postCallFn = () => {
    dispatch(getProfileData(false));
    closeModal();
  };
  const updateSocialMediaHandle = (values: CommonObjectType) => {
    dispatch(
      updateSocialMediaHandles(
        {
          [Facebook]: values[Facebook],
          [Instagram]: values[Instagram],
          [TikTok]: values[TikTok],
          [Twitter]: values[Twitter],
        },
        postCallFn
      )
    );
  };

  return (
    <>
      <Modal
        size={"2xl"}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          className="filter_modal"
          style={{ paddingTop: 12 }}
          backgroundColor={Colors.bgDark}
        >
          <ModalBody padding="15px">
            <Box px={{ md: "15px", base: "0px" }} className="content_container">
              <Box className="bold" style={{ fontSize: 18, paddingBottom: 24 }}>
                {fieldName?.[field]}
              </Box>
              <Formik initialValues={getInitialValues()} onSubmit={() => {}}>
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  setFieldTouched,
                  setFieldValue,
                }) => (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                      }}
                    >
                      <Flex rowGap={"20px"} direction="column">
                        <MaterialStyleInput
                          parentProps={{ style: { width: 300 } }}
                          value={values[field]}
                          onChange={(e: any) =>
                            setFieldValue(field, e.target.value)
                          }
                          onBlur={() => setFieldTouched(field)}
                          type={"text"}
                          label={fieldName?.[field]}
                        />
                      </Flex>
                      <Flex justifyContent="flex-end" columnGap={4} mt={8}>
                        <CustomBtn
                          className="purple_btn medium inv"
                          onClick={closeModal}
                        >
                          {loading && (
                            <Spinner size={"xs"} style={{ marginRight: 10 }} />
                          )}
                          {Strings.cancel}
                        </CustomBtn>
                        <CustomBtn
                          className="purple_btn medium"
                          onClick={() => updateSocialMediaHandle(values)}
                          style={{ paddingLeft: "30px", paddingRight: "30px" }}
                        >
                          {loading && (
                            <Spinner size={"xs"} style={{ marginRight: 10 }} />
                          )}
                          {Strings.save}
                        </CustomBtn>
                      </Flex>
                    </form>
                  </>
                )}
              </Formik>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddSocialMediaHandleModal;
