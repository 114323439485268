import { useEffect, useState } from "react";
import { Colors, logger, LoggerStrings, SalesDataSampleFileURL, Strings } from "@be-tagged/shared";
import { SampleFileIcon } from "@be-tagged/shared/src/assets/icons";
import { Flex, Text, Image, Link, Box } from "@chakra-ui/react";
import WhatIsThisModal from "src/dashboard-module/common/WhatIsThisModal";

const SalesDataSampleSection = () => {
  const [openModal, setOpenModal] = useState(false);
  const [clickedOnSampleFile, setClickedOnSampleFile] = useState(false);
  useEffect(() => {
    if (clickedOnSampleFile) {
      const user = Strings.brand;
      logger.info(`${user} ${LoggerStrings.clickedOnDownloadButton}`);
    }
  }, [clickedOnSampleFile]);
  return (
    <Flex className="sample_sales_data_container" borderColor={Colors.borderColor} color={Colors.purple}>
      <Link href={SalesDataSampleFileURL} target="_blank" rel="noopener noreferrer">
        <Flex className="file_link">
          <Image src={SampleFileIcon} />
          <Text
            as={"span"}
            onClick={() => {
              setClickedOnSampleFile(true);
            }}
          >
            {Strings.salesData} {Strings.sampleFile}
          </Text>
        </Flex>
      </Link>
      <Text color={Colors.borderColor} as={"span"}>
        |
      </Text>
      <Box color={Colors.purple} className="helper_text" cursor={"pointer"} onClick={() => setOpenModal(true)}>
        {Strings.whatIsThis}
      </Box>
      <WhatIsThisModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        bodyText={Strings.salesDataInfoText}
        modalHeader={`${Strings.salesData} ${Strings.sampleFile}`}
      />
    </Flex>
  );
};

export default SalesDataSampleSection;
