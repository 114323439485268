import {
  getBrandProfileDetailsURL,
  getInfluencerProfileDetailsURL,
  getAddNewEmailURL,
  getAddNewPhoneURL,
  getChangePasswordURL,
  getUpdateProfilePictureURL,
  getUpdateSocialMediaHandlesURL,
  getSetAsPrimaryEmailURL,
  getSetAsPrimaryPhoneURL,
  getAndAddBankAccountsURL,
  getDeleteBankAccountURL,
  getSetAsPrimaryBankAccountURL,
  getBrandMemberDeleteAccountURL,
  getInfluencerDeleteAccountURL
} from "../../../utils/UrlBuilder";
import {
  SET_PROFILE_DATA,
  SET_SUCCESS,
  UPDATE_BANK_ACCOUNTS,
  UPDATE_EMAILS_IN_PROFILE_DATA,
  UPDATE_PHONES_IN_PROFILE_DATA,
  UPDATE_PROFILE_PICTURE
} from "../../constants/ReduxConstants";
import { HTTPMethod } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import Strings from "../../../strings/LocalisedStrings";
import { updateOTPVerifyModalStatus } from "../GlobalActions";
import { sendEmailOTP, sendPhoneOTP, verifyPhoneAndEmail } from "./VerifyOTP.actions";
import { logger } from "../../../services/Logging";
import LoggerStrings from "../../../strings/LoggerStrings";

export const getProfileData = (isBrand: boolean) => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.GET,
    queryStringParams: {},
    url: isBrand ? getBrandProfileDetailsURL() : getInfluencerProfileDetailsURL()
  };
  return fetchAsync(dispatch, [request], [postProfileFetch]);
};

const postProfileFetch = (payload: any) => ({
  type: SET_PROFILE_DATA,
  payload: payload.data
});

export const addNewEmail =
  (data: any, isMobile: boolean = true, callbackFn?: any) =>
  async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      data,
      url: getAddNewEmailURL()
    };
    return fetchAsync(dispatch, [request], [(payload: any) => postAddNewEmail(payload, isMobile, callbackFn)]);
  };

const postAddNewEmail = (payload: any, isMobile?: boolean, callbackFn?: any) => async (dispatch: any) => {
  callbackFn?.(payload?.data?.data);
  {
    !isMobile && dispatch(updateOTPVerifyModalStatus(true));
  }
  dispatch(
    sendEmailOTP({
      emailAddressId: payload?.data?.data?.id
    })
  );
  return dispatch({
    type: UPDATE_EMAILS_IN_PROFILE_DATA,
    payload: payload.data
  });
};

export const addNewPhone =
  (data: any, isMobile: boolean = true, callbackFn?: any) =>
  async (dispatch: any) => {
    const request = {
      method: HTTPMethod.POST,
      data,
      url: getAddNewPhoneURL()
    };
    return fetchAsync(dispatch, [request], [(payload: any) => postAddNewPhone(payload, isMobile, callbackFn)]);
  };

const postAddNewPhone = (payload: any, isMobile?: boolean, callbackFn?: any) => async (dispatch: any) => {
  callbackFn?.(payload?.data?.data);
  {
    !isMobile && dispatch(updateOTPVerifyModalStatus(true));
  }
  dispatch(
    sendPhoneOTP({
      phoneId: payload?.data?.data?.id
    })
  );
  return dispatch({
    type: UPDATE_PHONES_IN_PROFILE_DATA,
    payload: payload.data
  });
};
export const changePassword = (data: any, callbackFn?: any) => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.POST,
    data,
    url: getChangePasswordURL()
  };
  return fetchAsync(dispatch, [request], [(payload: any) => postPasswordChangeFn(payload, callbackFn)]);
};

const postPasswordChangeFn = (payload: any, callbackFn: any) => async (dispatch: any) => {
  callbackFn?.();
  return dispatch({
    type: SET_SUCCESS,
    payload: {
      message: payload.data.data
    }
  });
};

export const updateProfilePicture = (data: any, callbackFn?: any) => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.POST,
    data,
    url: getUpdateProfilePictureURL()
  };
  return fetchAsync(dispatch, [request], [(payload: any) => postProfilePicUpdate(payload, callbackFn)]);
};

export const updateSocialMediaHandles = (data: any, callbackFn?: any) => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.POST,
    data,
    url: getUpdateSocialMediaHandlesURL()
  };
  return fetchAsync(dispatch, [request], [() => postProfileDataUpdate(LoggerStrings.updatedSocialMediaHandles, callbackFn)]);
};

export const setAsPrimaryEmail = (id: any, callbackFn?: any) => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.POST,
    url: getSetAsPrimaryEmailURL(id)
  };
  return fetchAsync(dispatch, [request], [() => postProfileDataUpdate(LoggerStrings.changedPrimaryEmail, callbackFn)]);
};

export const setAsPrimaryPhone = (id: any, callbackFn?: any) => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.POST,
    url: getSetAsPrimaryPhoneURL(id)
  };
  return fetchAsync(dispatch, [request], [() => postProfileDataUpdate(LoggerStrings.changedPrimaryPhone, callbackFn)]);
};

const postProfileDataUpdate = (message: string, callbackFn: any) => () => {
  logger.info(`${Strings.user} ${message}`);
  callbackFn?.();
};

export const getBankAccounts = () => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.GET,
    url: getAndAddBankAccountsURL()
  };
  return fetchAsync(dispatch, [request], [setBankAccounts]);
};

const setBankAccounts = (payload: any) => ({
  type: UPDATE_BANK_ACCOUNTS,
  payload
});

export const addBankAccounts = (data: any) => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.POST,
    url: getAndAddBankAccountsURL(),
    data
  };
  return fetchAsync(dispatch, [request], [postAccountAdditionFn]);
};

export const setAsPrimaryBankAccount = (id: any, callbackFn?: any) => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.POST,
    url: getSetAsPrimaryBankAccountURL(id)
  };
  return fetchAsync(dispatch, [request], [() => postProfileDataUpdate(LoggerStrings.changedPrimaryBankAccount, callbackFn)]);
};

const postAccountAdditionFn = () => async (dispatch: any) => {
  logger.info(`${Strings.influencer} ${LoggerStrings.addedBankAccountSuccessfully}`);
  dispatch(getBankAccounts());
  return dispatch({
    type: SET_SUCCESS,
    payload: {
      message: Strings.bankAccountAdded
    }
  });
};

export const deleteBankAccounts = (id: number) => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.DELETE,
    url: getDeleteBankAccountURL(id)
  };
  return fetchAsync(dispatch, [request], [postAccountDeletionFn]);
};

const postAccountDeletionFn = () => async (dispatch: any) => {
  dispatch(getBankAccounts());
  return dispatch({
    type: SET_SUCCESS,
    payload: {
      message: Strings.bankAccountDeleted
    }
  });
};

const postProfilePicUpdate = (payload: any, callbackFn: any) => (dispatch: any) => {
  logger.info(`${Strings.user} ${LoggerStrings.changedProfilePicture}`);
  callbackFn?.();
  dispatch({
    type: UPDATE_PROFILE_PICTURE,
    payload: payload?.data?.profilePicUrl
  });
  dispatch({
    type: SET_SUCCESS,
    payload: {
      message: Strings.profilePicUpdated
    }
  });
};

export const deleteUserAccount = (isBrand: boolean) => async (dispatch: any) => {
  const request = {
    method: HTTPMethod.DELETE,
    url: isBrand ? getBrandMemberDeleteAccountURL() : getInfluencerDeleteAccountURL()
  };
  return fetchAsync(dispatch, [request], undefined, false);
};
