export enum OnboardingSteps {
  Name = 1,
  BusinessName = 2,
  ProductsServices = 3,
  TermsAndConditions = 4,
}
export enum OnboardingStepsName {
  NameStep = "NameStep",
  BusinessStep = "BusinessStep",
  ProdServStep = "ProdServStep",
  TermsAndConditionsStep = "TermsAndConditionsStep",
}
