import { Colors, Strings } from "@be-tagged/shared";
import { Flex, Box, SimpleGrid, Skeleton } from "@chakra-ui/react";
import { SalesDataUpload } from "src/dashboard-module/components/ProductDetails/HelperComponents/UploadComponents";
import ReactImageFallback from "react-image-fallback";
import { withCommas } from "src/app/utils";
import { SUPPORTED_FILES } from "src/dashboard-module/interfaces";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTING_URLS } from "src/app/constants";
import { ProductStatus } from "@be-tagged/shared/src/enums";

const BrandProductsSoldRow = ({ item }: { item: any }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { BUSINESS_INSIGHTS, PRODUCT_DETAILS } = ROUTING_URLS;
  const navigateToProductDetails = (id: number) => {
    navigate(pathname.replace(BUSINESS_INSIGHTS, PRODUCT_DETAILS) + "/" + id);
  };
  const { VideoMp4, Mp4 } = SUPPORTED_FILES;
  return (
    <div>
      <Flex
        mb={5}
        direction={{
          base: "column",
          md: "row",
        }}
        bgColor="white"
        shadow="md"
        p={{ md: "15px", base: "25px" }}
        borderRadius={10}
      >
        <Flex alignItems="center" width={{ md: "350px", base: "fit-content" }}>
          <Box
            width="57px"
            mr={5}
            onClick={() => {
              navigateToProductDetails(item?.brandProductId);
            }}
            cursor={"pointer"}
          >
            {item?.showCaseMediaUrls?.[0]?.includes(Mp4) ? (
              <>
                <video
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                  autoPlay
                  muted
                >
                  <source src={item?.showCaseMediaUrls?.[0]} type={VideoMp4} />
                </video>
              </>
            ) : (
              <ReactImageFallback
                onClick={() => {
                  navigateToProductDetails(item?.brandProductId);
                }}
                cursor={"pointer"}
                fallbackImage={<Skeleton className="insights_product_image" />}
                initialImage={<Skeleton className="insights_product_image" />}
                src={item?.showCaseMediaUrls?.[0]}
                alt={"Product"}
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
            )}
          </Box>
          <Box
            onClick={() => {
              navigateToProductDetails(item?.brandProductId);
            }}
            cursor={"pointer"}
            fontSize="18px"
            fontWeight="600"
            color={Colors.purple}
            width="200px"
          >
            <Box width="180px">{item?.productName}</Box>
          </Box>
        </Flex>
        <SimpleGrid
          templateColumns={{
            md: "1fr 1fr 1fr",
            base: "repeat(3, 1fr)",
          }}
          width="-webkit-fill-available"
          spacingY={5}
          mt={{ md: "0px", base: "15px" }}
        >
          <Box>
            <Box className="title" color={Colors.grey100}>
              {Strings.price}
            </Box>
            <Box className="value" color={Colors.grey}>
              {item?.currencySymbol} {withCommas(item?.price)}
            </Box>
          </Box>
          <Box>
            <Box className="title" color={Colors.grey100}>
              {Strings.sales}
            </Box>
            <Box className="value" color={Colors.grey}>
              {withCommas(item?.sales)}
            </Box>
          </Box>
          <Box>
            <Box className="title" color={Colors.grey100}>
              {Strings.value}
            </Box>
            <Box className="value" color={Colors.grey}>
              {item?.currencySymbol}&nbsp;
              {withCommas(item?.salesValue)}
            </Box>
          </Box>
        </SimpleGrid>
        <SimpleGrid
          templateColumns={{
            md: "1fr 2fr",
            base: "1fr 2fr",
          }}
          width="-webkit-fill-available"
          spacingY={5}
          mt={{ md: "0px", base: "15px" }}
        >
          <Box>
            <Box className="title" color={Colors.grey100}>
              {Strings.commission}
            </Box>
            <Box className="value" color={Colors.grey}>
              {item?.currencySymbol}&nbsp;
              {withCommas(item?.commission)}
            </Box>
          </Box>

          <Box>
            <Box className="title" color={Colors.grey100}>
              {Strings.salesDataUpdatedOn}
            </Box>
            <Flex className="value" color={Colors.grey}>
              <SalesDataUpload
                brandProductId={item?.brandProductId}
                date={item?.salesDataLastUploadedAtUtc}
                width={{ md: "140px", base: "100px" }}
                isProductIncommDetails={item?.productListedOn === "InCommMall"}
                productUnlisted={item?.productStatus === ProductStatus.Unlisted}
                productApprovalPending={
                  item?.productStatus === ProductStatus.ApprovalPending
                }
              />
            </Flex>
          </Box>
        </SimpleGrid>
      </Flex>
    </div>
  );
};

export default BrandProductsSoldRow;
