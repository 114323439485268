import { Colors, Strings } from "@be-tagged/shared";
const NoDataFound = ({ customText }: { customText?: string }) => {
  return (
    <div style={Styles.container}>({customText || Strings.noDataFound})</div>
  );
};

export default NoDataFound;

const Styles = {
  container: {
    color: Colors.grey100,
    fontStyle: "italic",
    fontSize: 10,
  },
};
