import { FC } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import {
  Selection,
  Onboarding,
  Dashboard,
  PageNotFound,
  PrivacyPolicy,
} from "../screens";
import { DIRECT_TRACKING_BLACKLISTED_ROUTES, ROUTING_URLS } from "../constants";

import { ProtectedRoutes, UnlockedRoutes } from "./PrivateRoute";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";
import { logger } from "@be-tagged/shared";
import Support from "../screens/Support";

const {
  GENERAL,
  ONBOARDING,
  SELECT,
  LANDING,
  NON_EXISTENT,
  PRIVACY_POLICY,
  SUPPORT,
} = ROUTING_URLS;

/**
 * RoutingManager will take care of the routing logic.
 * Based on whether the user is logged in or not, it will route to the desired component (element), or away from it to the login screen
 * Since it's the only component for the entire app whose immediate parent is the store Provider, it'll also set the token
 * @returns {JSX.Element}
 */
const RoutingManager: FC<{}> = (): JSX.Element => {
  const { isLoggedIn, isOnboarded, isBrand } = useAuth();
  const location = useLocation();
  const navigationAction = useNavigationType();

  useEffect(() => {
    // Two ways of logging a page. If we take history into consideration, then we'll log the target page URLs (with or without the redirection).
    // If we don't want to log the pages that'll redirect (due to auth), an alternative would be to track history from the parent components (Dashboard, Selection, etc.)

    const { pathname } = location;
    // Owing to redirection / self tracking logic, do not track the blacklisted routes
    if (DIRECT_TRACKING_BLACKLISTED_ROUTES.includes(pathname)) return;
    const getPageCategory = () => {
      if (pathname.includes(ROUTING_URLS.LANDING)) return "Dashboard";
      else if (pathname.includes(ROUTING_URLS.SELECT)) return "Login / Sign Up";
      else return "";
    };
    logger.trackPage(`${document.title} (${getPageCategory()})`, {
      isRedirected: navigationAction === "REPLACE",
      isBrand,
      pathname: location.pathname,
    });
  }, [location.pathname]);

  return (
    <Routes>
      <Route
        path={GENERAL}
        element={<Navigate replace to={isLoggedIn ? ONBOARDING : SELECT} />}
      />
      <Route
        path={`${SELECT}/*`}
        element={
          isLoggedIn ? <Navigate replace to={ONBOARDING} /> : <Selection />
        }
      />

      <Route element={<ProtectedRoutes />}>
        <Route
          path={ONBOARDING}
          element={
            isOnboarded ? <Navigate replace to={LANDING} /> : <Onboarding />
          }
        />

        <Route element={<UnlockedRoutes />}>
          <Route path={`${LANDING}/*`} element={<Dashboard />} />
        </Route>
      </Route>

      <Route path={NON_EXISTENT} element={<PageNotFound />} />
      <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={SUPPORT} element={<Support />} />
    </Routes>
  );
};

export default RoutingManager;
