import { Strings } from "@be-tagged/shared";
import { WarningIcon } from "@be-tagged/shared/src/assets/icons";
import { Box, CloseButton } from "@chakra-ui/react";
import { Alert, AlertDescription } from "@chakra-ui/react";
import { FC } from "react";

interface CouponIndicatorProps {
  close: () => void;
  codesExhausted?: boolean;
  productStatus?: string;
}
const LowCouponIndicator: FC<CouponIndicatorProps> = ({
  close,
  codesExhausted,
  productStatus,
}) => {
  return (
    <Box className="coupon_indicator_parent">
      <Alert
        p={2}
        w={"fit-content"}
        pr={"50px"}
        status="error"
        colorScheme="white"
        borderRadius={"lg"}
      >
        <img src={WarningIcon} alt={"warning"} />
        &nbsp;
        <AlertDescription fontSize={"12px"}>
          {productStatus === Strings.listed &&
            (codesExhausted
              ? Strings.couponCodesExhausted
              : Strings.couponCodeExpiring)}
          {productStatus === Strings.unListed && Strings.productUnlistedAlert}
        </AlertDescription>
        <CloseButton
          onClick={close}
          position="absolute"
          right="8px"
          top="5px"
        />
      </Alert>
    </Box>
  );
};

export default LowCouponIndicator;
