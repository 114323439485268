import { FC, useEffect } from "react";
import {
  CountrySelect,
  ErrorMsg,
  Label,
  MaterialStyleInput,
} from "../../../../app/components";
import { ONBOARDING_TEXT } from "../../../../app/constants";
import { Strings, Colors, RootState } from "@be-tagged/shared";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import { Box } from "@chakra-ui/react";
import {
  FacebookIcon as FBIcon,
  InstagramIcon,
  TikTokIcon,
  TwitterIcon,
} from "@be-tagged/shared/src/assets/icons";
import { CitySelect } from "../../../../app/components";
import { useSelector } from "react-redux";
import TooltipWrapper from "src/app/components/TooltipWrapper";

const {
  FullName,
  Country,
  City,
  Instagram,
  TikTok,
  Facebook,
  Twitter,
  EntryValidation,
} = FormikKeys;

interface IArgs {
  errors: { [field: string]: string };
  values: { [field: string]: any };
  touched: { [field: string]: boolean };
  isSubmitting?: boolean;
  handleChange: (e: any) => void;
  handleBlur: (e: any) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

/**
 * Component that comes as the first step of Influencer onboarding
 * Takes in the name of the Influencer (text), and their city (dropdown).
 * @param {Object} - of the type IArgs
 * @returns {JSX.Element} - A React Functional Component
 */
export const NameAndCityInput: FC<IArgs> = ({
  errors,
  values,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
}): JSX.Element => {
  const { preText, postText } = ONBOARDING_TEXT.inputForm.name.influencerTitle;

  const { countries, isCountriesLoading } = useSelector((state: RootState) => {
    return {
      countries: state?.commonReducer?.commonData?.countries,
      isCountriesLoading: state?.commonReducer?.commonData?.isCountriesLoading,
    };
  });

  const isCityDisabled = !values[Country];

  useEffect(() => {
    if (values[Country]) {
      values[City] = null;
    }
  }, [values[Country]]);

  return (
    <>
      <div className="input_title">
        {preText} {postText}
      </div>
      <div style={{ position: "relative", bottom: 10 }}>
        <MaterialStyleInput
          style={{ ...Styles.materialInput, marginBottom: 20 }}
          label={Strings.yourName}
          value={values[FullName]}
          onChange={handleChange(FullName)}
          onBlur={handleBlur(FullName)}
          hideLabelOnEnter={true}
        />
        {touched[FullName] && errors[FullName] && (
          <ErrorMsg>{errors[FullName]}</ErrorMsg>
        )}
      </div>
      <div style={{ position: "relative", bottom: 10, width: 300 }}>
        <Label>{Strings.yourCountry}</Label>
        <CountrySelect
          isMulti={false}
          isLoading={isCountriesLoading}
          countryOptions={countries}
          selectedCountries={values[Country]}
          setCountries={(items: any) => setFieldValue(Country, items)}
          onBlur={() => setFieldTouched(Country)}
        />
        {touched[Country] && errors[Country] && (
          <ErrorMsg bottom={-15}>{errors[Country]}</ErrorMsg>
        )}
      </div>
      <div
        style={{ position: "relative", top: 5, marginBottom: 40, width: 300 }}
      >
        <TooltipWrapper label={isCityDisabled ? Strings.enterCountryFirst : ""}>
          <CitySelect
            isDisabled={isCityDisabled}
            countryIsoCode={values[Country]?.isoCode}
            selectedCity={values[City]}
            setSelectedCity={(city: any) => setFieldValue(City, city)}
            onBlur={() => setFieldTouched(City)}
          />
        </TooltipWrapper>
      </div>
    </>
  );
};

/**
 * Component that comes as the second step of Influencer onboarding
 * Prompts the user to input their social media handles
 * @param {Object} of the type IArgs
 * @returns {JSX.Element} - A React Functional Component
 */
export const SocialMediaHandleInput: FC<IArgs> = ({
  errors,
  values,
  touched,
  isSubmitting,
  handleChange,
  handleBlur,
}): JSX.Element => {
  const atPrefix = "@";
  const slashPrefix = " / ";
  const { postText } = ONBOARDING_TEXT.inputForm.business.influencerTitle;

  const Inputs = [
    {
      icon: InstagramIcon,
      prefix: atPrefix,
      key: Instagram,
    },
    {
      icon: TikTokIcon,
      prefix: atPrefix,
      key: TikTok,
    },
    {
      icon: FBIcon,
      prefix: slashPrefix,
      key: Facebook,
    },
    {
      icon: TwitterIcon,
      prefix: atPrefix,
      key: Twitter,
    },
  ];

  return (
    <>
      <div className="input_title" style={{ minHeight: "auto" }}>
        {values[FullName]}
        {postText}
      </div>

      <div className="social_handles_container">
        {Inputs.map(({ icon, prefix, key }, idx) => {
          return (
            <div key={idx} className="handle_row">
              <img className="icon" src={icon} alt="Social Media Icon" />
              <div className="input_container">
                <span
                  className="handle_prefix"
                  style={{ borderColor: Colors.borderColor }}
                >
                  {prefix}
                </span>
                <MaterialStyleInput
                  parentProps={{
                    style: { margin: "15px 0 3px" },
                  }}
                  label={" "}
                  value={values[key]}
                  onChange={handleChange(key)}
                  onBlur={handleBlur(EntryValidation)}
                />
                {touched[key] && errors[key] && (
                  <ErrorMsg>{errors[key]}</ErrorMsg>
                )}
              </div>
            </div>
          );
        })}
        {(touched[EntryValidation] || !isSubmitting) &&
          errors[EntryValidation] && (
            <Box pos={"relative"}>
              <ErrorMsg bottom={-10}>{errors[EntryValidation]}</ErrorMsg>
            </Box>
          )}
      </div>
    </>
  );
};

const Styles = {
  materialInput: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  dropdownParent: {
    marginTop: 15,
  },
};
