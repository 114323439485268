import { Colors, Strings } from "@be-tagged/shared";
import { Box, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ROUTING_PARAMS, ROUTING_URLS } from "src/app/constants";

const LandingFooterActions = ({ isBrand }: { isBrand: boolean }) => {
  return (
    <Box className="bottom_action_container bold" color={Colors.purple}>
      {isBrand && (
        <>
          <Link
            to={`${ROUTING_URLS.LANDING}/${ROUTING_URLS.TEAMS}?action=${ROUTING_PARAMS.ADD_TEAM_MEMBER}`}
          >
            {Strings.addTeamMember}
          </Link>
          <Text as="span" color={Colors.grey100}>
            |
          </Text>
        </>
      )}
      <Link to={"/"}>{Strings.howItWorks}</Link>
    </Box>
  );
};

export default LandingFooterActions;
