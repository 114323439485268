import { Colors, Strings } from "@be-tagged/shared";
import { Flex, Box, SimpleGrid, Center, Skeleton } from "@chakra-ui/react";
import {
  FacebookIcon,
  InstagramIcon,
  Product_1,
  TikTokIcon,
  TwitterIcon,
} from "@be-tagged/shared/src/assets/icons";
import { CommonObjectType } from "src/app/constants";
import { SocialMedia } from "@be-tagged/shared/src/enums";
import ReactImageFallback from "react-image-fallback";
import { withCommas } from "src/app/utils";
import { SUPPORTED_FILES } from "src/dashboard-module/interfaces";

const InfluencerProductsSoldRow = ({ item }: { item: any }) => {
  const imgMapping: CommonObjectType = {
    [SocialMedia.TikTok]: TikTokIcon,
    [SocialMedia.Facebook]: FacebookIcon,
    [SocialMedia.Instagram]: InstagramIcon,
    [SocialMedia.Twitter]: TwitterIcon,
  };
  const { VideoMp4, Mp4 } = SUPPORTED_FILES;
  return (
    <div>
      <Flex
        mb={5}
        direction={{
          base: "column",
          md: "row",
        }}
        bgColor="white"
        shadow="md"
        p={3}
        borderRadius={10}
        flexWrap="wrap"
      >
        <Box>
          <Flex>
            <Center w="80px" h="80px">
              {item?.showCaseMediaUrls?.[0]?.includes(Mp4) ? (
                <>
                  <video
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                    autoPlay
                    muted
                  >
                    <source
                      src={item?.showCaseMediaUrls?.[0]}
                      type={VideoMp4}
                    />
                  </video>
                </>
              ) : (
                <ReactImageFallback
                  fallbackImage={
                    <Skeleton className="insights_product_image" />
                  }
                  initialImage={<Skeleton className="insights_product_image" />}
                  src={item?.showCaseMediaUrls?.[0]}
                  alt={"Product"}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
              )}
            </Center>

            <Flex
              direction={"column"}
              pl={5}
              justifyContent="space-evenly"
              width={{ md: "150px", base: "fit-content" }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: Colors.purple,
                }}
              >
                {item?.productName}
              </p>
              <p className="title">
                <span style={{ color: Colors.grey100 }}>
                  {Strings.sales} -{" "}
                </span>
                <span style={{ fontSize: "14px" }}>
                  {withCommas(item?.numberOfSales)}
                </span>
              </p>
              <p className="title">
                <span style={{ color: Colors.grey100 }}>
                  {Strings.commission} :&nbsp;
                </span>
                <span style={{ fontSize: "14px" }}>
                  {item?.currencySymbol}&nbsp;
                  {withCommas(item?.commission)}
                </span>
              </p>
            </Flex>
          </Flex>
        </Box>
        <SimpleGrid
          templateColumns={{
            md: "repeat(4, 1fr)",
            base: "repeat(2, 1fr)",
          }}
          pl={{ md: "20", base: "0" }}
          pt={{ md: "0", base: "5" }}
          spacingX={{
            base: "10",
            md: "20",
          }}
        >
          {item?.links.map((ele: any, key: number) => {
            return (
              <Flex
                key={key}
                direction={"column"}
                justifyContent="start"
                width={{ md: "100px", base: "max-content" }}
                pb={{ md: "0", base: "5" }}
                pt={1}
                alignItems="baseline"
              >
                <Flex alignItems="end">
                  <Box height="24px" width="24px" mr={2}>
                    <img
                      src={imgMapping?.[ele?.salesChannel]}
                      alt="Sale Channel"
                    />
                  </Box>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {ele.salesChannel}
                  </p>
                </Flex>

                <p
                  style={{
                    color: Colors.purple,
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  {ele.url}
                </p>
              </Flex>
            );
          })}
        </SimpleGrid>
      </Flex>
    </div>
  );
};

export default InfluencerProductsSoldRow;
