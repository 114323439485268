/// <reference types="@types/segment-analytics" />
import { LogLevels } from "../../enums/LogLevels";
import { ILogTransport } from "./LogTransportInterface";
import UserModel from "./UserModel";

export interface TrackingClientContainer {
  analytics: SegmentAnalytics.AnalyticsJS
}

export default abstract class SegmentLogTransportBase implements ILogTransport {
  abstract client: TrackingClientContainer;
  abstract init: () => void;

  log = (message: string, attributes: Record<string, any>, level: LogLevels) => {
    this.client.analytics.track(message, {
      ...attributes,
      level
    })
  }

  identify = (user: UserModel) => {
    this.client.analytics.identify(user.id, user)
  }

  trackPage = (name: string, attributes?: Record<string, any>) => {
    this.client.analytics.page(name, name, attributes)
  }

  trackScreen = (name: string, attributes?: Record<string, any>) => {
    //@ts-ignore
    this.client.analytics.screen(name, attributes)
  }
}