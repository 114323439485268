import { Colors, Strings } from "@be-tagged/shared";
import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { SearchIcon } from "@be-tagged/shared/src/assets/icons";
import { useEffect } from "react";
import { useState } from "react";
import usePrevious from "src/app/hooks/usePreviousValue";
import { SyntheticEvent } from "react";

interface SearchBarProps {
  onSearch?: (x?: any) => any;
  onInputChange?: any;
}

const SearchBar: FC<SearchBarProps> = ({ onSearch, onInputChange }) => {
  const [text, setText] = useState("");
  const previousVal = usePrevious(text);

  useEffect(() => {
    if (previousVal && !text) onSearch?.(text);
  }, [text]);

  const handleOnSearch = (e?: SyntheticEvent) => {
    e?.preventDefault();
    onSearch?.(text);
  };

  return (
    <form onSubmit={handleOnSearch}>
      <Box width={{ md: "40vw", base: "75vw" }} position="relative">
        <input
          type="text"
          onChange={(e: any) => {
            setText(e.target.value);
            onInputChange?.(e.target.value);
          }}
          value={text}
          placeholder={Strings.typeYourSearch}
          className="search-bar box-shadow"
        />
        <button
          type="submit"
          className="search-button box-shadow"
          style={{ background: Colors.purple }}
        >
          <img src={SearchIcon} alt={"Search"} />
        </button>
      </Box>
    </form>
  );
};

export default SearchBar;
