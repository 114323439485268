import { Box, Flex, Text } from "@chakra-ui/react";
import { Colors, Strings } from "@be-tagged/shared";
import { DashboardGroup2New } from "@be-tagged/shared/src/assets/icons";
import { CustomBtn } from "src/app/components";

export const BrandFacts = () => {
  return (
    <>
      <Text as="p" mb={6}>
        <Text as="span" fontWeight={"bold"}>
          {Strings.facts1}
        </Text>
        {Strings.facts2}{" "}
        <Text as="span" fontWeight={"bold"}>
          {Strings.facts3}
        </Text>
        &nbsp;
        {Strings.facts4}
      </Text>
      <Text as="p">
        <Text as="span" fontWeight={"bold"}>
          {Strings.facts5}
        </Text>
        {Strings.facts6}{" "}
        <Text as="span" fontWeight={"bold"}>
          {Strings.facts7}
        </Text>
        {Strings.facts8}
        <Text as="span" fontWeight={"bold"}>
          {Strings.facts9}
        </Text>{" "}
        {Strings.facts10}
      </Text>
    </>
  );
};

export const BrandListProduct = ({
  goToListing,
}: {
  goToListing: () => void;
}) => {
  return (
    <Box bgColor={Colors.bgLight} className="white_box_container">
      <Flex className="list_section">
        <Box className="list_product_container">
          <img
            src={DashboardGroup2New}
            alt="List Product"
            id="list_product_img"
          />
        </Box>
        <Box className="list_product_text">
          <Box className="text">{Strings.listYourProductNew}</Box>
          <Box className="actions_btn_container">
            <CustomBtn
              id="action_btn"
              className="purple_btn"
              onClick={goToListing}
            >
              {Strings.listProduct}
            </CustomBtn>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
