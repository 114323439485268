import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { CUSTOM_DATE_FORMAT, MEMBER_SINCE_DATE_FORMAT } from "../constants";
dayjs.extend(utc);
export const getFormattedLocalFromUTC = (dateString: string): string => {
  if (!dateString) return "";
  return dayjs(dateString).local().format(CUSTOM_DATE_FORMAT);
};
export const getMemberSinceFormattedLocalFromUTC = (
  dateString: string
): string => {
  if (!dateString) return "";
  return dayjs(dateString).local().format(MEMBER_SINCE_DATE_FORMAT);
};
