import {
  clearInfluencerRequests,
  getInfluencerRequestsForApproval,
  RootState,
  validateInfluencerRequest,
} from "@be-tagged/shared";
import { Box, Flex } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./InfluencersList.styles.scss";
import ActionConfirmModal from "./ActionConfirmModal";
import ProductRequestRow from "./ProductRequestRow";
import { InfluencerRequestContext } from "src/app/contexts/InfluencerRequestContext";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "src/app/components/LoadingSpinner";

interface ProductProps {
  id?: any;
}
const initialParams = {
  skip: 0,
  take: 5,
  order: "",
  criteria: "",
};
const InfluencersList: FC<ProductProps> = ({ id }): JSX.Element => {
  const initActionDataVal = {
    showModal: false,
    productId: 0,
    influencerId: 0,
    action: null,
    requestIds: [],
    clearSelectedRequests: () => {},
  };

  const [influencerRequestActionData, setInfluencerRequestActionData] =
    useState(initActionDataVal);

  const [queryParams, setQueryParams] = useState(initialParams);

  const dispatch = useDispatch();

  const { requestsForApproval = {} } = useSelector(
    (state: RootState) => state?.brandReducer?.productData
  );

  const closeActionModal = () => {
    setInfluencerRequestActionData(initActionDataVal);
  };

  const getInfluencersList = () => {
    dispatch(getInfluencerRequestsForApproval(id, queryParams));
  };
  useEffect(() => {
    getInfluencersList();
  }, [queryParams]);

  useEffect(() => {
    return () => {
      dispatch(clearInfluencerRequests());
    };
  }, []);

  const confirmAction = () => {
    const { requestIds, action, influencerId, clearSelectedRequests } =
      influencerRequestActionData;
    let finalObject = {
      influencerId,
      brandProductId: id,
      approvalStatus: action,
      productPromotionRequests: requestIds,
    };
    dispatch(validateInfluencerRequest(finalObject));
    clearSelectedRequests();
    closeActionModal();
  };

  const loadNextRequests = () => {
    setQueryParams({
      ...queryParams,
      skip: queryParams.take,
      take: queryParams.skip + queryParams.take,
    });
  };

  const requestsForProduct = requestsForApproval?.[id] || [];

  return (
    <InfluencerRequestContext.Provider
      value={{
        ...influencerRequestActionData,
        setData: setInfluencerRequestActionData,
      }}
    >
      <Box>
        <Flex
          direction={"column"}
          maxHeight={{ base: "500px", md: "220px" }}
          overflow="scroll"
          mr={-3}
        >
          <InfiniteScroll
            dataLength={requestsForProduct?.data?.length || 0}
            next={loadNextRequests}
            hasMore={requestsForProduct?.nextLink}
            loader={<LoadingSpinner />}
          >
            {(requestsForProduct?.data || []).map(
              (productRequest: any, index: number) => (
                <ProductRequestRow
                  productRequest={productRequest}
                  key={id + "|" + index}
                />
              )
            )}
          </InfiniteScroll>
        </Flex>
        <ActionConfirmModal
          actionData={influencerRequestActionData}
          isOpen={influencerRequestActionData.showModal}
          onClose={closeActionModal}
          onConfirm={confirmAction}
        />
      </Box>
    </InfluencerRequestContext.Provider>
  );
};

export default InfluencersList;
