import { Colors, Strings } from "@be-tagged/shared";
import { Divider, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import TermsAndConditionModal from "src/account-module/common/TermsAndConditionModal";
import "./footer.scss";
const Footer = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div>
      <Divider height={2} mt={"50px"} mb={"30px"} borderBottomWidth={"3px"} />
      <Flex className="terms-conditions" mb={"20px"} color={Colors.grey100}>
        <Text onClick={() => setOpenModal(true)} style={{ cursor: "pointer" }}>
          {Strings.termsAndConditions}
        </Text>
        <Text>{Strings.poweredByBeTagged}</Text>
      </Flex>
      <TermsAndConditionModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      />
    </div>
  );
};

export default Footer;
