import { createReducer } from "../reducerUtil";
import {
  RESET_INFLUENCER_REDUCER,
  UPDATE_BRANDS_LIST,
  UPDATE_COMMISSION_PAYOUT,
  UPDATE_LEADERBOARD_DATA,
  UPDATE_LEADERBOARD_INSIGHTS,
  UPDATE_LOADING_BRANDS_STATUS,
  UPDATE_PRODUCTS_PROMOTED,
  CLEAR_PRODUCTS_PROMOTED
} from "../../constants/ReduxConstants";
import { appendToPrevious } from "../../../utils/StoreUtils";

interface InfluencerReducerType {
  brands: any[];
  isLoadingBrands: boolean;
  insights: { [k: string]: any };
  productLeaderboard: { [k: string]: any };
  productData: any;
}

const initialState = {
  brands: [],
  isLoadingBrands: false,
  insights: {},
  productLeaderboard: {
    data: {},
    insights: {},
  },
  productData: {},
};

export const setBrandsList = (
  state: InfluencerReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    brands: payload,
  };
};

export const updateLoadingBrandsStatus = (
  state: InfluencerReducerType,
  payload: boolean
): typeof state => {
  return {
    ...state,
    isLoadingBrands: payload,
  };
};

export const updateCommissionPayout = (
  state: InfluencerReducerType,
  payload: any
): typeof state => {
  let commissionPayoutData = {};
  if (payload?.data) {
    commissionPayoutData = appendToPrevious(
      state?.insights?.commissionPayout?.data || [],
      payload
    );
  }
  return {
    ...state,
    insights: {
      ...state.insights,
      commissionPayout: commissionPayoutData,
    },
  };
};

export const updateLeaderboard = (
  state: InfluencerReducerType,
  payload: any
): typeof state => {
  let productLeaderboardData = {};
  if (payload?.data) {
    productLeaderboardData = appendToPrevious(
      state?.productLeaderboard?.data?.data || [],
      payload
    );
  }
  return {
    ...state,
    productLeaderboard: {
      ...state.productLeaderboard,
      data: productLeaderboardData,
    },
  };
};

export const updateLeaderboardInsights = (
  state: InfluencerReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    productLeaderboard: {
      ...state.productLeaderboard,
      insights: payload.data,
    },
  };
};

export const updateProductsPromoted = (
  state: InfluencerReducerType,
  payload: any
): typeof state => {
  if (payload.isMobile) {
    const currentData = state?.productData?.productsPromoted || [];
    return {
      ...state,
      productData: {
        ...state.productData,
        productsPromoted: [...currentData, ...payload.data.data],
      },
    };
  }
  return {
    ...state,
    productData: {
      ...state.productData,
      productsPromoted: payload.data,
    },
  };
};

export const clearProductsPromotedBy = (
  state: InfluencerReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    productData: {
      ...state.productData,
      productsPromoted: payload,
    },
  }
};

export const resetInfluencerReducer = (): InfluencerReducerType => {
  return initialState;
};

export default createReducer(initialState, {
  [UPDATE_BRANDS_LIST]: setBrandsList,
  [UPDATE_LOADING_BRANDS_STATUS]: updateLoadingBrandsStatus,
  [UPDATE_COMMISSION_PAYOUT]: updateCommissionPayout,
  [UPDATE_LEADERBOARD_DATA]: updateLeaderboard,
  [UPDATE_LEADERBOARD_INSIGHTS]: updateLeaderboardInsights,
  [RESET_INFLUENCER_REDUCER]: resetInfluencerReducer,
  [UPDATE_PRODUCTS_PROMOTED]: updateProductsPromoted,
  [CLEAR_PRODUCTS_PROMOTED]: clearProductsPromotedBy,
});
