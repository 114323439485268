import { FC, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Spinner,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  Colors,
  deleteTeamMember,
  getBrandTeamMembers,
  logger,
  LoggerStrings,
  RootState,
  Strings,
} from "@be-tagged/shared";
import { CustomBtn } from "src/app/components";
import { TeamQueryParams } from "@be-tagged/shared/src/enums";
import { CommonObjectType } from "src/app/constants";

interface RemoveMemberModalProp {
  isOpen: boolean;
  id: number;
  email: string;
  onClose: () => void;
  closeModal: () => void;
}
/**
 * @param {Object} props - Of the form RemoveTeamMemberProp
 * @returns {JSX.Element} - A React Functional Component
 */
const RemoveMemberModal: FC<RemoveMemberModalProp> = ({
  isOpen,
  id,
  email,
  closeModal,
  onClose,
}): JSX.Element => {
  const initialParams: CommonObjectType = {
    skip: 0,
    take: 5,
    order: "",
    criteria: "",
  };
  const { loading } = useSelector(
    (state: RootState) => state?.appReducer?.loading
  );
  const dispatch = useDispatch();
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteMemberStage, setDeleteMemberStage] = useState(0);
  const postCallFn = () => {
    const finalPayload = initialParams as TeamQueryParams;
    dispatch(getBrandTeamMembers(finalPayload));
    closeModal();
    setDeleteMemberStage(2);
  };
  const removeTeamMember = () => {
    setDeleteMemberStage(1);
    dispatch(deleteTeamMember(id, postCallFn));
  };
  useEffect(() => {
    if (isOpen) {
      logger.info(
        `${Strings.brand} ${LoggerStrings.clickedOnDeleteMember} ${email}`
      );
    }
    if (deleteMemberStage) {
      logger.info(
        `${Strings.brand} ${LoggerStrings.clickedOnYesDelete} ${email}`
      );
    }
  }, [isOpen, deleteMemberStage, email]);
  return (
    <>
      <Modal
        size={"2xl"}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          className="filter_modal"
          style={{ paddingTop: 12 }}
          backgroundColor={Colors.bgDark}
        >
          <ModalBody padding="15px">
            <Box px={{ md: "15px", base: "0px" }} className="content_container">
              <Box className="bold" style={{ fontSize: 18, paddingBottom: 24 }}>
                {Strings.removeTeamMember}
              </Box>
              <Box>
                {Strings.confirmDeleteTeamMember}
                {email}?
              </Box>
              <Flex justifyContent="flex-end" columnGap={4} mt={8}>
                <CustomBtn
                  type="button"
                  className="purple_btn medium inv"
                  onClick={closeModal}
                >
                  {loading && (
                    <Spinner size={"xs"} style={{ marginRight: 10 }} />
                  )}
                  {Strings.cancel}
                </CustomBtn>
                <CustomBtn
                  type="submit"
                  className="purple_btn medium"
                  onClick={() => {
                    removeTeamMember();
                  }}
                  style={{ paddingLeft: "30px", paddingRight: "30px" }}
                >
                  {loading && (
                    <Spinner size={"xs"} style={{ marginRight: 10 }} />
                  )}
                  {Strings.remove}
                </CustomBtn>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default RemoveMemberModal;
