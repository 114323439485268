import { RootState } from "@be-tagged/shared";
import { setAccessToken } from "@be-tagged/shared/src/config/AccessTokenHelper";
import { FC } from "react";
import { useSelector } from "react-redux";

/**
 * TokenLoader component fires the function that sets the access token to the axios client
 * Obtains the access token from the redux store
 * @returns {JSX.Element} - A React Functional Component
 */
const TokenLoader: FC = (): JSX.Element => {
  const token = useSelector(
    (state: RootState) => state?.userReducer?.userData?.accessToken
  );
  if (token) setAccessToken(token);
  return <></>;
};

export default TokenLoader;
