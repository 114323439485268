import { Colors, Strings } from "@be-tagged/shared";
import { Product_1 } from "@be-tagged/shared/src/assets/icons";
import { Box, Flex, Skeleton, useMediaQuery } from "@chakra-ui/react";
import { FC } from "react";
import ReactImageFallback from "react-image-fallback";
import { Link } from "react-router-dom";
import { ROUTING_URLS } from "src/app/constants";
import { SUPPORTED_FILES } from "src/dashboard-module/interfaces";

const { PRODUCT_DETAILS } = ROUTING_URLS;
interface ProductProps {
  name?: string;
  brandProductId?: number;
  brandName?: string;
  price?: string;
  currencySymbol: string;
  commissionPercentage?: string;
  images?: string;
}

const ProductsList: FC<ProductProps> = ({
  name,
  brandProductId,
  price,
  currencySymbol,
  commissionPercentage,
  brandName,
  images,
}): JSX.Element => {
  const { VideoMp4, Mp4 } = SUPPORTED_FILES;
  return (
    <Link to={`${PRODUCT_DETAILS}/${brandProductId}`}>
      <Flex
        borderColor={Colors.borderColor}
        className="product_item"
        _hover={{
          bgColor: Colors.bgLight,
        }}
        p={2}
      >
        <Flex className="img_and_name">
          {images?.includes(Mp4) ? (
            <>
              <video
                className="product_img"
                style={{ width: 70, height: 70, objectFit: "cover" }}
                autoPlay
                muted
              >
                <source src={images} type={VideoMp4} />
              </video>
            </>
          ) : (
            <ReactImageFallback
              src={images}
              fallbackImage={
                <Skeleton
                  className="product_img"
                  style={{ width: 70, height: 70, objectFit: "cover" }}
                />
              }
              style={{ width: 70, height: 70, objectFit: "cover" }}
              alt={"Product"}
              className="product_img"
            />
          )}
          <Flex className="col">
            <Box className="small_title" style={Styles.header}>
              {Strings.name}
            </Box>
            <Box className="value">{name}</Box>
          </Flex>
        </Flex>

        <Flex className="details">
          <Flex className="col">
            <Box className="small_title" style={Styles.header}>
              {Strings.brand}
            </Box>
            <Box className="value">{brandName}</Box>
          </Flex>
          <Flex className="col">
            <Box className="small_title" style={Styles.header}>
              {Strings.iprice}
            </Box>
            <Box className="value">
              {currencySymbol} {price}
            </Box>
          </Flex>
          <Flex className="col">
            <Box className="small_title" style={Styles.header}>
              {Strings.icommission}
            </Box>
            <Box className="value">{commissionPercentage}%</Box>
          </Flex>
        </Flex>
      </Flex>
    </Link>
  );
};

const Styles = {
  header: {
    color: Colors.grey100,
  },
};

export default ProductsList;
