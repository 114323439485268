import {
  getNotificationsURL,
  readAllNotificationsURL,
  readNotificationURL,
} from "../../../utils/UrlBuilder";
import { NotificatonQueryParams, FormikKeys, HTTPMethod } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import {
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_VIA_SCROLL,
} from "../../constants/ReduxConstants";

// To get the notifications
export const getNotifications =
  (
    params: NotificatonQueryParams,
    viaScroll: boolean,
    booleanFn?: any,
    isMobile: boolean = false
  ) =>
  async (dispatch: any) => {
    booleanFn?.(true);
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: params,
      url: getNotificationsURL(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [
        (payload: any) =>
          setNotifications(payload, viaScroll, booleanFn, isMobile),
      ],
      false
    );
  };

export const setNotifications = (
  payload: any,
  viaScroll: boolean = false,
  booleanFn?: any,
  isMobile?: boolean
) => {
  return async (dispatch: any) => {
    if (viaScroll) {
      booleanFn?.(false);
      return dispatch({
        type: SET_NOTIFICATIONS_VIA_SCROLL,
        payload: {
          isMobile,
          data: payload?.data || {},
        },
      });
    }
    return dispatch({
      type: SET_NOTIFICATIONS,
      payload: isMobile ? payload?.data?.data || [] : payload?.data || {},
    });
  };
};

export const readNotification =
  (notificationId: number) => async (dispatch: any) => {
    const request = {
      method: HTTPMethod.PUT,
      queryStringParams: {},
      url: readNotificationURL(notificationId),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => postReadAllNotificationFn(payload, false)],
      false
    );
  };

export const readAllNotifications =
  (isMobile: boolean = false) =>
  async (dispatch: any) => {
    const request = {
      method: HTTPMethod.PUT,
      queryStringParams: {},
      url: readAllNotificationsURL(),
    };
    return fetchAsync(
      dispatch,
      [request],
      [(payload: any) => postReadAllNotificationFn(payload, isMobile)],
      false
    );
  };

const postReadAllNotificationFn =
  (payload: any, isMobile: boolean) => (dispatch: any) => {
    isMobile
      ? dispatch(getNotifications({ skip: 0, take: 10 }, false, () => {}, true))
      : dispatch(getNotifications({ skip: 0, take: 10 }, false, undefined));
  };
