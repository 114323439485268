import { FC, useEffect, useState } from "react";

import { Box, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

// Shared Assets
import {
  RootState,
  Strings,
  clearErrors,
  updateOTPVerifyModalStatus,
} from "@be-tagged/shared";
import { ArrowLeft } from "@be-tagged/shared/src/assets/icons";

// Helper / Custom Components
import OTPModal from "../common/OTPModal";
import { AppLogo, ErrorCleaner, SplitOptionDarkBg } from "src/app/components";
import SignUpForm from "../components/SignUp";
import SignInForm from "../components/SignIn";
// Component-specific constants
import { IBodyText } from "../interfaces";
import ForgotPassword from "../components/ForgotPassword";

import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ROUTING_URLS } from "src/app/constants";
import { ROLE_TYPES } from "src/app/enums";
import { DEVICE } from "src/app/assets/styles/styleEnums";
import UpdateInfo from "../components/UpdateInfo";

// Strings imported from shared
const {
  userforgotPassword,
  boostBusiness,
  spreadTheWord,
  alreadyHavingAccount,
  noAccount,
  signIn,
  signUp,
  dontFeelLikeSignIn,
  dontFeelLikeSignup,
  dontFeelLikeResetPass,
} = Strings;

/**
 * Returns object having strings as values which are shown as text in the body
 * @param isLogin {boolean} - Whether to show the text for Sign In screen, or for Sign Up screen
 * @returns {IBodyText} - Object having strings as values
 */
const getTxtBody = (
  isLogin: boolean,
  isForgotPass: boolean,
  isBrand: boolean
): IBodyText => {
  return {
    formHeader: isForgotPass
      ? userforgotPassword
      : isBrand
      ? boostBusiness
      : spreadTheWord,
    otherAction: {
      notifier: isLogin ? noAccount : alreadyHavingAccount,
      btnTxtAction: isLogin ? signUp : signIn,
    },
    btnTxtAction: isLogin ? signIn : signUp,
    txtRevertAction: isLogin
      ? dontFeelLikeSignIn
      : isForgotPass
      ? dontFeelLikeResetPass
      : dontFeelLikeSignup,
  };
};

/**
 * ActionsLayout is the layout component for Sign In / Sign Up Actions
 * @param {Object} - Props that the component receives, of the form ActionLayoutProps
 * @returns {JSX.Element}
 */
const ActionsLayout: FC<{
  roleType: ROLE_TYPES;
}> = ({ roleType }): JSX.Element => {
  const { pathname } = useLocation();

  const [isUpdateMode, setUpdateMode] = useState(false);

  const isLoginMode = pathname.includes(ROUTING_URLS.SIGN_IN);
  const isForgotPassMode = pathname.includes(ROUTING_URLS.FORGOT_PASSWORD);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { showOTPVerifyModal } = useSelector(
    (state: RootState) => state.appReducer
  );

  const dispatch = useDispatch();

  const isBrand: boolean = roleType === ROLE_TYPES.Brand;
  const txtBody = getTxtBody(isLoginMode, isForgotPassMode, isBrand);

  /**
   * trigger the modal whenever the value of showOTPVerifyModal becomes true
   */
  useEffect(() => {
    if (showOTPVerifyModal) onOpen();
    else onClose();
    //eslint-disable-next-line
  }, [showOTPVerifyModal]);

  const navigate = useNavigate();

  const goToPreviousScreen = () => {
    dispatch(clearErrors());
    navigate(ROUTING_URLS.SELECT);
  };

  const updateDetailsClick = () => {
    setUpdateMode(true);
  };

  useEffect(() => {
    return () => {
      dispatch(updateOTPVerifyModalStatus(false));
    };
  }, []);
  const [isSmall] = useMediaQuery(DEVICE.custom);

  return (
    <>
      <ErrorCleaner />
      <SplitOptionDarkBg className="option form">
        {!(isBrand || isSmall) && (
          <Box mb={"-20px"}>
            <Link to={`${ROUTING_URLS.GENERAL}`}>
              <AppLogo />
            </Link>
          </Box>
        )}

        <Box className={"inner" + (!isBrand ? " inf" : "")} pos={"relative"}>
          <Box>
            <Box className="form_header">{txtBody.formHeader}</Box>
            <Box>
              {/* Render the appropriate component based on whether the user is at the sign-in or sign-up URL */}
              <Routes>
                <Route
                  path={ROUTING_URLS.SIGN_IN}
                  element={
                    isUpdateMode ? (
                      <UpdateInfo />
                    ) : (
                      <SignInForm txtBody={getTxtBody(true, false, isBrand)} />
                    )
                  }
                />
                <Route
                  path={ROUTING_URLS.SIGN_UP}
                  element={
                    isUpdateMode ? (
                      <UpdateInfo />
                    ) : (
                      <SignUpForm
                        isUpdateMode={isUpdateMode}
                        txtBody={getTxtBody(false, false, isBrand)}
                      />
                    )
                  }
                />
                <Route
                  path={ROUTING_URLS.FORGOT_PASSWORD}
                  element={
                    <ForgotPassword
                      isBrand={isBrand}
                      txtBody={getTxtBody(false, true, isBrand)}
                    />
                  }
                />
              </Routes>
            </Box>
          </Box>
          <Box
            role="button"
            onClick={goToPreviousScreen}
            className={"revert_signup_btn" + (!isBrand ? " inf" : "")}
            style={{ fontSize: "14px" }}
            w={"fit-content"}
          >
            <img src={ArrowLeft} alt="Back Arrow" />
            &nbsp; {txtBody.txtRevertAction}
          </Box>
        </Box>
      </SplitOptionDarkBg>
      <OTPModal
        isOpen={isOpen}
        onClose={onClose}
        callbackFn={updateDetailsClick}
      />
    </>
  );
};

export default ActionsLayout;
