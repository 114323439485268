export default {
  blackColor: '#000000',
  themeColor: '#FFFFFF',
  statusBarColor: 'transparent',
  dollarGreen: '#59BF59',
  purple: '#BA178C',
  purple200: '#ba178c80',
  grey: '#333333',
  grey100: '#999999',
  grey200: '#666666',
  grey300: '#E9ECF3',
  grey400: '#F9FBFF',
  bgDark: '#F5F7FC',
  bgLight: '#FBFCFF',
  shadowColor: '#7574b5',
  borderColor: '#D3E0FC',
  blue500: "#8E9BBB",
  red: "#FF0000",
  pink: "#ED64C6"
};
