import { Box } from "@chakra-ui/react";
import useAuth from "src/app/hooks/useAuth";
import LandingFooterActions from "./HelperComponents/Common/LandingFooterActions";
import "./Landing.styles.scss";
import BrandMain from "./HelperComponents/Brand";
import InfluencerMain from "./HelperComponents/Influencer";
import { Strings } from "@be-tagged/shared";
import useSetPageTitle from "src/app/hooks/useSetPageTitle";

/**
 * Landing Screen is displayed under following conditions:
 * After Onboarding, if the user has not done it yet
 * Directly after Log In, if the user has logged in after completing onboarding
 * @returns {JSX.Element} - A React Functional Component
 */
const Landing = (): JSX.Element => {
  const { isBrand } = useAuth();
  useSetPageTitle(Strings.home);

  return (
    <>
      <Box className="landing_main">
        {isBrand ? <BrandMain /> : <InfluencerMain />}
        <LandingFooterActions isBrand={isBrand} />
      </Box>
    </>
  );
};

export default Landing;
