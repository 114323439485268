import { Colors, Strings } from "@be-tagged/shared";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import { Flex, Image, Text } from "@chakra-ui/react";
import { CommonObjectType } from "src/app/constants";
import {
  TikTokIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
} from "@be-tagged/shared/src/assets/icons";
import { FC } from "react";
import { withCommas } from "src/app/utils";

interface IProductSales {
  insights: CommonObjectType;
}
const socialMedia: CommonObjectType = {
  [FormikKeys.TikTok]: {
    name: Strings.tikTok,
    icon: TikTokIcon,
  },
  [FormikKeys.Facebook]: {
    name: Strings.facebook,
    icon: FacebookIcon,
  },
  [FormikKeys.Instagram]: {
    name: Strings.instagram,
    icon: InstagramIcon,
  },
  [FormikKeys.Twitter]: {
    name: Strings.twitter,
    icon: TwitterIcon,
  },
};

const ProductSales: FC<IProductSales> = ({ insights }): JSX.Element => {
  return (
    <>
      {Object.keys(insights || {})?.map(function (key: string) {
        if (key !== "monthlySalesDistribution") {
          return (
            <Flex
              px={4}
              py={3}
              borderRadius="10px"
              border={`1px solid ${Colors.borderColor}`}
              direction="row"
              fontSize="14px"
              key={key}
              width={250}
              boxShadow="0px 3px 30px rgba(153, 186, 208, 0.3);"
            >
              <Flex alignItems="center">
                <Image w={"24px"} src={socialMedia[key]?.icon} mr={2} />
                <Text as="span" fontWeight={600} w={"90px"}>
                  {socialMedia[key]?.name}
                </Text>
              </Flex>
              <Flex
                gap={"20px"}
                className="col"
                direction="column"
                borderLeftWidth="2px"
                height="min-content"
                pl={3}
              >
                <Text>
                  {Strings.sales} : {withCommas(insights[key]?.sales)}
                </Text>
                <Text>
                  {Strings.clicks} : {withCommas(insights[key]?.clicks)}
                </Text>
              </Flex>
            </Flex>
          );
        }
      })}
    </>
  );
};

export default ProductSales;
