export enum ROLE_TYPES {
  None = "NONE",
  Brand = "BRAND",
  Influencer = "INFLUENCER",
}

export enum LOGIN_ACTIONS {
  None = "NONE",
  Login = "LOGIN",
  Register = "REGISTER",
}