import { getApiBaseURL } from "../config/APIconfig";

export const getVerifyPhoneEmailUrl = (): string => {
  return `${getApiBaseURL()}/verify`;
};

export const getVerifyPhoneUrl = (): string => {
  return `${getApiBaseURL()}/verify-phone`;
};

export const getEmailOTPUrl = (): string => {
  return `${getApiBaseURL()}/send-email-otp`;
};

export const getPhoneOTPUrl = (): string => {
  return `${getApiBaseURL()}/send-phone-otp`;
};

export const getVerifyEmailUrl = (): string => {
  return `${getApiBaseURL()}/verify-email`;
};

export const getSendResetCodeUrl = (): string => {
  return `${getApiBaseURL()}/forgot-password/send-reset-code`;
};

export const getVerifyResetCodeUrl = (): string => {
  return `${getApiBaseURL()}/forgot-password/verify-reset-code`;
};

export const getResetPasswordUrl = (): string => {
  return `${getApiBaseURL()}/forgot-password/reset-password`;
};

export const getCategoriesUrl = (): string => {
  return `${getApiBaseURL()}/lookups/categories`;
};

export const getCountriesUrl = (): string => {
  return `${getApiBaseURL()}/lookups/countries`;
};

export const getCurrenciesUrl = (): string => {
  return `${getApiBaseURL()}/lookups/currency-codes`;
};

export const getCitiesURL = (): string => {
  return `${getApiBaseURL()}/lookups/cities`;
};

export const getBrandCountriesURL = (): string => {
  return `${getApiBaseURL()}/brand-products/countries`;
};

export const getBrandsLookupURL = (): string => {
  return `${getApiBaseURL()}/lookups/brands`;
};

export const getSignedUploadUrl = () => {
  return `${getApiBaseURL()}/signed-url-upload`;
};

export const getRefreshedFileUrl = () => {
  return `${getApiBaseURL()}/refresh-file`;
};

export const getBrandProductsUrl = () => {
  return `${getApiBaseURL()}/brand-products`;
};

export const getCommissionDistributionUrl = () => {
  return `${getApiBaseURL()}/brand-products/calculate-commission-distribution`;
};

export const getInfluencerProductsUrl = () => {
  return `${getApiBaseURL()}/influencer-products`;
};

export const getFilteredBrandProductsURL = () => {
  return `${getApiBaseURL()}/brand-products/filter`;
};

export const getFilteredInfluencerProductsURL = () => {
  return `${getApiBaseURL()}/influencer-products/filter`;
};

export const getProductLeaderboardURL = (brandProductId: number) => {
  return `${getApiBaseURL()}/influencer-products/${brandProductId}/leaderboard`;
};

export const getProductLeaderboardInsightsURL = (brandProductId: number) => {
  return `${getApiBaseURL()}/influencer-products/${brandProductId}/leaderboard/insights`;
};

export const getInfluencerRequestsForApprovalURL = (brandProductId: number) => {
  return `${getApiBaseURL()}/brand-products/${brandProductId}/promotion-requests`;
};

export const getUpdateDiscountCodesURL = (brandProductId: number) => {
  return `${getApiBaseURL()}/brand-products/${brandProductId}/discount-codes`;
};
// products promoted by Influencers
export const getProductsPromotedByURL = () => {
  return `${getApiBaseURL()}/influencer-products/promoted-by-me/filter`;
};

// Sign Up
export const getBrandSignUpURL = (): string => {
  return `${getApiBaseURL()}/sign-up/brand`;
};

export const getInfluencerSignUpURL = (): string => {
  return `${getApiBaseURL()}/sign-up/influencer`;
};

export const getUpdateContactDetailsURL = (): string => {
  return `${getApiBaseURL()}/change-otp-info`;
};

// Sign In
export const getBrandEmailSignInURL = (): string => {
  return `${getApiBaseURL()}/authenticate/brand/email-login`;
};

export const getInfluencerEmailSignInURL = (): string => {
  return `${getApiBaseURL()}/authenticate/influencer/email-login`;
};

export const getBrandPhoneSignInURL = (): string => {
  return `${getApiBaseURL()}/authenticate/brand/phone-login`;
};

export const getInfluencerPhoneSignInURL = (): string => {
  return `${getApiBaseURL()}/authenticate/influencer/phone-login`;
};

export const getBrandOTPVerifySignInURL = (): string => {
  return `${getApiBaseURL()}/authenticate/brand/phone-login/verify`;
};

export const getInfluencerOTPVerifySignInURL = (): string => {
  return `${getApiBaseURL()}/authenticate/influencer/phone-login/verify`;
};

// Onboarding
export const getBrandOnboardingURL = (): string => {
  return `${getApiBaseURL()}/complete-brand-onboarding`;
};

export const getInfluencerOnboardingURL = (): string => {
  return `${getApiBaseURL()}/complete-influencer-onboarding`;
};

// User Info
export const getBrandUserInfoURL = () => {
  return `${getApiBaseURL()}/brand-member-info`;
};

export const getInfluencerInfoURL = () => {
  return `${getApiBaseURL()}/influencer-info`;
};

export const setPromotionRequestURL = () => {
  return `${getApiBaseURL()}/brand-products/{id}/promotions`;
};

// Insights
export const getInfluencerInsightsURL = () => {
  return `${getApiBaseURL()}/influencer-business-insights/summary`;
};
export const getBrandInsightsURL = () => {
  return `${getApiBaseURL()}/brand-business-insights/summary`;
};
export const getInfluencerProductsSoldURL = () => {
  return `${getApiBaseURL()}/influencer-business-insights/products-sold`;
};
export const getBrandProductsSoldURL = () => {
  return `${getApiBaseURL()}/brand-business-insights/products-sold`;
};
export const getInfluencerPlatformInsightsURL = () => {
  return `${getApiBaseURL()}/influencer-business-insights/platform-insights`;
};
export const getCommissionPayoutURL = () => {
  return `${getApiBaseURL()}/influencer-business-insights/commission-payout`;
};
export const getBrandPlatformInsightsURL = () => {
  return `${getApiBaseURL()}/brand-business-insights/platform-insights`;
};
export const getInfluencersOfBrandURL = () => {
  return `${getApiBaseURL()}/brand-business-insights/influencers`;
};
export const getSalesDataUploadURL = (productId: number) => {
  return `${getApiBaseURL()}/brand-products/${productId}/sales-data`;
};
export const getInfluencersOfProductURL = (productId: number) => {
  return `${getApiBaseURL()}/brand-products/${productId}/influencers`;
};
export const getProductInsightsURL = (productId: number) => {
  return `${getApiBaseURL()}/brand-products/${productId}/insights`;
};
export const getUnlistProductURL = (productId: number) => {
  return `${getApiBaseURL()}/brand-products/${productId}/unlist`;
};
// Dashboard
export const getBrandDashboardDetailsURL = () => {
  return `${getApiBaseURL()}/dashboard/brand`;
};
export const getInfluencerDashboardDetailsURL = () => {
  return `${getApiBaseURL()}/dashboard/influencer`;
};
// Profile
export const getInfluencerProfileDetailsURL = () => {
  return `${getApiBaseURL()}/profile/influencer`;
};
export const getBrandProfileDetailsURL = () => {
  return `${getApiBaseURL()}/profile/brand-member`;
};
export const getAddNewEmailURL = () => {
  return `${getApiBaseURL()}/profile/emails`;
};
export const getAddNewPhoneURL = () => {
  return `${getApiBaseURL()}/profile/phones`;
};
export const getChangePasswordURL = () => {
  return `${getApiBaseURL()}/profile/change-password`;
};
export const getUpdateProfilePictureURL = () => {
  return `${getApiBaseURL()}/profile/profile-picture`;
};
export const getUpdateSocialMediaHandlesURL = () => {
  return `${getApiBaseURL()}/profile/social-media`;
};
export const getSetAsPrimaryEmailURL = (id: number) => {
  return `${getApiBaseURL()}/profile/emails/${id}/set-primary`;
};
export const getSetAsPrimaryPhoneURL = (id: number) => {
  return `${getApiBaseURL()}/profile/phones/${id}/set-primary`;
};
// Brand Team
export const getBrandRolesURL = () => {
  return `${getApiBaseURL()}/lookups/roles`;
};
export const getBrandTeamMembersURL = () => {
  return `${getApiBaseURL()}/brand-team/members`;
};
export const getAddTeamMemberURL = () => {
  return `${getApiBaseURL()}/brand-team/members`;
};
export const getDelateTeamMemberURL = (id: number) => {
  return `${getApiBaseURL()}/brand-team/members/${id}`;
};
// Bank Accounts
export const getAndAddBankAccountsURL = () => {
  return `${getApiBaseURL()}/profile/bank-accounts`;
};

export const getDeleteBankAccountURL = (id: number) => {
  return `${getApiBaseURL()}/profile/bank-accounts/${id}`;
};

export const getSetAsPrimaryBankAccountURL = (id: number) => {
  return `${getApiBaseURL()}/profile/bank-accounts/${id}/set-primary`;
};

// Notifications

export const getNotificationsURL = () => {
  return `${getApiBaseURL()}/notifications`;
};

export const readNotificationURL = (notificationId: number) => {
  return `${getApiBaseURL()}/notifications/${notificationId}/read`;
};

export const readAllNotificationsURL = () => {
  return `${getApiBaseURL()}/notifications/read-all`;
};

// Delete Account

export const getInfluencerDeleteAccountURL = () => {
  return `${getApiBaseURL()}/profile/influencer`;
};

export const getBrandMemberDeleteAccountURL = () => {
  return `${getApiBaseURL()}/profile/brand-member`;
};
