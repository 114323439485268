import React from "react";
import ReactDOM from "react-dom";

import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./configureStore";
import theme from "./app/assets/styles/theme";
import { Toasts } from "./app/components";
import RoutingManager from "./app/routes";
import GlobalCSS from "./app/assets/styles/global.css";

import { PersistGate } from "redux-persist/integration/react";
import { TokenLoader } from "./app/components";

// Loading the fonts
import "@be-tagged/shared/src/theme/fonts/fontsForCss.scss";
import { LoggerWeb } from "./app/services/Logging";

const webLogger = new LoggerWeb();
webLogger.init();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <GlobalCSS />
            <Toasts />
            <TokenLoader />
            <RoutingManager />
          </BrowserRouter>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
