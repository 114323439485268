import { brandUserKey, RootState } from "@be-tagged/shared";
import { useSelector } from "react-redux";

interface AuthProperties {
  isLoggedIn: boolean;
  isBrandFullyOnboarded: boolean;
  isOnboarded: boolean;
  isBrand: boolean;
  name: string;
  role: string;
  preferredCategories: any[];
  profilePic: string;
  fullnameForOnBoardedUser: string;
  isOnlyBrandOnboarded: boolean;
  isOnlyUserOnboarded: boolean;
}

/**
 * useAuth hook will be used to get the authentication properties from the redux store
 * @returns {Object} - of the type AuthProperties
 */
const useAuth = (): AuthProperties => {
  const authObject: AuthProperties = useSelector((state: RootState) => {
    const { isLoggedIn, isBrand, profileData = {} } = state?.userReducer;
    const { profilePicUrl = "" } = profileData;
    const { userInfo, additionalInfo = {} } = state?.userReducer?.userData;
    const { categories } =
      (isBrand ? additionalInfo?.[brandUserKey] : additionalInfo) || [];
    const { name, role } = additionalInfo || "";
    const isBrandFullyOnboarded =
      userInfo?.isBrandOnBoarded && userInfo?.isUserOnBoarded;
    const isOnlyBrandOnboarded =
      userInfo?.isBrandOnBoarded && !userInfo?.isUserOnBoarded;
    const isOnlyUserOnboarded =
      !userInfo?.isBrandOnBoarded && userInfo?.isUserOnBoarded;
    const isInfluencerFullyOnboarded = userInfo?.isInfluencerOnBoarded;
    const fullnameForOnBoardedUser = userInfo?.fullName;
    let isOnboarded = false;
    const profilePic = profilePicUrl || additionalInfo?.profilePicUrl;
    if (isBrand && isBrandFullyOnboarded) isOnboarded = true;
    else if (!isBrand && isInfluencerFullyOnboarded) isOnboarded = true;

    return {
      isBrandFullyOnboarded,
      isOnlyBrandOnboarded,
      isOnlyUserOnboarded,
      isLoggedIn,
      fullnameForOnBoardedUser,
      isBrand,
      isOnboarded,
      preferredCategories: categories,
      name,
      role,
      profilePic,
    };
  });

  return authObject;
};

export default useAuth;
