import { combineReducers } from "redux";
import appReducer from "./GlobalReducer/App.reducer";
import brandReducer from "./BrandReducer/Brand.reducer";
import commonReducer from "./CommonReducer/Common.reducer";
import userReducer from "./UserReducer/User.reducer";
import influencerReducer from "./InfluencerReducer/Influencer.reducer";

const rootReducer = combineReducers({
  appReducer,
  brandReducer,
  commonReducer,
  influencerReducer,
  userReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
