import { Skeleton, Stack } from "@chakra-ui/react";
import { FC } from "react";
interface InsightsSkeletonProps {
  commissionPayout?: boolean;
  influencerPlatformInsights?: boolean;
}
const InsightsSkeleton: FC<InsightsSkeletonProps> = ({
  commissionPayout,
  influencerPlatformInsights,
}) => {
  return (
    <Stack>
      {[1, 2, 3, 4, 5].map((el: number, idx: number) => (
        <Skeleton
          key={idx}
          className={
            commissionPayout
              ? "commission_payout_skeleton"
              : influencerPlatformInsights
              ? "platform_insights_skeleton"
              : "insights_skeleton"
          }
        />
      ))}
    </Stack>
  );
};

export default InsightsSkeleton;
