import {
  Box,
  Divider,
  Flex,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Fade,
  useMediaQuery,
  Skeleton,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import {
  clearProductDetails,
  getCommissionDistribution,
  getProductDetails,
  logger,
  LoggerStrings,
  MIN_PERCENT_FOR_COUPON_UPLOAD,
  RootState,
  Strings,
  unlistProduct,
} from "@be-tagged/shared";
import "./ProductDetails.styles.scss";
import { Colors } from "@be-tagged/shared";
import { CustomBtn } from "../../../app/components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";
import TabProductDetails from "./TabProductDetails";
import { DEVICE } from "src/app/assets/styles/styleEnums";
import useAuth from "src/app/hooks/useAuth";
import LowCouponIndicator from "./HelperComponents/LowCouponIndicator";
import Insights from "./HelperComponents/Insights";
import TabInfluencers from "./TabInfluencers";
import TabLeaderboard from "./TabLeaderboard";
import { getFormattedLocalFromUTC } from "src/app/utils/DateUtils";
import { ProductStatus } from "@be-tagged/shared/src/enums";
import { withCommas } from "src/app/utils";
import TooltipWrapper from "src/app/components/TooltipWrapper";
import UnlistConfirmationModal from "./HelperComponents/UnlistConfirmationModal";
import { ROUTING_URLS } from "src/app/constants";
import useSetPageTitle from "src/app/hooks/useSetPageTitle";

/**
 * @returns {JSX.Element}
 */
const ProductDetails: FC<{}> = (): JSX.Element => {
  const { isBrand } = useAuth();
  const { id = "" } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const onTabChange = (index: number) => {
    setTabIndex(index);
  };
  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  const onClickUnlist = () => {
    setShowConfirmationModal(true);
  };
  const [clickedEdit, setClickedEdit] = useState(false);
  const { pathname } = useLocation();
  const onClickEdit = () => {
    navigate(
      pathname.replace(
        ROUTING_URLS.PRODUCT_DETAILS + "/" + parseInt(id),
        ROUTING_URLS.PRODUCT_LISTING
      ) +
        "/" +
        parseInt(id)
    );
    setClickedEdit(true);
  };
  const { loading, productDetails } = useSelector((state: RootState) => {
    return {
      loading: state.appReducer,
      productDetails:
        state.commonReducer?.commonData?.productData?.productDetails || null,
    };
  });

  useSetPageTitle(`${productDetails?.name}`, [productDetails]);

  const tags = ((productDetails?.hashtags || "") as string)
    ?.split(",")
    ?.filter((el) => el);
  const dispatch = useDispatch();

  const [showCouponIndicator, setShowCouponIndicator] =
    useState<boolean>(false);
  const [showCouponExpiredIndicator, setShowCouponExpiredIndicator] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(getProductDetails(parseInt(id), isBrand));
    return () => {
      dispatch(clearProductDetails());
    };
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (isBrand && productDetails) {
      const {
        discountCodesCount: totalCodes,
        exhaustedDiscountCodesCount: usedCodes,
        buyerDiscountPercentage,
      } = productDetails;
      const couponsLeft = totalCodes - usedCodes;
      const percentSoFar = (couponsLeft / (totalCodes || 1)) * 100;
      const isLowOnCodes = percentSoFar <= MIN_PERCENT_FOR_COUPON_UPLOAD;
      if (isLowOnCodes && buyerDiscountPercentage) setShowCouponIndicator(true);
      if (usedCodes === 0 && totalCodes === 0) setShowCouponIndicator(false);
      if (percentSoFar === 0 && buyerDiscountPercentage)
        setShowCouponExpiredIndicator(true);
    }
  }, [productDetails]);
  const getCommissionDist = (comm: any) => {
    dispatch(
      getCommissionDistribution({
        commissionPercentage: Number(comm),
      })
    );
  };

  const getCategories = (catArray: any[] = []) => {
    return catArray.map((c) => c?.name)?.join(", ");
  };

  const [isNotSmallerScreen] = useMediaQuery(DEVICE.tablet);
  const [isSmallerScreen] = useMediaQuery(DEVICE.tabletMin);

  useEffect(() => {
    if (productDetails?.name) {
      let tabName = "";
      switch (tabIndex) {
        case 0:
          tabName = "Product Details";
          break;
        case 1:
          tabName = "Leaderboard";
          if (isBrand) tabName = "Influencers";
          break;
        case 2:
          if (isBrand) tabName = "Insights";
          break;
        default:
          break;
      }

      logger.info(
        productDetails?.name +
          `${Strings.productDetailsTab} - ${
            isBrand ? Strings.brand : Strings.influencer
          } ${LoggerStrings.justOpenedThe} ${tabName} ${LoggerStrings.tab}`,
        {
          productId: productDetails?.brandProductId || id,
        }
      );
    }
  }, [tabIndex, productDetails]);

  useEffect(() => {
    if (clickedEdit) {
      const user = Strings.brand;
      logger.info(`${user} ${LoggerStrings.clickedOnEditButton}`);
    }
  }, [clickedEdit]);

  return (
    <>
      <UnlistConfirmationModal
        isOpen={showConfirmationModal}
        id={parseInt(id)}
        productName={productDetails?.name}
        closeModal={closeConfirmationModal}
        onClose={() => {}}
      />
      <Box className="product_details_container">
        <Box>
          <Box className="details-box">
            <Box>
              <ReactImageFallback
                src={productDetails?.bannerImageUrl}
                fallbackImage={<Skeleton className="hero-image" />}
                className="hero-image"
                alt={"Product"}
              />
            </Box>
            {showCouponIndicator && (
              <Fade in={showCouponIndicator}>
                <LowCouponIndicator
                  close={() => setShowCouponIndicator(false)}
                  codesExhausted={showCouponExpiredIndicator}
                  productStatus={productDetails?.productStatus}
                />
              </Fade>
            )}

            {isSmallerScreen ? (
              <Box className="tags">
                <div></div>
                <TooltipWrapper label={Strings.useHashtags}>
                  <div style={{ display: "inline-flex" }}>
                    {tags?.map((item: any, index: any) => (
                      <div key={index} className="tags-style">
                        #{item}
                      </div>
                    ))}
                  </div>
                </TooltipWrapper>
                <div></div>
              </Box>
            ) : (
              <div></div>
            )}
            <SimpleGrid
              templateColumns={{ md: "5fr 4fr", base: "none" }}
              rowGap={3}
              px={{ md: "40px", base: "0px" }}
              alignItems="baseline"
              justifyItems="start"
              pt={{ base: "30px", md: "0px" }}
              pb={{ base: "30px", md: "20px" }}
              className="product_info"
            >
              <Flex
                direction={{ md: "row", base: "column" }}
                alignItems="baseline"
              >
                <Box
                  fontSize="36px"
                  fontWeight="600"
                  color={Colors.dollarGreen}
                  mt={-1}
                  mr={3}
                  maxWidth={{ md: "220px", base: "max-content" }}
                >
                  {productDetails?.name}
                </Box>
                {!isNotSmallerScreen && (
                  <Divider
                    orientation="vertical"
                    borderRightWidth="3px"
                    borderLeftWidth="0px"
                    height="15px"
                    mr={3}
                  />
                )}
                <Box fontSize="12px" fontWeight="600" color={Colors.grey100}>
                  {productDetails?.listedOnUtc && (
                    <>
                      {Strings.listedOn}{" "}
                      {getFormattedLocalFromUTC(productDetails?.listedOnUtc)}
                    </>
                  )}
                </Box>
              </Flex>
              {!isSmallerScreen && tags?.length > 0 && (
                <Box className="tags">
                  <Box
                    color={Colors.grey100}
                    fontSize={"11px"}
                    fontWeight={"600"}
                  >
                    {Strings.useHashtags}
                  </Box>
                  <div>
                    {tags?.map((item: any, index: any) => (
                      <div key={index} className="tags-style">
                        #{item}
                      </div>
                    ))}
                  </div>
                </Box>
              )}
              <Flex
                direction={{ md: "row", base: "column" }}
                justifyItems="center"
                columnGap={3}
                flexWrap="wrap"
              >
                <Box fontWeight="600">
                  {Strings.price}:&nbsp;{productDetails?.currencySymbol}
                  {withCommas(productDetails?.price)}
                </Box>
                {!isNotSmallerScreen && (
                  <Divider
                    orientation="vertical"
                    borderRightWidth="3px"
                    borderLeftWidth="0px"
                    height="15px"
                    m="auto"
                  />
                )}
                <Box fontWeight="600">
                  {Strings.commission}:&nbsp;
                  {productDetails?.commissionPercentage}
                  {" %"}
                </Box>
                {!isNotSmallerScreen && (
                  <Divider
                    orientation="vertical"
                    borderRightWidth="3px"
                    borderLeftWidth="0px"
                    height="15px"
                    m="auto"
                  />
                )}
                <Box fontWeight="600">
                  {Strings.buyerDiscount}
                  {" : "}
                  {productDetails?.buyerDiscountPercentage}
                  {" %"}
                </Box>
              </Flex>{" "}
            </SimpleGrid>

            <Divider
              orientation="horizontal"
              borderBottomWidth="2px"
              width={{ md: "78vw", base: "90vw" }}
              margin="auto"
              className="divider"
            />
            <Box className="product-category">
              <div></div>
              <div
                style={
                  isNotSmallerScreen
                    ? {
                        display: "inline-flex",
                        rowGap: "10px",
                        flexDirection: "column",
                      }
                    : {
                        display: "inline-flex",
                        columnGap: "10px",
                        flexDirection: "row",
                      }
                }
              >
                {!isBrand && (
                  <div>
                    {Strings.brand}
                    {" : "}
                    {productDetails?.brandName}
                  </div>
                )}
                {!isBrand && !isNotSmallerScreen && (
                  <Divider
                    orientation="vertical"
                    borderRightWidth="2px"
                    borderLeftWidth="0px"
                    height="20px"
                    margin="auto"
                  />
                )}
                <div>
                  {Strings.category}
                  {" : "}
                  {getCategories(productDetails?.categories)}
                </div>
                {!isNotSmallerScreen && (
                  <Divider
                    orientation="vertical"
                    borderRightWidth="2px"
                    borderLeftWidth="0px"
                    height="20px"
                    margin="auto"
                  />
                )}
                <div>
                  {Strings.offerType}
                  {" : "}
                  {productDetails?.isApprovalBasedOffer
                    ? Strings.approvalBased
                    : Strings.open}
                </div>
                {!isNotSmallerScreen && (
                  <Divider
                    orientation="vertical"
                    borderRightWidth="2px"
                    borderLeftWidth="0px"
                    height="20px"
                    margin="auto"
                  />
                )}
                <div>
                  {Strings.available}
                  {" : "}
                  {productDetails?.country?.name}
                </div>
              </div>
              <div>
                {isBrand && (
                  <Box mr={{ md: "40px" }} mt={{ sm: "20px", md: 0 }}>
                    <Flex gap={"10px"} mt={{ md: "0px", base: "20px" }}>
                      {[ProductStatus.Listed, ProductStatus.Rejected].includes(
                        productDetails?.productStatus
                      ) && (
                        <Flex columnGap={5} ml={{ md: "5", base: "0" }}>
                          <CustomBtn
                            onClick={onClickEdit}
                            className="purple_btn inv"
                          >
                            {Strings.edit}
                          </CustomBtn>
                          <CustomBtn
                            onClick={onClickUnlist}
                            className="purple_btn"
                          >
                            {Strings.unList}
                          </CustomBtn>
                        </Flex>
                      )}
                    </Flex>
                  </Box>
                )}
              </div>
            </Box>
            <Box
              paddingX={{ md: "40px", base: "inherit" }}
              width={{ md: "53%", base: "100%" }}
              marginTop={5}
              marginX={!isBrand ? "auto" : "0px"}
            >
              {productDetails?.productStatus === ProductStatus.Listed &&
                showCouponExpiredIndicator && (
                  <Box
                    fontSize={"14px"}
                    background={"#FFE1DD"}
                    paddingY={"7px"}
                    paddingX={"15px"}
                    borderRadius={"10px"}
                  >
                    {isBrand && Strings.couponCodesExhaustedMessage}
                  </Box>
                )}
              {isBrand &&
                productDetails?.productStatus === ProductStatus.Unlisted && (
                  <Box
                    fontSize={"14px"}
                    background={"#FFE1DD"}
                    paddingY={"7px"}
                    paddingX={"15px"}
                    borderRadius={"10px"}
                  >
                    {Strings.youHaveUnlistedThisProduct}
                  </Box>
                )}
              {productDetails?.productStatus === ProductStatus.Listed &&
                productDetails?.hasAllCodesExhausted && (
                  <Box
                    fontSize={"14px"}
                    background={"#FFE1DD"}
                    paddingY={"7px"}
                    paddingX={"15px"}
                    borderRadius={"10px"}
                  >
                    {!isBrand && Strings.productTemporaryUnavailable}
                  </Box>
                )}
              {!isBrand &&
                productDetails?.productStatus === ProductStatus.Unlisted && (
                  <Box
                    fontSize={"14px"}
                    background={"#FFE1DD"}
                    paddingY={"7px"}
                    paddingX={"15px"}
                    borderRadius={"10px"}
                  >
                    {`${
                      Strings.sellerHasWithdrawnOffer
                    } ${getFormattedLocalFromUTC(
                      productDetails?.unlistedAtUtc
                    )} ${Strings.sellTillDate} ${getFormattedLocalFromUTC(
                      productDetails?.salesAllowedTillUtc
                    )}`}
                  </Box>
                )}
            </Box>
            {isBrand && (
              <Box className="total-sales">
                <div>
                  <div className="gray-text" style={{ marginBottom: "1.2%" }}>
                    {Strings.totalSales}
                  </div>
                  <div>{withCommas(productDetails?.totalSales)}</div>
                </div>

                <div>
                  <div className="gray-text" style={{ marginBottom: "1.2%" }}>
                    {Strings.totalSalesAmount}
                  </div>
                  <div>
                    {productDetails?.currencySymbol}&nbsp;
                    {withCommas(productDetails?.totalSalesAmount)}
                  </div>
                </div>

                <div>
                  <div className="gray-text" style={{ marginBottom: "1.2%" }}>
                    {Strings.totalInfluencers}
                  </div>
                  <div>{withCommas(productDetails?.totalInfluencers)}</div>
                </div>

                <div>
                  <div className="gray-text" style={{ marginBottom: "1.2%" }}>
                    {Strings.commissionPayable}
                  </div>

                  <div>
                    {productDetails?.currencySymbol}&nbsp;
                    {withCommas(productDetails?.payableCommission)}
                  </div>
                </div>
              </Box>
            )}
            <Box className="tabs">
              <Tabs
                colorScheme={"purple"}
                onChange={(index: number) => onTabChange(index)}
              >
                <TabList className="tab-list" pb="10px">
                  <div className={tabIndex === 0 ? "selected_tab" : ""}>
                    <Tab
                      fontSize="14px"
                      pb="0px !important"
                      _selected={{
                        borderBottomWidth: "0px",
                        boxShadow: "none",
                        fontWeight: "600",
                      }}
                    >
                      {Strings.productDetailsTab}
                    </Tab>
                  </div>
                  {isBrand ? (
                    <div className={tabIndex === 1 ? "selected_tab" : ""}>
                      <Tab
                        fontSize="14px"
                        pb="0px !important"
                        _selected={{
                          borderBottomWidth: "0px",
                          boxShadow: "none",
                          fontWeight: "600",
                        }}
                      >
                        {Strings.influencersTab}
                      </Tab>
                    </div>
                  ) : (
                    <div className={tabIndex === 1 ? "selected_tab" : ""}>
                      <Tab
                        fontSize="14px"
                        pb="0px !important"
                        _selected={{
                          borderBottomWidth: "0px",
                          boxShadow: "none",
                          fontWeight: "600",
                        }}
                      >
                        {Strings.leaderboardTab}
                      </Tab>
                    </div>
                  )}
                  {isBrand && (
                    <div className={tabIndex === 2 ? "selected_tab" : ""}>
                      <Tab
                        fontSize="14px"
                        pb="0px !important"
                        _selected={{
                          borderBottomWidth: "0px",
                          boxShadow: "none",
                          fontWeight: "600",
                        }}
                      >
                        {Strings.insightsTab}
                      </Tab>
                    </div>
                  )}
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <TabProductDetails
                      isBrand={isBrand}
                      productDetails={productDetails}
                    />
                  </TabPanel>
                  <TabPanel pl={0} pr={0}>
                    {isBrand ? (
                      <TabInfluencers brandProductId={parseInt(id)} />
                    ) : (
                      <TabLeaderboard brandProductId={parseInt(id)} />
                    )}
                  </TabPanel>
                  <TabPanel px={0}>{isBrand && <Insights />}</TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProductDetails;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
