import {
  getBrandUserInfoURL,
  getInfluencerInfoURL,
} from "../../../utils/UrlBuilder";
import { UPDATE_USER_DATA } from "../../constants/ReduxConstants";
import { HTTPMethod } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import { RootState } from "../../reducers";

export const getUserInfo =
  () => async (dispatch: any, getState: () => RootState) => {
    const { isBrand } = getState().userReducer;
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: {},
      url: isBrand ? getBrandUserInfoURL() : getInfluencerInfoURL(),
    };

    await fetchAsync(dispatch, [request], [updateUserInfo]);
  };

const updateUserInfo = (payload: any) => {
  return {
    type: UPDATE_USER_DATA,
    payload: payload.data,
  };
};
