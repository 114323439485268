import { logger } from "@be-tagged/shared";
import { Environments } from "@be-tagged/shared/src/enums";
import ConsoleLogTransport from "@be-tagged/shared/src/services/Logging/ConsoleLogTransport";
import { ILogTransport } from "@be-tagged/shared/src/services/Logging/LogTransportInterface";
import { MixpanelLogTransportWeb } from "./MixpanelLogTransportWeb";
import { SegmentLogTransportWeb } from "./SegmentLogTransportWeb";
import { SentryLogTransportWeb } from "./SentryLogTransportWeb";

export class LoggerWeb {
  init() {
    let logProviders: ILogTransport[] = [];

    switch (process.env.REACT_APP_ENV_NAME as Environments) {
      case Environments.Production:
        logProviders = [
          new SegmentLogTransportWeb(),
          new SentryLogTransportWeb(),
          new MixpanelLogTransportWeb(),
        ];
        break;
      case Environments.Staging:
        logProviders = [
          new SegmentLogTransportWeb(),
          new SentryLogTransportWeb(),
          new MixpanelLogTransportWeb(),
        ];
        break;
      case Environments.Local:
        logProviders = [
          new ConsoleLogTransport(),
          new MixpanelLogTransportWeb(),
        ];
        break;
      case Environments.QA:
        logProviders = [
          new ConsoleLogTransport(),
          new SentryLogTransportWeb(),
          new MixpanelLogTransportWeb(),
        ];
        break;
      default:
        // Case for development
        logProviders = [
          new ConsoleLogTransport(),
          new SegmentLogTransportWeb(),
          new MixpanelLogTransportWeb(),
        ];
        break;
    }

    logger.init(logProviders);
  }
}
