import { Colors, RootState, Strings } from "@be-tagged/shared";
import { Flex, Text, Box, VStack } from "@chakra-ui/react";

import { ProductHunt } from "@be-tagged/shared/src/assets/icons";
import { useSelector } from "react-redux";
import { ROUTING_URLS } from "src/app/constants";
import { useLocation, useNavigate } from "react-router-dom";
import ProductsList from "../Influencer/ProductsList";
import { scrollToTop } from "src/app/utils/Misc";

const { LANDING, PRODUCTS_CATALOG } = ROUTING_URLS;

const ProductsMatchingInterest = () => {

  const { pathname } = useLocation();
  const navigate = useNavigate();


  const navigateToProductsCatalog = () => {
    scrollToTop()
    navigate(pathname.replace(LANDING, PRODUCTS_CATALOG));
  };
  const { productCatalog } =
    useSelector((state: RootState) => {
      return state.commonReducer?.commonData?.productData;
    }) || [];

  return (
    <Flex
      className="influencer_products_container"
      bg={{ base: Colors.bgLight, md: "none" }}
    >
      <img id="helper_img" src={ProductHunt} alt="Influencer" />
      <Box w={"100%"}>
        <Text mb={"16px"} className="bold">
          {Strings.productsOfYourInterest}
        </Text>
        <VStack spacing={4} align="stretch">
          {(productCatalog?.data || []).map((el: any) => {
            return (
              <ProductsList
                key={el.brandProductId}
                images={el.showCaseMediaUrls?.[0]}
                {...el}
              />
            );
          })}
        </VStack>
        <Flex className="actions_btn_container" color={Colors.purple}>
          <button className="bold" onClick={navigateToProductsCatalog}>
            {Strings.viewAllProducts}
          </button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ProductsMatchingInterest;
