import { createReducer } from "../reducerUtil";
import {
  UPDATE_VERIFY_PHONE_STATUS,
  UPDATE_VERIFY_EMAIL_STATUS,
  UPDATE_ONBOARDING_VALUES,
  UPDATE_IS_USER_LOGGED_IN,
  SET_USER_INFO,
  SET_USER_AS_BRAND,
  SET_ACCESS_TOKEN,
  UPDATE_USER_DATA,
  UPDATE_USER_INFO,
  RESET_USER_REDUCER,
  SET_PROFILE_DATA,
  UPDATE_EMAILS_IN_PROFILE_DATA,
  UPDATE_PHONES_IN_PROFILE_DATA,
  UPDATE_BANK_ACCOUNTS,
  UPDATE_PROFILE_PICTURE,
} from "../../constants/ReduxConstants";
import {
  clearAccessToken,
  setAccessToken,
} from "../../../config/AccessTokenHelper";

interface UserReducerType {
  userData: any;
  isLoggedIn: boolean;
  isBrand: boolean;
  profileData: any;
}

const initialState = {
  userData: {},
  isLoggedIn: false,
  isBrand: false,
  profileData: {},
};

export const setUserInfo = (
  state: UserReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    userData: payload,
  };
};

export const updateUserInfo = (
  state: UserReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    userData: {
      ...state.userData,
      userInfo: { ...state.userData.userInfo, ...payload },
    },
  };
};

export const updateVerifyPhoneStatus = (
  state: UserReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    userData: {
      ...state.userData,
      userInfo: {
        ...state.userData.userInfo,
        isPhoneVerified: payload,
      },
    },
  };
};

export const updateVerifyEmailStatus = (
  state: UserReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    userData: {
      ...state.userData,
      userInfo: {
        ...state.userData.userInfo,
        isEmailVerified: payload,
      },
    },
  };
};

export const updateOnboardingValues = (
  state: UserReducerType,
  payload: object
): typeof state => {
  return {
    ...state,
    userData: {
      ...state.userData,
      userInfo: {
        ...state.userData.userInfo,
        ...payload,
      },
    },
  };
};

export const updateIsUserLoggedIn = (
  state: UserReducerType,
  payload: boolean
): typeof state => {
  return {
    ...state,
    isLoggedIn: payload,
  };
};

// Access Token //
export const updateAccessToken = (
  state: UserReducerType,
  payload: any
): typeof state => {
  if (payload) {
    setAccessToken(payload);
    return {
      ...state,
      userData: {
        ...state.userData,
        accessToken: payload,
      },
    };
  } else {
    clearAccessToken();
    return {
      ...state,
      userData: {},
      isLoggedIn: false,
    };
  }
};

export const updateAdditionalData = (
  state: UserReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    userData: {
      ...state.userData,
      additionalInfo: payload,
    },
  };
};

export const setUserAsBrand = (
  state: UserReducerType,
  payload: boolean
): typeof state => {
  return {
    ...state,
    isBrand: payload,
  };
};

export const setProfileData = (
  state: UserReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    // using spread since otherwise the "bank details" data might get overwritten
    profileData: { ...state.profileData, ...payload },
  };
};

export const updateEmailsInProfileData = (
  state: UserReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    profileData: {
      ...state.profileData,
      emailAddresses: [...state.profileData.emailAddresses, payload.data],
    },
  };
};

export const updatePhonesInProfileData = (
  state: UserReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    profileData: {
      ...state.profileData,
      phones: [...state.profileData.phones, payload.data],
    },
  };
};

export const updateBankAccounts = (
  state: UserReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    profileData: {
      ...state.profileData,
      bankAccounts: payload.data,
    },
  };
};

export const updateProfilePicture = (
  state: UserReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    profileData: {
      ...state.profileData,
      profilePicUrl: payload,
    },
    userData: {
      ...state.userData,
      additionalInfo: {
        ...state.userData.additionalInfo,
        profilePicUrl: payload
      }
    }
  };
};

export const resetUserReducer = (): UserReducerType => {
  return initialState;
};

export default createReducer(initialState, {
  [UPDATE_VERIFY_PHONE_STATUS]: updateVerifyPhoneStatus,
  [UPDATE_VERIFY_EMAIL_STATUS]: updateVerifyEmailStatus,
  [UPDATE_ONBOARDING_VALUES]: updateOnboardingValues,
  [UPDATE_IS_USER_LOGGED_IN]: updateIsUserLoggedIn,
  [UPDATE_USER_DATA]: updateAdditionalData,
  [SET_USER_AS_BRAND]: setUserAsBrand,
  [SET_USER_INFO]: setUserInfo,
  [UPDATE_USER_INFO]: updateUserInfo,
  [SET_ACCESS_TOKEN]: updateAccessToken,
  [RESET_USER_REDUCER]: resetUserReducer,
  [SET_PROFILE_DATA]: setProfileData,
  [UPDATE_EMAILS_IN_PROFILE_DATA]: updateEmailsInProfileData,
  [UPDATE_PHONES_IN_PROFILE_DATA]: updatePhonesInProfileData,
  [UPDATE_BANK_ACCOUNTS]: updateBankAccounts,
  [UPDATE_PROFILE_PICTURE]: updateProfilePicture,
  [UPDATE_BANK_ACCOUNTS]: updateBankAccounts,
});
