import { FC, useEffect } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Flex, Img, Center } from "@chakra-ui/react";
import "./whatIsThisModal.scss";
import { QuestionMarkIcon } from "@be-tagged/shared/src/assets/icons";
import { CustomBtn } from "src/app/components";
import clevertap from "clevertap-web-sdk";
import { logger, LoggerStrings, Strings } from "@be-tagged/shared";
import useAuth from "src/app/hooks/useAuth";

interface WhatIsThisModalProp {
  isOpen: boolean;
  noImage?: boolean;
  onClose: () => void;
  bodyText: string;
  modalHeader: string;
}

const WhatIsThisModal: FC<WhatIsThisModalProp> = ({ isOpen, onClose, modalHeader, bodyText, noImage = false }): JSX.Element => {
  const { isBrand } = useAuth();
  useEffect(() => {
    if (isOpen) {
      const user = isBrand ? Strings.brand : Strings.influencer;
      clevertap.event.push("Product Viewed");
      logger.info(`${user} ${LoggerStrings.openedWhatIsThisModal} ${modalHeader}`);
    }
  }, [isOpen]);
  return (
    <Modal size={!noImage ? "3xl" : "2xl"} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={true}>
      <ModalOverlay />
      <ModalContent>
        {!noImage && (
          <Center>
            <Flex className="question_mark_img_container">
              <Img id="question_mark" src={QuestionMarkIcon} />
            </Flex>
          </Center>
        )}
        <ModalHeader>
          {!noImage && Strings.whatIsThisPreText}
          {modalHeader}
        </ModalHeader>
        <ModalBody>
          {!noImage ? (
            modalHeader === Strings.sellingProductPlatformText ? (
              <div style={{ padding: "5px" }}>
                <p>{Strings.sellingProductPlatformInfoText1}</p>
                <br />
                <p>{Strings.sellingProductPlatformInfoText2}</p>
                <br />
                <p>{Strings.sellingProductPlatformInfoText3}</p>
              </div>
            ) : (
              bodyText
            )
          ) : (
            <div style={{ padding: "5px" }}>
              <ul>
                <li>{Strings.step1}</li>
                <li>{Strings.step2}</li>
                <li>{Strings.step3}</li>
                <li>{Strings.step4}</li>
                <li>{Strings.step5}</li>
                <li>{Strings.step6}</li>
              </ul>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <CustomBtn type="button" className="purple_btn medium inv" onClick={onClose}>
            {Strings.ok}
          </CustomBtn>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WhatIsThisModal;
