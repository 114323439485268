import { Colors, Strings } from "@be-tagged/shared";
import { Box, Text } from "@chakra-ui/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import dayjs from "dayjs";
import { FC } from "react";
import { Bar } from "react-chartjs-2";
import { CommonObjectType } from "src/app/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

interface IMonthlySales {
  monthlySalesGraph: CommonObjectType;
}

const MonthlySales: FC<IMonthlySales> = ({
  monthlySalesGraph,
}): JSX.Element => {
  const labels = (monthlySalesGraph || [])?.map((item: any) => {
    var check = dayjs(item.month);
    return check.format("MMM");
  });
  const data = {
    labels,
    datasets: [
      {
        label: "Sales Data",
        data: (monthlySalesGraph || [])?.map((item: any) => item?.sales),
        backgroundColor: Colors.pink,
        borderRadius: 20,
        barPercentage: 0.2,
      },
    ],
  };
  return (
    <Box mt={"30px"}>
      <Box>
        <Text fontSize={"14px"} fontWeight={600}>
          {Strings.monthlySales}
        </Text>
        <Box
          w={{
            sm: "100%",
            md: "50%",
          }}
        >
          <Bar options={options} data={data} />
        </Box>
      </Box>
    </Box>
  );
};

export default MonthlySales;
