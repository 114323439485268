import { Center, Spinner } from "@chakra-ui/react";
import { FC } from "react";

interface SpinnerProps {
  isLoading?: boolean;
  size?: string;
}

const LoadingSpinner: FC<SpinnerProps> = ({
  isLoading = true,
  size = "lg",
}): JSX.Element | null => {
  if (!isLoading) return null;
  return (
    <Center>
      <Spinner size={size} />
    </Center>
  );
};

export default LoadingSpinner;
