import { Text, Flex, Box } from "@chakra-ui/react";
import { Colors, Strings } from "@be-tagged/shared";
import useAuth from "src/app/hooks/useAuth";

const WelcomeText = () => {
  const { name } = useAuth();
  return (
    <Flex
      className="primary"
      wrap={"wrap"}
      lineHeight="1.2"
      direction={{
        base: "column",
        tablet: "row",
      }}
    >
      <Box mr={"10px"}>{Strings.welcome}</Box>
      <Box color={Colors.dollarGreen}>{name}</Box>
    </Flex>
  );
};
export default WelcomeText;
