import { FC, useRef, useState } from "react";
import { Box, Spinner } from "@chakra-ui/react";
import { Formik } from "formik";

import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import clevertap from "clevertap-web-sdk";

import {
  Colors,
  RootState,
  Strings,
  clearErrors,
  userSignUp,
  PasswordRegex,
  EmailRegex,
} from "@be-tagged/shared";
import { FormikKeys } from "@be-tagged/shared/src/enums";

import {
  ErrorCleaner,
  MaterialStyleInput,
  CustomBtn,
  ErrorMsg,
} from "../../../app/components";

import { CountryCodeObject, FormProps } from "../../interfaces";
import MobileInput from "../../common/MobileInput";
import { YUP_VALIDATION } from "../../../app/strings";
import { defaultCountryCode, ROUTING_URLS } from "../../../app/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { getURLParams } from "src/app/utils/Misc";
import useClearInputAutofill from "src/app/hooks/useClearInputAutofill";
import useSetPageTitle from "src/app/hooks/useSetPageTitle";

//Fetching Formik Keys
const { Password, Email, Phone, CountryCode } = FormikKeys;

interface SubmittedDetailsInt {
  [Phone]?: string;
  [CountryCode]?: CountryCodeObject;
  [Password]: string;
  [Email]: string;
}

// Strings
const { workEmail, emailId, password } = Strings;

// values from URL
const invitationKeyFromURL = getURLParams("invitation-key");
const emailFromURL = getURLParams("email");

// Functions for Formik
const getInitialValues = () => {
  return {
    [Email]: emailFromURL ?? "",
    [Password]: "",
    [Phone]: "",
    [CountryCode]: defaultCountryCode,
  };
};
const getValidationSchema = () => {
  return {
    [Email]: yup
      .string()
      .matches(EmailRegex, Strings.enterValidEmail)
      .required(),
    [Password]: yup
      .string()
      .required(Strings.enterPassword)
      .matches(PasswordRegex, Strings.passwordMatch),
    [CountryCode]: yup.object().required(YUP_VALIDATION.COUNTRY_CODE_PRESENCE),
    [Phone]: yup
      .string()
      .min(7, YUP_VALIDATION.MOBILE_NUMBER_LENGTH)
      .max(15, YUP_VALIDATION.MOBILE_NUMBER_LENGTH)
      .required(YUP_VALIDATION.MOBILE_NUMBER_PRESENCE),
  };
};

/**
 * SignUp is the form that contains inputs for Registering
 * A user can sign up after providing necessary details, on success of which he may be taken to the onboarding screen.
 * @param {Object} - Props for the component
 * bodyText - the text context to display inside the form
 * setLoginMode - method which will allow it to switch to Sign Up screen from inside.
 * @returns {JSX.Element} - React Component
 */
const SignUp: FC<FormProps> = ({ txtBody }): JSX.Element => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.appReducer);
  const { SIGN_IN, SIGN_UP } = ROUTING_URLS;
  const [showPass, setShowPass] = useState<boolean>(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // To fix the chrome autofill issue
  const emailInputRef = useRef();
  useClearInputAutofill([emailInputRef]);

  const isBrand = pathname.includes(ROUTING_URLS.BRAND);

  useSetPageTitle(`${isBrand ? "Brand" : "Influencer"} Sign Up`);

  const callBackidentitySet = (payload: any = {}) => {
    const { brandMemberInfo, influencerInfo } = payload;
    if (isBrand) {
      clevertap.onUserLogin.push({
        Site: {
          Identity: brandMemberInfo?.userKey,
        },
      });
    } else {
      clevertap.onUserLogin.push({
        Site: {
          Identity: influencerInfo?.userKey,
        },
      });
    }
  };

  const onSubmit = async (details: SubmittedDetailsInt) => {
    const phone = details?.[Phone] || "";
    const countryCode = details?.[CountryCode];

    dispatch(
      userSignUp(
        {
          email: details?.[Email],
          countryCode: countryCode?.isdCode,
          phone,
          password: details?.[Password],
          invitationKey: invitationKeyFromURL ?? undefined,
        },
        isBrand,
        callBackidentitySet
      )
    );
  };

  const switchLoginMode = () => {
    dispatch(clearErrors());
    navigate(pathname.replace(SIGN_UP, SIGN_IN));
  };

  return (
    <>
      <ErrorCleaner />
      <Formik
        initialValues={getInitialValues()}
        onSubmit={onSubmit}
        validationSchema={yup.object().shape(getValidationSchema())}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          setFieldValue,
          touched,
          isValid,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Box pb={{ base: 0, lg: "20px" }}>
                <Box pos={"relative"} pb={"12px"}>
                  <MaterialStyleInput
                    parentProps={{ style: { width: 300 } }}
                    value={values.email}
                    name={Email}
                    ref={emailInputRef}
                    onChange={handleChange(Email)}
                    onBlur={() => setFieldTouched(Email)}
                    type={Email}
                    label={isBrand ? workEmail : emailId}
                  />
                  {touched.email && errors.email && (
                    <ErrorMsg bottom={-2}>{errors.email}</ErrorMsg>
                  )}
                </Box>

                <Box pos={"relative"} pb={"12px"}>
                  <MobileInput
                    countryCode={values.countryCode}
                    handleCountryCodeChange={(countryCode: any) =>
                      setFieldValue(CountryCode, countryCode)
                    }
                    phone={values[Phone]}
                    handlePhoneChange={handleChange(Phone)}
                    setFieldTouched={setFieldTouched}
                  />
                  {touched[Phone] && errors[Phone] && (
                    <ErrorMsg bottom={-2}>{errors[Phone]}</ErrorMsg>
                  )}
                </Box>

                <Box pos={"relative"} pb={"12px"}>
                  <MaterialStyleInput
                    parentProps={{ style: { width: 300 } }}
                    value={values.password}
                    name={Password}
                    onChange={handleChange(Password)}
                    onBlur={() => setFieldTouched(Password)}
                    type={showPass ? "text" : "password"}
                    label={password}
                  />
                  {touched.password && errors.password && (
                    <Box>
                      <ErrorMsg style={{ position: "static" }}>
                        {errors.password}
                      </ErrorMsg>
                    </Box>
                  )}
                  {values[Password] !== "" && (
                    <button
                      type={"button"}
                      onClick={() => {
                        setShowPass(!showPass);
                      }}
                      style={{
                        color: Colors.purple,
                        fontSize: "11px",
                        fontWeight: "600",
                      }}
                    >
                      {showPass ? Strings.hidePass : Strings.showPass}
                    </button>
                  )}
                </Box>
              </Box>

              <Box className="actions_btn_container">
                <CustomBtn
                  id="action_btn"
                  className="purple_btn"
                  disabled={!isValid || loading}
                  type="submit"
                  style={{
                    fontSize: 14,
                    marginBottom: 30,
                  }}
                >
                  {loading && (
                    <Spinner size={"xs"} style={{ marginRight: 10 }} />
                  )}
                  {txtBody.btnTxtAction}
                </CustomBtn>
              </Box>
            </form>

            <Box
              onClick={() => {
                switchLoginMode();
                setFieldValue(Password, "");
                setFieldTouched(Password, false);
              }}
              className="member_already"
            >
              {txtBody.otherAction.notifier}{" "}
              <span
                role={"button"}
                style={{
                  color: Colors.purple,
                }}
                className="bold"
              >
                &nbsp;{txtBody.otherAction.btnTxtAction}
              </span>
            </Box>
          </>
        )}
      </Formik>
    </>
  );
};

export default SignUp;
