import { createReducer } from "../reducerUtil";
import {
  UPDATE_INFLUENCER_SEEKING_APPROVAL_DATA,
  UPDATE_INFLUENCER_REQUESTS_FOR_APPROVAL,
  EMPTY_APPROVAL_DATA,
  UPDATE_INFLUENCERS_OF_PRODUCT,
  SET_BRAND_COUNTRIES,
  UPDATE_PRODUCT_INSIGHTS,
  UPDATE_INFLUENCERS_OF_BRAND,
  RESET_BRAND_REDUCER,
  UPDATE_BRAND_COUNTRIES_LOADING_STATUS,
  UPDATE_TEAM_MEMBER_LIST,
  UPDATE_LOADING_TEAM_MEMBERS,
  UPDATE_BRAND_ROLES_LOADING_STATUS,
  SET_BRAND_ROLES,
  ADD_TEAM_MEMBER,
  CLEAR_TEAM_MEMBER_LIST,
  CLEAR_ALL_INFLUENCER_REQUESTS,
  UPDATE_INFLUENCER_REQUESTS_STATUS,
  COMMISSION_PERCENTAGE_DISTRIBUTION,
  EMPTY_COMMISSION_PERCENTAGE_DISTRIBUTION
} from "../../constants/ReduxConstants";
import { appendToPrevious } from "../../../utils/StoreUtils";

interface BrandReducerType {
  productData: any;
  countries: any;
  roles: any;
  isCountriesLoading: boolean;
  isRolesLoading: boolean;
  insights: { [k: string]: any };
  isTeamMembersLoading: boolean;
  teamMembers: any;
  commissionPercentageDistribution: any;
}

const initialState = {
  productData: {},
  countries: [],
  roles: [],
  isCountriesLoading: false,
  isRolesLoading: false,
  isTeamMembersLoading: false,
  insights: {},
  teamMembers: [],
  commissionPercentageDistribution: {},
};

export const updateInfluencerSeekingApprovalData = (
  state: BrandReducerType,
  payload: any
): typeof state => {
  if (payload.isMobile) {
    const currentData = state.productData.approvalData || [];
    return {
      ...state,
      productData: {
        ...state.productData,
        approvalData: [...currentData, ...payload.response.data],
      },
    };
  }
  return {
    ...state,
    productData: {
      ...state.productData,
      approvalData: payload.response,
    },
  };
};

export const emptyApprovalData = (
  state: BrandReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    productData: {
      ...state.productData,
      approvalData: payload,
    },
  };
};
export const updateInfluencerRequestsForApproval = (
  state: BrandReducerType,
  payload: any
): typeof state => {
  let requestDataForId = {};
  if (payload?.data) {
    requestDataForId = appendToPrevious(
      state?.productData?.requestsForApproval?.[payload.id]?.data,
      payload
    );
  }

  return {
    ...state,
    productData: {
      ...state.productData,
      requestsForApproval: {
        ...(state?.productData?.requestsForApproval || {}),
        [payload.id]: requestDataForId,
      },
    },
  };
};

const clearInfluencerRequests = (
  state: BrandReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    productData: {
      ...state.productData,
      requestsForApproval: {},
    },
  };
};

export const updateInfluencerRequestsStatus = (
  state: BrandReducerType,
  payload: { requestData: any; responseData: any }
): typeof state => {
  let requestsForApproval = {
    ...(state.productData.requestsForApproval || {}),
  };
  const { requestData, responseData } = payload;
  // Get the influencer requests array against a product ID
  const influencersArr = (requestsForApproval[requestData?.brandProductId]
    ?.data || []) as any[];
  // Find the influencer in which the there are promotion requests, and the ones updated (i.e., the ones in the response) are to be replaced
  let idx = influencersArr.findIndex(
    (el: any) => el.influencerId === requestData?.influencerId
  );
  const influencerReqData = influencersArr[idx];

  if (idx > -1) {
    responseData?.data?.requests.forEach((request: any) => {
      const indexOfReq = influencerReqData.requests.findIndex(
        (el: any) => el.promotionRequestId === request.promotionRequestId
      );
      if (indexOfReq > -1) {
        influencerReqData.requests[indexOfReq] = request;
      }
    });
  }
  requestsForApproval = {
    ...requestsForApproval,
    [requestData?.brandProductId]: {
      ...requestsForApproval?.[requestData?.brandProductId],
      data: influencersArr,
    },
  };

  return {
    ...state,
    productData: {
      ...state.productData,
      requestsForApproval,
    },
  };
};

export const updateInfluencersOfProduct = (
  state: BrandReducerType,
  payload?: any
): typeof state => {
  let influencerData = {};
  if (payload?.data) {
    influencerData = appendToPrevious(
      state?.productData?.influencersOfProduct?.data,
      payload
    );
  }
  return {
    ...state,
    productData: {
      ...state.productData,
      influencersOfProduct: influencerData,
    },
  };
};

export const updateBrandCountries = (
  state: BrandReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    countries: payload.data,
  };
};

export const setBrandCountriesLoading = (
  state: BrandReducerType,
  payload: boolean
): typeof state => {
  return {
    ...state,
    isCountriesLoading: payload,
  };
};

export const setProductInsights = (
  state: BrandReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    productData: {
      ...state.productData,
      insights: payload.data,
    },
  };
};

export const updateInfluencersOfBrand = (
  state: BrandReducerType,
  payload: any
): typeof state => {
  let infData = {};
  if (payload?.data) {
    infData = appendToPrevious(
      state.insights?.influencers?.data || [],
      payload
    );
  }
  return {
    ...state,
    insights: {
      ...state.insights,
      influencers: infData,
    },
  };
};

export const updateTeamMembers = (
  state: BrandReducerType,
  payload: any
): typeof state => {
  if (payload.isMobile) {
    const currentData = state.teamMembers || [];
    return {
      ...state,
      teamMembers: [...currentData, ...payload.response.data],
    };
  }
  return {
    ...state,
    teamMembers: payload.response,
  };
};

export const addTeamMember = (
  state: BrandReducerType,
  payload: any
): typeof state => {
  const currentData = state.teamMembers || [];
  return {
    ...state,
    teamMembers: [payload.response.data, ...currentData],
  };
};

export const clearTeamMembersList = (
  state: BrandReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    teamMembers: payload,
  };
};

export const updateLoadingTeamMembers = (
  state: BrandReducerType,
  payload: boolean
): typeof state => {
  return {
    ...state,
    isTeamMembersLoading: payload,
  };
};

export const setBrandRolesLoading = (
  state: BrandReducerType,
  payload: boolean
): typeof state => {
  return {
    ...state,
    isRolesLoading: payload,
  };
};

export const updateBrandRoles = (
  state: BrandReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    roles: payload.data,
  };
};

export const commissionPercentageDistribution = (
  state: BrandReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    commissionPercentageDistribution: payload.data,
  };
};

export const emptyCommissionPercentage = (
  state: BrandReducerType,
  payload: any
): typeof state => {
  return {
    ...state,
    commissionPercentageDistribution: payload,
  };
};

export const resetBrandReducer = (): BrandReducerType => {
  return initialState;
};

export default createReducer(initialState, {
  [UPDATE_INFLUENCER_SEEKING_APPROVAL_DATA]:
    updateInfluencerSeekingApprovalData,
  [UPDATE_INFLUENCER_REQUESTS_FOR_APPROVAL]:
    updateInfluencerRequestsForApproval,
  [EMPTY_APPROVAL_DATA]: emptyApprovalData,
  [UPDATE_INFLUENCERS_OF_PRODUCT]: updateInfluencersOfProduct,
  [UPDATE_INFLUENCER_REQUESTS_STATUS]: updateInfluencerRequestsStatus,
  [UPDATE_INFLUENCERS_OF_BRAND]: updateInfluencersOfBrand,
  [SET_BRAND_COUNTRIES]: updateBrandCountries,
  [UPDATE_BRAND_COUNTRIES_LOADING_STATUS]: setBrandCountriesLoading,
  [UPDATE_PRODUCT_INSIGHTS]: setProductInsights,
  [RESET_BRAND_REDUCER]: resetBrandReducer,
  [UPDATE_TEAM_MEMBER_LIST]: updateTeamMembers,
  [ADD_TEAM_MEMBER]: addTeamMember,
  [UPDATE_LOADING_TEAM_MEMBERS]: updateLoadingTeamMembers,
  [UPDATE_BRAND_ROLES_LOADING_STATUS]: setBrandRolesLoading,
  [SET_BRAND_ROLES]: updateBrandRoles,
  [ADD_TEAM_MEMBER]: addTeamMember,
  [CLEAR_TEAM_MEMBER_LIST]: clearTeamMembersList,
  [CLEAR_ALL_INFLUENCER_REQUESTS]: clearInfluencerRequests,
  [RESET_BRAND_REDUCER]: resetBrandReducer,
  [COMMISSION_PERCENTAGE_DISTRIBUTION]: commissionPercentageDistribution,
  [EMPTY_COMMISSION_PERCENTAGE_DISTRIBUTION]: emptyCommissionPercentage,
});
