import { FC, useState } from "react";
import { Box, Spinner } from "@chakra-ui/react";

import { Formik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import { RootState, Strings } from "@be-tagged/shared";
import { FormikKeys } from "@be-tagged/shared/src/enums";

import { getSendResetCode } from "@be-tagged/shared/src/store/actions/CommonActions/ForgotPassword.actions";

import {
  CustomBtn,
  ErrorCleaner,
  MaterialStyleInput,
} from "../../../app/components";
import { YUP_VALIDATION } from "../../../app/strings";
import OTPInput from "./OTPInput";
import useAuth from "src/app/hooks/useAuth";

//Fetching Formik Keys
const { Email } = FormikKeys;

interface SubmittedDetailsInt {
  [Email]?: string;
}
const getInitialValues: () => SubmittedDetailsInt = () => {
  let initalValues = {
    [Email]: "",
  };
  return initalValues;
};
const getValidationSchema = () => {
  return {
    [Email]: yup.string().email().required(YUP_VALIDATION.EMAIL_PRESENCE),
  };
};

/**
 * @returns {FC}
 */

const EmailInput: FC<{ isBrand: boolean }> = ({ isBrand }): JSX.Element => {
  const dispatch = useDispatch();
  const userType = isBrand ? "BrandMember" : "Influencer";
  const { loading } = useSelector((state: RootState) => state.appReducer);
  const [flag, setFlag] = useState(true);
  const [primaryEmail, setPrimaryEmail] = useState("");
  const EmailVerify = async (details: SubmittedDetailsInt) => {
    const { email } = details;
    const tempemail = email === undefined ? "" : email;
    setPrimaryEmail(tempemail);
    dispatch(
      getSendResetCode({ email: tempemail, userType: userType }, () => {
        setFlag(false);
      })
    );
  };
  return (
    <Box
      py={{
        sm: 0,
        md: 10,
      }}
    >
      <ErrorCleaner />
      <Formik
        initialValues={getInitialValues()}
        onSubmit={EmailVerify}
        validationSchema={yup.object().shape(getValidationSchema())}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldTouched,
          errors,
          touched,
          isValid,
        }) => (
          <>
            {flag ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <>
                  <Box pos={"relative"} pb={"12px"}>
                    <MaterialStyleInput
                      parentProps={{ style: { width: 300 } }}
                      value={values.email}
                      onChange={handleChange(Email)}
                      onBlur={() => setFieldTouched(Email)}
                      type={Email}
                      label={Strings.workEmail}
                    />
                    {touched.email && errors.email && (
                      <Box className="error_msg">{errors.email}</Box>
                    )}
                  </Box>
                  <Box className="actions_btn_container">
                    <CustomBtn
                      id="action_btn"
                      className="purple_btn"
                      disabled={!isValid || loading}
                      type="submit"
                      style={{
                        fontSize: 14,
                        marginBottom: 30,
                      }}
                    >
                      {loading && (
                        <Spinner size={"xs"} style={{ marginRight: 10 }} />
                      )}
                      {"Next"}
                    </CustomBtn>
                  </Box>
                </>
              </form>
            ) : (
              <OTPInput primaryEmail={primaryEmail} userType={userType} />
            )}
          </>
        )}
      </Formik>
    </Box>
  );
};

export default EmailInput;
