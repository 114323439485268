import { OfferType } from "../enums";

export const influencersRange = [
  {
    label: "0-10",
    value: {
      from: 0,
      to: 10
    }
  },
  {
    label: "10-20",
    value: {
      from: 10,
      to: 20
    }
  },
  {
    label: "20-30",
    value: {
      from: 20,
      to: 30
    }
  },
  {
    label: "30-40",
    value: {
      from: 30,
      to: 40
    }
  },
  {
    label: "40-50",
    value: {
      from: 40,
      to: 50
    }
  },
  {
    label: "50-75",
    value: {
      from: 50,
      to: 75
    }
  },
  {
    label: "75-100",
    value: {
      from: 75,
      to: 100
    }
  },
  {
    label: "More than 100",
    value: {
      from: 100,
      to: null
    }
  }
]

export const salesRange = [
  {
    label: "0-10",
    value: {
      from: 0,
      to: 10
    }
  },
  {
    label: "11-20",
    value: {
      from: 11,
      to: 20
    }
  },
  {
    label: "21-30",
    value: {
      from: 21,
      to: 30
    }
  },
  {
    label: "31-40",
    value: {
      from: 31,
      to: 40
    }
  },
  {
    label: "41-50",
    value: {
      from: 41,
      to: 50
    }
  },
  {
    label: "50-75",
    value: {
      from: 50,
      to: 75
    }
  },
  {
    label: "75-100",
    value: {
      from: 75,
      to: 100
    }
  },
  {
    label: "More than 100",
    value: {
      from: 100,
      to: null
    }
  }
]

export const commissionRange = [
  {
    label: "0%-2%",
    value: {
      from: 0,
      to: 2
    }
  },
  {
    label: "2%-5%",
    value: {
      from: 2,
      to: 5
    }
  },
  {
    label: "5%-7%",
    value: {
      from: 5,
      to: 7
    }
  },
  {
    label: "7%-10%",
    value: {
      from: 7,
      to: 10
    }
  },
  {
    label: "10%-15%",
    value: {
      from: 10,
      to: 15
    }
  },
  {
    label: "15%-20%",
    value: {
      from: 15,
      to: 20
    }
  },
  {
    label: "20%-30%",
    value: {
      from: 20,
      to: 30
    }
  },
  {
    label: "30%-40%",
    value: {
      from: 30,
      to: 40
    }
  },
  {
    label: "More than 40%",
    value: {
      from: 40,
      to: null
    }
  }
]

export const offerType = [
  {
    label: "Open",
    value: OfferType.Open,
  },
  {
    label: "Approval Based",
    value: OfferType.ApprovalBased,
  },
];

export const productSoldFilters = [
  { label: 'Price', value: 'Price' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Value', value: 'Value' },
  { label: 'Commission', value: 'Commission' },
  { label: 'Sales data uploaded on', value: 'Sales data uploaded on' },
];

export const influencersFilters = [
  { label: 'Sales', value: 'Sales' },
  { label: 'Commission', value: 'Commission' },
  { label: 'Products Sold', value: 'Products Sold' },
];