import { FC } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Colors, Strings } from "@be-tagged/shared";
import { ChevronDown } from "@be-tagged/shared/src/assets/icons";
import { dateFiltersSinceListing } from "src/app/constants";

interface TagSelectProps {
  selectedTags: any;
  setTags: (e: any) => void;
}

interface CountrySelectProps {
  countryOptions: any;
  isMulti?: boolean;
  selectedCountries: any;
  onBlur?: any;
  isLoading?: boolean;
  alternativeStyle?: boolean;
  setCountries: (e: any) => void;
  placeholder?: string;
  labelPrefix?: string;
  isDisabled?: boolean;
}

interface CountryCodeSelectProps {
  countryCodeOptions: any[];
  countryCode: any;
  setCountryCode: (e: any) => void;
}

interface DateTimeRangeProps {
  onChange: (x: any) => any;
  value: any;
  isInclusive?: boolean;
}

interface CurrencySelectProps {
  isDisabled: boolean;
  currencyOptions: any;
  selectedCurrency: any;
  setCurrency: (e: any) => void;
}

const NoDividerIndicator = {
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null,
};

export const PurpleArrowDropdown = {
  DropdownIndicator: (props: any) => (
    <components.DropdownIndicator {...props}>
      <img src={ChevronDown} alt="chevron-drown" />
    </components.DropdownIndicator>
  ),
  IndicatorSeparator: () => null,
};

export const TagSelect: FC<TagSelectProps> = ({
  selectedTags,
  setTags,
}): JSX.Element => {
  const handleKeyDown = (e: any) => {
    if (["#", " "].includes(e.key)) {
      e.preventDefault();
    }
  };

  const getTagValues = (selectedTags: any) => {
    const tempValues = selectedTags.map((tag: any) => {
      const tempTag = { ...tag };
      tempTag.label = tag.label[0] === "#" ? tag.label : `#${tag.label}`;
      return tempTag;
    });
    return tempValues;
  };

  return (
    <CreatableSelect
      isMulti
      onKeyDown={handleKeyDown}
      placeholder=" "
      isClearable={false}
      className="creatable"
      components={NoDividerIndicator}
      onChange={setTags}
      options={[
        {
          value: "",
          label: Strings.createHashtag,
          isDisabled: true,
        },
      ]}
      styles={beTaggedStyles}
      value={getTagValues(selectedTags)}
      formatCreateLabel={(userInput) => `#${userInput}`}
    />
  );
};

export const CountrySelect: FC<CountrySelectProps> = ({
  countryOptions,
  isMulti,
  selectedCountries,
  onBlur = undefined,
  isLoading = false,
  alternativeStyle = false,
  labelPrefix = "",
  placeholder = "",
  setCountries,
  isDisabled = false,
}): JSX.Element => (
  <Select
    isDisabled={isDisabled}
    components={PurpleArrowDropdown}
    placeholder={placeholder}
    isMulti={isMulti}
    isClearable={false}
    styles={alternativeStyle ? borderedDropdownStyle : beTaggedStyles}
    options={countryOptions}
    isLoading={isLoading}
    getOptionValue={(option) => option.id}
    getOptionLabel={(option) => labelPrefix + option.name}
    blurInputOnSelect={false}
    onBlur={onBlur}
    value={selectedCountries}
    onChange={setCountries}
  />
);

export const CountryCodeSelect: FC<CountryCodeSelectProps> = ({
  countryCodeOptions,
  countryCode,
  setCountryCode,
}): JSX.Element => (
  <Select
    components={PurpleArrowDropdown}
    isClearable={false}
    styles={{ ...beTaggedStyles, ...countryCodeDropdownStyle }}
    options={countryCodeOptions}
    getOptionValue={(option) => option.id}
    getOptionLabel={(option) => `+` + option?.isdCode + ` (${option?.name})`}
    value={countryCode}
    onChange={setCountryCode}
  />
);

export const CurrencySelect: FC<CurrencySelectProps> = ({
  isDisabled = false,
  currencyOptions,
  selectedCurrency,
  setCurrency,
}) => {
  return (
    <Select
      isDisabled={isDisabled}
      components={NoDividerIndicator}
      placeholder={`${Strings.currency}*`}
      isSearchable={true}
      styles={beTaggedStyles}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => `${option.name} (${option.symbol})`}
      value={selectedCurrency}
      options={currencyOptions}
      onChange={setCurrency}
    />
  );
};

export const SortDropdown: FC<any> = ({ options, value, onChange }) => {
  return (
    <Select
      components={PurpleArrowDropdown}
      styles={{ ...borderedDropdownStyle, ...countryCodeDropdownStyle }}
      isClearable={true}
      isSearchable={false}
      options={options}
      getOptionLabel={(option: any) => option?.name}
      value={value}
      onChange={onChange}
    />
  );
};

// Dropdown Styles
export const beTaggedStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    paddingTop: 5,
    paddingBottom: 3,
    "&:hover": {
      backgroundColor: Colors.dollarGreen,
      color: Colors.bgLight,
    },
  }),
  container: (provided: any) => ({
    ...provided,
    flex: 1,
    fontSize: 14,
    paddingBottom: 2,
  }),
  valueContainer: (provided: any) => ({ ...provided, padding: 0 }),
  control: () => ({
    display: "flex",
    flex: 1,
    width: "inherit",
    background: "transparent",
    borderBottom: `1px solid ${Colors.borderColor}`,
  }),
  placeholder: (provided: any) => ({ ...provided, color: Colors.blackColor }),
};

export const TimeRangeFilter: FC<DateTimeRangeProps> = ({
  onChange,
  value,
  isInclusive = false,
}): JSX.Element => (
  <Select
    components={PurpleArrowDropdown}
    styles={{ control: customControlStyles }}
    isClearable={false}
    isSearchable={false}
    options={dateFiltersSinceListing}
    getOptionLabel={(option: any) => option?.name}
    value={value}
    onChange={onChange}
  />
);

const customControlStyles = (baseStyles: any) => ({
  ...baseStyles,
  backgroundColor: Colors.grey300,
  height: 25,
  minHeight: 25,
  border: "none",
});

export const countryCodeDropdownStyle = {
  menu: (provided: any) => ({
    ...provided,
    width: "200px",
  }),
};

export const borderedDropdownStyle = {
  control: customControlStyles,
  valueContainer: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (baseStyles: any) => ({
    ...baseStyles,
    padding: 0,
  }),
};
