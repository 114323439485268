import SegmentLogTransportBase, {
  TrackingClientContainer,
} from "@be-tagged/shared/src/services/Logging/SegmentLogTransportBase";
import segmentLogScriptSetup from "./SegmentLogScriptSetup";

export class SegmentLogTransportWeb extends SegmentLogTransportBase {
  client: TrackingClientContainer = {
    analytics: {} as SegmentAnalytics.AnalyticsJS,
  };

  init = () => {
    segmentLogScriptSetup();
    this.client = window as TrackingClientContainer;
  };
}
