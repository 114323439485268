import { FC, useEffect, useRef, useState } from "react";

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  PinInput,
  PinInputField,
  Spinner,
  Switch,
  Text,
} from "@chakra-ui/react";
import { Formik } from "formik";

import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import clevertap from "clevertap-web-sdk";

import {
  RootState,
  Colors,
  Strings,
  verifyUserWithPhoneOTP,
  userSignInWithEmail,
  userSignInWithPhone,
  logger,
  LoggerStrings,
  EmailRegex,
} from "@be-tagged/shared";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import { clearErrors, OTP_LENGTH } from "@be-tagged/shared";

import {
  MaterialStyleInput,
  ErrorCleaner,
  CustomBtn,
  ErrorMsg,
} from "../../../app/components";
import { CountryCodeObject, FormProps } from "../../interfaces";
import MobileInput from "../../common/MobileInput";
import {
  CommonObjectType,
  defaultCountryCode,
  ROUTING_URLS,
} from "../../../app/constants";
import { YUP_VALIDATION } from "../../../app/strings";
import useClearInputAutofill from "src/app/hooks/useClearInputAutofill";

const { Password, Email, MobileOTP, Phone, CountryCode } = FormikKeys;

interface SubmittedDetailsInt {
  [Phone]: string;
  [CountryCode]: CountryCodeObject;
  [Password]?: string;
  [Email]?: string;
  [MobileOTP]?: string;
}

/**
 * Function For returning the inital values for Sign In
 * @returns {Object} initalValues for the Sign In Form
 */
const getInitialValues: () => SubmittedDetailsInt = () => {
  let initalValues = {
    [Email]: "",
    [Password]: "",
    [Phone]: "",
    [CountryCode]: defaultCountryCode,
    [MobileOTP]: "",
  };
  return initalValues;
};

/**
 * Returns the validation schema for Formik
 * @param isPhone - a boolean (true / false) indicating whether the input is via mobile / email
 * @returns {Object} the validation schema
 */
const getValidationSchema: (isPhone: boolean) => any = (isPhone = false) => {
  if (isPhone) {
    return {
      [Phone]: yup
        .string()
        .min(7, YUP_VALIDATION.MOBILE_NUMBER_LENGTH)
        .max(15, YUP_VALIDATION.MOBILE_NUMBER_LENGTH)
        .required(YUP_VALIDATION.MOBILE_NUMBER_PRESENCE),
      [CountryCode]: yup
        .object()
        .required(YUP_VALIDATION.COUNTRY_CODE_PRESENCE),
      [MobileOTP]: yup
        .string()
        .length(OTP_LENGTH, YUP_VALIDATION.OTP_LENGTH)
        .required(YUP_VALIDATION.OTP_PRESENCE),
    };
  }
  return {
    [Email]: yup
      .string()
      .matches(EmailRegex, Strings.enterValidEmail)
      .required(YUP_VALIDATION.EMAIL_PRESENCE),
    [Password]: yup
      .string()
      .min(6, YUP_VALIDATION.PASSWORD_LENGTH)
      .required(YUP_VALIDATION.PASSWORD_PRESENCE),
  };
};

/**
 * SignIn is the form that contains inputs for Sigining In
 * The user may choose to sign in via mobile (which then requires an OTP), or via email (which requires a password)
 * @param {Object} - Props for the component
 * bodyText - the text context to display inside the form
 * setLoginMode - method which will allow it to switch to Sign In screen from inside.
 * @returns {FC} - React Functional Component
 */
const SignIn: FC<FormProps> = ({ txtBody }): JSX.Element => {
  const [isPhone, setByPhone] = useState<boolean>(false);
  const [showOTP, setShowOTP] = useState<boolean>(false);
  const [showPass, setShowPass] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.appReducer);

  // To fix the chrome autofill issue
  const emailInputRef = useRef();
  useClearInputAutofill([emailInputRef]);

  const { SIGN_IN, SIGN_UP } = ROUTING_URLS;
  const isBrand = pathname.includes(ROUTING_URLS.BRAND);

  const brandVerifySignIn = async (details: SubmittedDetailsInt) => {
    const { email, password, phone, countryCode, mobileOTP } = details;

    let objectToSend: CommonObjectType = {};
    if (isPhone) {
      objectToSend = {
        phone,
        countryCode: countryCode?.isdCode,
        otp: mobileOTP,
      };
      dispatch(
        verifyUserWithPhoneOTP(objectToSend, isBrand, navigateToOnboarding)
      );
    } else {
      objectToSend = { email, password };
      dispatch(
        userSignInWithEmail(objectToSend, isBrand, navigateToOnboarding)
      );
    }
  };

  const sendOTP = (objectToSend: object) => {
    dispatch(
      userSignInWithPhone(objectToSend, isBrand, () => setShowOTP(true))
    );
  };

  const navigateToOnboarding = (payload: any = {}) => {
    const { brandMemberInfo, influencerInfo } = payload;
    if (isBrand) {
      clevertap.onUserLogin.push({
        Site: {
          Identity: brandMemberInfo?.userKey,
        },
      });
    } else {
      clevertap.onUserLogin.push({
        Site: {
          Identity: influencerInfo?.userKey,
        },
      });
    }
    navigate(ROUTING_URLS.ONBOARDING);
  };

  const switchLoginMode = (setFieldValue: any, setFieldTouched: any) => {
    dispatch(clearErrors());
    setFieldValue(Password, "");
    setFieldTouched(Password, false);
    navigate(pathname.replace(SIGN_IN, SIGN_UP));
  };

  const changeLoginMode = (setErrors: any) => {
    setByPhone(!isPhone);
    setErrors({});
    dispatch(clearErrors());
  };

  const forgotPassword = () => {
    navigate(pathname.replace(SIGN_IN, ROUTING_URLS.FORGOT_PASSWORD));
  };

  useEffect(() => {
    const user = isBrand ? Strings.brand : Strings.influencer;
    logger.info(
      `${user} ${LoggerStrings.userIsTryingTo} ${LoggerStrings.logInVia} ${
        isPhone ? Strings.mobileNumber : Strings.emailAddress
      }`,
      {
        isBrand,
      }
    );
  }, [isPhone]);

  useEffect(() => {
    if (showOTP) {
      const user = isBrand ? Strings.brand : Strings.influencer;
      logger.info(`${user} ${LoggerStrings.hadTheSignInOTPSent}`);
    }
  }, [showOTP]);

  return (
    <>
      <ErrorCleaner />
      <Formik
        initialValues={getInitialValues()}
        onSubmit={brandVerifySignIn}
        validationSchema={yup.object().shape(getValidationSchema(isPhone))}
      >
        {({
          errors,
          isValid,
          touched,
          values,
          handleChange,
          handleSubmit,
          setErrors,
          setFieldTouched,
          setFieldValue,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Box pb={{ base: 0, lg: "20px" }}>
                {/* Switch for email and phone number */}
                <FormControl display="flex" mb={"40px"} alignItems="center">
                  <FormLabel fontWeight={"normal"} htmlFor="login_via" mb="0">
                    {Strings.emailId}
                  </FormLabel>

                  <Switch
                    isChecked={isPhone}
                    onChange={() => changeLoginMode(setErrors)}
                    colorScheme={"green"}
                    size={"md"}
                    className="colored_switch"
                    id="login_via"
                  />
                  <FormLabel
                    fontWeight={"normal"}
                    htmlFor="login_via"
                    mb="0"
                    ml="3"
                  >
                    {Strings.mobileNumber}
                  </FormLabel>
                </FormControl>

                {/* Check the mode of Signing in, and render appropriate inputs */}
                {isPhone ? (
                  <Box>
                    <Flex pos={"relative"}>
                      <Box pos={"relative"} pb={"12px"}>
                        <MobileInput
                          countryCode={values[CountryCode]}
                          handleCountryCodeChange={(countryCode: any) =>
                            setFieldValue(CountryCode, countryCode)
                          }
                          phone={values[Phone]}
                          handlePhoneChange={handleChange(Phone)}
                          setFieldTouched={setFieldTouched}
                        />
                      </Box>
                      <Box
                        cursor={errors[Phone] ? "not-allowed" : "pointer"}
                        display={"inherit"}
                        color={
                          !values[Phone] || errors[Phone]
                            ? Colors.grey100
                            : Colors.purple
                        }
                      >
                        <button
                          className="transparent_btn"
                          disabled={
                            !values[Phone] || (errors[Phone] ? true : false)
                          }
                          style={{
                            position: "relative",
                            ...Styles.resendOTPBtn,
                          }}
                          type={"button"}
                          onClick={() => {
                            sendOTP({
                              [CountryCode]: values[CountryCode]?.isdCode,
                              [Phone]: values[Phone],
                            });
                          }}
                        >
                          {Strings.sendOTP}
                        </button>
                      </Box>

                      {touched[Phone] && errors[Phone] && (
                        <ErrorMsg bottom={-2}>{errors[Phone]}</ErrorMsg>
                      )}
                    </Flex>
                    {showOTP && (
                      <Box marginTop={"15px"}>
                        <Text
                          fontSize={"12px"}
                          fontWeight={"600"}
                          color={Colors.grey100}
                          marginBottom={"3px"}
                        >
                          {Strings.otp}
                        </Text>
                        <Box>
                          <HStack pos={"relative"}>
                            <PinInput
                              value={values[MobileOTP]}
                              onChange={handleChange(MobileOTP)}
                            >
                              {new Array(6).fill("").map((el, idx) => (
                                <PinInputField
                                  onBlur={() => {
                                    setFieldTouched(MobileOTP);
                                  }}
                                  placeholder={""}
                                  borderRadius={0}
                                  key={idx}
                                />
                              ))}
                            </PinInput>
                          </HStack>

                          {touched[MobileOTP] && errors[MobileOTP] && (
                            <Box pos={"relative"}>
                              <ErrorMsg bottom={-20}>
                                {errors[MobileOTP]}
                              </ErrorMsg>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <>
                    <Box pos={"relative"} pb={"12px"}>
                      <MaterialStyleInput
                        ref={emailInputRef}
                        parentProps={{ style: { width: 300 } }}
                        value={values[Email]}
                        onChange={handleChange(Email)}
                        onBlur={() => setFieldTouched(Email)}
                        type={Email}
                        name={Email}
                        label={isBrand ? Strings.workEmail : Strings.emailId}
                      />
                      {touched[Email] && errors[Email] && (
                        <ErrorMsg bottom={-2}>{errors[Email]}</ErrorMsg>
                      )}
                    </Box>

                    <Box pos={"relative"} pb={"12px"}>
                      <MaterialStyleInput
                        parentProps={{ style: { width: 300 } }}
                        value={values[Password]}
                        onChange={handleChange(Password)}
                        onBlur={() => setFieldTouched(Password)}
                        type={showPass ? "text" : "password"}
                        name={Password}
                        label={Strings[Password]}
                      />
                      {touched[Password] && errors[Password] && (
                        <ErrorMsg bottom={-2}>{errors[Password]}</ErrorMsg>
                      )}
                      {values[Password] !== "" && (
                        <button
                          type={"button"}
                          onClick={() => {
                            setShowPass(!showPass);
                          }}
                          style={{
                            color: Colors.purple,
                            fontSize: "11px",
                            fontWeight: "600",
                          }}
                        >
                          {showPass ? Strings.hidePass : Strings.showPass}
                        </button>
                      )}
                    </Box>
                  </>
                )}
              </Box>

              <Box marginTop={"5px"} className="actions_btn_container">
                <CustomBtn
                  id="action_btn"
                  className="purple_btn"
                  disabled={!isValid || loading}
                  type="submit"
                  style={{
                    fontSize: 14,
                    marginBottom: 30,
                  }}
                >
                  {loading && (
                    <Spinner size={"xs"} style={{ marginRight: 10 }} />
                  )}
                  {txtBody.btnTxtAction}
                </CustomBtn>
              </Box>
            </form>
            {!isPhone && (
              <Box onClick={forgotPassword} maxW={"fit-content"}>
                <span
                  role={"button"}
                  style={{
                    color: Colors.purple,
                    fontSize: "13px",
                    maxWidth: "fit-content",
                  }}
                  className="bold"
                >
                  &nbsp;{Strings.forgotPassword}
                </span>
              </Box>
            )}

            <Box
              className="member_already"
              style={{ fontSize: "15px", paddingTop: "20px" }}
            >
              {txtBody.otherAction.notifier}{" "}
              <Box
                as="span"
                role={"button"}
                style={{ color: Colors.purple, fontSize: "14px" }}
                className="bold"
                onClick={() => {
                  switchLoginMode(setFieldValue, setFieldTouched);
                }}
              >
                &nbsp;{txtBody.otherAction.btnTxtAction}
              </Box>
            </Box>
          </>
        )}
      </Formik>
    </>
  );
};

const Styles = {
  resendOTPBtn: {
    color: "inherit",
    bottom: 15,
    left: -30,
    fontWeight: "bold",
    minWidth: "fit-content",
    borderBottom: `1px solid ${Colors.borderColor}`,
    alignSelf: "flex-end",
    fontSize: 12,
  },
};

export default SignIn;
