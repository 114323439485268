import { FC, useState } from "react";
import { SocialMedia } from "@be-tagged/shared/src/enums";
import { Flex, Box, Checkbox } from "@chakra-ui/react";
import { SUPPORTED_FILES } from "../../../interfaces";
import { DragDropComponent } from ".";
import { Colors } from "@be-tagged/shared";

interface SocialsDropzoneProps {
  name: SocialMedia;
  files: any[];
  setFiles: any;
  onChange: any;
}

const SocialMediaDropzone: FC<SocialsDropzoneProps> = ({
  name,
  files,
  setFiles,
  onChange,
}): JSX.Element => {
  const [isDisabled, setIsDisabled] = useState<boolean>(!files.length);

  return (
    <Flex direction={"column"}>
      <Flex alignItems={"flex-end"} mb={2}>
        <Checkbox
          className="label"
          style={Styles.label}
          colorScheme="pink"
          mr={2}
          isChecked={!isDisabled}
          onChange={() => setIsDisabled(!isDisabled)}
        >
          <span style={{ fontSize: "12px" }}>{name}</span>
        </Checkbox>
      </Flex>

      <Box width={"250px"}>
        <DragDropComponent
          files={files}
          accept={SUPPORTED_FILES.Zip}
          disabled={isDisabled}
          setFiles={setFiles}
          onChange={(urlArr: string[]) => onChange(urlArr, name)}
        />
      </Box>
    </Flex>
  );
};

const Styles = {
  label: {
    fontWeight: "600",
    lineHeight: "1",
    color: Colors.grey200,
  },
};

export default SocialMediaDropzone;
