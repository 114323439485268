import { Platforms } from "../enums/Platforms";

export const getPlatform = (): Platforms => {
  if (typeof document !== "undefined") {
    // condition for ReactJS
    return Platforms.Web;
  } else if (
    typeof navigator !== "undefined" &&
    navigator.product === "ReactNative"
  ) {
    // condition for React Native
    return Platforms.Mobile;
  } else return Platforms.API;
};
