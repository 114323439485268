import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Box,
  Flex,
  Image,
} from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { BeTaggedLogoMini } from "@be-tagged/shared/src/assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Strings } from "@be-tagged/shared";
import { setShowOnboardingModal } from "@be-tagged/shared";

interface IOnboardingWaitModal {
  isOpen: boolean;
  onClose: () => void;
}

/**
 * OnboardingWaitModal comes up as a loader while the user's data is processed by the backend
 * @param {Object} props - Of the form IOnboardingWaitModal
 * @returns {JSX.Element} - A React Functional Component
 */
const OnboardingWaitModal: FC<IOnboardingWaitModal> = ({
  isOpen,
  onClose,
}): JSX.Element => {
  const error = useSelector((state: RootState) => state?.appReducer?.error);
  const dispatch = useDispatch();
  useEffect(() => {
    if (error) {
      // if the server has responded with an error / i.e., onboarding could not be completed
      onClose();
      dispatch(setShowOnboardingModal(false));
    }
    //eslint-disable-next-line
  }, [error]);

  return (
    <>
      <Modal
        size={"2xl"}
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalBody>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              className="content_container"
              flexDir={"column"}
            >
              <Image
                src={BeTaggedLogoMini}
                marginTop="20px"
                marginBottom={"20px"}
              />
              <Box className="bold" style={{ fontSize: 18, paddingBottom: 24 }}>
                {Strings.grabACupOfCoffee}
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default OnboardingWaitModal;
