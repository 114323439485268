import { RESET_APP_REDUCER, RESET_BRAND_REDUCER, RESET_COMMON_REDUCER, RESET_INFLUENCER_REDUCER, RESET_USER_REDUCER, SET_ACCESS_TOKEN } from '../../constants/ReduxConstants';

export const userLogout = (navigatorFn: any) => (dispatch: any) => {
  dispatch({
    type: RESET_BRAND_REDUCER,
    payload: null
  })
  dispatch({
    type: RESET_COMMON_REDUCER,
    payload: null
  })
  dispatch({
    type: RESET_APP_REDUCER,
    payload: null
  })
  dispatch({
    type: RESET_INFLUENCER_REDUCER,
    payload: null
  })
  dispatch({
    type: RESET_USER_REDUCER,
    payload: null
  })
  dispatch({
    type: SET_ACCESS_TOKEN,
    payload: null
  })
  navigatorFn && navigatorFn()
}