export const UPDATE_LOADING_STATUS = "UPDATE_LOADING_STATUS";
export const SHOW_OTP_VERIFY_MODAL = "SHOW_OTP_VERIFY_MODAL";
export const UPDATE_VERIFY_EMAIL_STATUS = "UPDATE_VERIFY_EMAIL_STATUS";
export const UPDATE_VERIFY_PHONE_STATUS = "UPDATE_VERIFY_PHONE_STATUS";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
export const SHOW_ONBOARDING_MODAL = "SHOW_ONBOARDING_MODAL";
export const VERIFY_RESET_CODE = "VERIFY_RESET_CODE";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const UPDATE_LOADING_COUNTRIES_STATUS =
  "UPDATE_LOADING_COUNTRIES_STATUS";
export const SET_BRAND_COUNTRIES = "SET_BRAND_COUNTIRES"; // specific countries of brand, used in insights
export const UPDATE_BRAND_COUNTRIES_LOADING_STATUS =
  "UPDATE_BRAND_COUNTRIES_LOADING_STATUS";
export const SET_CURRENCIES = "SET_CURRENCIES";
export const UPDATE_IS_USER_LOGGED_IN = "UPDATE_IS_USER_LOGGED_IN";
export const UPDATE_ONBOARDING_VALUES = "UPDATE_ONBOARDING_VALUES";
export const UPDATE_CATALOG_LIST = "UPDATE_CATALOG_LIST";
export const UPDATE_LOADING_CATALOG_LIST = "UPDATE_LOADING_CATALOG_LIST";
export const UPDATE_PRODUCT_DETAILS = "UPDATE_PRODUCT_DETAILS";
export const IS_LOADING_PRODUCT_DETAILS = "IS_LOADING_PRODUCT_DETAILS";
export const SEND_RESET_CODE = "SEND_RESET_CODE";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const COMPLETED_PRODUCT_LISTING = "COMPLETED_PRODUCT_LISTING";
export const SET_USER_INFO = "SET_USER_INFO";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const SET_USER_AS_BRAND = "SET_USER_AS_BRAND";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const CLEAR_PRODUCT_CATALOG = "CLEAR_PRODUCT_CATALOG";
export const EMPTY_APPROVAL_DATA = "EMPTY_APPROVAL_DATA";
export const LOGIN_VIA_PHONE = "LOGIN_VIA_PHONE";
export const SET_PROMOTION_REQUEST = "SET_PROMOTION_REQUEST";
export const UPDATE_PRODUCT_PROMOTION_DETAIL =
  "UPDATE_PRODUCT_PROMOTION_DETAIL";
export const CLEAR_PRODUCT_DETAILS = "CLEAR_PRODUCT_DETAILS";
export const UPDATE_LOADING_CITIES_STATUS = "UPDATE_LOADING_CITIES_STATUS";
export const UPDATE_CITIES_LIST = "UPDATE_CITIES_LIST";
export const UPDATE_LOADING_BRANDS_STATUS = "UPDATE_LOADING_BRANDS_STATUS";
export const UPDATE_BRANDS_LIST = "UPDATE_BRANDS_LIST";
export const UPDATE_INFLUENCER_SEEKING_APPROVAL_DATA =
  "UPDATE_INFLUENCER_SEEKING_APPROVAL_DATA";
export const UPDATE_INFLUENCER_REQUESTS_FOR_APPROVAL =
  "UPDATE_INFLUENCER_REQUESTS_FOR_APPROVAL";
export const UPDATE_INFLUENCER_REQUESTS_STATUS =
  "UPDATE_INFLUENCER_REQUESTS_STATUS";
export const REQUEST_ACTION_PERFORMED_SUCCESSFULLY =
  "REQUEST_ACTION_PERFORMED_SUCCESSFULLY";
export const UPDATE_INSIGHTS_SUMMARY = "UPDATE_INSIGHTS_SUMMARY";
export const UPDATE_PRODUCTS_SOLD_LIST = "UPDATE_PRODUCTS_SOLD_LIST";
export const UPDATE_PLATFORM_INSIGHTS_LIST = "UPDATE_PLATFORM_INSIGHTS_LIST";
export const CLEAR_PLATFORM_INSIGHTS_LIST = "CLEAR_PLATFORM_INSIGHTS_LIST";
export const CLEAR_ALL_INFLUENCER_REQUESTS = "CLEAR_ALL_INFLUENCER_REQUESTS";
export const UPDATE_INFLUENCERS_OF_PRODUCT = "UPDATE_INFLUENCERS_OF_PRODUCT";
export const UPDATE_PRODUCT_INSIGHTS = "UPDATE_PRODUCT_INSIGHTS";
export const UPDATE_INFLUENCERS_OF_BRAND = "UPDATE_INFLUENCERS_OF_BRAND";
export const UPDATE_COMMISSION_PAYOUT = "UPDATE_COMMISSION_PAYOUT";
export const UPDATE_DASHBOARD_DATA = "UPDATE_DASHBOARD_DATA";
export const SET_DASHBOARD_DATA_LOADING_STATUS =
  "SET_DASHBOARD_DATA_LOADING_STATUS";
export const UPDATE_LEADERBOARD_DATA = "UPDATE_LEADERBOARD_DATA";
export const UPDATE_LEADERBOARD_INSIGHTS = "UPDATE_LEADERBOARD_INSIGHTS";
// Resetting the reducers back to their initial states
export const RESET_BRAND_REDUCER = "RESET_BRAND_REDUCER";
export const RESET_COMMON_REDUCER = "RESET_COMMON_REDUCER";
export const RESET_INFLUENCER_REDUCER = "RESET_INFLUENCER_REDUCER";
export const RESET_USER_REDUCER = "RESET_USER_REDUCER";
export const RESET_APP_REDUCER = "RESET_APP_REDUCER";
// Profile
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const UPDATE_EMAILS_IN_PROFILE_DATA = "UPDATE_EMAILS_IN_PROFILE_DATA";
export const UPDATE_PHONES_IN_PROFILE_DATA = "UPDATE_PHONES_IN_PROFILE_DATA";
// Brand Team
export const UPDATE_TEAM_MEMBER_LIST = "UPDATE_TEAM_MEMBER_LIST";
export const UPDATE_LOADING_TEAM_MEMBERS = "UPDATE_LOADING_TEAM_MEMBERS";
export const UPDATE_BRAND_ROLES_LOADING_STATUS =
  "UPDATE_BRAND_ROLES_LOADING_STATUS";
export const SET_BRAND_ROLES = "SET_BRAND_ROLES";
export const UPDATE_PRODUCTS_PROMOTED = "UPDATE_PRODUCTS_PROMOTED";
export const UPDATE_BANK_ACCOUNTS = "UPDATE_BANK_ACCOUNTS";
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
export const CLEAR_TEAM_MEMBER_LIST = "CLEAR_TEAM_MEMBER_LIST";
export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE";
export const CLEAR_PRODUCTS_PROMOTED = "CLEAR_PRODUCTS_PROMOTED";
export const COMMISSION_PERCENTAGE_DISTRIBUTION =
  "COMMISSION_PERCENTAGE_DISTRIBUTION";
export const EMPTY_COMMISSION_PERCENTAGE_DISTRIBUTION =
  "EMPTY_COMMISSION_PERCENTAGE_DISTRIBUTION";

// Notifications

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_NOTIFICATIONS_VIA_SCROLL = "SET_NOTIFICATIONS_VIA_SCROLL";
