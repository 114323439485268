import {
  getBrands,
  RootState,
  Strings,
} from "@be-tagged/shared";
import { FormikKeys } from "@be-tagged/shared/src/enums";
import { debounce } from "@be-tagged/shared/src/utils";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { beTaggedStyles, Label, PurpleArrowDropdown } from "src/app/components";
import { FC } from "react";
import { useState } from "react";
import { useEffect } from "react";

interface BrandSelectProps {
  selectedBrand: any[] | undefined;
  setSelectedBrand: (args: any) => any;
}

const { QuerySearch } = FormikKeys;
const INITAL_QUERY_VALUE = "~";

/**
 * Multi-select dropdown for brand search
 * @param {Object} - props of the component, of the type BrandSelectProps
 * @returns {JSX.Element} - a React Functional Component
 */
const BrandSelect: FC<BrandSelectProps> = ({
  selectedBrand,
  setSelectedBrand,
}): JSX.Element => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState<string>(INITAL_QUERY_VALUE);
  const { brands, isLoadingBrands } = useSelector(
    (state: RootState) => state.influencerReducer
  );

  useEffect(() => {
    if (!brands.length && query === INITAL_QUERY_VALUE) {
      handleInputChange("");
    }
  }, []);

  const handleInputChange = (newValue: string) => {
    if (newValue !== query) {
      setQuery(newValue);
      return dispatch(
        getBrands({
          [QuerySearch]: newValue,
        })
      );
    }
    return undefined;
  };

  return (
    <div>
      <Label>{Strings.brand}</Label>
      <Select
        components={{ ...PurpleArrowDropdown, NoOptionsMessage }}
        styles={beTaggedStyles}
        value={selectedBrand}
        isMulti
        options={brands}
        getOptionLabel={(option: any) => `${option?.name}`}
        getOptionValue={(option) => option.id}
        onInputChange={debounce(handleInputChange)}
        onChange={setSelectedBrand}
        isLoading={isLoadingBrands}
      />
    </div>
  );
};

/**
 * A React-select component that is rendered when elements are being fetched
 * @param {Object} props - React Select Props
 * @returns {JSX.Element} - A React Functional component
 */
const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>{props.selectProps.isLoading ? Strings.loading : ""}</span>
    </components.NoOptionsMessage>
  );
};

export default BrandSelect;
