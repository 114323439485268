import { getBrandRoles, RootState, Strings } from "@be-tagged/shared";
import { debounce } from "@be-tagged/shared/src/utils";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { beTaggedStyles, Label, PurpleArrowDropdown } from "src/app/components";
import { FC } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { CommonObjectType } from "src/app/constants";

interface RoleSelectProps {
  selectedRole: CommonObjectType | undefined;
  setSelectedRole: (args: any) => any;
  onBlur?: any;
}

const INITAL_QUERY_VALUE = "~";

/**
 * Dropdown for role select
 * @param {Object} - props of the component, of the type RoleSelectProps
 * @returns {JSX.Element} - a React Functional Component
 */
const RoleSelect: FC<RoleSelectProps> = ({
  selectedRole,
  setSelectedRole,
  onBlur,
}): JSX.Element => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState<string>(INITAL_QUERY_VALUE);
  const { roles, isRolesLoading } = useSelector(
    (state: RootState) => state.brandReducer
  );

  useEffect(() => {
    if (!roles.length && query === INITAL_QUERY_VALUE) {
      handleInputChange("");
    }
  }, []);

  const handleInputChange = (newValue: string) => {
    if (newValue !== query) {
      setQuery(newValue);
      return dispatch(getBrandRoles());
    }
    return undefined;
  };
  return (
    <Box width={{ md: "250px", base: "auto" }}>
      <Label>{Strings.role}</Label>
      <Select
        components={{ ...PurpleArrowDropdown, NoOptionsMessage }}
        styles={beTaggedStyles}
        value={selectedRole}
        isMulti={false}
        options={roles}
        getOptionLabel={(option: any) => `${option?.name}`}
        getOptionValue={(option) => option.id}
        onInputChange={debounce(handleInputChange)}
        onChange={setSelectedRole}
        isLoading={isRolesLoading}
        onBlur={onBlur}
      />
    </Box>
  );
};

/**
 * A React-select component that is rendered when elements are being fetched
 * @param {Object} props - React Select Props
 * @returns {JSX.Element} - A React Functional component
 */
const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>{props.selectProps.isLoading ? Strings.loading : ""}</span>
    </components.NoOptionsMessage>
  );
};

export default RoleSelect;
