import {
  clearPlatformInsights,
  Colors,
  getPlatformInsights,
  RootState,
  Strings,
} from "@be-tagged/shared";
import {
  FormikKeys,
  PlatformInsightsQueryParams,
  SocialMedia,
} from "@be-tagged/shared/src/enums";
import {
  Avatar,
  Box,
  Center,
  Flex,
  SimpleGrid,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InsightsTableRow from "./InsightsTableRow";
import { NoDataFound, NotFound } from "@be-tagged/shared/src/assets/icons";
import useAuth from "src/app/hooks/useAuth";
import { CommonObjectType, ROUTING_URLS } from "src/app/constants";
import useData from "src/app/hooks/useData";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "src/app/components/LoadingSpinner";
import ReactImageFallback from "react-image-fallback";
import InsightsSkeleton from "../SkeletonLayout";
import { withCommas } from "src/app/utils";
import { SUPPORTED_FILES } from "src/dashboard-module/interfaces";
import { useNavigate, useLocation } from "react-router-dom";

const { StartFrom, ListQty, CountryID, SalesChannel } = FormikKeys;
const { TikTok, Instagram, Facebook, Twitter } = SocialMedia;
interface ITabPlatformInsights {
  country: CommonObjectType | null;
  dateParams: any;
}
const PER_PAGE = 10;
/**
 * @param {Object} props - Of the form ITabPlatformInsights
 * @returns {JSX.Element} - A React Functional Component
 */
const TabPlatformInsights: FC<ITabPlatformInsights> = ({
  country,
  dateParams,
}): JSX.Element => {
  // Tab Index to keep track of the selected tab
  const [tabIndex, setTabIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMounted, setHasMounted] = useState(false);
  const [isScrollLoading, setIsScrollLoading] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { BUSINESS_INSIGHTS, PRODUCT_DETAILS } = ROUTING_URLS;
  const navigateToProductDetails = (id: number) => {
    navigate(pathname.replace(BUSINESS_INSIGHTS, PRODUCT_DETAILS) + "/" + id);
  };

  const { isBrand } = useAuth();
  const { loading } = useData();

  const saleChannel = [Instagram, TikTok, Facebook, Twitter];

  const dispatch = useDispatch();

  const platformInsights = useSelector((state: RootState) => {
    return state.commonReducer.commonData?.insights?.platformInsights || {};
  });

  const getPlatformInsightsData = (par: any, viaScroll: boolean) => {
    let params: CommonObjectType = {
      ...par,
      ...dateParams,
    };
    if (isBrand) params[CountryID] = country?.id;

    let finalPayload = {
      ...params,
      ...dateParams,
    } as PlatformInsightsQueryParams;

    {
      !isBrand &&
        dispatch(
          getPlatformInsights(
            finalPayload,
            isBrand,
            viaScroll ? setIsScrollLoading : undefined
          )
        );
    }
    {
      isBrand &&
        country !== null &&
        dispatch(
          getPlatformInsights(
            finalPayload,
            isBrand,
            viaScroll ? setIsScrollLoading : undefined
          )
        );
    }
  };

  const fetchPlatformInsights = (viaScroll: boolean = false) => {
    getPlatformInsightsData(
      {
        [StartFrom]: currentPage * PER_PAGE - PER_PAGE,
        [ListQty]: PER_PAGE,
        [SalesChannel]: saleChannel[tabIndex],
      },
      viaScroll
    );
  };

  useEffect(() => {
    if (isBrand && !country) return;
    fetchPlatformInsights(currentPage !== 1);
  }, [currentPage]);

  useEffect(() => {
    dispatch(clearPlatformInsights());
    if (hasMounted) fetchPlatformInsights();
    else setHasMounted(true);
  }, [tabIndex, country, dateParams]);

  useEffect(() => {
    return () => {
      dispatch(clearPlatformInsights());
    };
  }, []);

  const onTabChange = (index: number) => {
    setTabIndex(index);
    setCurrentPage(1);
  };
  const { VideoMp4, Mp4 } = SUPPORTED_FILES;
  return (
    <>
      <Tabs
        colorScheme={"purple"}
        onChange={(index) => onTabChange(index)}
        isLazy={true}
      >
        <TabList justifyContent={{ base: "center", md: "flex-start" }}>
          <div className={tabIndex === 0 ? "selected_tab" : ""}>
            <Tab
              fontSize="14px"
              pb="0px !important"
              _selected={{
                borderBottomWidth: "0px",
                boxShadow: "none",
                fontWeight: "600",
              }}
            >
              {Strings.instagram}
            </Tab>
          </div>
          <div className={tabIndex === 1 ? "selected_tab" : ""}>
            <Tab
              fontSize="14px"
              pb="0px !important"
              _selected={{
                borderBottomWidth: "0px",
                boxShadow: "none",
                fontWeight: "600",
              }}
            >
              {Strings.tikTok}
            </Tab>
          </div>
          <div className={tabIndex === 2 ? "selected_tab" : ""}>
            <Tab
              fontSize="14px"
              pb="0px !important"
              _selected={{
                borderBottomWidth: "0px",
                boxShadow: "none",
                fontWeight: "600",
              }}
            >
              {Strings.facebook}
            </Tab>
          </div>
          <div className={tabIndex === 3 ? "selected_tab" : ""}>
            <Tab
              fontSize="14px"
              pb="0px !important"
              _selected={{
                borderBottomWidth: "0px",
                boxShadow: "none",
                fontWeight: "600",
              }}
            >
              {Strings.twitter}
            </Tab>
          </div>
        </TabList>

        <TabPanels>
          {saleChannel?.map((el: any, index: number) => {
            return (
              <TabPanel pl={0} pr={0} key={index}>
                <Stack>
                  {platformInsights?.data?.length === 0 ||
                  (isBrand && !country) ? (
                    <Center>
                      {isBrand ? (
                        <Flex direction="column" alignItems="center">
                          <img src={NotFound} alt="NoDataFound" />
                          <Box fontSize="14px">{Strings.noDataFound}</Box>
                        </Flex>
                      ) : (
                        <Flex direction="column">
                          <img src={NoDataFound} alt="NoDataFound" />
                          <Box fontSize="14px">{Strings.noSale}</Box>
                          <Box fontSize="14px">{Strings.promoteSellEarn}</Box>
                        </Flex>
                      )}
                    </Center>
                  ) : (
                    <>
                      {isBrand ? (
                        <Flex
                          direction={"column"}
                          minHeight={{ md: "350", base: "100px" }}
                        >
                          {loading && !isScrollLoading ? (
                            <InsightsSkeleton />
                          ) : (
                            <InfiniteScroll
                              dataLength={platformInsights?.data?.length || 0}
                              next={() => {
                                setCurrentPage(currentPage + 1);
                              }}
                              hasMore={platformInsights?.nextLink}
                              loader={<LoadingSpinner />}
                            >
                              {platformInsights?.data?.map(
                                (el: any, index: number) => {
                                  return (
                                    <Box
                                      onClick={() => {
                                        navigateToProductDetails(
                                          el?.brandProductId
                                        );
                                      }}
                                      cursor={"pointer"}
                                      key={index}
                                    >
                                      <Flex
                                        mb={5}
                                        direction={{
                                          base: "column",
                                          md: "row",
                                        }}
                                        bgColor="white"
                                        shadow="md"
                                        p="20px"
                                        borderRadius={10}
                                      >
                                        <Flex
                                          alignItems="center"
                                          width={{
                                            md: "400px",
                                            base: "fit-content",
                                          }}
                                        >
                                          <Box
                                            width={"57px"}
                                            height={"57px"}
                                            mr={5}
                                          >
                                            {el.showCaseMediaUrls[0]?.includes(
                                              Mp4
                                            ) ? (
                                              <>
                                                <video
                                                  className="insights_product_image"
                                                  autoPlay
                                                  muted
                                                >
                                                  <source
                                                    src={
                                                      el.showCaseMediaUrls[0]
                                                    }
                                                    type={VideoMp4}
                                                  />
                                                </video>
                                              </>
                                            ) : (
                                              <ReactImageFallback
                                                fallbackImage={
                                                  <Skeleton className="insights_product_image" />
                                                }
                                                initialImage={
                                                  <Skeleton className="insights_product_image" />
                                                }
                                                src={el.showCaseMediaUrls[0]}
                                                alt={"Product"}
                                                className="insights_product_image"
                                              />
                                            )}
                                          </Box>
                                          <Box
                                            fontSize="18px"
                                            fontWeight="600"
                                            color={Colors.blackColor}
                                            width={{
                                              md: "250px",
                                              base: "fit-content",
                                            }}
                                          >
                                            <Box
                                              width={{
                                                md: "200px",
                                                base: "fit-content",
                                              }}
                                            >
                                              {el.brandProductName}
                                            </Box>
                                          </Box>
                                        </Flex>
                                        <SimpleGrid
                                          templateColumns={{
                                            md: "1fr 1fr 1fr 1fr",
                                            base: "repeat(2, 1fr)",
                                          }}
                                          width="-webkit-fill-available"
                                          spacingY={5}
                                          mt={{ md: "0px", base: "15px" }}
                                        >
                                          <Box fontSize="14px">
                                            <Box color={Colors.grey100}>
                                              {Strings.sales}
                                            </Box>
                                            <Box color={Colors.grey}>
                                              {withCommas(el.sales)}
                                            </Box>
                                          </Box>
                                          <Box fontSize="14px">
                                            <Box color={Colors.grey100}>
                                              {Strings.clicks}
                                            </Box>
                                            <Box color={Colors.grey}>
                                              {withCommas(el.clicks)}
                                            </Box>
                                          </Box>
                                          <Box fontSize="14px">
                                            <Box color={Colors.grey100}>
                                              {Strings.conversion}
                                            </Box>
                                            <Box color={Colors.grey}>
                                              {el.conversions}
                                            </Box>
                                          </Box>
                                          <Box fontSize="14px">
                                            <Box color={Colors.grey100}>
                                              {Strings.influencersInvolved}
                                            </Box>
                                            <Box color={Colors.grey}>
                                              {withCommas(
                                                el.influencersInvolved
                                              )}
                                            </Box>
                                          </Box>
                                        </SimpleGrid>
                                      </Flex>
                                    </Box>
                                  );
                                }
                              )}
                            </InfiniteScroll>
                          )}
                        </Flex>
                      ) : loading && !isScrollLoading ? (
                        <InsightsSkeleton influencerPlatformInsights={true} />
                      ) : (
                        <InfiniteScroll
                          className="insights_scroll_parent"
                          dataLength={platformInsights?.data?.length || 0}
                          next={() => {
                            setCurrentPage(currentPage + 1);
                          }}
                          hasMore={platformInsights?.nextLink}
                          loader={<LoadingSpinner />}
                        >
                          {platformInsights?.data?.map(
                            (el: any, index: number) => {
                              return (
                                <Box
                                  onClick={() => {
                                    navigateToProductDetails(
                                      el?.brandProductId
                                    );
                                  }}
                                  cursor={"pointer"}
                                  key={index}
                                >
                                  <InsightsTableRow
                                    productName={el.productName}
                                    currencySymbol={el.currencySymbol}
                                    productImage={el.showCaseMediaUrls[0]}
                                    currentInfluencerData={
                                      el.currentInfluencerData
                                    }
                                    allInfluencersAverageData={
                                      el.allInfluencersAverageData
                                    }
                                    topSellingInfluencerData={
                                      el.topSellingInfluencerData
                                    }
                                  />
                                </Box>
                              );
                            }
                          )}
                        </InfiniteScroll>
                      )}
                    </>
                  )}
                </Stack>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default TabPlatformInsights;
