import { Colors } from "@be-tagged/shared";
import styled from "styled-components";
import { DEVICE } from "../../assets/styles/styleEnums";
import {
  BtnArrowRight,
  FooterBackground,
} from "@be-tagged/shared/src/assets/icons";

interface ErrorMsgProps {
  bottom?: number;
}

const BottomColoredBar = styled.div<{ img?: string }>`
  background-image: ${`url(${FooterBackground})`};
  width: 100%;
  height: 35px;
  background-size: 50%;

  @media ${DEVICE.custom} {
    height: 22px;
    background-size: 100%;
  }
`;

const SelectionSplit = styled.section`
  @media ${DEVICE.custom} {
    flex-direction: column;
  }
`;

const SplitOption = styled.div`
  background-color: ${Colors.bgDark};

  @media ${DEVICE.customMin} {
    &:hover {
      background-color: ${Colors.themeColor};
    }
  }

  &:nth-of-type(2) {
    @media ${DEVICE.custom} {
      background-color: ${Colors.themeColor};
    }
  }

  &.selected {
    background-color: ${Colors.themeColor};
  }

  &.form {
    background-color: ${Colors.bgDark};
  }

  &:nth-of-type(1),
  &.form,
  &.selected {
    @media ${DEVICE.custom} {
      background-color: ${Colors.bgDark};
    }
  }
`;

const SplitOptionDarkBg = styled(SplitOption)`
  background-color: ${Colors.bgDark}!important;
`;

const CustomBtn = styled.button`
  background-color: ${Colors.purple};
  color: ${Colors.themeColor};

  &.inv {
    background-color: ${Colors.themeColor};
    color: ${Colors.purple};
  }
`;

const ButtonGroup = styled.div`
  margin-top: 30px;
  display: flex;

  .action_btn {
    @extend .purple_btn;
  }
`;

const Step = styled.div`
  background-color: ${Colors.themeColor};
  color: ${Colors.blue500}; // to be replaced with value from shared

  &.active {
    background-color: ${Colors.dollarGreen};
    color: ${Colors.themeColor};
  }
`;

const ArrowBtn = styled(CustomBtn)`
  &.left {
    background-image: url(${BtnArrowRight});
    transform: scaleX(-1);
  }

  &.right {
    transform: scale(1.2);
    background-image: url(${BtnArrowRight});
  }
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;
  color: ${Colors.blackColor};
`;

const ErrorMsg = styled.div<ErrorMsgProps>`
  position: absolute;
  font-size: 9px;
  color: red;
  bottom: ${({ bottom }) => (bottom ? bottom + "px" : "2px")};
`;
ErrorMsg.defaultProps = {
  bottom: -15,
};

export {
  BottomColoredBar,
  SelectionSplit,
  SplitOption,
  SplitOptionDarkBg,
  CustomBtn,
  ButtonGroup,
  Step,
  ArrowBtn,
  Label,
  ErrorMsg,
};
