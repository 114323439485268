import { Strings } from "@be-tagged/shared";

export const MOBILE_NUMBER_LENGTH = Strings.enterValidMobileNumber;
export const MOBILE_NUMBER_PRESENCE = Strings.enterMobileNumber;

export const MOBILE_NUMBER_VALIDITY = Strings.enterValidMobileNumber;

export const OTP_LENGTH = Strings.enterValidOTP;
export const OTP_PRESENCE = Strings.enterValidOTP;
export const OTP_VALIDITY = Strings.enterValidOTP;

export const COUNTRY_CODE_PRESENCE = Strings.enterCountryCode;

export const EMAIL_PRESENCE = Strings.enterValidEmail;

export const PASSWORD_LENGTH = Strings.enterValidPassword;
export const PASSWORD_PRESENCE = Strings.enterPassword;

export const ROLE_PRESENCE = Strings.enterRole;

export const YUP_VALIDATION = {
  MOBILE_NUMBER_LENGTH,
  MOBILE_NUMBER_PRESENCE,
  MOBILE_NUMBER_VALIDITY,
  OTP_LENGTH,
  OTP_PRESENCE,
  OTP_VALIDITY,
  PASSWORD_LENGTH,
  PASSWORD_PRESENCE,
  EMAIL_PRESENCE,
  COUNTRY_CODE_PRESENCE,
  ROLE_PRESENCE,
};
