import { useState, FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Flex, Link, Progress, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import {
  Colors,
  DiscountCodesSampleFileURL,
  logger,
  LoggerStrings,
  MIN_PERCENT_FOR_COUPON_UPLOAD,
  Strings,
  updateDiscountCodes,
  uploadSalesData,
} from "@be-tagged/shared";

import { FormikKeys } from "@be-tagged/shared/src/enums";

import { getFormattedLocalFromUTC } from "src/app/utils";
import { SUPPORTED_FILES } from "src/dashboard-module/interfaces";
import { DragDropComponent } from "../../ProductListing/HelperComponents";

interface DiscountCodeProps {
  totalCodes: number;
  usedCodes: number;
  productUnlisted?: boolean;
  productApprovalPending?: boolean;
  isBuyerDiscountZero?: boolean;
}

interface SalesDataUploadProps {
  brandProductId: number;
  prefix?: string;
  date: string;
  width?: any;
  productUnlisted?: boolean;
  isProductIncommDetails?: boolean;
  productApprovalPending?: boolean;
}

export const DiscountCodeUpload: FC<DiscountCodeProps> = ({
  totalCodes,
  usedCodes,
  productUnlisted,
  isBuyerDiscountZero,
}) => {
  const [discountFile, setDiscountFile] = useState([]);
  const { id } = useParams();

  const dispatch = useDispatch();
  const onDiscountFileChange = (fileURIs: string[]) => {
    dispatch(
      updateDiscountCodes({
        [FormikKeys.BrandProductID]: parseInt(id as string),
        [FormikKeys.DiscountCodesFileURL]: fileURIs?.[0],
      })
    );
  };
  const [clickedUpload, setClickedUpload] = useState(false);
  const couponsLeft = totalCodes - usedCodes;
  const percentSoFar = (couponsLeft / (totalCodes || 1)) * 100;
  const isLowOnCodes = percentSoFar <= MIN_PERCENT_FOR_COUPON_UPLOAD;
  const user = Strings.brand;
  useEffect(() => {
    if (clickedUpload) {
      logger.info(`${user} ${LoggerStrings.clickedOnUploadDiscountCode}`, {
        prodId: id,
      });
    }
  }, [clickedUpload]);

  return (
    <Box mb={"10px"}>
      <Box>
        <Text pb={"5px"}>{Strings.couponCodes}</Text>
        <Flex alignItems={"center"}>
          <Box w={"100px"} h={"fit-content"}>
            <Progress
              size={"sm"}
              borderRadius="full"
              colorScheme={isLowOnCodes ? "red" : "green"}
              value={percentSoFar}
            />
          </Box>
          <DragDropComponent
            disabled={!isLowOnCodes || productUnlisted || isBuyerDiscountZero}
            accept={SUPPORTED_FILES.ExcelFile}
            files={discountFile}
            setFiles={setDiscountFile}
            onChange={onDiscountFileChange}
            onlyChildren={true}
          >
            <Text
              as="span"
              fontSize={"12px"}
              lineHeight={1}
              pl={"10px"}
              color={Colors.purple}
              onClick={() => {
                setClickedUpload(true);
              }}
            >
              {Strings.upload}
            </Text>
          </DragDropComponent>
          <Text color={Colors.borderColor} as={"span"} mx={3}>
            |
          </Text>
          <Link
            href={DiscountCodesSampleFileURL}
            target="_blank"
            rel="noopener noreferrer"
            color={Colors.purple}
            fontSize={"12px"}
            fontWeight={"600"}
            boxShadow="none !important"
          >
            <Flex className="file_link">
              <Text as={"span"}>{Strings.sampleFile}</Text>
            </Flex>
          </Link>
        </Flex>
        <Text
          as="span"
          pos={"absolute"}
          fontWeight={"normal"}
          color={Colors.grey100}
        >
          {couponsLeft} {Strings.left}
        </Text>
      </Box>
    </Box>
  );
};

export const SalesDataUpload: FC<SalesDataUploadProps> = ({
  brandProductId,
  date = "",
  prefix = "",
  width = "auto",
  productUnlisted,
  productApprovalPending,
  isProductIncommDetails,
}) => {
  if (!brandProductId) return null;
  const [salesDataFile, setSalesDataFile] = useState([]);

  const dispatch = useDispatch();

  const onCouponFileChange = (fileURIs: string[]) => {
    dispatch(
      uploadSalesData({
        brandProductId,
        salesDataFileUrl: fileURIs[0],
      })
    );
  };
  const [clickedUpload, setClickedUpload] = useState(false);
  const user = Strings.brand;
  useEffect(() => {
    if (clickedUpload) {
      logger.info(`${user} ${LoggerStrings.clickedOnUploadSalesData}`, {
        prodId: brandProductId,
      });
    }
  }, [clickedUpload]);

  return (
    <Box mb={"10px"}>
      <Box>
        <Flex alignItems={"center"}>
          <Text
            width={width}
            fontSize={"inherit"}
            fontWeight={"inherit"}
            color={"inherit"}
          >
            {date
              ? prefix + getFormattedLocalFromUTC(date)
              : `(${Strings.yetToUpload})`}
          </Text>
          <DragDropComponent
            disabled={
              productUnlisted ||
              isProductIncommDetails ||
              productApprovalPending
            }
            accept={SUPPORTED_FILES.ExcelFile}
            files={salesDataFile}
            setFiles={setSalesDataFile}
            onChange={onCouponFileChange}
            onlyChildren={true}
          >
            <Text
              as="span"
              fontSize={"12px"}
              lineHeight={1}
              fontWeight={600}
              pl={"10px"}
              color={Colors.purple}
              onClick={() => {
                setClickedUpload(true);
              }}
            >
              {Strings.upload}
            </Text>
          </DragDropComponent>
        </Flex>
      </Box>
    </Box>
  );
};
