import {
  Avatar,
  Box,
  Checkbox,
  Flex,
  Link,
  SimpleGrid,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { Colors, Strings } from "@be-tagged/shared";
import { getFormattedLocalFromUTC, withCommas } from "src/app/utils";
import { PossibleRequestStates } from "@be-tagged/shared/src/enums";
import { CustomBtn } from "src/app/components";
import {
  FacebookIcon,
  Influencer1,
  InstagramIcon,
  SadFace,
  SmileFace,
  TikTokIcon,
  TwitterIcon,
} from "@be-tagged/shared/src/assets/icons";
import { useState } from "react";
import { CommonObjectType } from "src/app/constants";
import { FC } from "react";
import { useContext } from "react";
import { InfluencerRequestContext } from "src/app/contexts/InfluencerRequestContext";

interface ProductRequestRow {
  productRequest: CommonObjectType;
}

interface InfluencerRequestsProps {
  reqArr: any[];
  influencerId: number;
}

interface PromotionRequestProps {
  influencerRequest: CommonObjectType;
  onCheckboxToggle: (x?: any) => void;
  isSelected: boolean;
}

const ProductRequestRow: FC<ProductRequestRow> = ({ productRequest }) => {
  const {
    profilePicUrl = "",
    influencerId,
    influencerName = "",
    productsSold = "",
    requestedOnUtc = "",
    requests: influencerRequests = [], // these are the influencer requests
  } = productRequest;

  return (
    <Box className="request_row" borderColor={Colors.borderColor}>
      <Flex
        direction={{
          base: "column",
          md: "row",
        }}
        pt={5}
        pl={{ md: "30px" }}
        pb="10px"
      >
        <Flex className="inf_name">
          <Box w="60px" h="60px">
            <Avatar
              size={"sm"}
              src={profilePicUrl}
              style={{
                width: "60px",
                height: "60px",
                objectFit: "cover",
                borderRadius: "100px",
              }}
            />
          </Box>
          <Flex direction={"column"}>
            <Box h="70px" pl={2}>
              <p
                className="text"
                style={{
                  color: Colors.purple,
                }}
              >
                {influencerName}
              </p>
              <p className="gray-text">
                {Strings.productsSold}{" "}
                <span
                  style={{
                    color: Colors.blackColor,
                    fontSize: "14px",
                  }}
                >
                  - {productsSold}
                </span>
              </p>
              <p className="gray-text" style={{ fontWeight: "normal" }}>
                {Strings.interestExpressedOn} -{" "}
                {getFormattedLocalFromUTC(requestedOnUtc)}
              </p>
            </Box>
          </Flex>
        </Flex>
        <InfluencerRequests
          influencerId={influencerId}
          reqArr={influencerRequests}
        />
        <Spacer />
      </Flex>
    </Box>
  );
};

const InfluencerRequests: FC<InfluencerRequestsProps> = ({
  reqArr,
  influencerId,
}) => {
  const [selectedRequests, setSelectedRequests] = useState<any[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const influencerContext = useContext(InfluencerRequestContext);

  const clearSelectedRequests = () => {
    setSelectedRequests([]);
  };
  const handleActionClick = (_action: PossibleRequestStates) => {
    const salesChannel = selectedRequests.map((id) => {
      return reqArr.find((x: CommonObjectType) => x.promotionRequestId === id)
        .salesChannel;
    });
    const obj = {
      influencerId,
      showModal: true,
      action: _action,
      requestIds: selectedRequests,
      salesChannel,
      clearSelectedRequests: clearSelectedRequests,
    };
    influencerContext?.setData?.(obj);
  };

  const onSelectAllClick = () => {
    if (!isAllSelected) {
      const allPendingIds = reqArr
        .filter((el) => el.approvalStatus == PossibleRequestStates.Pending)
        .map((el) => el.promotionRequestId);
      setSelectedRequests(allPendingIds);
    } else {
      setSelectedRequests([]);
    }
    setIsAllSelected(!isAllSelected);
  };

  const onCheckboxToggle = (reqId: number, currentIndex: number) => {
    const _selected = [...selectedRequests];
    if (currentIndex > -1) {
      _selected.splice(currentIndex, 1);
      setIsAllSelected(false);
    } else {
      _selected.push(reqId);
    }
    const allPendingIds = reqArr.filter(
      (el) => el.approvalStatus == PossibleRequestStates.Pending
    )?.length;
    if (allPendingIds === _selected.length) {
      setIsAllSelected(true);
    }
    setSelectedRequests(_selected);
  };

  const isPendingRequestAvailable = () => {
    return reqArr.find(
      (el) => el.approvalStatus == PossibleRequestStates.Pending
    );
  };
  return (
    <Flex className="inf_req_container">
      <Flex className="checkboxes">
        {isPendingRequestAvailable() && (
          <Box className="sm_cb_select">
            <Checkbox
              onChange={onSelectAllClick}
              className="label"
              colorScheme="pink"
              mr={2}
              isChecked={isAllSelected}
            >
              <Text as="span" className="label">
                {Strings.selectAll}
              </Text>
            </Checkbox>
          </Box>
        )}
        <Box className="reqs">
          {reqArr?.map((infReq: any) => {
            const reqId = infReq?.promotionRequestId;
            const indexAt = selectedRequests.findIndex((el) => el === reqId);
            return (
              <PromotionRequest
                key={reqId}
                influencerRequest={infReq}
                onCheckboxToggle={() => onCheckboxToggle(reqId, indexAt)}
                isSelected={indexAt > -1}
              />
            );
          })}
        </Box>
      </Flex>
      <Flex className="req_action_btns">
        <CustomBtn
          disabled={!selectedRequests.length}
          onClick={() => handleActionClick(PossibleRequestStates.Rejected)}
          className="purple_btn medium reject_btn inv"
        >
          {Strings.reject}
        </CustomBtn>
        <CustomBtn
          disabled={!selectedRequests.length}
          onClick={() => handleActionClick(PossibleRequestStates.Approved)}
          className="purple_btn medium accept_btn"
        >
          {Strings.accept}
        </CustomBtn>
      </Flex>
    </Flex>
  );
};

const PromotionRequest: FC<PromotionRequestProps> = ({
  influencerRequest,
  onCheckboxToggle,
  isSelected,
}) => {
  const {
    promotionRequestId = "",
    salesChannel = "",
    approvalStatus = "",
    actedOnUtc,
    isRejectedDueToPeriodExpiration,
    socialMediaHandleUrl,
  } = influencerRequest;

  const getSocialMediaIcon = (item: string) => {
    switch (item) {
      case Strings.facebook:
        return FacebookIcon;
      case Strings.instagram:
        return InstagramIcon;
      case Strings.twitter:
        return TwitterIcon;
      case Strings.tikTok:
        return TikTokIcon;
    }
  };
  const returnSmileyOrCheckbox = () => {
    const channelAndFollowers = (
      <>
        <Text as="span" className="label">
          {salesChannel}{" "}
        </Text>
      </>
    );
    switch (approvalStatus) {
      case PossibleRequestStates.Approved:
        return (
          <Flex className="status_container">
            <img src={SmileFace} alt="Approved" className="emoji" />
            <div>
              <Flex>
                <div>{channelAndFollowers}</div>
                <Link
                  href={socialMediaHandleUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  boxShadow="none !important"
                  marginLeft={"8px"}
                >
                  <img
                    src={getSocialMediaIcon(salesChannel)}
                    alt="fb_icon"
                    width="15px"
                  />
                </Link>
              </Flex>
              <Box className="action_subtext" color={Colors.grey100}>
                {Strings.approvedOn} {getFormattedLocalFromUTC(actedOnUtc)}
              </Box>
            </div>
          </Flex>
        );
      case PossibleRequestStates.Rejected:
        return isRejectedDueToPeriodExpiration ? (
          <Flex className="status_container">
            <img src={SadFace} alt="Rejected" className="emoji" />
            <div>
              <div style={{ color: Colors.grey100 }}>{channelAndFollowers}</div>
              <Link
                href={socialMediaHandleUrl}
                target="_blank"
                rel="noopener noreferrer"
                boxShadow="none !important"
              >
                <img
                  src={getSocialMediaIcon(salesChannel)}
                  alt="fb_icon"
                  width="15px"
                />
              </Link>
              <Box className="action_subtext" color={Colors.grey100}>
                {Strings.rejectedAutomatically}
              </Box>
            </div>
          </Flex>
        ) : (
          <Flex className="status_container">
            <img src={SadFace} alt="Rejected" className="emoji" />
            <div>
              <Flex>
                <div>{channelAndFollowers}</div>
                <Link
                  href={socialMediaHandleUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  boxShadow="none !important"
                  marginLeft={"8px"}
                >
                  <img
                    src={getSocialMediaIcon(salesChannel)}
                    alt="fb_icon"
                    width="15px"
                  />
                </Link>
              </Flex>
              <Box className="action_subtext" color={Colors.grey100}>
                {Strings.rejectedOn} {getFormattedLocalFromUTC(actedOnUtc)}
              </Box>
            </div>
          </Flex>
        );
      case PossibleRequestStates.Pending:
        return (
          <>
            <Checkbox
              key={promotionRequestId}
              className="label"
              colorScheme="pink"
              mr={2}
              isChecked={isSelected}
              onChange={onCheckboxToggle}
            >
              {channelAndFollowers}
            </Checkbox>
            <Link
              href={socialMediaHandleUrl}
              target="_blank"
              rel="noopener noreferrer"
              boxShadow="none !important"
            >
              <img
                src={getSocialMediaIcon(salesChannel)}
                alt="fb_icon"
                width="15px"
              />
            </Link>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <Flex className="promotion_req">
      <Flex>{returnSmileyOrCheckbox()}</Flex>
    </Flex>
  );
};

export default ProductRequestRow;
