import { getCitiesURL } from "../../../utils/UrlBuilder";
import { AsyncQueryParams, FormikKeys, HTTPMethod } from "../../../enums";
import { fetchAsync } from "../GlobalActions/App.actions";
import {
  UPDATE_CITIES_LIST,
  UPDATE_LOADING_CITIES_STATUS,
} from "../../constants/ReduxConstants";

// To get the cities
export const getCities =
  (params: AsyncQueryParams) => async (dispatch: any) => {
    dispatch(updateCitiesLoadingStatus(true));
    const request = {
      method: HTTPMethod.GET,
      queryStringParams: {
        [FormikKeys.ListQty]: params[FormikKeys.ListQty] || 10,
        ...params,
      },
      url: getCitiesURL(),
    };
    return fetchAsync(dispatch, [request], [updateCitiesList]);
  };

const updateCitiesLoadingStatus = (payload: boolean) => ({
  type: UPDATE_LOADING_CITIES_STATUS,
  payload,
});

export const updateCitiesList = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(updateCitiesLoadingStatus(false));
    return dispatch({
      type: UPDATE_CITIES_LIST,
      payload: payload?.data?.data || [],
    });
  };
};
