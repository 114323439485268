import {
  clearProductLeaderboard,
  Colors,
  getLeaderboardInsights,
  getProductLeaderboard,
  RootState,
  Strings,
} from "@be-tagged/shared";
import {
  FacebookIcon,
  HelpDeskIcon,
  Influencer1,
  InstagramIcon,
  MentosIcon,
  NotFound,
  RibbonIcon,
  TikTokIcon,
  TwitterIcon,
} from "@be-tagged/shared/src/assets/icons";
import {
  FormikKeys,
  LeaderboardParams,
  SocialMedia,
} from "@be-tagged/shared/src/enums";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Center,
  Divider,
  Flex,
  SimpleGrid,
  useMediaQuery,
} from "@chakra-ui/react";
import { mockData, rank } from "./mockData";
import { FC, useEffect, useState } from "react";
import { DEVICE } from "src/app/assets/styles/styleEnums";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "src/app/components/LoadingSpinner";
import useData from "src/app/hooks/useData";
import { CommonObjectType } from "src/app/constants";
import { withCommas } from "src/app/utils";
const PER_PAGE = 10;
interface ITabLeaderboard {
  brandProductId: any;
}
const details: { [k: string]: any } = {
  [FormikKeys.TikTok]: {
    name: Strings.tikTok,
    icon: TikTokIcon,
  },
  [FormikKeys.Facebook]: {
    name: Strings.facebook,
    icon: FacebookIcon,
  },
  [FormikKeys.Instagram]: {
    name: Strings.instagram,
    icon: InstagramIcon,
  },
  [FormikKeys.Twitter]: {
    name: Strings.twitter,
    icon: TwitterIcon,
  },
};
const { StartFrom, ListQty } = FormikKeys;

/**
 * @returns {JSX.Element} - A React Functional Component
 */
const TabLeaderboard: FC<ITabLeaderboard> = ({
  brandProductId,
}): JSX.Element => {
  const [isSmallerScreen] = useMediaQuery(DEVICE.tablet);
  const [currentPage, setCurrentPage] = useState(1);
  const [isScrollLoading, setIsScrollLoading] = useState(false);
  const { loading } = useData();
  const dispatch = useDispatch();
  const productLeaderboard = useSelector((state: RootState) => {
    return state.influencerReducer?.productLeaderboard || {};
  });
  const insights = useSelector((state: RootState) => {
    return state.influencerReducer?.productLeaderboard?.insights || {};
  });
  const getProductLeaderboardData = (par: any, viaScroll: boolean = false) => {
    let params: CommonObjectType = {
      ...par,
    };
    let finalPayload = {
      ...params,
    } as LeaderboardParams;
    dispatch(
      getProductLeaderboard(
        finalPayload,
        viaScroll ? setIsScrollLoading : undefined
      )
    );
  };
  const fetchProductLeaderboard = (viaScroll: boolean = false) => {
    getProductLeaderboardData(
      {
        brandProductId: brandProductId,
        [StartFrom]: currentPage * PER_PAGE - PER_PAGE,
        [ListQty]: PER_PAGE,
      },
      viaScroll
    );
  };
  useEffect(() => {
    dispatch(getLeaderboardInsights(brandProductId));
  }, []);
  useEffect(() => {
    fetchProductLeaderboard(currentPage !== 1);
  }, [currentPage]);
  useEffect(() => {
    return () => {
      dispatch(clearProductLeaderboard());
    };
  }, []);
  return (
    <Flex direction="row">
      <Box>
        <Flex
          direction={"column"}
          maxHeight={{ md: "500" }}
          overflowY={{ md: "scroll" }}
          width={{ md: "52vw", base: "fit-content" }}
        >
          {isSmallerScreen && (
            <>
              <Flex
                bgColor={Colors.bgLight}
                p={2}
                mb={4}
                boxShadow="0px 3px 30px rgba(153, 186, 208, 0.3)"
                borderRadius="8px"
              >
                <img src={MentosIcon} alt="Tip" height="32px" width="27px" />
                <Box fontSize="12px" pl={4}>
                  {Strings.topSocialMedia}
                </Box>
              </Flex>
              <Accordion allowToggle mb={5} bgColor={Colors.bgLight}>
                <AccordionItem borderTopWidth="0px" borderBottomWidth="0px">
                  <h2>
                    <AccordionButton
                      className="accordion"
                      style={{ outline: "none" }}
                      boxShadow="0px 3px 30px rgba(153, 186, 208, 0.3)"
                      py={5}
                      bgColor="white"
                      borderTopRadius="8px"
                      borderBottomRadius="8px"
                      _expanded={{ borderBottomRadius: "0px" }}
                    >
                      <Flex flex={1}>
                        <img src={RibbonIcon} alt="Rank" />
                        <Box fontSize="16px" fontWeight="600" pl={1}>
                          {Strings.yourRank} : {insights?.position}&nbsp;
                        </Box>
                      </Flex>
                      <AccordionIcon color={Colors.purple} />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    backgroundColor="white"
                    borderBottomRadius="8px"
                  >
                    <Flex direction="column">
                      {Object.keys(rank.socialMedia || {})?.map(function (key) {
                        return (
                          <Flex alignItems="flex-start" key={key} mb={4}>
                            <img
                              src={details?.[key]?.icon}
                              alt="Social Media"
                              width="24px"
                              height="24px"
                            />
                            <Flex direction="column" ml={4}>
                              <Box fontSize="14px" fontWeight="600" mb={1}>
                                {details?.[key]?.name}
                              </Box>
                              <Flex
                                fontSize="12px"
                                columnGap={2}
                                flexWrap="wrap"
                              >
                                <Box
                                  borderRightWidth="2px"
                                  borderColor={Colors.grey100}
                                  height="15px"
                                  pr={3}
                                >
                                  {Strings.sales} :{" "}
                                  {insights?.socialMediaDetails?.[key].sales}
                                </Box>
                                <Box
                                  borderRightWidth="2px"
                                  borderColor={Colors.grey100}
                                  height="15px"
                                  pr={3}
                                  ml={1}
                                >
                                  {Strings.clicks} :{" "}
                                  {insights?.socialMediaDetails?.[key].clicks}
                                </Box>
                                <Box ml={1}>
                                  {Strings.conversion} :&nbsp;
                                  {Math.round(
                                    insights?.socialMediaDetails?.[key]
                                      .conversions * 10
                                  ) / 10}
                                </Box>
                              </Flex>
                            </Flex>
                          </Flex>
                        );
                      })}
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </>
          )}
          {loading && !isScrollLoading ? (
            <LoadingSpinner />
          ) : (
            <InfiniteScroll
              dataLength={productLeaderboard?.data?.length || 0}
              next={() => {
                setCurrentPage(currentPage + 1);
              }}
              hasMore={productLeaderboard?.nextLink}
              loader={<LoadingSpinner />}
            >
              {productLeaderboard?.data?.data?.length === 0 ? (
                <Center>
                  <Flex direction="column" alignItems="center">
                    <img src={NotFound} alt="NoDataFound" />
                    <Box fontSize="16px" mt={4}>
                      {Strings.noDataFound}
                    </Box>
                  </Flex>
                </Center>
              ) : (
                productLeaderboard?.data?.data?.map(
                  (el: any, index: number) => {
                    return (
                      <>
                        <Flex
                          mt={5}
                          mb={5}
                          direction={{
                            base: "column",
                            md: "row",
                          }}
                          flexWrap="wrap"
                          rowGap={5}
                        >
                          <Box>
                            <Flex width={{ md: "280px", base: "fit-content" }}>
                              <Box w="80px" h="80px">
                                <Avatar
                                  size={"sm"}
                                  src={el.influencerProfilePicUrl}
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    objectFit: "cover",
                                    borderRadius: "100px",
                                  }}
                                />
                              </Box>

                              <Flex
                                direction={"column"}
                                pl={5}
                                justifyContent="space-evenly"
                                width={{ md: "180px", base: "fit-content" }}
                              >
                                <p
                                  className="purple-text"
                                  style={{ fontSize: "14px" }}
                                >
                                  {el.name}
                                </p>
                                <p className="title">
                                  <span style={{ color: Colors.grey100 }}>
                                    {Strings.sales} -{" "}
                                  </span>
                                  <span style={{ fontSize: "14px" }}>
                                    {withCommas(el.sales)}
                                  </span>
                                </p>
                                <p className="title">
                                  <span style={{ color: Colors.grey100 }}>
                                    {Strings.commission}
                                    &nbsp;:&nbsp;
                                  </span>
                                  <span style={{ fontSize: "14px" }}>
                                    {withCommas(el.commission)}
                                  </span>
                                </p>
                              </Flex>
                            </Flex>
                          </Box>
                          <SimpleGrid
                            templateColumns="repeat(2, 1fr)"
                            templateRows={{ md: "repeat(2, 1fr)" }}
                            pt={{ md: "0", base: "5" }}
                            spacingX={{
                              base: "10",
                              md: "10",
                            }}
                            spacingY={{
                              base: "0",
                              md: "5",
                            }}
                          >
                            {el?.salesChannelData?.map(
                              (ele: any, index: number) => {
                                return (
                                  <Flex
                                    key={index}
                                    direction={"column"}
                                    justifyContent="space-evenly"
                                    width={"max-content"}
                                    pb={{ md: "0", base: "5" }}
                                  >
                                    <Flex
                                      alignItems={{
                                        md: "flex-end",
                                        base: "flex-start",
                                      }}
                                      width={{
                                        md: "180px",
                                        base: "fit-content",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={
                                            ele.salesChannel ===
                                            SocialMedia.TikTok
                                              ? details?.[FormikKeys.TikTok]
                                                  ?.icon
                                              : details?.[
                                                  ele.salesChannel.toLowerCase()
                                                ]?.icon
                                          }
                                          alt="Platform"
                                          width="24px"
                                          height="24px"
                                        />
                                      </div>
                                      <Flex
                                        direction={{
                                          md: "row",
                                          base: "column",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          {ele.salesChannel ===
                                          SocialMedia.TikTok
                                            ? details?.[FormikKeys.TikTok]?.name
                                            : details?.[
                                                ele.salesChannel.toLowerCase()
                                              ]?.name}
                                          &nbsp;
                                        </p>
                                        {!isSmallerScreen && (
                                          <Box
                                            fontSize="14px"
                                            fontWeight="600"
                                            color={Colors.grey100}
                                          >
                                            {" "}
                                            :&nbsp;
                                          </Box>
                                        )}
                                        <Box
                                          fontSize="14px"
                                          fontWeight="600"
                                          color={Colors.grey100}
                                          ml={{ base: "10px", md: "0px" }}
                                        >
                                          {withCommas(ele.sale)}&nbsp;
                                          {Strings.sales}
                                        </Box>
                                      </Flex>
                                    </Flex>
                                  </Flex>
                                );
                              }
                            )}
                          </SimpleGrid>
                        </Flex>
                        <Divider
                          orientation="horizontal"
                          borderBottomWidth="2px"
                          borderTopWidth="0px"
                        />
                      </>
                    );
                  }
                )
              )}
            </InfiniteScroll>
          )}
        </Flex>
      </Box>
      {!isSmallerScreen && (
        <>
          <Box>
            <Divider
              orientation="vertical"
              borderRightWidth="3px"
              borderLeftWidth="0px"
              height="500px"
              ml={1}
            />
          </Box>
          <Box>
            <Flex ml={3} direction="column">
              <Flex mb={8} flexWrap="wrap">
                <img src={RibbonIcon} alt="Rank" />
                <Box fontSize="16px" fontWeight="600" pl={1}>
                  {Strings.yourRank} : {withCommas(insights?.position)}&nbsp;
                </Box>
                <Box>
                  ({Strings.outOf} {withCommas(insights?.totalInfluencers)}{" "}
                  {Strings.influencers})
                </Box>
              </Flex>
              {Object.keys(insights?.socialMediaDetails || {})?.map(function (
                key
              ) {
                return (
                  <Flex alignItems="flex-start" key={key} mb={4}>
                    <img
                      src={details?.[key]?.icon}
                      alt="Social Media"
                      width="24px"
                      height="24px"
                    />
                    <Flex direction="column" ml={4}>
                      <Box fontSize="14px" fontWeight="600" mb={1}>
                        {details?.[key]?.name}
                      </Box>
                      <Flex fontSize="14px" columnGap={2} flexWrap="wrap">
                        <Box
                          borderRightWidth="2px"
                          borderColor={Colors.grey100}
                          height="15px"
                          pr={3}
                        >
                          {Strings.sales} :{" "}
                          {withCommas(
                            insights?.socialMediaDetails?.[key].sales
                          )}
                        </Box>
                        <Box
                          borderRightWidth="2px"
                          borderColor={Colors.grey100}
                          height="15px"
                          pr={3}
                          ml={1}
                        >
                          {Strings.clicks} :{" "}
                          {withCommas(
                            insights?.socialMediaDetails?.[key].clicks
                          )}
                        </Box>
                        <Box ml={1}>
                          {Strings.conversion} :&nbsp;
                          {Math.round(
                            insights?.socialMediaDetails?.[key].conversions * 10
                          ) / 10}
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                );
              })}
              <Flex
                bgColor={Colors.bgLight}
                px={3}
                py={2}
                mt={4}
                boxShadow="0px 3px 30px rgba(153, 186, 208, 0.3)"
                borderRadius="8px"
              >
                <img src={MentosIcon} alt="Tip" height="32px" width="27px" />
                <Box fontSize="12px" pl={4}>
                  {Strings.topSocialMedia}
                </Box>
              </Flex>
              {/* Book Now Option Used To be Here */}
            </Flex>
          </Box>
        </>
      )}
    </Flex>
  );
};

export default TabLeaderboard;
