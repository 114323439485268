import { FC } from "react";
import { Box } from "@chakra-ui/react";
import { Outlet, Route, Routes } from "react-router-dom";

// Custom Components
import { FooterBackground } from "@be-tagged/shared/src/assets/icons";
import { Colors } from "@be-tagged/shared";

// Child Components
import SelectionOption from "../../common/SelectionOption";
import { BottomColoredBar, SelectionSplit } from "../../../app/components";
import UserLayout from "../../layout/UserLayout";

import { ROLE_TYPES } from "../../../app/enums";
import { ROUTING_URLS } from "../../../app/constants";

// Component-level Styles
import "./intro.scss";

/**
 * Renders a selection screen (Brand / Influencer) which will enable a user to either login or register
 * @returns {JSX.Element} - A React functional component
 */
const SelectionLayout: FC<{}> = (): JSX.Element => {
  return (
    <Box
      bg={{ lg: Colors.themeColor, base: Colors.bgDark }}
      className="container"
    >
      <SelectionSplit className="selection_split">
        <Routes>
          {/* Route index will be rendered when no additional path is present */}
          <Route
            index
            element={
              <>
                <SelectionOption roleType={ROLE_TYPES.Brand} />
                <SelectionOption roleType={ROLE_TYPES.Influencer} />
              </>
            }
          />
          {/* Load the Brand Layout when brand is present in the path (i.e., brand is selected) */}
          <Route
            path={`${ROUTING_URLS.BRAND}/*`}
            element={<UserLayout roleType={ROLE_TYPES.Brand} />}
          />
          {/* Load the Influencer Layout when influencer is present in the path (i.e., influencer is selected) */}
          <Route
            path={`${ROUTING_URLS.INFLUENCER}/*`}
            element={<UserLayout roleType={ROLE_TYPES.Influencer} />}
          />
        </Routes>
        <Outlet />
      </SelectionSplit>
      <BottomColoredBar img={FooterBackground} />
    </Box>
  );
};

export default SelectionLayout;
