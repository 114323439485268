import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import SentryLogTransportBase from "@be-tagged/shared/src/services/Logging/SentryLogTransportBase";

export class SentryLogTransportWeb extends SentryLogTransportBase {
  client = Sentry;

  init = () => {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      release: process.env.REACT_APP_ENV_VERSION || "1.0.0",
      environment: process.env.REACT_APP_ENV_NAME || "production",
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  };
}
